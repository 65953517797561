import register_girll_img from "../Assets/LandingPage/register_girl_img.png";
import React from "react";
import green_tick_img from "../Assets/LandingPage/green_tick.png";

function Responsive() {
  return (
    <div>
      <div id="responsive" align="center">
        <div id="inner_dis_size" className="container-fluid">
          <div id="reg_outer">
            <div className="row">
              <div className="col-md-6 p-0 m-0">
                {/* <div className="p-0 m-0"> */}
                  <img
                    src={register_girll_img}
                    alt=""
                    width="100%"
                    height="100%"style={{objectFit:"cover", backgroundSize:"cover"}}
                  />
                {/* </div> */}
              </div>
              <div className="col-md-6 p-0 m-0" align="left">
                <div id="reg_form_text">
                  Register
                  <br /> <span id="for_webinar">For Free Webinar</span>
                </div>{" "}
                <div id="reg_points" className="d-flex">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={green_tick_img} alt="" width="100%" />
                    </div>
                    <div>Access to Expert Knowledge</div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ marginLeft: "23px" }}
                  >
                    <div>
                      <img src={green_tick_img} alt="" width="100%" />
                    </div>
                    <div>Interactive Learning Experience</div>
                  </div>
                </div>
                <div id="reg_points" className="d-flex">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={green_tick_img} alt="" width="100%" />
                    </div>
                    <div>Cost-Effective Learning</div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ marginLeft: "61px" }}
                  >
                    <div>
                      <img src={green_tick_img} alt="" width="100%" />
                    </div>
                    <div>Skill Enhancement</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Responsive;
