import React from "react";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
// import {AiFillPlayCircle,AiOutlineRight} from "react-icons/ai";
import brud_crumbs_arrow_icon from "../Assets/brudcrums_arrow_icon.svg";
import machine_learning_blog_img from "../Assets/machine_learning_img.avif";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import blog_details2_img from "../Assests/Images/blog_details2_img.png";
const MachineLearningBlogDetail = () => {
  return (
    <div>
      <Header />
      <div className="py-3" style={{ background: "#FAFAFB" }}>
        <div className="container">
          <div className="d-flex align-items-center mt-5 ">
            <div className="d-flex mt-5 align-items-center">
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: "#065BD6",
                  lineHeight: "16px",
                }}
              >
                <a href="/ezine" className="text-decoration-none">EZine</a> 
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <LazyLoadImage src={brud_crumbs_arrow_icon} width="100%"></LazyLoadImage>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#07152C",
                }}
              >
                {" "}
                Machine Learning...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex py-3 mt-5">
          <div className="">
            <div
              className=""
              style={{
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                lineHeight: "16px",
                background: "#07152C",
                opacity: "100%",
                bottom: "12px",
                left: "20px",
                Zindex: "3",
                padding: "4px 12px",
                borderRadius: "12px",
              }}
            >
              Technology
            </div>
          </div>
          <div
            className="d-flex my-auto px-2"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#6A7380",
            }}
          >
            <div>29 AUG 2022</div>
            <div
              style={{
                paddingLeft: "8px",
                fontSize: "2rem",
                marginTop: "-5%",
              }}
            >
              .
            </div>
            <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 ">
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "50px",
                  lineHeight: "60px",
                  marginTop: "36px",
                }}
              >
                Difference Between Machine Learning and Artificial Intelligence
              </div>
            </div>
            <div className="mt-5">
              <LazyLoadImage
                src={machine_learning_blog_img}
                className="w-100"
                alt="blog details image"
              />
            </div>
            <div className="py-3"></div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              These are the current ‘trendy’ buzzwords in the market –
              Artificial Intelligence and Machine Learning. While both of them
              are used interchangeably, they are slightly disparate terms and
              technologies. The field of AI has seen tremendous advancements
              even as of 2018 and is already starting to influence our daily
              lives. Machine learning, on the other hand, uses algorithms or
              mathematical models to make an inference. If we were to further
              break down machine learning, you will find another embedded
              technology known as Deep Learning.{" "}
            </div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Broadly defined, Artificial Intelligence can adapt, act, sense,
              and even reason, while machine learning is about performance
              improvement when the algorithm is exposed to an environment
              constantly. At times, it is not surprising to find companies
              proclaiming AI when they are not even clear about their product’s
              limitations. Recently, a study revealed misuse where companies
              have claimed the use of AI but were not even close to using the
              technology. There is still a lot of confusion revolving around AI
              and ML.{" "}
            </div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              NYINST, one of the leading software training institutes in
              Chennai, aims to dissipate all doubts between machine learning and
              artificial intelligence. We provide some of the most comprehensive
              courses that expose students to the latest advances in AI and ML.
            </div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              The tech industry is so abuzz with the two terms that in 2014,
              according to Bloomberg, at least $300 million was invested in AI
              start-ups, which is also a 300% increase from the previous year.
              Scientists are working hard to inculcate machines with intelligent
              behavior thus trying to facilitate smart responses to real-time
              situations. Very slowly, yet steadily, AI is growing from being a
              mere study to mainstream technology. Even early stages enterprise
              adoption is visualized, with tech giants such as Google and
              Facebook, placing their bets on AI.{" "}
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                ABOUT ARTIFICIAL INTELLIGENCE
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              John McCarthy, a researcher at Stanford defines artificial
              intelligence as the engineering of building intelligent machines
              and particularly intelligent programs. In other words, computers
              are used to understand and mimic human intelligence and do not
              necessarily involve tasks that are observed biologically. While AI
              aims to imitate human behavior, one of the integral parts of AI
              research is knowledge engineering. A relationship between objects,
              properties, and categories is established by AI using
              problem-solving, common sense and analytical reasoning, which
              could ideally be very tedious.{" "}
            </div>

            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Types of AI
              </div>
              <div
                className="mt-3"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                AI is of two types – Vertical and Horizontal
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Vertical AI bots perform only one task such as automating a
                repetitive work or even scheduling a meeting. Where humans tend
                to make a mistake in these, AI leaves little room for error.
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Unlike Vertical AI, Horizontal AI is capable of multi-tasking.
                Some good examples of Horizontal AI are Cortana, Siri, and
                Alexa. These services can be used widely through question and
                answer settings or any other process practically, without having
                it confined to just a singular job. Horizontal AIs are designed
                after a comprehensive analysis of the human brain after which,
                complex algorithms are used to perform analogous human tasks. A
                decision system is created that can learn, adapt and recommend a
                set of actions that have to be taken, automatically.
              </div>
            </div>
            <div className="mt-5"
              style={{
                fontWeight: "700",
                fontSize: "31px",
                lineHeight: "40px",
              }}
            >
              MACHINE LEARNING
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Tom M. Mitchell a computer scientist and pioneer of machine
              learning defines it as a study of algorithms that let computer
              programs improve automatically through experience. It means that
              machine learning designs and applies algorithms developed through
              the knowledge accumulated from past cases. Some tough issues such
              as detecting credit card frauds, face recognition, enabling
              self-driven cars, etc. are resolved through machine learning. ML
              constitutes of three major areas:
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontWeight: "700",
                fontSize: "31px",
                lineHeight: "40px",
              }}
            >
              Supervised learning
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Training data sets provided to the system are analyzed by
              supervised learning algorithms that then produce an incidental
              function. Newer examples can be mapped based on the conclusion
              derived
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontWeight: "700",
                fontSize: "31px",
                lineHeight: "40px",
              }}
            >
              Unsupervised learning
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Instead of data sets, unclustered data is fed and the machine is
              allowed to learn on its own without any supervision. This is much
              harder due to the complexity of data insertion, which might even
              leave a problem unresolved with no inferences derived.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontWeight: "700",
                fontSize: "31px",
                lineHeight: "40px",
              }}
            >
              Reinforcement learning
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Software agents and machines automatically determine the ideal
              context to maximize performance. Not characterized by learning
              methods but rather by learning problems, reinforcement learning
              will choose any method best suited to resolve an issue.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              AI and Machine Learning are subjects of utmost interest today, due
              to their progressive nature.  At NYINST, we teach you to challenge
              common misconceptions that revolve around AI. You will study and
              implement roadmaps for strategically implementing AI technology,
              and manage AI. Get a GTM certification along with hands-on
              industry experience in this quickly evolving field, from our
              experts.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontWeight: "700",
                fontSize: "31px",
                lineHeight: "40px",
              }}
            >
              NYINST
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              You get to work on live projects while being able to learn all the
              key concepts through industry experts and knowledgeable
              instructors who help you derive real-world results. Get a
              customized learning experience that will develop not just your
              skills, but your personality as well.
            </div>
            <div
              className="mt-3 mobile_para_font mb-5"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
             Our NYINST is specifically designed to address emerging technologies and prepare the student for an expanded future.
            </div>
          </div>
          {/* <div className='col-md-3'>Enquiry Now</div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MachineLearningBlogDetail;
