import React from "react";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
// import {AiFillPlayCircle,AiOutlineRight} from "react-icons/ai";
import brud_crumbs_arrow_icon from "../Assets/brudcrums_arrow_icon.svg";
import data_driven_blog_img from "../Assets/data_driven_img.avif";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import blog_details2_img from "../Assests/Images/blog_details2_img.png";
const DataDrivenBlogDetails = () => {
  return (
    <div>
      <Header />
      <div className="py-3" style={{ background: "#FAFAFB" }}>
        <div className="container">
          <div className="d-flex align-items-center mt-5 ">
            <div className="d-flex mt-5 align-items-center">
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: "#065BD6",
                  lineHeight: "16px",
                }}
              >
                <a href="/ezine" className="text-decoration-none">
                  EZine
                </a>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <LazyLoadImage
                  src={brud_crumbs_arrow_icon}
                  width="100%"
                ></LazyLoadImage>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#07152C",
                }}
              >
                {" "}
                Data Driven...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex py-3 mt-5">
          <div className="">
            <div
              className=""
              style={{
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                lineHeight: "16px",
                background: "#07152C",
                opacity: "100%",
                bottom: "12px",
                left: "20px",
                Zindex: "3",
                padding: "4px 12px",
                borderRadius: "12px",
              }}
            >
              Technology
            </div>
          </div>
          <div
            className="d-flex my-auto px-2"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#6A7380",
            }}
          >
            <div>12 DEC 2022</div>
            <div
              style={{
                paddingLeft: "8px",
                fontSize: "2rem",
                marginTop: "-5%",
              }}
            >
              .
            </div>
            <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 ">
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "50px",
                  lineHeight: "60px",
                  marginTop: "36px",
                }}
              >
                Way to Run A Data-Driven Online Business
              </div>
            </div>
            <div className="mt-5">
              <LazyLoadImage
                src={data_driven_blog_img}
                className="w-100"
                alt="Data Driven Online Business"
              />
            </div>
            <div className="py-3"></div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Being ‘Data-Driven’ seems to be a popular expression in the
              Business World Nowadays. It’s a cold topic on how AI or Artificial
              Intelligence shall change its process, and Big Data enabling
              organizations to settle on exact expectations and in smarter
              choices.
              <div className="mt-4">
                Data-driven sources indeed have some outstanding quantifiable
                advantages. As per an investigation from McKinsey and Company,
                organizations that have effectively coordinated data into their
                methodologies are multiple times bound to turn a benefit. They
                are also bound to draw in a higher number of customers than
                businesses that don’t have data as a top preference.
              </div>
              <div className="mt-4">
                Obviously, data is similar, if not progressively essential for
                online businesses or entrepreneur. Since E-commerce
                organizations have no “individual” connection with clients, it
                is basic that each choice and scheme is sponsored up with solid
                datasets.
              </div>
              <div className="mt-4">
                So, by what way can online businesses certify that they are
                exceptionally data-driven?
              </div>
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Be Smarter with the Research Process
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              One of the ongoing e-commerce business processes is Customer
              analytics or audience analytics. Regardless of whether you
              presently can’t seem to launch your store or you’ve been working
              for quite a long time, knowing your client’s inclinations, needs,
              and wants will constantly be the right choice. Analyzing your
              audience is one of the keys to a fruitful online sales
              methodology, so joining AI and machine learning makes this
              progression a lot less demanding to do.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              For example, the Big Data system can be joined with customary
              datasets, like, market research reviews, to rapidly and precisely
              evaluate summed up shopper information and draw expectations.
              Machine learning can make your data assortment a stride further by
              recognizing further insights and making significant arrangements
              of data.
            </div>

            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Let Your Data Streams Drive Content Creation
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Search engine optimization (SEO) is basically the soul for a
              vigorous online business. It pipes in customers who are browsing
              for items or data on the website; any shortcomings in this
              strategy can have unforgiving repercussions in connection to the
              online attention of the business.
            </div>

            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              A fine digital marketer uses information and research to reveal
              the watchwords that are driving the highest traffic to their site
              and fuse them into their writings to attract more visitors. AI
              helps in a spot the patterns by guiding your content creation
              strategies. This method is mainly useful for online brands that
              need to join personalization into their content. As Artificial
              Intelligence targets in creating accurate content analysis report,
              it is used to track the client behavior by targeted content or
              keywords and that is otherwise known as Content marketing.
            </div>

            <div className="mt-5 mb-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Be 100% Sure About Your Ad Spending Decisions
              </div>

              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                By utilizing systematic methodologies, you can see precisely
                where your advertisements are creating the most elevated return.
                These datasets can manage your endeavors towards the stages and
                systems that are driving the most noteworthy number of clients.
                Notwithstanding utilizing a straightforward Google Analytics
                report can help recognize the traffic sources that are
                performing best, for example, organic searches, online reviews,
                referral traffic or social media.
              </div>
              <div
                className="mt-5"
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Conclusion
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Turning into a data-driven online business ought to be a top
                needs in the current business world. To say in short, strong
                datasets and precise analysis are fundamental elements for a
                good marketing strategy. Technology tools such as AI, machine
                learning and BI (Business Intelligence) is becoming more
                reachable for this organizational uses. By using the accurate
                source, will turn your business with great success.
              </div>

              <div
                className="mt-3 mobile_para_font mb-5"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                If you’re willing to get your hands on Big data analyst, AI, and
                BI; then get yourself trained in the best Artificial
                Intelligence training institutes and be the pro!
              </div>
            </div>
          </div>
          {/* <div className='col-md-3'>Enquiry Now</div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DataDrivenBlogDetails;
