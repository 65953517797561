import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import Footer from "../Pages//Footer";
import Header from "../Pages/Header";
import dmi_img1 from "../Assets/dmi_img1.jpg";
import dmi_img2 from "../Assets/dmi_img2.jpg";
import dmi_img3 from "../Assets/dmi_img3.jpg";
import dmi_img4 from "../Assets/dmi_img4.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DmiEnggEvent = () => {
  return (
    <div>
      <Header />
      <div className="mb-5 " style={{ background: "#065BD6" }}>
        <div className="container py-5">
          <div className="row container">
            <div
              className="col-lg-2 p-0 m-0 text-white text-truncate"
              style={{
                fontSize: "13px",
                lineHeight: "16px",
                whiteSpace: "no-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <a href="/events" className=" text-white">
                Meet & greet Home <AiOutlineRight />
              </a>{" "}
              NYINST organized a box campus recruitment drive for the
              final year students of Palanchur DMI Engineering College{" "}
            </div>
          </div>
          <div className="row">
            <div className=" col-lg-2 text-white"></div>
            <div className="col-lg-8 text-white ">
              <div style={{ paddingTop: "60px" }}>
                <div
                  className="d-flex"
                  style={{
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontWeight: "500",
                  }}
                >
                  <div className="text-start">31 JAN 2019 </div>
                  <div
                    className="mobile_dot_position"
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      fontSize: "2rem",
                      marginTop: "-1%",
                    }}
                  >
                    .
                  </div>
                  <div> 01:00pm</div>
                </div>

                <div className="text-start events_title_font_style">
                  NYINST organized a box campus recruitment drive for the
                  final year students of Palanchur DMI Engineering College
                </div>
                <div
                  className="container mobile_hero_event_height"
                  style={{ height: "15rem" }}
                >
                  <img
                    src={dmi_img1}
                    className="w-100 "
                    style={{ bottom: "-50%" }}
                    alt="DMI Engg College"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
      {/* events bottom height */}
      <div
        className="mobile_bottom_event_height"
        style={{ height: "15rem" }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="py-3 mt-lg-5 events_content_font_style">
              NYINST organized a box campus recruitment drive for the
              final year students of Palanchur DMI Engineering College. More
              than 100 students showed up for the screening test with NYINST to
              set up their value-added training infrastructure and enhance their
              employability. Looking for training to upgrade your skills to get
              an IT job? Join us now
            </div>
            <div className="py-3 w-100">
              <img
                src={dmi_img2}
                className="w-100 img-fluid"
                alt="block chain seminar "
              />
            </div>

            <div className="py-3 w-100">
              <img
                src={dmi_img3}
                className="w-100 img-fluid"
                alt="block chain seminar "
              />
            </div>
            <div className="py-3 w-100">
              <img
                src={dmi_img4}
                className="w-100 img-fluid"
                alt="block chain seminar "
              />
            </div>
          </div>

          <div className="col-lg-2"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DmiEnggEvent;
