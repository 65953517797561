import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import "../Styles/HireFromUs/Roles.css";
import role1 from "../Assets/HireFromUs/roles1.png";
import role2 from "../Assets/HireFromUs/roles2.png";
import role3 from "../Assets/HireFromUs/roles3.png";
import role4 from "../Assets/HireFromUs/roles4.png";
import role5 from "../Assets/HireFromUs/roles5.png";
import role6 from "../Assets/HireFromUs/roles6.png";
import role7 from "../Assets/HireFromUs/roles7.png";
const StickyStyled = styled.div`
  .section {
    height: 100vh;
  }

  .sticky {
    // background-color: red;
    margin-top: 10px;
    // width: 100%;

    // & div {
    //   padding: 50px;
    // }
  }

  .blue {
    background-color: blue;
  }
`;

function Roles() {
  const pinnedRef = useRef(null);

  useEffect(() => {
    const pinnedElement = pinnedRef.current;

    if (pinnedElement) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              pinnedElement.classList.add("pinned");
            } else {
              pinnedElement.classList.remove("pinned");
            }
          });
        },
        { threshold: 0.8 } // Adjust the threshold as needed
      );

      observer.observe(pinnedElement);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const [isPinned, setIsPinned] = useState(false);
  return (
    <div>
      <div className="container ">
        {" "}
        <div className="d-lg-block d-none">
          <div className="">
            {" "}
            <StickyStyled>
              <Controller>
                <div className="row">
                  <div className="col-md-6 position-relative">
                    {" "}
                    <Scene
                      duration={2500}
                      pin={true}
                      enabled={true}
                      // indicators={true}
                    >
                      <div className={`sticky`} ref={pinnedRef}>
                        <div
                          id="pinned_title"
                          style={{
                            fontSize: "3.25rem",
                            fontWeight: "400",
                            lineHeight: "4rem",
                          }}
                        >
                          Roles that our <br />
                          candidates Fullfill
                        </div>
                        <div id="pinned_sub_content">
                          Explore diverse roles our candidates excel in, <br />
                          showcasing practical programming skills gained <br />{" "}
                          through hands-on experience.
                        </div>
                      </div>
                    </Scene>
                  </div>
                  <div className="col-md-6" align="center">
                    {" "}
                    <Scene duration={300} classToggle={true}>
                      {(progress, event) => (
                        console.log(event.type, "type"),
                        (
                          <div
                            align="left"
                            id="roles_bg"
                            className="d-flex align-items-center"
                            style={{
                              //   width: "46rem",
                              //   height: "22.75rem",
                              backgroundColor: "#E25E3E",
                              marginTop: "3rem",
                              opacity: event.type === "leave" ? 0.5 : 1,
                              paddingBottom: "0",
                              // opacity: 1 - progress,
                              //   borderRadius: "0.5rem",
                            }}
                          >
                            <div id="role_title">
                              Full Stack Developer
                              <div id="role_subtitle">
                                The candidate brings hands-on experience with
                                live projects and has undergone intensive
                                training in various stacks, including HTML5,
                                CSS3, JavaScript, Bootstrap, ReactJS, NodeJS,
                                MySQL, MongoDB, and more.
                              </div>
                            </div>
                            <div id="role_img">
                              <img src={role1} alt="" width="100%" />
                            </div>
                          </div>
                        )
                      )}
                    </Scene>
                    <Scene duration={300}>
                      {(progress, event) => (
                        <div
                          align="left"
                          id="roles_bg"
                          className="d-flex align-items-center"
                          style={{
                            //   width: "46rem",
                            //   height: "22.75rem",
                            backgroundColor: "#662549",
                            marginTop: "3rem",
                            opacity: event.type === "leave" ? 0.5 : 1,
                            // opacity: 1 - progress,
                            //   borderRadius: "0.5rem",
                          }}
                        >
                          <div id="role_title">
                            Full Stack Mobile Developer
                            <div id="role_subtitle">
                              The candidate possesses working experience from
                              real projects on specific platforms such as
                              Android, iOS, and Flutter. Their expertise extends
                              to backend technologies, ensuring comprehensive
                              development capabilities.
                            </div>
                          </div>
                          <div id="role_img">
                            <img src={role2} alt="" width="100%" />
                          </div>
                        </div>
                      )}
                    </Scene>
                    <Scene duration={300}>
                      {(progress, event) => (
                        <div
                          align="left"
                          id="roles_bg"
                          className="d-flex align-items-center"
                          style={{
                            //   width: "46rem",
                            //   height: "22.75rem",
                            backgroundColor: "#4C4B16",
                            marginTop: "3rem",
                            opacity: event.type === "leave" ? 0.5 : 1,
                            // opacity: 1 - progress,
                            //   borderRadius: "0.5rem",
                          }}
                        >
                          <div id="role_title">
                            Front-End Developer
                            <div id="role_subtitle">
                              With hands-on experience in live projects, the
                              candidate has successfully utilized frontend
                              technologies and frameworks like HTML5, CSS3,
                              Bootstrap, and JavaScript. Additionally, they are
                              well-versed in ReactJS/AngularJS for creating
                              dynamic and visually appealing user interfaces.
                            </div>
                          </div>
                          <div id="role_img">
                            <img src={role3} alt="" width="100%" />
                          </div>
                        </div>
                      )}
                    </Scene>
                    <Scene duration={300}>
                      {(progress, event) => (
                        // console.log((progress,"kl")),
                        <div
                          align="left"
                          id="roles_bg"
                          className="d-flex align-items-center"
                          style={{
                            //   width: "46rem",
                            //   height: "22.75rem",
                            backgroundColor: "#2B4F60",
                            marginTop: "3rem",
                            opacity: event.type === "leave" ? 0.5 : 1,
                            // opacity: 1 - progress,
                            //   borderRadius: "0.5rem",
                          }}
                        >
                          <div id="role_title">
                            Backend Developer
                            <div id="role_subtitle">
                              Specializing in backend technologies, the
                              candidate excels in specific programming
                              languages, including JAVA, Python, PHP, or NodeJS.
                              Proficient in managing databases, they have
                              expertise in both MySQL and MongoDB.
                            </div>
                          </div>
                          <div id="role_img">
                            <img src={role4} alt="" width="100%" />
                          </div>
                        </div>
                      )}
                    </Scene>
                    <Scene duration={300}>
                      {(progress, event) => (
                        // console.log((progress,"kl")),
                        <div
                          align="left"
                          id="roles_bg"
                          className="d-flex align-items-center"
                          style={{
                            //   width: "46rem",
                            //   height: "22.75rem",
                            backgroundColor: "#C79A37",
                            marginTop: "3rem",
                            opacity: event.type === "leave" ? 0.5 : 1, // Adjust the opacity values
                            // opacity: 1 - progress,
                            //   borderRadius: "0.5rem",
                          }}
                        >
                          <div id="role_title">
                            Data Analytics
                            <div id="role_subtitle">
                              Bringing expertise in the latest tools and
                              technologies such as PowerBI, Tableau, and Excel,
                              the candidate handles vast datasets from diverse
                              sources like MySQL and MongoDB, ensuring
                              insightful data analysis.
                            </div>
                          </div>
                          <div id="role_img">
                            <img src={role5} alt="" width="100%" />
                          </div>
                        </div>
                      )}
                    </Scene>
                    <Scene duration={300}>
                      {(progress, event) => (
                        // console.log((progress,"kl")),
                        <div
                          align="left"
                          id="roles_bg"
                          className="d-flex align-items-center"
                          style={{
                            //   width: "46rem",
                            //   height: "22.75rem",
                            backgroundColor: "#724720",
                            marginTop: "3rem",
                            opacity: event.type === "leave" ? 0.5 : 1, // Adjust the opacity values
                            // opacity: 1 - progress,
                            //   borderRadius: "0.5rem",
                          }}
                        >
                          <div id="role_title">
                            Quality Analyst
                            <div id="role_subtitle">
                              Boosting hands-on working experience across
                              multiple projects, the candidate specializes in
                              writing manual and automated test cases. Their
                              proficiency includes using tools like Selenium,
                              Appium, TestNG, and others, coupled with strong
                              analytical skills.
                            </div>
                          </div>
                          <div id="role_img">
                            <img src={role6} alt="" width="100%" />
                          </div>
                        </div>
                      )}
                    </Scene>{" "}
                    <Scene duration={300}>
                      {(progress, event) => (
                        // console.log((progress,"kl")),
                        <div
                          align="left"
                          id="roles_bg"
                          className="d-flex align-items-center"
                          style={{
                            //   width: "46rem",
                            //   height: "22.75rem",
                            backgroundColor: "#612072",
                            marginTop: "3rem",
                            opacity: event.type === "leave" ? 0.5 : 1, // Adjust the opacity values
                            // opacity: 1 - progress,
                            //   borderRadius: "0.5rem",
                          }}
                        >
                          <div id="role_title">
                            Data Scientist
                            <div id="role_subtitle">
                              With a deep understanding of Artificial
                              Intelligence, Machine Learning, Deep Learning,
                              Neural Networks, NLP, and more, the candidate
                              possesses expertise in the Python programming
                              language and relevant libraries for comprehensive
                              data science applications.
                            </div>
                          </div>
                          <div id="role_img">
                            <img src={role7} alt="" width="100%" />
                          </div>
                        </div>
                      )}
                    </Scene>
                  </div>
                </div>
              </Controller>
            </StickyStyled>
          </div>
          {/* <div className="col-6">
      {" "}
      <StickyStyled>
        <div className="section" />
        <Controller>
          <Scene duration={600} pin={true} enabled={true}>
            <div className="sticky">
              <div>Pin Test</div>
            </div>
          </Scene>
          <Scene duration={250}>
            <div className="sticky">
              <div>Pin Test</div>
            </div>
          </Scene>
          <Scene duration={300} pin={true} >
            <div className="sticky blue">
              <div>Pin Test</div>
            </div>
          </Scene>
        </Controller>
        <div className="section" />
      </StickyStyled>
    </div> */}
        </div>
        {/* for mobiles */}
        <div className="d-lg-none d-block">
          <div id="mobile_pinned_title">
            Roles that our <br />
            candidates Fullfill
          </div>
          <div id="mobile_pinned_sub_content">
            Explore diverse roles our candidates excel in, showcasing practical
            programming skills gained through hands-on experience.
          </div>
          <div
            align="left"
            id="mobile_roles_bg"
            className=""
            style={{
              //   width: "46rem",
              //   height: "22.75rem",
              backgroundColor: "#E25E3E",
              marginTop: "0.75rem",
              // opacity: event.type === "leave" ? 0.5 : 1,
              paddingBottom: "0",
              // opacity: 1 - progress,
              //   borderRadius: "0.5rem",
            }}
          >
            <div id="mobile_role_img">
              <img src={role1} alt="" width="100%" />
            </div>
            <div id="mobile_role_title">
              Full Stack Developer
              <div id="mobile_role_subtitle">
                The candidate brings hands-on experience with live projects and
                has undergone intensive training in various stacks, including
                HTML5, CSS3, JavaScript, Bootstrap, ReactJS, NodeJS, MySQL,
                MongoDB, and more.
              </div>
            </div>
          </div>
          <div
            align="left"
            id="mobile_roles_bg"
            className=""
            style={{
              //   width: "46rem",
              //   height: "22.75rem",
              backgroundColor: "#662549",
              marginTop: "0.75rem",
              // opacity: event.type === "leave" ? 0.5 : 1,
              paddingBottom: "0",
              // opacity: 1 - progress,
              //   borderRadius: "0.5rem",
            }}
          >
            <div id="mobile_role_img">
              <img src={role2} alt="" width="100%" />
            </div>
            <div id="mobile_role_title">
              Full Stack Mobile Developer
              <div id="mobile_role_subtitle">
                The candidate possesses working experience from real projects on
                specific platforms such as Android, iOS, and Flutter. Their
                expertise extends to backend technologies, ensuring
                comprehensive development capabilities.
              </div>
            </div>
          </div>
          <div
            align="left"
            id="mobile_roles_bg"
            className=""
            style={{
              //   width: "46rem",
              //   height: "22.75rem",
              backgroundColor: "#4C4B16",
              marginTop: "0.75rem",
              // opacity: event.type === "leave" ? 0.5 : 1,
              paddingBottom: "0",
              // opacity: 1 - progress,
              //   borderRadius: "0.5rem",
            }}
          >
            <div id="mobile_role_img">
              <img src={role3} alt="" width="100%" />
            </div>
            <div id="mobile_role_title">
              Frontend Developer
              <div id="mobile_role_subtitle">
                With hands-on experience in live projects, the candidate has
                successfully utilized frontend technologies and frameworks like
                HTML5, CSS3, Bootstrap, and JavaScript. Additionally, they are
                well-versed in ReactJS/AngularJS for creating dynamic and
                visually appealing user interfaces.
              </div>
            </div>
          </div>
          <div
            align="left"
            id="mobile_roles_bg"
            className=""
            style={{
              //   width: "46rem",
              //   height: "22.75rem",
              backgroundColor: "#2B4F60",
              marginTop: "0.75rem",
              // opacity: event.type === "leave" ? 0.5 : 1,
              paddingBottom: "0",
              // opacity: 1 - progress,
              //   borderRadius: "0.5rem",
            }}
          >
            <div id="mobile_role_img">
              <img src={role4} alt="" width="100%" />
            </div>
            <div id="mobile_role_title">
              Backend Developer
              <div id="mobile_role_subtitle">
                Specializing in backend technologies, the candidate excels in
                specific programming languages, including JAVA, Python, PHP, or
                NodeJS. Proficient in managing databases, they have expertise in
                both MySQL and MongoDB.
              </div>
            </div>
          </div>
          <div
            align="left"
            id="mobile_roles_bg"
            className=""
            style={{
              //   width: "46rem",
              //   height: "22.75rem",
              backgroundColor: "#C79A37",
              marginTop: "0.75rem",
              // opacity: event.type === "leave" ? 0.5 : 1,
              paddingBottom: "0",
              // opacity: 1 - progress,
              //   borderRadius: "0.5rem",
            }}
          >
            <div id="mobile_role_img">
              <img src={role5} alt="" width="100%" />
            </div>
            <div id="mobile_role_title">
              Data Analytics
              <div id="mobile_role_subtitle">
                Bringing expertise in the latest tools and technologies such as
                PowerBI, Tableau, and Excel, the candidate handles vast datasets
                from diverse sources like MySQL and MongoDB, ensuring insightful
                data analysis.
              </div>
            </div>
          </div>
          <div
            align="left"
            id="mobile_roles_bg"
            className=""
            style={{
              //   width: "46rem",
              //   height: "22.75rem",
              backgroundColor: "#724720",
              marginTop: "0.75rem",
              // opacity: event.type === "leave" ? 0.5 : 1,
              paddingBottom: "0",
              // opacity: 1 - progress,
              //   borderRadius: "0.5rem",
            }}
          >
            <div id="mobile_role_img">
              <img src={role6} alt="" width="100%" />
            </div>
            <div id="mobile_role_title">
              Quality analyst
              <div id="mobile_role_subtitle">
                Boosting hands-on working experience across multiple projects,
                the candidate specializes in writing manual and automated test
                cases. Their proficiency includes using tools like Selenium,
                Appium, TestNG, and others, coupled with strong analytical
                skills.
              </div>
            </div>
          </div>
          <div
            align="left"
            id="mobile_roles_bg"
            className=""
            style={{
              //   width: "46rem",
              //   height: "22.75rem",
              backgroundColor: "#612072",
              marginTop: "0.75rem",
              // opacity: event.type === "leave" ? 0.5 : 1,
              paddingBottom: "0",
              // opacity: 1 - progress,
              //   borderRadius: "0.5rem",
            }}
          >
            <div id="mobile_role_img">
              <img src={role7} alt="" width="100%" />
            </div>
            <div id="mobile_role_title">
              Data Scientist
              <div id="mobile_role_subtitle">
                With a deep understanding of Artificial Intelligence, Machine
                Learning, Deep Learning, Neural Networks, NLP, and more, the
                candidate possesses expertise in the Python programming language
                and relevant libraries for comprehensive data science
                applications.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roles;
