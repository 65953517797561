import React from "react";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
// import {AiFillPlayCircle,AiOutlineRight} from "react-icons/ai";
import brud_crumbs_arrow_icon from "../Assets/brudcrums_arrow_icon.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import software_testing from "../Assets/software_testing_blog_img.avif";
// import blog_details2_img from "../Assests/Images/blog_details2_img.png";
const SoftwareTestingBlogDetails = () => {
  return (
    <div>
      <Header />
      <div className="py-3" style={{ background: "#FAFAFB" }}>
        <div className="container">
          <div className="d-flex align-items-center mt-5 ">
            <div className="d-flex mt-5 align-items-center">
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: "#065BD6",
                  lineHeight: "16px",
                }}
              >
             <a href="/ezine" className="text-decoration-none">EZine</a>   
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <LazyLoadImage src={brud_crumbs_arrow_icon} width="100%"></LazyLoadImage>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#07152C",
                }}
              >
                {" "}
                Software Testing...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex py-3 mt-5">
          <div className="">
            <div
              className=""
              style={{
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                lineHeight: "16px",
                background: "#07152C",
                opacity: "100%",
                bottom: "12px",
                left: "20px",
                Zindex: "3",
                padding: "4px 12px",
                borderRadius: "12px",
              }}
            >
              Technology
            </div>
          </div>
          <div
            className="d-flex my-auto px-2"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#6A7380",
            }}
          >
            <div>03 JAN 2022</div>
            <div
              style={{
                paddingLeft: "8px",
                fontSize: "2rem",
                marginTop: "-5%",
              }}
            >
              .
            </div>
            <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 ">
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "50px",
                  lineHeight: "60px",
                  marginTop: "36px",
                }}
              >
                A Successful Career Path to Software Testing
              </div>
            </div>
            <div className="mt-5">
              <LazyLoadImage
                src={software_testing}
                className="w-100"
                alt="Software Testing Blog"
              />
            </div>
            <div className="py-3"></div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Software tester or QA practitioner is one of the interesting Jobs
              many IT lovers go for. Before unfolding about Software testing, I
              ‘do like to ping you that you are going to select the precise
              course because a Software testing or QA is the best platform for
              newcomers who enter IT industries with a misunderstanding of low
              wages.{" "}
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                What is Software Testing?
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Application testing or software testing is the process of
              examining, assessing and determining the quality of the app or
              software. This activity is done to evaluate the original results
              with the expected results detecting errors, gaps in the required
              software. The only must skill needed for a software testing is the
              ‘ability to find bug’. If you say ‘Yes’, move forward in your
              career.
            </div>

            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              In many IT companies, new testers and QA practitioners lack few
              technical and non-technical skills. To improvise your abilities,
              NY has come forth in mastering you become the profession.
            </div>
            <div className="mt-5"
              style={{
                fontWeight: "700",
                fontSize: "31px",
                lineHeight: "40px",
              }}
            >
              SKILLS REQUIRED TO BECOME QA TESTER
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Now it’s time to discuss a few essential skills to become a QA
              tester. Following is the checklist to determine whether it is
              suitable for your career path.
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Technical Skills:
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              <li>
                {" "}
                Basic information on Database/SQL: Software Systems have a lot
                of data out of sight. This information is put away in various
                sorts of databases like Oracle, MySQL, and so on in the backend.
                Thus, the conditioned data shall be approved. All things
                considered, simple or complex SQL queries can be utilized to
                check whether genuine data is put away in the backend databases.
              </li>
              <li>
                Fundamental knowledge of Linux commands: Most of the software
                applications like Web-Services, Databases, and Application
                Servers are conveyed on Linux machines. So it is urgent for
                testers to know about Linux commands.
              </li>
              <li>
                Information and hands-on experience of a Test Management Tool:
                Test Management is an imperative part of Software testing.
                Without appropriate test the management systems, software
                testing procedure will come up short. Some knowledge in defect
                tracking tools for the test case is vital (for example QC, Jira,
                and Bugzilla).
              </li>
              <li>
                Basic knowledge of analytics, Software development, and agile
                methodologies modules is required to become a good QA tester.
              </li>
              <li>
                Knowing at least one core programming language with a related
                automation tool like Java and OOPs will help testers in learning
                selenium.
              </li>
            </div>
            <div className="mt-5"
              style={{
                fontWeight: "700",
                fontSize: "31px",
                lineHeight: "40px",
              }}
            >
              Non-Technical Skills:
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              <li>
                {" "}
                Verbal and written communication skill is required so that
                testing artifacts can be created easily.
              </li>
              <li>
                Sharp analytical skill is needed to become a successful software
                tester.
              </li>
              <li>
                Testing in time is a demanding challenge in Industries. He/she
                must manage the workloads, exhibit optimal time and be
                productive.
              </li>
              <li>
                As said in previous articles, passion towards work gains success
                for workers.
              </li>
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Academic History needed for Software Tester
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                You must hold any degree among B. Tech, B.E., MCA, BCA, BSc
                computer science. If your degree seems missing but you’re
                interested to become a tester, then certification in software
                testing is valid that gets you the complete knowledge on the
                test life cycle, testing strategies, and software development.
                We at NYINST help you achieve your dream career with
                success in completion of course.
              </div>

              <div
                className="mt-5"
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                What are the Career Paths?
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                <li>QA Analyst (Fresher)</li>
                <li>Sr. QA Analyst (2-3 years’ exp)</li>
                <li>QA Team Coordinator (5-6 years’ exp)</li>
                <li>Test Manager (8-11 years’ exp)</li>
                <li>Senior Test Manager (14+ exp)</li>
              
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                You must hold any degree among B. Tech, B.E., MCA, BCA, BSc
                computer science. If your degree seems missing but you’re
                interested to become a tester, then certification in software
                testing is valid that gets you the complete knowledge on the
                test life cycle, testing strategies, and software development.
                We at NYINST help you achieve your dream career with
                success in completion of course.
              </div>
         
            </div>
          </div>
          {/* <div className='col-md-3'>Enquiry Now</div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SoftwareTestingBlogDetails;
