import React, { useRef, useEffect, useState } from "react";
import TechieHeroSection from "./TechieHeroSection";
import Header from "../Pages/Header";
import AboutTechie from "./AboutTechie";
import WhySoftware from "./WhySoftware";
import Capstone from "./Capstone";
import TechStack from "./TechStack";
// import { useInView } from "react-intersection-observer";
import Confusion from "./Confuse";
// import { useLocation, useNavigate } from "react-router-dom";
import ProgramOverview from "./ProgramOverview";
import Journey from "./Journey";
import Faq from "./Faq";
import Footer from "../Pages/Footer";
import Certificate from "./Certificate";
import { useInView } from "react-intersection-observer";
function TechieHome(props) {
  const sectionRefs = {
    home: useRef(null),
    about: useRef(null),
    // techstack: useRef(null),
    // certificate: useRef(null),
    // pricing: useRef(null),
    // faq: useRef(null),

    // Add more sections as needed
  };

  const [sectionId, setSectionId] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Check which section is currently in view
      for (const [key, ref] of Object.entries(sectionRefs)) {
        if (ref.current) {
          const rect = ref.current.getBoundingClientRect();
          const scrollThreshold = window.innerHeight / 8;

          // Adjust the scroll threshold based on your needs
          if (rect.top <= scrollThreshold && rect.bottom >= scrollThreshold) {
            setSectionId(key);
            break;
          }
        }
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Detach the scroll event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRefs]);

  // const scrollToSection = (section) => {
  //   if (sectionRefs[section].current) {
  //     sectionRefs[section].current.scrollIntoView({
  //       behavior: "smooth",
  //     });
  //   }
  // };
  // const [section, setSection] = useState("software");
  // // const[color, setColor] = useState("software")
  // const [softwareRef, softwareInView] = useInView({
  //   /* Optional options */
  //   threshold: 0,
  // });
  // const [capstone, capstoneInView] = useInView({
  //   /* Optional options */
  //   threshold: 0,
  // });
  // // Add more refs as needed

  // useEffect(() => {
  //   if (softwareInView) setSection("software");
  //   else if (capstoneInView) setSection("capstone");
  //   // Add more conditions as needed
  // }, [softwareInView, capstoneInView]); // Fix the dependency array

  const sectionRefss = {
    // home: useRef(null),
    about: useRef(null),
    techstack: useRef(null),
    // confusion: useRef(null),
    pricing: useRef(null),
    faq: useRef(null),
    certificate: useRef(null),
    // capstone: useRef(null),
    // Add more sections as needed
  };

  const [activeSection, setActiveSection] = useState("");
  const [activeSectionRef, setActiveSectionRef] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Check which section is currently in view
      for (const [key, ref] of Object.entries(sectionRefss)) {
        if (ref.current) {
          const rect = ref.current.getBoundingClientRect();
          const scrollThreshold = window.innerHeight / 2;

          if (rect.top <= scrollThreshold && rect.bottom >= scrollThreshold) {
            setActiveSection(key);
            setActiveSectionRef(key);

            // Log the current ref
            // console.log(`${key} ref:`, ref.current);

            break;
          }
        }
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Detach the scroll event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRefs]);
  // console.log(sectionRefss.software, "kl");
  return (
    <div  className="position-relative"
    style={{ zIndex: "0", backgroundColor: "white" }}>
      <div>
        {/* Pass activeSectionRef as a prop to the Header component */}
        <Header menu={sectionId} activeSectionRef={activeSectionRef} />
      </div>

      <div ref={sectionRefs.home} id="home">
        <TechieHeroSection />
      </div>

      <div ref={sectionRefs.about} id="about">
        <div ref={sectionRefss.about}>
          <AboutTechie menu={sectionId} activeSectionRef={activeSectionRef}/>
        </div>
        <div id="software">
          {" "}
          <WhySoftware menu={activeSectionRef} />
        </div>
        <div id="capstone">
          <Capstone menu={activeSectionRef} />
        </div>
        <div ref={sectionRefss.techstack} id="techstack">
          <TechStack menu={sectionId} />
        </div>
        <div id="confusion">
          <Confusion menu={sectionId} />
        </div>
        <div id="certificate" ref={sectionRefss.certificate}>
          <Certificate menu={sectionId} />
        </div>
        <div id="pricing" ref={sectionRefss.pricing}>
          <ProgramOverview menu={sectionId} />
        </div>

        <Journey menu={sectionId} />
        <div id="faq" ref={sectionRefss.faq}>
          <Faq menu={sectionId} />
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default TechieHome;
