import react from "react";
import { FullPage, Slide } from "react-full-page";
import "../Styles/IAMS.css";
// import iams_star from "../Assets/iams_hero_star.png";
import iams_logo from "../Assets/iams_logo.png";
import iams_layer from "../Assets/iams_fist_layer.png";
import nyinst_logo from "../Assets/iams_ny_logo.png";
import xcelcorp_logo from "../Assets/xcelcorp_img.png";

export default function FullPages() {
  return (
    <FullPage scrollingSpeed={0} duration={500}>
      <div>
        <div className="tawk-min-container d-none"></div>
      </div>
      <Slide duration={1000} controls={false}>
        <div id="first_slide">
          <div id="iams_left_star">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
            >
              <g clip-path="url(#clip0_6799_10634)">
                <path
                  d="M28.4251 13.7572C37.5589 37.9614 37.0232 39.3179 13.8171 50.7523C38.0213 41.6185 39.3778 42.1541 50.8122 65.3602C41.6785 41.156 42.2141 39.7996 65.4202 28.3651C41.216 37.4989 39.8595 36.9633 28.4251 13.7572Z"
                  fill="url(#paint0_linear_6799_10634)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_6799_10634"
                  x1="28.4251"
                  y1="13.7572"
                  x2="50.8122"
                  y2="65.3602"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F4F792" />
                  <stop offset="0.49" stop-color="#FFED79" />
                  <stop offset="1" stop-color="#755A2B" />
                </linearGradient>
                <clipPath id="clip0_6799_10634">
                  <rect
                    width="60"
                    height="60"
                    fill="white"
                    transform="translate(0.157227 23.9769) rotate(-23.4528)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div id="iams_logo" className="d-flex align-items-center">
            <img src={iams_logo} alt="iams_logo" width="100%" />
          </div>
          <div id="iams_right_star">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
            >
              <g clip-path="url(#clip0_6799_10634)">
                <path
                  d="M28.4251 13.7572C37.5589 37.9614 37.0232 39.3179 13.8171 50.7523C38.0213 41.6185 39.3778 42.1541 50.8122 65.3602C41.6785 41.156 42.2141 39.7996 65.4202 28.3651C41.216 37.4989 39.8595 36.9633 28.4251 13.7572Z"
                  fill="url(#paint0_linear_6799_10634)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_6799_10634"
                  x1="28.4251"
                  y1="13.7572"
                  x2="50.8122"
                  y2="65.3602"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F4F792" />
                  <stop offset="0.49" stop-color="#FFED79" />
                  <stop offset="1" stop-color="#755A2B" />
                </linearGradient>
                <clipPath id="clip0_6799_10634">
                  <rect
                    width="60"
                    height="60"
                    fill="white"
                    transform="translate(0.157227 23.9769) rotate(-23.4528)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div id="iams_welcome_text">Welcome To</div>
          <h1 id="iams_heading">
            Industry Academia <br />{" "}
            <span id="iams_subheading">Maha Sammelan</span>
          </h1>
          <div id="iams_layer">
            <img src={iams_layer} alt="" width="100%" />
          </div>
          <div id="iams_address_info">Belgavi, Karnataka on 20-April-2024</div>
          <div id="iams_brand_logos_bg">
            <div id="xcelcorp_logo">
              <img src={xcelcorp_logo} alt="" />
            </div>
            <div id="ny_logo">
              <img src={nyinst_logo} alt="" />
            </div>
          </div>
        </div>
      </Slide>
      <Slide duration={1000} controls={false}>
        <div id="second_slide">
          <div id="iams_reg">Registration</div>
          <div id="iams_heading2">
            Building a Vibrant <br />
            Industry-Academia Global Platform
          </div>
          <div id="iams_layer_2">
            <img src={iams_layer} alt="" width="100%" />
          </div>
          <div id="iams_invitation_text">By Invitation Only, Register Now!</div>
          <div id="iams_reg_button_bg">
            <a
              href="https://forms.gle/DMUDitwRGtqKwbav8"
              className="text-decoration-none"
              target="_blank"
            >
              <button className="btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M12.5 19H14.5V6.00003L20.8939 8.74028C21.2616 8.89786 21.5 9.2594 21.5 9.65943V19H23.5V21H1.5V19H3.5V5.6499C3.5 5.25472 3.73273 4.89659 4.09386 4.73609L11.7969 1.31251C12.0493 1.20035 12.3448 1.314 12.4569 1.56634C12.4853 1.63027 12.5 1.69945 12.5 1.76941V19Z"
                    fill="#0C1522"
                  />
                </svg>
                Industry Registration
              </button>
            </a>
            <a
              href="https://forms.gle/zx24f63xnC2DEmKGA"
              className="text-decoration-none"
              target="_blank"
            >
              <button className="btn">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                >
                  <path
                    d="M22 18.9999H21V8.99991H17V6.58569L11 0.585693L5 6.58569V8.99991H1V18.9999H0V20.9999H22V18.9999ZM5 19H3V11H5V19ZM17 11H19V19H17V11ZM10 12H12V19H10V12Z"
                    fill="#0C1522"
                  />
                </svg>
                Academy Registration
              </button>
            </a>
          </div>
        </div>
      </Slide>
      <Slide duration={1000} controls={false}>
        <div id="iams_third_slide">
          <div></div>
          <div id="iams_contact_text" align="center">
            Contact
          </div>
          <div id="iams_reach_text">Reach Us </div>
          <div id="iams_location_bg">
            <div id="iams_single_loc">
              <div id="iams_location_header">
                <div id="iams_yellow_line"></div>
                <div id="iams_location_text">
                  Bangalore<div id="iams_office_text">Office</div>
                </div>
              </div>{" "}
              <div id="iams_enquiry_bg">
                <div id="iams_icon_bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16H19.9381C19.446 19.9463 16.0796 23 12 23V21C15.3137 21 18 18.3137 18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V16H3C1.89543 16 1 15.1046 1 14V10C1 8.89543 1.89543 8 3 8H4.06189C4.55399 4.05369 7.92038 1 12 1C16.0796 1 19.446 4.05369 19.9381 8H21ZM7.75944 15.7849L8.81958 14.0887C9.74161 14.6662 10.8318 15 12 15C13.1682 15 14.2584 14.6662 15.1804 14.0887L16.2406 15.7849C15.0112 16.5549 13.5576 17 12 17C10.4424 17 8.98882 16.5549 7.75944 15.7849Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div id="iams_event_text">
                  Event Enquiry
                  <div id="iams_mail">
                    <a
                      href="mailto:+info@nyinst.com"
                      className="text-decoration-none text-white"
                    >
                      {" "}
                      info@nyinst.com
                    </a>
                    /
                    <a
                      href="tel:+91 8925 904 895"
                      className="text-decoration-none text-white"
                    >
                      +91 8925 904 895
                    </a>
                  </div>
                </div>
              </div>
              <div id="" className="iams_add_text">
                <div id="iams_icon_bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div id="iams_event_text">
                  Address
                  <div id="iams_mail" className="d-lg-block d-none d-md-block">
                    No.1 Pine Hurst, 100 Feet Ring Rd, BTM 2nd Stage, BTM
                    Layout, <br />
                    Bengaluru, Karnataka 560076
                  </div>
                  <div id="iams_mail" className="d-lg-none d-block d-md-none">
                    No.1 Pine Hurst, 100 Feet Ring Rd, BTM 2nd Stage, BTM
                    Layout, Bengaluru, Karnataka 560076
                  </div>
                </div>
              </div>
              <a
                href="https://maps.app.goo.gl/NgpjLLSpnVttquVEA"
                className="text-decoration-none"
                target="_blank"
              >
                <div id="iams_google_map">
                  Locate In Google Maps{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#2F80FA"
                    />
                  </svg>
                </div>
              </a>
            </div>
            <div id="iams_single_loc">
              <div id="iams_location_header">
                <div id="iams_yellow_line"></div>
                <div id="iams_location_text">
                  Chennai<div id="iams_office_text">Office</div>
                </div>
              </div>{" "}
              <div id="" className="iams_add_text">
                <div id="iams_icon_bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16H19.9381C19.446 19.9463 16.0796 23 12 23V21C15.3137 21 18 18.3137 18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V16H3C1.89543 16 1 15.1046 1 14V10C1 8.89543 1.89543 8 3 8H4.06189C4.55399 4.05369 7.92038 1 12 1C16.0796 1 19.446 4.05369 19.9381 8H21ZM7.75944 15.7849L8.81958 14.0887C9.74161 14.6662 10.8318 15 12 15C13.1682 15 14.2584 14.6662 15.1804 14.0887L16.2406 15.7849C15.0112 16.5549 13.5576 17 12 17C10.4424 17 8.98882 16.5549 7.75944 15.7849Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div id="iams_event_text">
                  Event Enquiry
                  <div id="iams_mail">
                    <a
                      href="mailto:+info@nyinst.com"
                      className="text-decoration-none text-white"
                    >
                      {" "}
                      info@nyinst.com
                    </a>
                    /
                    <a
                      href="tel:+91 8925 904 895"
                      className="text-decoration-none text-white"
                    >
                      +91 8925 904 895
                    </a>
                  </div>
                </div>
              </div>
              <div id="" className="iams_add_text">
                <div id="iams_icon_bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div id="iams_event_text">
                  Address
                  <div id="iams_mail" className="d-lg-block d-none d-md-block">
                    Prince Info ParkB-Block, 5th Floor81B, 2nd Main Road,
                    Ambattur <br />
                    Industrial Estate Chennai - 600058
                  </div>
                  <div id="iams_mail" className="d-lg-none d-block d-md-none">
                    Prince Info ParkB-Block, 5th Floor81B, 2nd Main Road,
                    Ambattur Industrial Estate Chennai - 600058
                  </div>
                </div>
              </div>
              <a
                href="https://maps.app.goo.gl/MDjorsEpAj6jTYc37"
                className="text-decoration-none"
                target="_blank"
              >
                <div id="iams_google_map">
                  Locate In Google Maps{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#2F80FA"
                    />
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Slide>
    </FullPage>
  );
}
