import React from "react";
import "../Styles/HireFromUs/Stats.css";
import threeDays from "../Assets/HireFromUs/DAYS.json";
import Star from "../Assets/HireFromUs/STAR.json";
import percent from "../Assets/HireFromUs/PERCENT.json";
import Lottie from "react-lottie";

function Stats() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: threeDays,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <div align="center">
        <div className="container d-lg-block d-none">
          <div id="stats_title">PROVEN STATS TILL THIS DAY</div>
          <div className="row" align="left">
            <div className="col-md-4">
              <div id="single_stat_bg">
                <div id="single_stat_title">Max 3 days</div>
                <div id="single_sub_title">To hire a candidate</div>
                <div
                  id="lottie"
                  style={{ marginTop: "2.75rem", objectFit: "cover" }}
                >
                  <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: threeDays,
                  }}
                    style={{ height: "", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <div id="single_stat_bg">
                <div id="single_stat_title">92%</div>
                <div id="single_sub_title">Offer Acceptance</div>
                <div
                  id="lottie"
                  style={{ marginTop: "2.75rem", objectFit: "cover" }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: percent,
                    }}
                    style={{ height: "", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <div id="single_stat_bg">
                <div id="single_stat_title">4.8</div>
                <div id="single_sub_title">Satisfied ratings after 3 month</div>
                <div
                  id="lottie"
                  style={{ marginTop: "2.75rem", objectFit: "cover" }}
                >
                  <Lottie
                   options={{
                    loop: true,
                    autoplay: true,
                    animationData: Star,
                  }}
                    style={{ height: "", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* for moobiles */}
        <div className="container d-lg-none d-block">
          <div id="mobile_stats_title">PROVEN STATS TILL THIS DAY</div>
          <div className="row" align="left">
            <div className="col-md-6">
              <div id="mobile_single_stat_bg">
                <div id="mobile_single_stat_title">Max 3 days</div>
                <div id="mobile_single_sub_title">To hire a candidate</div>
                <div
                  id="mobile_lottie"
                  style={{ marginTop: "1.25rem", objectFit: "cover" }}
                >
                  <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: threeDays,
                  }}
                    style={{ height: "", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div id="mobile_single_stat_bg">
                <div id="mobile_single_stat_title">92%</div>
                <div id="mobile_single_sub_title">Offer Acceptance</div>
                <div
                  id="mobile_lottie"
                  style={{ marginTop: "1.25rem", objectFit: "cover" }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: percent,
                    }}
                    style={{ height: "", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div id="mobile_single_stat_bg">
                <div id="mobile_single_stat_title">4.8</div>
                <div id="mobile_single_sub_title">Satisfied ratings after 3 month</div>
                <div
                  id="mobile_lottie"
                  style={{ marginTop: "1.25rem", objectFit: "cover" }}
                >
                  <Lottie
                   options={{
                    loop: true,
                    autoplay: true,
                    animationData: Star,
                  }}
                    style={{ height: "", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;
