import React from "react";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
// import {AiFillPlayCircle,AiOutlineRight} from "react-icons/ai";
import brud_crumbs_arrow_icon from "../Assets/brudcrums_arrow_icon.svg";
import data_analytics_blog_img from "../Assets/data_analytics_blog_img.avif";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import blog_details2_img from "../Assests/Images/blog_details2_img.png";
const DataAnalyticsBlogDetails = () => {
  return (
    <div>
      <Header />
      <div className="py-3" style={{ background: "#FAFAFB" }}>
        <div className="container">
          <div className="d-flex align-items-center mt-5 ">
            <div className="d-flex mt-5 align-items-center">
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: "#065BD6",
                  lineHeight: "16px",
                }}
              >
                 <a href="/ezine" className="text-decoration-none">EZine</a>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <LazyLoadImage src={brud_crumbs_arrow_icon} width="100%"></LazyLoadImage>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#07152C",
                }}
              >
                {" "}
                Data Analytics...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex py-3 mt-5">
          <div className="">
            <div
              className=""
              style={{
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                lineHeight: "16px",
                background: "#07152C",
                opacity: "100%",
                bottom: "12px",
                left: "20px",
                Zindex: "3",
                padding: "4px 12px",
                borderRadius: "12px",
              }}
            >
              Technology
            </div>
          </div>
          <div
            className="d-flex my-auto px-2"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#6A7380",
            }}
          >
            <div>12 NOV 2022</div>
            <div
              style={{
                paddingLeft: "8px",
                fontSize: "2rem",
                marginTop: "-5%",
              }}
            >
              .
            </div>
            <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 ">
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "50px",
                  lineHeight: "60px",
                  marginTop: "36px",
                }}
              >
                India dawdles in tech skills amongst peers and ease of doing
                business.
              </div>
            </div>
            <div className="mt-5">
              <LazyLoadImage
                src={data_analytics_blog_img}
                className="w-100"
                alt="India Dawdles in Tech Skills "
              />
            </div>
            <div className="py-3"></div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              India may have garnered a lot of attention for its global policy
              changes and ease of doing business. But as far as the job
              revolution is concerned, despite its highly favorable
              demographics, India seems to lag in its preparation.{" "}
              <div className="mt-4">
                A Silicon-valley based e-learning firm assessed that India
                dawdles in two out of three future skills – data science,
                technology, and business.
              </div>
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Preview
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              While the consumer and consulting are equipped with better skill
              sets, manufacturing and technology see better availability in
              business skills. Sectors such as finance, insurance, healthcare
              and automotive possess significantly lower skillsets and have to
              still catchup. The Annual Employability Survey 2019 found that at
              least 80% of Indian engineers are not employable and only 2.5% of
              them have the AI or Artificial Intelligence skills required by the
              industry. In the past nine years, with only a handful of them
              possessing next-gen tech skills, there has been no change in the
              employability prospects of Indian engineering graduates. The
              report also states that a systematic and fundamental change has to
              be brought in to deal with the high unemployability numbers, which
              would help the ad-hoc changes in the Indian higher education
              system.{" "}
            </div>

            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Statistics{" "}
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              India, lower at 50th spot in business skills, and 51st in data
              science is ranked 44th out of the 60 countries in the technology
              domain. 90% of developing economies and two-thirds of the world’s
              population is falling behind in critical skills. Europe emerged as
              the most skilled region with 24 countries in the EU region taking
              the top spots across the three domains.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              It is one of the biggest markets for learning. Even Courser boasts
              of about 3.9 million learners and the highest course completion
              rate of 60%. While the country does display a relatively strong
              inclination towards technology, these are primarily general
              computer science skills, software engineering, databases, and
              others. Sales are strong as well, but communication, management,
              and accounting and finance skills have a little bit of catching up
              to do. The population seems exceptionally good at mathematics
              leaving a lot of room for data science skills but yet the country
              lags in statistics and the machine learning space.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Its demographic dividend in an otherwise aging world, India is
              sitting on an untapped goldmine and with an average age of just 29
              years, it will be the youngest country in 2020.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              NYINST is trying to put India on the Global Skills Index with
              its GTM programs.
            </div>

            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Big Data Analytics
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Learn all about Big Data and Analytics at NYINST from the
              beginners’ to the advanced techniques taught by experienced,
              working professionals. Big data concepts are taught practically
              and elaborately while alleviating all your doubts about the Hadoop
              Framework. In this rapidly evolving world, there is a need for big
              data analysts and data science experts.
            </div>

            <div className="mt-5 mb-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Courses
              </div>
              <div
                className="mt-3"
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Android Mobile App Development
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Well, by now you have a basic understanding of full stack
                development and the salary range. Let us discuss how to become a
                full stack developer.
              </div>
              <div
                className="mt-5"
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                iOS Application Development
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Master iOS app development techniques at NYINST where you also
                get intensive hands-on experience in developing the apps. Our
                trainers will familiarize you with Swift Web Services, MySQL, QA
                Practices, XML, Source Code Control Systems and much more and
                support you constantly till you attain course completion and
                placement.
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                IoT and IoM applications
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                In a highly connected world, it is extremely important to learn
                how to manage these devices. Our extensive course on IoT and IoM
                will teach you about the usage of digitally controlled machines
                in our daily lives. You will also be familiarized with
                programming kits like Raspberry Pi 3, Arduino and embedded
                systems using C, Python, and other languages. Furthermore, you
                will also learn sensors impacted by heat, cold, motion, etc.,
                along with usages of devices such as motors, cameras, LEDs and
                much more. Learn all about basic networking hardware and
                programming interfaces such as HDMI, Ethernet, I/O Devices, and
                USB.
              </div>
            </div>
          </div>
          {/* <div className='col-md-3'>Enquiry Now</div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DataAnalyticsBlogDetails;
