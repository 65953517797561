import { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import "../Styles/TechiePro/ApplyForm.css";
import axios from "axios";
import qs from "qs";
import india_img from "../Assets/india_img.png";
import success_tick from "../Assets/success_tick_img.png";
import tpro_img from "../Assets/t_pro.png";
import tnerd_img from "../Assets/t_nerds.png";
import tsuper_img from "../Assets/t_super.png";
import { PulseLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import "../Styles/BookForm.css";
import book_now_img from "../Assets/book_now_img.png";
import fsd_web from "../Assets/LandingPage/Fullstack-web-development.pdf";
import fsd_app from "../Assets/LandingPage/Fullstack-mobile-app-development.pdf";
import fsd from "../Assets/LandingPage/Fullstack-app-web.pdf";

function BookNowFormWebMobile(props) {
  const [show, setShow] = useState(props.show);
  // let show = props.show;
  //   console.log(show, "sd");
  //   console.log(props.location, "hj");
  const handleClose = () => setShow(false);
  const location = useLocation();
  //   const handleShow = () => setShow(true);
  //   console.log(handleClose);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [year, setYear] = useState("");
  const [deg, setDeg] = useState("");
  const [loading, setLoading] = useState();
  const [status, setStatus] = useState();
  // const location =
  const reqData = {
    mod: "Candidate",
    actionType: "add-user-biodata",
    subAction: JSON.stringify({
      NAME: name,
      MOBILE_NUMBER: phone,
      EMAIL: email,
      YOP: year,
      DEGREE: deg,
      INTERESTED_COURSE:
        props.dept === "Full Stack Mobile Development"
          ? "Full Stack Mobile Development"
          : props.dept === "Full Stack Web Development"
          ? "Full Stack Web Development"
          : "Full Stack Development",
    }),
    // EMAIL: mail,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "data");
      setLoading(false);
      setStatus(res.data.XSCStatus);
      if (res.data.XSCStatus === 0) {
        setName("");
        setPhone("");
        setEmail("");
        setYear("");
        setDeg("");
      }
    });
    console.log(props.dept, "web");
    try {
      // Simulating an asynchronous operation (replace this with your actual logic)
      // This is just to show how you can handle loading state
      setStatus(1); // Set loading state
      // Your actual asynchronous logic here...

      // Assuming you have a file URL (replace 'yourFileUrl' with the actual URL)
      const fileUrl =
        props.dept === "Full Stack Mobile Development"
          ? fsd_app
          : props.dept === "Full Stack Web Development"
          ? fsd_web
          : fsd;

      // Create a link element
      const link = document.createElement("a");
      link.href = fileUrl;

      // Set the download attribute to specify the filename
      link.download =  props.dept === "Full Stack Mobile Development"
      ? fsd_app
      : props.dept === "Full Stack Web Development"
      ? fsd_web
      : fsd;
;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically trigger a click on the link
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);

      // Set the status after successful download (if needed)
      setStatus(0); // Set status to success
    } catch (error) {
      console.error("Error:", error);
      // Handle errors if necessary
    }
  };

  return (
    <div id="book_now">
      <div className="" id="mobile_apply_form">
        <Modal
          id="main-modal"
          className="d-lg-none d-block "
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="position-absolute top-0"
            style={{ right: "0" }}
          >
            <Modal.Title id=""></Modal.Title>
          </Modal.Header>
          {/* <Modal.Body> */}
          <div id="mobile_register_form">
            <div id="apply_for_text_bg">
              <div id="apply_for_text">
                Please, fill in the <br />
                form To
                <span style={{ color: "#006BFF" }}> download!</span>
              </div>
            </div>

            <div>
              <form className="position-relative" onSubmit={handleSubmit}>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="mobile_web_name"
                    id="mobile_web_name"
                    className="px-2"
                    value={name}
                    onChange={(e) => setName(e.target.value.trimStart())}
                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                    // onChange={(e) =>
                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                    // }
                    type="text"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    htmlFor="mobile_web_name"
                    className="position-absolute start-0 top-0 input-label"
                  >
                    Name
                  </label>
                </div>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="mobile_web_email"
                    id="mobile_web_email"
                    // className=""
                    className={email.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase().trimStart())}
                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                    // onChange={(e) =>
                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                    // }
                    type="email"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    className="position-absolute start-0 top-0 input-label"
                    htmlFor="mobile_web_email"
                  >
                    Email
                  </label>
                </div>
                <div id="name_input_field">
                  <div className="position-relative" id="name_input_field">
                    <input
                      name="mobile_web_phone_number"
                      id="mobile_web_phone_number"
                      // className="px-2"
                      className={phone.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                      value={phone}
                      maxLength={10}
                      onChange={(e) =>
                        setPhone(e.target.value.replace(/\D/g, ""))
                      }
                      // onChange={(e) => setCollege({ collegeName: e.target.value })}
                      // onChange={(e) =>
                      //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                      // }
                      type="phone"
                      // value={collegeName}
                      // placeholder="College/University Name"

                      required
                    />
                    <label
                      htmlFor="mobile_web_phone_number"
                      className="position-absolute start-0 top-0 input-label"
                    >
                      Phone Number
                    </label>
                  </div>
                </div>
                <div id="name_input_field">
                  <div className="row">
                    <div className="col-6">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="mobile_web_year"
                          id="mobile_web_year"
                          // className="px-2"
                          className={year.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                          value={year}
                          onChange={(e) => setYear(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="mobile_web_year"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Graduation Year
                        </label>
                      </div>
                    </div>
                    <div className="col-6 ps-0">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="mobile_web_degree"
                          id="mobile_web_degree"
                          className="px-2"
                          value={deg}
                          onChange={(e) => setDeg(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="mobile_web_degree"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Degree
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="mobile_border_bottom" className=""></div>

                <div id="mobile_form_submit_btn">
                  {status === 0 ? (
                    <button
                      className="btn"
                      type="submit"
                      style={{
                        height: "3.25rem",
                        background: "#02EE6A14",
                        border: "2px solid #02EE6A",
                        color: "#02EE6A",
                        fontSize: "0.8rem",
                        lineHeight: "2rem",
                      }}
                    >
                      <img
                        src={success_tick}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />{" "}
                      Thanks For Submitting!
                    </button>
                  ) : (
                    <button className="btn" type="submit">
                      Submit{" "}
                      {loading ? <PulseLoader color="white" size={6} /> : ""}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {/* </Modal.Body> */}

          {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}

export default BookNowFormWebMobile;
