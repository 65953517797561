import React, { useState, useEffect, useRef } from "react";
import "../Styles/InternBenefits.css";

function InternBenefits({ menu }) {
  useEffect(() => {
    // console.log("Active ref in TechieHeroSection:", menu);
  }, [menu]);
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "/techie-intern#benefits") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  const [scrollPosition, setScrollPosition] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 0 ? setScrollPosition("sticky") : setScrollPosition("");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const handleBenefits = () => {
    setShow1(true);
    setShow2(false);
    setShow3(false);
    setShow4(false);
  };
  const handleBenefits2 = () => {
    setShow2(true);
    setShow1(false);
    setShow3(false);
    setShow4(false);
  };
  const handleBenefits3 = () => {
    setShow3(true);
    setShow2(false);
    setShow1(false);
    setShow4(false);
  };
  const handleBenefits4 = () => {
    setShow4(true);
    setShow2(false);
    setShow3(false);
    setShow1(false);
  };

  return (
    <div id="benefits" ref={myRef}>
      <div className="container d-lg-block d-none" id="benefits_bg">
        <div id="benefits_topic">
          Benefits you’re going to get <br />
          from<span id="intern_program_text"> this program</span>
        </div>
        <div className="row">
          <div className="col-md-5 ">
            <div
              id="bene_sing_point_bg"
              onClick={handleBenefits}
              style={{
                border: show1
                  ? "2px solid #639C19"
                  : "1px solid var(--Foundation-Grey-G40, #DFE1E4)",
                color: show1 ? "#639C19" : "#79818D",
                fontWeight: show1 ? "500" : "400",
                cursor:"pointer"
              }}
            >
              Student Benefits
            </div>
            <div
              id="bene_sing_point_bg"
              onClick={handleBenefits2}
              style={{
                border: show2
                  ? "2px solid #639C19"
                  : "1px solid var(--Foundation-Grey-G40, #DFE1E4)",
                color: show2 ? "#639C19" : "#79818D",
                fontWeight: show2 ? "500" : "400",
                cursor:"pointer"
              }}
            >
            Equip
            </div>
            <div
              id="bene_sing_point_bg"
              onClick={handleBenefits3}
              style={{
                border: show3
                  ? "2px solid #639C19"
                  : "1px solid var(--Foundation-Grey-G40, #DFE1E4)",
                color: show3 ? "#639C19" : "#79818D",
                fontWeight: show3 ? "500" : "400",
                cursor:"pointer"
              }}
            >
             Eligibility Criteria
            </div>
            <div
              id="bene_sing_point_bg"
              onClick={handleBenefits4}
              style={{
                border: show4
                  ? "2px solid #639C19"
                  : "1px solid var(--Foundation-Grey-G40, #DFE1E4)",
                color: show4 ? "#639C19" : "#79818D",
                fontWeight: show4 ? "500" : "400",
                cursor:"pointer"
              }}
            >
             On boarding process
            </div>
          </div>

          {show1 ? (
            <div className="col-md-7 mt-0 pt-0 ">
              <div>
                <div id="benefits_sing_pt" className="d-flex mt-4">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Student will get an opportunity to physically join our
                    software development centers in Bangalore and Chennai.
                  </div>
                </div>
                <div id="benefits_sing_pt" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>Work on live projects.</div>
                </div>
                <div id="benefits_sing_pt" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>Hands-on practical learnings.</div>
                </div>
                <div id="benefits_sing_pt" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Peer-2-peer learning and interact with the Sr. IT Project
                    Professionals (India/USA).
                  </div>
                </div>
                <div id="benefits_sing_pt" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>1:1 Mentorship & More...</div>
                </div>
              </div>
            </div>
          ) : show2 ? (
            <div className="col-md-7">
              <div id="benefits_sing_pt" className="d-flex mt-4">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>
                Getting ready for the job market (campus interview and
offline job placement).
                </div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>Professional Resume Preparation.</div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>Soft skill and personality development.</div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>
                Learn how to face and clear job interviews.
                </div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>Entrepreneurship Development.</div>
              </div>
            </div>
          ) : show3 ? (
            <div className="col-md-7">
              <div id="benefits_sing_pt" className="d-flex mt-4">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>
                Degree with B.Tech/B.E/M.E/M.Tech/BCA/MCA/B.Sc
                </div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>Any Semester</div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>Good English Communication skill</div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>
                Positive Attitude
                </div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>Ability to learn quickly</div>
              </div>
            </div>
          ) : show4 ? (
            <div className="col-md-7">
              <div id="benefits_sing_pt" className="d-flex mt-4">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>
                Only eligible candidates can join the internship program.
                </div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>Techie Intern-30 & Techie Intern-60 programs require
at least one round on the interview selection process.</div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>Techie Intern-90 & Techie Intern-180 programs require
at least two rounds of the Interview selection process (HR
and Technical evaluation process).
</div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>
                Limited candidates are allowed in each session and it is
based on first-come-first basis.
                </div>
              </div>
              <div id="benefits_sing_pt" className="d-flex">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                      fill="#639C19"
                    />
                  </svg>
                </div>
                <div>If the student underperforms and not committed, he/she
might be advised to drop off the program.</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* for mobiles */}
      <div className="container d-lg-none d-block" id="mobile_benefits">
        <div id="mobile_benefits_topic">
          Benefits you’re going to get <br />
          from<span id="mobile_intern_program_text"> this program</span>
        </div>
        <div
          id="mobile_single_bg"
          onClick={handleBenefits}
          style={{
            border: show1 ? "1.5px solid #639C19" : "1px solid #DFE1E4",
          }}
        >
          <div
            style={{
              color: show1 ? "#639C19" : "#79818D",
              fontWeight: show1 ? "500" : "",
            }}
          >
            Students Benefits
          </div>
          <div>
            {show1 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.9995 8.9994L16.2422 13.242L14.828 14.6563L11.9995 11.8278L9.17111 14.6563L7.75691 13.242L11.9995 8.9994Z"
                  fill="#639C19"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.0005 15.0006L7.75781 10.758L9.17203 9.34375L12.0005 12.1722L14.8289 9.34375L16.2431 10.758L12.0005 15.0006Z"
                  fill="#79818D"
                />
              </svg>
            )}
          </div>
        </div>
        {show1 ? (
          <div>
            {" "}
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>
                Student will get an opportunity to physically join our software
                development centers in Bangalore and Chennai.
              </div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Work on live projects.</div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Hands-on practical learnings.</div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>
                Peer-2-peer learning and interact with the Sr. IT Project
                Professionals (India/USA).
              </div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>1:1 Mentorship & More...</div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div id="mobile_single_bg" onClick={handleBenefits2}   style={{
            border: show2 ? "1.5px solid #639C19" : "1px solid #DFE1E4",
          }}>
          <div   style={{
              color: show2 ? "#639C19" : "#79818D",
              fontWeight: show2 ? "500" : "",
            }}>Equip</div>
          <div>
          {show2 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.9995 8.9994L16.2422 13.242L14.828 14.6563L11.9995 11.8278L9.17111 14.6563L7.75691 13.242L11.9995 8.9994Z"
                  fill="#639C19"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.0005 15.0006L7.75781 10.758L9.17203 9.34375L12.0005 12.1722L14.8289 9.34375L16.2431 10.758L12.0005 15.0006Z"
                  fill="#79818D"
                />
              </svg>
            )}
          </div>
        </div>
        {show2 ? (
          <div>
            {" "}
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>
              Getting ready for the job market (campus interview and
offline job placement).
              </div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Professional Resume Preparation.</div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Soft skill and personality development.</div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>
              Learn how to face and clear job interviews
              </div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Entrepreneurship Development.</div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div id="mobile_single_bg" onClick={handleBenefits3} style={{
            border: show3 ? "1.5px solid #639C19" : "1px solid #DFE1E4",
          }}>
          <div style={{
              color: show3 ? "#639C19" : "#79818D",
              fontWeight: show3 ? "500" : "",
            }}>Eligibility Criteria</div>
          <div>
          {show3 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.9995 8.9994L16.2422 13.242L14.828 14.6563L11.9995 11.8278L9.17111 14.6563L7.75691 13.242L11.9995 8.9994Z"
                  fill="#639C19"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.0005 15.0006L7.75781 10.758L9.17203 9.34375L12.0005 12.1722L14.8289 9.34375L16.2431 10.758L12.0005 15.0006Z"
                  fill="#79818D"
                />
              </svg>
            )}
          </div>
        </div>
        {show3 ? (
          <div>
            {" "}
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>
              Degree with B.Tech/B.E/M.E/M.Tech/BCA/MCA/B.Sc
              </div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Any Semester</div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Good English Communication skill</div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>
              Positive Attitude
              </div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Ability to learn quickly</div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div id="mobile_single_bg" onClick={handleBenefits4}   style={{
            border: show4 ? "1.5px solid #639C19" : "1px solid #DFE1E4",
          }}>
          <div style={{
              color: show4 ? "#639C19" : "#79818D",
              fontWeight: show4 ? "500" : "",
            }}>On boarding process</div>
          <div>
          {show4 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.9995 8.9994L16.2422 13.242L14.828 14.6563L11.9995 11.8278L9.17111 14.6563L7.75691 13.242L11.9995 8.9994Z"
                  fill="#639C19"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.0005 15.0006L7.75781 10.758L9.17203 9.34375L12.0005 12.1722L14.8289 9.34375L16.2431 10.758L12.0005 15.0006Z"
                  fill="#79818D"
                />
              </svg>
            )}
          </div>
        </div>
        {show4 ? (
          <div>
            {" "}
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>
              Only eligible candidates can join the internship program.
              </div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Techie Intern-30 & Techie Intern-60 programs require
at least one round on the interview selection process.</div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>Techie Intern-90 & Techie Intern-180 programs require
at least two rounds of the Interview selection process (HR
and Technical evaluation process).</div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>
              Limited candidates are allowed in each session and it is
based on first-come-first basis.
              </div>
            </div>
            <div className="d-flex" id="mob_sing_point">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                    fill="#639C19"
                  />
                </svg>
              </div>
              <div>If the student underperforms and not committed, he/she
might be advised to drop off the program.</div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default InternBenefits;
