import React, { useEffect } from "react";
import "../Styles/TechiePro/Capstone.css";
import project1_img from "../Assets/techiepro/project1_img.png";
import project2_img from "../Assets/techiepro/project2_img.png";
function Capstone({ menu }) {
  useEffect(() => {
    // console.log("Active ref in TechieHeroSection:", menu);
  }, [menu]);

  return (
    <div id="capstone">
      {" "}
      <div id="capstone_bg">
        <div className="container d-lg-block d-none">
          <div id="capstone_topic">
            Get to work on real
            <br />
            <span id="capstone_project_text" style={{ color: "#8625D2" }}>
              {" "}
              Live Projects
            </span>{" "}
          </div>
          <div className="d-lg-block d-none">
            {" "}
            <div
              id="project_info_bg"
              className="d-md-flex flex-sm-column flex-lg-row"
            >
              <div>
                <img
                  src={project1_img}
                  alt=""
                  style={{ width: "34.5rem", height: "21.5rem" }}
                />
              </div>
              <div id="project_detail">
                <div id="project_topic">
                  Digital Wallet & Payment Application
                </div>
                In the fast-paced digital era, where convenience is key, digital
                wallets and payment apps have emerged as indispensable tools for
                managing finances and making transactions seamlessly. These
                innovative solutions have revolutionized the way we handle
                money, providing a secure and efficient alternative to
                traditional cash and card transactions.
                {/* <div id="project_key_points">
                  <div id="key_bg">Communication</div>
                  <div id="key_bg">Communication</div>
                </div> */}
              </div>
            </div>
            <div id="project_info_bg">
              {" "}
              <div id="project_detail">
                <div id="project_topic">E-commerce Applications</div>
                In the era of digital convenience, E-commerce applications have
                become the go-to solution for consumers seeking a hassle-free
                and efficient shopping experience. These applications bring the
                entire marketplace to users' fingertips, offering a wide array
                of products, personalized recommendations, and streamlined
                transactions.
                {/* <div id="project_key_points">
                  <div id="key_bg">Communication</div>
                  <div id="key_bg">Communication</div>
                </div> */}
              </div>
              <div>
                <img
                  src={project2_img}
                  alt=""
                  style={{ width: "34.5rem", height: "21.5rem" }}
                />
              </div>
            </div>
          </div>

          {/* for mobiles */}
        </div>
        {/* for mobiles */}
        <div className="container d-lg-none d-block">
          <div id="mobile_capstone_topic">
            Get to work on real <br />
            <span
              id="mobile_capstone_project_text"
              style={{ color: "#8625D2" }}
            >
              {" "}
              Live Projects
            </span>{" "}
          </div>
          <div className="">
            {" "}
            <div
              id="mobile_project_info_bg"
              className="d-md-flex flex-sm-column flex-lg-row"
            >
              <div>
                <img src={project1_img} alt="" width="100%" />
              </div>
              <div id="mobile_project_detail">
                <div id="mobile_project_topic">
                  Digital Wallet & Payment Application
                </div>
                In the fast-paced digital era, where convenience is key, digital
                wallets and payment apps have emerged as indispensable tools for
                managing finances and making transactions seamlessly. These
                innovative solutions have revolutionized the way we handle
                money, providing a secure and efficient alternative to
                traditional cash and card transactions.
                {/* <div id="mobile_project_key_points">
                  <div id="mobile_key_bg">Communication</div>
                  <div id="mobile_key_bg">Communication</div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="">
            {" "}
            <div
              id="mobile_project_info_bg"
              className="d-md-flex flex-sm-column flex-lg-row"
            >
              <div>
                <img src={project2_img} alt="" width="100%" />
              </div>
              <div id="mobile_project_detail">
                <div id="mobile_project_topic">E-commerce Applications</div>
                In the era of digital convenience, E-commerce applications have
                become the go-to solution for consumers seeking a hassle-free
                and efficient shopping experience. These applications bring the
                entire marketplace to users' fingertips, offering a wide array
                of products, personalized recommendations, and streamlined
                transactions.
                {/* <div id="mobile_project_key_points">
                  <div id="mobile_key_bg">Communication</div>
                  <div id="mobile_key_bg">Communication</div>
                </div> */}
              </div>
            </div>
          </div>

          {/* <div >
          <div
            id="mobile_project_info_bg"
            className="d-md-flex flex-sm-column flex-column "
          >
            <div>
              <img src={project1_img} alt="" width="100%" />
            </div>
            <div id="mobile_project_detail"className="g-0">
              <div id="mobile_project_topic" >Movie Streaming Application</div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
              <div id="mobile_project_key_points">
                <div id="mobile_key_bg">Communication</div>
                <div id="mobile_key_bg">Communication</div>
              </div>
            </div>
          </div>
          <div
            id="mobile_project_info_bg"
            className="d-md-flex flex-sm-column flex-column "
          >
            {" "}
            <div id="mobile_project_detail">
              {" "}
              <div>
                <img src={project2_img} alt="" width="100%" />
              </div>
              <div id="mobile_project_topic">Movie Streaming Application</div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
              <div id="mobile_project_key_points">
                <div id="mobile_key_bg">Communication</div>
                <div id="mobile_key_bg">Communication</div>
              </div>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default Capstone;
