import React from "react";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
// import {AiFillPlayCircle,AiOutlineRight} from "react-icons/ai";
import brud_crumbs_arrow_icon from "../Assets/brudcrums_arrow_icon.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import full_stack_blog_img from "../Assets/full_stack_blog_img.avif";
const FullStackBlogDetails = () => {
  return (
    <div>
      <Header />
      <div className="py-3" style={{ background: "#FAFAFB" }}>
        <div className="container">
          <div className="d-flex align-items-center mt-5 ">
            <div className="d-flex mt-5 align-items-center">
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: "#065BD6",
                  lineHeight: "16px",
                }}
              >
               <a href="/ezine" className="text-decoration-none">EZine</a> 
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <LazyLoadImage src={brud_crumbs_arrow_icon} width="100%"></LazyLoadImage>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#07152C",
                }}
              >
                {" "}
                Full Stack Develo...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex py-3 mt-5">
          <div className="">
            <div
              className=""
              style={{
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                lineHeight: "16px",
                background: "#07152C",
                opacity: "100%",
                bottom: "12px",
                left: "20px",
                Zindex: "3",
                padding: "4px 12px",
                borderRadius: "12px",
              }}
            >
              Technology
            </div>
          </div>
          <div
            className="d-flex my-auto px-2"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#6A7380",
            }}
          >
            <div>14 DEC 2022</div>
            <div
              style={{
                paddingLeft: "8px",
                fontSize: "2rem",
                marginTop: "-5%",
              }}
            >
              .
            </div>
            <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 ">
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "50px",
                  lineHeight: "60px",
                  marginTop: "36px",
                }}
              >
                Full Stack Developer Salary In India 2023
              </div>
            </div>
            <div className="mt-5">
              <LazyLoadImage
                src={full_stack_blog_img}
                className="w-100"
                alt="blog details image"
              />
            </div>
            <div className="py-3">
              
            </div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Hello Job Seekers! Glad that you are here today to explore more
              about the developer career and Full Stack Developer Salary In
              India 2023. Gone are the days when students were bored with coding
              and dared to get into a technical job.{" "}
              <div className="mt-4">
                As there is an increase in digital transformations across all
                industries and the need for employees who can help organisations
                effectively manage their use of technology, there is a great
                demand for technical jobs in the recent days which come with
                good pay, exotic learning opportunities, accelerated career
                growth due to which most of the job seekers are drawn towards it
                irrespective of their educational qualification/domain.
              </div>
              <div className="mt-4">
                When it comes to technical jobs, there are two positions which
                are widely spoken about by freshers’ and yes job seekers
                aspiring to launch their career in technical domain. Here we go!
                software development and software testing. Software testers must
                ensure the product is usable while developers work on creating
                it. Nevertheless, there has always been a debate over developer
                and tester on salary comparison, complexity and career growth.
                No matter what the differences are, at the end of the day what
                lies beneath is the interest of the individuals and their
                aspirations.
              </div>{" "}
              <div className="mt-4">
                In this blog, we will go deeper about full stack development
                jobs, requirements, and salary range and career opportunities.
              </div>
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                What does a full stack developer do?
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              A full-stack developer supports in building and maintaining
              front-end development and the back-end development of a website. A
              full-stack developer’s work description include the following:{" "}
            </div>
            <div
              className="mt-4"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              <li>Developing and maintaining web services and interfaces</li>
              <li>Work on both front-end and back-end development processes</li>
              <li>Build new product features or APIs</li>
              <li>
                Perform testing , troubleshooting the Website and software, and
                solve the bugs
              </li>
              <li>
                Have to collaborate work with other departments on projects and
                sprints
              </li>{" "}
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              As a full-stack developer, you might need to be familiar with the
              following tools and programming languages:{" "}
            </div>
            <div
              className="mt-4"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              <li>Angular JS</li>
              <li>Apache HTTP Server</li>
              <li>AWS</li>
              <li>CSS</li>
              <li>Ember.js</li>
              <li>JavaScript</li>
              <li>jQuery</li>
              <li>Laravel</li>
              <li>Microsoft SQL Server</li>
              <li>MySQL</li>
              <li>Nginx</li>
              <li>Node.js</li>
              <li>PHP</li>
              <li>Polymer</li>
              <li>React</li>
              <li>Ruby on Rails</li>
              <li>Vue.js</li>
            </div>

            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Salary Range:
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Full Stack Developer salary in India ranges between ₹ 2.2 Lakhs to
              ₹ 16.0 Lakhs with an average annual salary of ₹ 6.0 Lakhs
              approximately.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              A full-stack developer’s salary in India 2023 is ₹375,000 annually
              for freshers.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              A early-level experience full-stack developer with of 1-4 years
              earns a standard of ₹553,006 annually
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              An Average Full stack Developer mid-level experience of 5-9 years
              can secure about INR 12-14 lakhs annually.
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                How To Become A Full Stack Developer?
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Well, by now you have a basic understanding of full stack
              development and the salary range. Let us discuss how to become a
              full stack developer.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              First criterion is to have a degree in Computer Science (B.COM,
              BCA, B.E, B.Sc) or any domain. There are various best software
              training institutes in chennai offering full time/part time
              courses on full stack development with job guarantee to help
              people realise their dreams.
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Conclusion
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font mb-5"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Thank you Guys!! Hope it was worth spending your time to learn
              about full stack development and full stack developer salary in
              India 2023. Do check the NYINST which provides best learning
              experience with live project & also with job placement. Wishing
              you all the very best for your dreams to come true.
            </div>
           
          </div>
          {/* <div className='col-md-3'>Enquiry Now</div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FullStackBlogDetails;
