import React from "react";
import "../Styles/ScholarShip/AboutSch.css";
function AboutSch() {
  return (
    <div>
      <div className="container d-lg-block d-none">
        <div className="row " id="sch_points_bg">
          <div className="col-md-4">
            <div id="sch_single_bg">
              <div className="d-flex align-items-center">
                <div id="img_bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M14.9987 1.66666V4.99999H24.9987V1.66666H28.332V4.99999H34.9987C35.9192 4.99999 36.6654 5.74619 36.6654 6.66666V33.3333C36.6654 34.2538 35.9192 35 34.9987 35H4.9987C4.07823 35 3.33203 34.2538 3.33203 33.3333V6.66666C3.33203 5.74619 4.07823 4.99999 4.9987 4.99999H11.6654V1.66666H14.9987ZM33.332 18.3333H6.66536V31.6667H33.332V18.3333ZM13.332 21.6667V25H9.9987V21.6667H13.332ZM21.6654 21.6667V25H18.332V21.6667H21.6654ZM29.9987 21.6667V25H26.6654V21.6667H29.9987ZM11.6654 8.33332H6.66536V15H33.332V8.33332H28.332V11.6667H24.9987V8.33332H14.9987V11.6667H11.6654V8.33332Z"
                      fill="#006BFF"
                    />
                  </svg>
                </div>
                <div id="abt_point_text">
                Apply & Get Notified
                  <div id="abt_sub_point">Upcoming Test</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="sch_single_bg">
              <div className="d-flex align-items-center">
                <div id="img_bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M19.9987 36.6667C10.7939 36.6667 3.33203 29.2047 3.33203 20C3.33203 10.7953 10.7939 3.33334 19.9987 3.33334C29.2034 3.33334 36.6654 10.7953 36.6654 20C36.6654 29.2047 29.2034 36.6667 19.9987 36.6667ZM19.9987 33.3333C27.3625 33.3333 33.332 27.3638 33.332 20C33.332 12.6362 27.3625 6.66668 19.9987 6.66668C12.6349 6.66668 6.66536 12.6362 6.66536 20C6.66536 27.3638 12.6349 33.3333 19.9987 33.3333ZM21.6654 20H28.332V23.3333H18.332V11.6667H21.6654V20Z"
                      fill="#006BFF"
                    />
                  </svg>
                </div>
                <div id="abt_point_text">
                  30 Minutes
                  <div id="abt_sub_point">Duration</div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="col-md-4">
            <div id="sch_single_bg">
              <div className="d-flex align-items-center">
                <div id="img_bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M29.7487 19.4972L28.5702 18.3187L12.0711 34.8178H5V27.7467L23.8562 8.89052L33.2843 18.3187C33.9352 18.9695 33.9352 20.0248 33.2843 20.6757L21.4992 32.4608L19.1422 30.1037L29.7487 19.4972ZM26.2132 15.9616L23.8562 13.6046L8.33333 29.1275V31.4845H10.6904L26.2132 15.9616ZM30.9272 4.17647L35.6413 8.89052C36.2922 9.54139 36.2922 10.5967 35.6413 11.2475L33.2843 13.6046L26.2132 6.53349L28.5702 4.17647C29.2212 3.52561 30.2763 3.52561 30.9272 4.17647Z"
                      fill="#006BFF"
                    />
                  </svg>
                </div>
                <div id="abt_point_text">
                  Online/Offline
                  <div id="abt_sub_point">Test Mode</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-md-4">
            <div id="sch_single_bg">
              <div className="d-flex align-items-center">
                <div id="img_bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M33.3333 36.6667H6.66667C5.7462 36.6667 5 35.9205 5 35V4.99999C5 4.07953 5.7462 3.33333 6.66667 3.33333H33.3333C34.2538 3.33333 35 4.07953 35 4.99999V35C35 35.9205 34.2538 36.6667 33.3333 36.6667ZM31.6667 33.3333V6.66666H8.33333V33.3333H31.6667ZM11.6667 9.99999H18.3333V16.6667H11.6667V9.99999ZM11.6667 20H28.3333V23.3333H11.6667V20ZM11.6667 26.6667H28.3333V30H11.6667V26.6667ZM21.6667 11.6667H28.3333V15H21.6667V11.6667Z"
                      fill="#006BFF"
                    />
                  </svg>
                </div>
                <div id="abt_point_text">
                  10 MCQ’S + Coding
                  <div id="abt_sub_point">Questions</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div id="sch_single_bg">
              <div className="d-flex align-items-center">
                <div id="img_bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M6.66536 30V23.8333C6.66536 22.4527 5.54608 21.3333 4.16536 21.3333H3.33203V18.6667H4.16536C5.54608 18.6667 6.66536 17.5473 6.66536 16.1667V10C6.66536 7.23858 8.90395 5 11.6654 5H13.332V8.33333H11.6654C10.7449 8.33333 9.9987 9.07953 9.9987 10V16.8333C9.9987 18.3097 9.03883 19.562 7.70908 20C9.03883 20.438 9.9987 21.6903 9.9987 23.1667V30C9.9987 30.9205 10.7449 31.6667 11.6654 31.6667H13.332V35H11.6654C8.90395 35 6.66536 32.7615 6.66536 30ZM33.332 23.8333V30C33.332 32.7615 31.0935 35 28.332 35H26.6654V31.6667H28.332C29.2525 31.6667 29.9987 30.9205 29.9987 30V23.1667C29.9987 21.6903 30.9585 20.438 32.2884 20C30.9585 19.562 29.9987 18.3097 29.9987 16.8333V10C29.9987 9.07953 29.2525 8.33333 28.332 8.33333H26.6654V5H28.332C31.0935 5 33.332 7.23858 33.332 10V16.1667C33.332 17.5473 34.4514 18.6667 35.832 18.6667H36.6654V21.3333H35.832C34.4514 21.3333 33.332 22.4527 33.332 23.8333Z"
                      fill="#006BFF"
                    />
                  </svg>
                </div>
                <div id="abt_point_text">
                Coding Fundamentals
                  <div id="abt_sub_point">Syllabus</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
      {/* for mobiles */}
      <div className="d-lg-none d-block container">
        <div id="mob_abt_bg">
          <div id="mob_single_abt_bg">
            <div id="mob_abt_icon_bg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M9.7474 1.08331V3.24998H16.2474V1.08331H18.4141V3.24998H22.7474C23.3457 3.24998 23.8307 3.73501 23.8307 4.33331V21.6666C23.8307 22.265 23.3457 22.75 22.7474 22.75H3.2474C2.64909 22.75 2.16406 22.265 2.16406 21.6666V4.33331C2.16406 3.73501 2.64909 3.24998 3.2474 3.24998H7.58073V1.08331H9.7474ZM21.6641 11.9166H4.33073V20.5833H21.6641V11.9166ZM8.66406 14.0833V16.25H6.4974V14.0833H8.66406ZM14.0807 14.0833V16.25H11.9141V14.0833H14.0807ZM19.4974 14.0833V16.25H17.3307V14.0833H19.4974ZM7.58073 5.41665H4.33073V9.74998H21.6641V5.41665H18.4141V7.58331H16.2474V5.41665H9.7474V7.58331H7.58073V5.41665Z"
                  fill="#006BFF"
                />
              </svg>
            </div>
            <div id="mob_abt_text">
            Apply & Get Notified
              <div id="mob_abt_cont">Upcoming Test</div>
            </div>
          </div>
          <div id="mob_single_abt_bg">
            <div id="mob_abt_icon_bg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M19.9987 36.6667C10.7939 36.6667 3.33203 29.2047 3.33203 20C3.33203 10.7953 10.7939 3.33334 19.9987 3.33334C29.2034 3.33334 36.6654 10.7953 36.6654 20C36.6654 29.2047 29.2034 36.6667 19.9987 36.6667ZM19.9987 33.3333C27.3625 33.3333 33.332 27.3638 33.332 20C33.332 12.6362 27.3625 6.66668 19.9987 6.66668C12.6349 6.66668 6.66536 12.6362 6.66536 20C6.66536 27.3638 12.6349 33.3333 19.9987 33.3333ZM21.6654 20H28.332V23.3333H18.332V11.6667H21.6654V20Z"
                  fill="#006BFF"
                />
              </svg>
            </div>
            <div id="mob_abt_text">
              30 Minutes
              <div id="mob_abt_cont">Duration</div>
            </div>
          </div>
          <div id="mob_single_abt_bg">
            <div id="mob_abt_icon_bg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M29.7487 19.4972L28.5702 18.3187L12.0711 34.8178H5V27.7467L23.8562 8.89052L33.2843 18.3187C33.9352 18.9695 33.9352 20.0248 33.2843 20.6757L21.4992 32.4608L19.1422 30.1037L29.7487 19.4972ZM26.2132 15.9616L23.8562 13.6046L8.33333 29.1275V31.4845H10.6904L26.2132 15.9616ZM30.9272 4.17647L35.6413 8.89052C36.2922 9.54139 36.2922 10.5967 35.6413 11.2475L33.2843 13.6046L26.2132 6.53349L28.5702 4.17647C29.2212 3.52561 30.2763 3.52561 30.9272 4.17647Z"
                  fill="#006BFF"
                />
              </svg>
            </div>
            <div id="mob_abt_text">
              Online/Offline
              <div id="mob_abt_cont">Test Mode</div>
            </div>
          </div>
          <div id="mob_single_abt_bg">
            <div id="mob_abt_icon_bg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M33.3333 36.6667H6.66667C5.7462 36.6667 5 35.9205 5 35V4.99999C5 4.07953 5.7462 3.33333 6.66667 3.33333H33.3333C34.2538 3.33333 35 4.07953 35 4.99999V35C35 35.9205 34.2538 36.6667 33.3333 36.6667ZM31.6667 33.3333V6.66666H8.33333V33.3333H31.6667ZM11.6667 9.99999H18.3333V16.6667H11.6667V9.99999ZM11.6667 20H28.3333V23.3333H11.6667V20ZM11.6667 26.6667H28.3333V30H11.6667V26.6667ZM21.6667 11.6667H28.3333V15H21.6667V11.6667Z"
                  fill="#006BFF"
                />
              </svg>
            </div>
            <div id="mob_abt_text">
              10 MCQ’S + Coding
              <div id="mob_abt_cont">Questions</div>
            </div>
          </div>
          <div id="mob_single_abt_bg">
            <div id="mob_abt_icon_bg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M6.66536 30V23.8333C6.66536 22.4527 5.54608 21.3333 4.16536 21.3333H3.33203V18.6667H4.16536C5.54608 18.6667 6.66536 17.5473 6.66536 16.1667V10C6.66536 7.23858 8.90395 5 11.6654 5H13.332V8.33333H11.6654C10.7449 8.33333 9.9987 9.07953 9.9987 10V16.8333C9.9987 18.3097 9.03883 19.562 7.70908 20C9.03883 20.438 9.9987 21.6903 9.9987 23.1667V30C9.9987 30.9205 10.7449 31.6667 11.6654 31.6667H13.332V35H11.6654C8.90395 35 6.66536 32.7615 6.66536 30ZM33.332 23.8333V30C33.332 32.7615 31.0935 35 28.332 35H26.6654V31.6667H28.332C29.2525 31.6667 29.9987 30.9205 29.9987 30V23.1667C29.9987 21.6903 30.9585 20.438 32.2884 20C30.9585 19.562 29.9987 18.3097 29.9987 16.8333V10C29.9987 9.07953 29.2525 8.33333 28.332 8.33333H26.6654V5H28.332C31.0935 5 33.332 7.23858 33.332 10V16.1667C33.332 17.5473 34.4514 18.6667 35.832 18.6667H36.6654V21.3333H35.832C34.4514 21.3333 33.332 22.4527 33.332 23.8333Z"
                  fill="#006BFF"
                />
              </svg>
            </div>
            <div id="mob_abt_text">
              Coding Fundamentals
              <div id="mob_abt_cont">Syllabus</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSch;
