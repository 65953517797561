import React from "react";
import how_it_work_img from "../Assets/HireFromUs/how_it_work_bg.jpg";
import "../Styles/HireFromUs/HowItWork.css";
import it_work1 from "../Assets/HireFromUs/it_work1.png";
import it_work2 from "../Assets/HireFromUs/it_work2.png";
import it_work3 from "../Assets/HireFromUs/it_work3.png";
import it_work4 from "../Assets/HireFromUs/it_work4.png";
import it_work5 from "../Assets/HireFromUs/it_work5.png";
import it_work6 from "../Assets/HireFromUs/it_work6.png";
function HowItWork() {
  return (
    <div>
      <div id="it_work_bg" className="d-lg-block d-none">
        <div
          className="position-relative"
          style={{ height: "54.25rem" }}
        >
          <img
            src={how_it_work_img}
            alt=""
            width="100%"
            style={{ objectFit: "cover", height: "54.25rem" }}
          />
          <div className="position-absolute top-50 w-75 translate-middle start-50">
            <div id="how_it_work_bg" className="" align="center">
              <div id="it_work_title_bg">
                <div id="it_work_first_title">How does it work?</div>
                <div id="second_title">We work as a team</div>
              </div>
              <div id="it_work_points_bg" className="d-flex align-items-center" align="left">
                <div className="">
                  <div id="it_work_single_point_bg">
                    <div id="img_bg">
                      <img src={it_work1} alt="" width="100%" />
                    </div>
                    <div id="collect_text">
                      1. To collect your requirements
                      <div id="it_work_sub_title">
                        Our Team will coordinate with you
                      </div>
                    </div>
                  </div>
                  <div id="it_work_single_point_bg">
                    <div id="img_bg">
                      <img src={it_work2} alt="" width="100%" />
                    </div>
                    <div id="collect_text">
                    2. We will find the right pick
                      <div id="it_work_sub_title">
                      For the requirement provided
                      </div>
                    </div>
                  </div>
                  <div id="it_work_single_point_bg">
                    <div id="img_bg">
                      <img src={it_work3} alt="" width="100%" />
                    </div>
                    <div id="collect_text">
                    3. Send profiles to you within 2 hrs
                      <div id="it_work_sub_title">
                      From the best resources we have
                      </div>
                    </div>
                  </div>
                
                </div>
                <div className="">
                <div id="it_work_single_point_bg">
                    <div id="img_bg">
                      <img src={it_work4} alt="" width="100%" />
                    </div>
                    <div id="collect_text">
                    4. Review resumes from your side
                      <div id="it_work_sub_title">
                    To see the profile aligns to you
                      </div>
                    </div>
                  </div>
                  <div id="it_work_single_point_bg">
                    <div id="img_bg">
                      <img src={it_work5} alt="" width="100%" />
                    </div>
                    <div id="collect_text">
                    5. To Schedule & Start Interview
                      <div id="it_work_sub_title">
                      Do your process to find the right fit
                      </div>
                    </div>
                  </div>
                  <div id="it_work_single_point_bg">
                    <div id="img_bg">
                      <img src={it_work6} alt="" width="100%" />
                    </div>
                    <div id="collect_text">
                    6. Your results for the candidates
                      <div id="it_work_sub_title">
                      Candidates will join ASAP
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* for mobiles */}
      <div id="mobile_it_work_bg" className="d-lg-none d-block">
        <div
          className="position-relative"
          // style={{ height: "54.25rem" }}
        >
          <img
            src={how_it_work_img}
            alt=""
            width="100%"
            style={{ objectFit: "cover", height: "54.25rem" }}
          />
          <div className="position-absolute top-50  translate-middle start-50 container">
            <div id="mobile_how_it_work_bg" className="" align="center">
              <div id="mobile_it_work_title_bg">
                <div id="mobile_it_work_first_title">How does it work?</div>
                <div id="mobile_second_title">We work as a team</div>
              </div>
              <div id="mobile_it_work_points_bg" className="row w-100" align="left">
                <div className="">
                  <div id="mobile_it_work_single_point_bg">
                    <div id="mobile_img_bg">
                      <img src={it_work1} alt="" width="100%" />
                    </div>
                    <div id="mobile_collect_text">
                      1. To collect your requirements
                      <div id="mobile_it_work_sub_title">
                        Our Team will coordinate with you
                      </div>
                    </div>
                  </div>
                  <div id="mobile_it_work_single_point_bg">
                    <div id="mobile_img_bg">
                      <img src={it_work2} alt="" width="100%" />
                    </div>
                    <div id="mobile_collect_text">
                    2. We will find the right pick
                      <div id="mobile_it_work_sub_title">
                      For the requirement provided
                      </div>
                    </div>
                  </div>
                  <div id="mobile_it_work_single_point_bg">
                    <div id="mobile_img_bg">
                      <img src={it_work3} alt="" width="100%" />
                    </div>
                    <div id="mobile_collect_text">
                    3. Send profiles to you within 2 hrs
                      <div id="mobile_it_work_sub_title">
                      From the best resources we have
                      </div>
                    </div>
                  </div>
                
                </div>
                <div className="">
                <div id="mobile_it_work_single_point_bg">
                    <div id="mobile_img_bg">
                      <img src={it_work4} alt="" width="100%" />
                    </div>
                    <div id="mobile_collect_text">
                    4. Review resumes from your side
                      <div id="mobile_it_work_sub_title">
                    To see the profile aligns to you
                      </div>
                    </div>
                  </div>
                  <div id="mobile_it_work_single_point_bg">
                    <div id="mobile_img_bg">
                      <img src={it_work5} alt="" width="100%" />
                    </div>
                    <div id="mobile_collect_text">
                    5. To Schedule & Start Interview
                      <div id="mobile_it_work_sub_title">
                      Do your process to find the right fit
                      </div>
                    </div>
                  </div>
                  <div id="mobile_it_work_single_point_bg">
                    <div id="mobile_img_bg">
                      <img src={it_work6} alt="" width="100%" />
                    </div>
                    <div id="mobile_collect_text">
                    6. Your results for the candidates
                      <div id="mobile_it_work_sub_title">
                      Candidates will join ASAP
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWork;
