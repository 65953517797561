import React from "react";
import "../Styles/Webinars.css";
import full_stack_webinar from "../Assets/full_stack_webinar.png";
import data_analytics from "../Assets/data_analytics_webinar.png";
import qa from "../Assets/qa_webinar.png";
function Webinars() {
  return (
    <div>
      <div id="webinar_bg">
        <div className="container-fluid d-lg-block d-none">
          <div id="webinar_topics" align="center">
            Level up your Skills With Our Webinars
            <div id="sub_topic">The best 45 to 60 mins you’ll get</div>
          </div>
          <div id="webinars_bg" align="center">
            <div className="row" align="left">
              {" "}
              <div className="col-4">
                {" "}
                <a
                  href="https://youtu.be/ohFY_n1rtT0"
                  className="text-decoration-none"
                  target="_blank"
                >
                  <div id="first_webinar_bg">
                    <div>
                      <img src={data_analytics} alt="" width="100%" />
                    </div>
                    <div
                      id="webinar_detail"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div id="webinar_status">
                        COMPLETED
                        <div id="webinar_date">3 Apr 2023 | 7:00 PM IST</div>
                      </div>
                      <div id="register_bg">Watch Now</div>
                    </div>
                  </div>
                </a>
              </div>{" "}
              <div className="col-4">
                {" "}
                <a
                  href="https://youtu.be/NMlUjy4TLJo"
                  className="text-decoration-none"
                  target="_blank"
                >
                  <div id="first_webinar_bg">
                    <div>
                      <img src={full_stack_webinar} alt="" width="100%" />
                    </div>
                    <div
                      id="webinar_detail"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div id="webinar_status">
                        COMPLETED
                        <div id="webinar_date">3 Apr 2023 | 7:00 PM IST</div>
                      </div>
                      <div id="register_bg">Watch Now</div>
                    </div>
                  </div>{" "}
                </a>
              </div>{" "}
              <div className="col-4">
                {" "}
                <a
                  href="https://youtu.be/5IvcC0zdW6s"
                  className="text-decoration-none"
                  target="_blank"
                >
                  <div id="first_webinar_bg">
                    <div>
                      <img src={qa} alt="" width="100%" />
                    </div>
                    <div
                      id="webinar_detail"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div id="webinar_status">
                        COMPLETED
                        <div id="webinar_date">3 Apr 2023 | 7:00 PM IST</div>
                      </div>
                      <div id="register_bg">Watch Now</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            {/* <div id="view_more_button" align="center">
              View All
              <svg
                style={{ marginLeft: "1.7vh" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                  fill="#006BFF"
                />
              </svg>
            </div> */}
          </div>
        </div>
        <div className="d-block d-lg-none">
          <div className="container-fluid">
            <div id="mobile_webinar_topics">
              Level up your Skills With Our Webinars
            </div>
            <div id="mobile_webinar_sub_topics">
              The best 45 to 60 mins you’ll get
            </div>
            <a
              href="https://youtu.be/ohFY_n1rtT0"
              className="text-decoration-none"
              target="_blank"
            >
              <div id="mobile_single_webinar_bg">
                <div>
                  <img src={data_analytics} alt="" width="100%" />
                </div>
                <div id="mobile_webinar_data">
                  <div>COMPLETED</div>
                  <div id="mobile_webinar_date">3 Apr 2023 | 7:00 PM IST</div>
                </div>
                <div id="mobile_reg_button">Watch Now</div>
              </div>
            </a>
            <a
              href="https://youtu.be/NMlUjy4TLJo"
              className="text-decoration-none"
              target="_blank"
            >
              <div id="mobile_single_webinar_bg">
                <div>
                  <img src={full_stack_webinar} alt="" width="100%" />
                </div>
                <div id="mobile_webinar_data">
                  <div>COMPLETED</div>
                  <div id="mobile_webinar_date">3 Apr 2023 | 7:00 PM IST</div>
                </div>
                <div id="mobile_reg_button">Watch Now</div>
              </div>
            </a>
            <a
              href="https://youtu.be/5IvcC0zdW6s"
              className="text-decoration-none"
              target="_blank"
            >
              <div id="mobile_single_webinar_bg">
                <div>
                  <img src={qa} alt="" width="100%" />
                </div>
                <div id="mobile_webinar_data">
                  <div>COMPLETED</div>
                  <div id="mobile_webinar_date">3 Apr 2023 | 7:00 PM IST</div>
                </div>
                <div id="mobile_reg_button">Watch Now</div>
              </div>
            </a>
            {/* <div id="mobile_view_more_button" align="center">
              View All
              <svg
                className="ms-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                  fill="#006BFF"
                />
              </svg>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Webinars;
