import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import Footer from "../Pages//Footer";
import Header from "../Pages/Header";
import panimalar_institute_img1 from "../Assets/panimalar_institute_img1.jpg";
import panimalar_institute_img2 from "../Assets/panimalar_institute_img2.jpeg";
import panimalar_institute_img3 from "../Assets/panimalar_institute_img3.jpeg";
import panimalar_institute_img4 from "../Assets/panimalar_institute_img4.jpg";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const PanimalarEvent = () => {
  return (
    <div>
      <Header />
      <div className="mb-5 " style={{ background: "#065BD6" }}>
        <div className="container py-5">
          <div className="row container">
            <div
              className="col-lg-2 p-0 m-0 text-white text-truncate"
              style={{
                fontSize: "13px",
                lineHeight: "16px",
                whiteSpace: "no-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <a href="/events" className=" text-white">
                Meet & greet Home
                <AiOutlineRight />
              </a>{" "}
              Panimalar Institute of Technology – NYINST conducted an
              on-campus recruitment{" "}
            </div>
          </div>
          <div className="row">
            <div className=" col-lg-2 text-white"></div>
            <div className="col-lg-8 text-white ">
              <div>
                <div
                  className="d-flex"
                  style={{
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontWeight: "500",
                  }}
                >
                  <div className="text-start">18 FEB 2019 </div>
                  <div
                    className="mobile_dot_position"
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      fontSize: "2rem",
                      marginTop: "-1%",
                    }}
                  >
                    .
                  </div>
                  <div> 02:00pm</div>
                </div>

                <div className="text-start events_title_font_style">
                  Panimalar Institute of Technology – NYINST conducted an
                  on-campus recruitment
                </div>
                <div
                  className="container mobile_hero_event_height"
                  style={{ height: "15rem" }}
                >
                  <img
                    src={panimalar_institute_img1}
                    className="w-100 "
                    style={{ bottom: "-50%" }}
                    alt="events details"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
      {/* events bottom height */}
      <div
        className="mobile_bottom_event_height"
        style={{ height: "15rem" }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="py-3 mt-lg-5 events_content_font_style">
              Recently, NYINST conducted an on-campus recruitment drive
              for all final year students of Panimalar Institute of Technology.
              Students eagerly participated in the screening test and got
              selected to move further with our GTM program focusing on
              providing the best placement offers with better work experience to
              build their personalities and talents
            </div>
            <div className="py-3 w-100">
              <img
                src={panimalar_institute_img2}
                className="w-100 img-fluid"
                alt="block chain seminar "
              />
            </div>
            <div className="py-3 w-100">
              <img
                src={panimalar_institute_img3}
                className="w-100 img-fluid"
                alt="block chain seminar "
              />
            </div>
            <div className="py-3 w-100">
              <img
                src={panimalar_institute_img4}
                className="w-100 img-fluid"
                alt="block chain seminar "
              />
            </div>
          </div>

          <div className="col-lg-2"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PanimalarEvent;
