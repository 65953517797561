import React, { useState } from "react";
import techie_pro_logo from "../Assets/t_pro.png";
import "../Styles/TechiePro/TechieHeroSection.css";
import tpro_hero from "../Assets/techiepro/pro_hero_img.png";
import launch_rocket_img from "../Assets/techiepro/launch_rocket_img.png";
import job_assistance_icon from "../Assets/techiepro/job_asst.png";
import duration_icon from "../Assets/techiepro/duration.png";
import online_exp_icon from "../Assets/techiepro/onsite.png";
import salary_icon from "../Assets/techiepro/industry_sal.png";
import limited_icon from "../Assets/techiepro/limited_intakes.png";
// import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import ApplyForm from "./ApplyForm";
import ApplyNow from "./ApplyNow";

function TechieHeroSection() {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function MyVerticallyCenteredModal(props) {
    return (
      // <Modal
      //   {...props}
      //   size="lg"
      //   aria-labelledby="contained-modal-title-vcenter"
      //   centered
      // >
      //   <Modal.Header closeButton>
      //     <Modal.Title id="contained-modal-title-vcenter">
      //       Modal heading
      //     </Modal.Title>
      //   </Modal.Header>
      //   <Modal.Body>
      //     <h4>Centered Modal</h4>
      //     <p>
      //       Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
      //       dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
      //       ac consectetur ac, vestibulum at eros.
      //     </p>
      //   </Modal.Body>
      //   <Modal.Footer>
      //     <Button onClick={props.onHide}>Close</Button>
      //   </Modal.Footer>
      // </Modal>
      <ApplyForm show={modalShow} onHide={() => setModalShow(false)} />
    );
  }

  return (
    <div>
      <div id="techie_bg">
        <div className="container d-lg-block d-none">
          <div className="row">
            {" "}
            <div className="col-md-6">
              <div id="pro_hero_content_bg">
                <div id="pro_img_bg">
                  <img src={techie_pro_logo} alt="" />
                </div>
                <div id="pro_hero_content" style={{ color: "#222F43" }}>
                  Your Journey Into
                  <br />
                  <span
                    id="software_text"
                    style={{
                      color: "#222F43",
                      fontSize: "2.5rem",
                      fontWeight: "400",
                      lineHeight: "3.5rem",
                      fontFamily: "Gilroy-Medium",
                    }}
                  >
                    Software Development
                    <br /> Nirvana
                  </span>
                </div>
                <div id="pro_sub_content" style={{ color: "#5B6574" }}>
                  Navigate and explore new horizons in the development
                </div>
                <div id="pro_button_bg">
                  <div id="apply_now_bg">
                    <button onClick={() => setModalShow(true)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM7 6H11V10H7V6ZM7 12H17V14H7V12ZM7 16H17V18H7V16ZM13 7H17V9H13V7Z"
                          fill="white"
                        />
                      </svg>
                      Apply Now
                    </button>
                  </div>{" "}
                  <ApplyNow
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                  <div id="chat_with_us">
                    <a
                      href="javascript:void(Tawk_API.toggle())"
                      className="text-decoration-none"
                    >
                      {" "}
                      <button style={{ border: "2px solid #E25234" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM5.76282 17H20V5H4V18.3851L5.76282 17ZM11 10H13V12H11V10ZM7 10H9V12H7V10ZM15 10H17V12H15V10Z"
                            fill="#E25234"
                          />
                        </svg>
                        <span style={{ color: "#E25234" }}>Chat with us</span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 z-0" id="rocket_bg">
              <div id="" className=" d-flex justify-content-between">
                <div id="launch_career_bg">
                  <div>
                    <img src={launch_rocket_img} alt="" />
                  </div>
                  <div>Launch Your Career</div>
                </div>
                <div id="tpro_hero_img_bg" align="end">
                  <img src={tpro_hero} alt="" width="100%" className="z-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="hero_bottom_bg" className="d-lg-block d-none">
          <div id="points_bg" className="container">
            <div id="single_point">
              <div>
                <img src={duration_icon} />
              </div>
              <div>Duration 60 Days</div>
            </div>
            <div id="single_point" style={{ borderLeft: "0" }}>
              <div>
                <img src={limited_icon} />
              </div>
              <div>Limited Intakes</div>
            </div>
            <div id="single_point" style={{ borderLeft: "0" }}>
              <div>
                <img src={online_exp_icon} />
              </div>
              <div>100% Onsite Experience</div>
            </div>
            <div id="single_point" style={{ borderLeft: "0" }}>
              <div>
                <img src={job_assistance_icon} />
              </div>
              <div>Job Assistance</div>
            </div>
            <div id="single_point" style={{ borderLeft: "0" }}>
              <div>
                <img src={salary_icon} />
              </div>
              <div>Industry Standard Salary</div>
            </div>
          </div>
        </div>
        {/* for mobiles */}
        <div className="container d-lg-none d-block">
          <div className="row">
            {" "}
            <div className="col-md-6">
              <div id="mobile_pro_hero_content_bg">
                <div id="mobile_pro_img_bg">
                  <img src={techie_pro_logo} alt="" />
                </div>
                <div id="mobile_pro_hero_content" style={{ color: "#222F43" }}>
                  Your Journey Into
                  <br />
                  <span id="mobile_software_text">
                    Software Development Nirvana
                  </span>
                </div>
                <div id="mobile_pro_sub_content" style={{ color: "#5B6574" }}>
                  Navigate and explore new horizons in the development
                </div>
                <div className="d-md-flex" id="mob_button_bg">
                  <div id="mobile_apply_now_bg">
                    <button onClick={() => setModalShow(true)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM7 6H11V10H7V6ZM7 12H17V14H7V12ZM7 16H17V18H7V16ZM13 7H17V9H13V7Z"
                          fill="white"
                        />
                      </svg>
                      Apply Now
                    </button>
                  </div>{" "}
                  <ApplyForm
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                  <div id="mobile_chat_with_us">
                    <a
                      href="javascript:void(Tawk_API.toggle())"
                      className="text-decoration-none"
                    >
                      <button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM5.76282 17H20V5H4V18.3851L5.76282 17ZM11 10H13V12H11V10ZM7 10H9V12H7V10ZM15 10H17V12H15V10Z"
                            fill="#E25234"
                          />
                        </svg>
                        Chat with us
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div id="mobile_" className=" d-flex justify-content-between">
                <div id="mobile_launch_career_bg">
                  <div>
                    <img src={launch_rocket_img} alt="" />
                  </div>
                  <div>Launch Your Career</div>
                </div>
                <div id="mobile_tpro_hero_img_bg" align="end">
                  <img src={tpro_hero} alt="" width="100%" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div
          id="mobile_hero_bottom_bg"
          className="d-lg-none d-block"
          align="center"
        >
          <div className="row ">
            <div className="col-6" style={{ borderRight: "1px solid #343F52" }}>
              {" "}
              <div
                className="w-100"
                style={{
                  marginTop: "2.25rem",
                  borderBottom: "1px solid #343F52",
                  paddingBottom: "1.75rem",
                }}
                id="tpro_mobile_single_point"
                align="center"
                // className="ms-4"
              >
                <div>
                  <img src={duration_icon} />
                </div>
                <div>Duration 60 Days</div>
              </div>
              <div
                style={{
                  marginTop: "2.25rem",
                  paddingBottom: "1.75rem",
                  borderBottom: "1px solid #343F52",
                }}
                id="tpro_mobile_single_point"
                align="center"

                // className="ms-4"
              >
                <div>
                  <img src={online_exp_icon} />
                </div>
                <div>100% Onsite Experience</div>
              </div>
            </div>
            <div className="col-6">
              {" "}
              <div
                className="w-100"
                style={{
                  marginTop: "2.25rem",
                  borderBottom: "1px solid #343F52",
                  paddingBottom: "1.75rem",
                }}
                id="tpro_mobile_single_point"
                align="center"
                // className="ms-4"
              >
                <div>
                  <img src={limited_icon} />
                </div>
                <div>Limited Intakes</div>
              </div>
              <div
                className="w-100"
                style={{
                  marginTop: "2.25rem",
                  paddingBottom: "1.75rem",
                  borderBottom: "1px solid #343F52",
                }}
                id="tpro_mobile_single_point"
                align="center"

                // className="ms-4"
              >
                <div>
                  <img src={job_assistance_icon} />
                </div>
                <div>Job Assistance</div>
              </div>
            </div>
            <div
              className="w-100"
              style={{
                marginTop: "1.25rem",
                // borderBottom: "1px solid #343F52",
                paddingBottom: "1.75rem",
              }}
              id="tpro_mobile_single_point"
              align="center"
              // className="ms-4"
            >
              <div>
                <img src={salary_icon} />
              </div>
              <div>Industry Standard Salary</div>
            </div>
          </div>
          {/* <div id="tpro_mobile_points_bg" className="container ">
            <div
              className="d-flex "
              align="center"
              style={{
                width: "100%",
                justifyContent: "space-between",
                borderBottom: "1px solid #343F52",
                paddingBottom: "1.75rem",
                paddingTop: "2rem",
              }}
            >
              <div
                id="tpro_mobile_single_point"
                align="center"
                className="ms-4"
              >
                <div>
                  <img src={duration_icon} />
                </div>
                <div>Duration 60 Days</div>
              </div>
              <div id="tpro_mobile_single_point" align="center">
                <div>
                  <img src={limited_icon} />
                </div>
                <div>Limited Intakes</div>
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              align="center"
              style={{
                width: "100%",
                justifyContent: "space-between",
                borderBottom: "1px solid #343F52",
                paddingBottom: "1.75rem",
                paddingTop: "2rem",
              }}
            >
              <div id="tpro_mobile_single_point" align="center">
                <div>
                  <img src={online_exp_icon} />
                </div>
                <div>100% Onsite Experience</div>
              </div>
              <div id="tpro_mobile_single_point" align="center" className=" ">
                <div>
                  <img src={job_assistance_icon} />
                </div>
                <div>Job Assistance</div>
              </div>
            </div>
          </div>{" "}
          <div
            id="tpro_mobile_single_point"
            align="center"
            style={{ marginTop: "1.25rem", marginBottom: "2rem" }}
          >
            <div>
              <img src={salary_icon} />
            </div>
            <div>Industry Standard Salary</div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default TechieHeroSection;
