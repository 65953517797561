import React from "react";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
// import {AiFillPlayCircle,AiOutlineRight} from "react-icons/ai";
import brud_crumbs_arrow_icon from "../Assets/brudcrums_arrow_icon.svg";
import database_design_blog_img from "../Assets/data_base_blog_img.avif";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import blog_details2_img from "../Assests/Images/blog_details2_img.png";
const DatabaseDesignBlogDetails = () => {
  return (
    <div>
      <Header />
      <div className="py-3" style={{ background: "#FAFAFB" }}>
        <div className="container">
          <div className="d-flex align-items-center mt-5 ">
            <div className="d-flex mt-5 align-items-center">
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: "#065BD6",
                  lineHeight: "16px",
                }}
              >
                <a href="/ezine" className="text-decoration-none">
                  EZine
                </a>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <LazyLoadImage
                  src={brud_crumbs_arrow_icon}
                  width="100%"
                ></LazyLoadImage>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#07152C",
                }}
              >
                {" "}
                Database Design...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex py-3 mt-5">
          <div className="">
            <div
              className=""
              style={{
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                lineHeight: "16px",
                background: "#07152C",
                opacity: "100%",
                bottom: "12px",
                left: "20px",
                Zindex: "3",
                padding: "4px 12px",
                borderRadius: "12px",
              }}
            >
              Technology
            </div>
          </div>
          <div
            className="d-flex my-auto px-2"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#6A7380",
            }}
          >
            <div>30 JAN 2022</div>
            <div
              style={{
                paddingLeft: "8px",
                fontSize: "2rem",
                marginTop: "-5%",
              }}
            >
              .
            </div>
            <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 ">
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "50px",
                  lineHeight: "60px",
                  marginTop: "36px",
                }}
              >
                What can I learn in Database Design and Development Course?
              </div>
            </div>
            <div className="mt-5">
              <LazyLoadImage
                src={database_design_blog_img}
                className="w-100"
                alt="Learn Database Design"
              />
            </div>
            <div className="py-3"></div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Databases are not unfamiliar to the world of web development. From
              robust user-oriented websites to the simplest blogs and
              directories, databases are utilized practically everywhere.
              Immaterial of the complexity or the simplicity of the website and
              its relative database, each of these tasks require careful
              planning to run smoothly, securely and efficiently.{" "}
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Overview of Database Design and Development
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Effective analysis and management of a business are usually
              accomplished through database-driven solutions. The flexibility of
              data retrieval, storage and modifications are possible only
              through well-structured databases that also serve a variety of
              programs and reporting engines. NYINST prepares future database
              design and development engineers to curate, optimize and fine-tune
              databases while incorporating solid security features of
              database-driven Web applications through MySQL, RDBMS, Oracle and
              much more to deliver efficient and scalable solutions.
            </div>

            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              What is database design and development?
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Organizations can plan, store and manage data with the help of a
              database design framework, which eventually acts as the lifeblood
              of every company. When an organization’s data is designed for
              useful storage and retrieval, then the consistency of data is said
              to be successfully achieved.
            </div>

            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                What is RDBMS or Relational Database Management System?
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              When a designated collection of programs enable IT teams to
              curate, update, administer and interact with an associative
              database then it is known as RDBMS or Relational Database
              Management System. Although SQL was invented after the initial
              development of the relational model and is not necessary for its
              use, most commercial RDBMS assemblies use Structured Query
              Language or SQL to access the database. Sets of data are stored by
              databases that can be queried in other applications. Typically,
              the development, administration, and use of database platforms are
              supported by a database management system. While including
              functions that maintain the accuracy, integrity, security, and
              consistency of data, RDBMS comes with a row table structure that
              connects all relative data elements.
            </div>

            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                The RDBMS Process
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Proposed sometime in 1969 by Edgar Codd, Relational Database has
                become dominant especially for commercial operations when
                compared to other database models. Contemporarily, there are
                several RDBMS available and out of these many of them are even
                open source and free. The main objective of a database design
                process is to eliminate data redundancy and ensure high data
                accuracy and integrity. Experts at NYINST will teach you that
                Relational Databases are actually a piece of art, more than just
                computer science.
              </div>

              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                <li>
                  You can customize them to suit a particular application and
                  yet, no two tailored applications are similar. It is up to the
                  database designer to define the guidelines and usage of the
                  system.
                </li>
                <li>
                  The primary step to the RDBMS process is requirement analysis
                  and data gathering. While this step may seem negligent it is
                  also the starting point to any database design technique.
                </li>
                <li>
                  Thereafter, you have to organize them in tables and specify
                  primary keys that uniquely identify each of the rows. Since it
                  would create ambiguities in retrieval, a table cannot contain
                  duplicate rows, in the relational model. An index is built on
                  the primary key to facilitate fast search and retrieval of
                  data.
                </li>
                <li>
                  In the third step, a relationship has to be defined among
                  tables because relational database works on dependent tables
                  rather than independent ones. This is also where the power of
                  relational database lies.
                </li>
                <li>
                  Finally, the design is refined and normalized by adding more
                  columns, creating fresh tables or splitting a large table and
                  much more.
                </li>
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Benefits of database development
              </div>

              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                A business gains a distinctive advantage when database
                technology is used to gather, store and process information
                about resources such as suppliers, customers, and even
                competitors. With database development, companies can reduce the
                amount of time they spend managing data. Analysis of this data
                can also be accomplished in different ways. Additionally, data
                management gets a disciplined approach as it turns disparate
                information into a valuable resource while simultaneously
                improving the consistency and quality of information.
                Sophisticated RDBMS plays a big role in the regulation of
                business. It can garner data, store and help assess business
                trends using historical annals while identifying valuable
                customers through sales records.
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Outcomes of learning at NYINST
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                At NYINST, students will learn to develop credible
                databases that can significantly improve a business’s
                performance. You will also know that every business depends
                heavily on the fidelity of databases and it is up to you as a
                professional database administrator to provide these
                organizations with advanced database solutions. While performing
                data modeling, data warehousing, and operational adjustments,
                learn to strategically develop an appropriate architecture and
                design using the latest tools and technologies. Complete a GTM
                program with NYINST to achieve the following:
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                <li>
                  Identifying, analyzing and resolving information technology
                  issues through applied critical thinking skills
                </li>
                <li>
                  While working in an information technology environment you
                  will be able to exhibit a professional attitude combined with
                  sharp interpersonal skills
                </li>
                <li>
                  You will be able to express your ideas and information clearly
                  to meet specific client requirements
                </li>
                <li>
                  Solve business problems while applying project management
                  techniques
                </li>
                <li>
                  Develop customer-based solutions and integrate them into the
                  user environment while collaborating with a team
                </li>
                <li>
                  Furthermore, you will be able to apply logical reasoning to a
                  problem
                </li>
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                GTM Course – Database Design and Development
              </div>
              <div
                className="mt-3 mobile_para_font "
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Upon successful completion of the GTM Database Design and
                Development course at NYINST, you will be capable of critical
                analysis and synthesis of a creative and effective solution to a
                business issue. Create a prototype database system to
                demonstrate the use and application of theoretical concepts that
                incorporate usability and integrity utilizing various software
                tools and technologies. Select and apply appropriate data
                modeling, database development and editing tools to design,
                implement and manage prototypes. Enhance database applications
                by demonstrating and applying basic concepts and principles of
                programming. Developers will also learn to manage and use a
                database as an aspect of the information infrastructure within
                an organization.
              </div>
              <div
                className="mt-3 mobile_para_font "
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Database design and development engineers are expected to build
                and maintain code that manages data received from assorted
                sources and in various data formats. All data transformations
                have to be performed by populating the data into a warehouse
                optimized for Data Mining.
              </div>
              <div
                className="mt-3 mobile_para_font mb-5"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                NYINST teaches you to keep a company’s database available,
                secure and stable. Our training process is so precise and
                complete that you may even end up training others in an
                organization on how to access a database and how to make the
                best use of its contents.
              </div>
            </div>
          </div>
          {/* <div className='col-md-3'>Enquiry Now</div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DatabaseDesignBlogDetails;
