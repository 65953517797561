import React, { useEffect } from "react";
import "../Styles/TechiePro/WhySoftware.css";
import impact_img from "../Assets/techiepro/impact_img.png";
import global_img from "../Assets/techiepro/global_img.png";
import deman_img from "../Assets/techiepro/demand_sal.png";
import avg_sal from "../Assets/techiepro/avg_sal.png";
import media_img1 from "../Assets/techiepro/in_media1.png";
import media_img2 from "../Assets/techiepro/in_media2.png";

function WhySoftware({ menu }) {
  useEffect(() => {
    // console.log("Active ref in TechieHeroSection:", menu);
  }, [menu]);
  return (
    <div id="software">
      <div id="why_software_bg">
        <div className="container d-lg-block d-none">
          <div id="why_software_topic">
            Why get into <br />{" "}
            <span id="why_software_text" style={{color:"#8625D2"}}>Software Engineering?</span>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div id="two_points_bg" className="position-relative">
                <div id="points_topic">Impact in start-up's</div>
                <div id="points_content">
                  Startups contribute significantly to the demand for software
                  engineers, with over 70% of new job opportunities arising from
                  innovative and emerging companies.
                </div>
                <div
                  className="position-absolute bottom-0 start-50 translate-middle-x "
                  id="impact_img"
                  style={{ top: "9.76rem" }}
                >
                  <img src={impact_img} alt="" width="100%" className="mt-1" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div id="two_points_bg">
                <div id="points_topic">Global Employment Trends</div>
                <div id="points_content">
                  Software Engineering is among the top 10 most in-demand
                  occupations globally
                </div>
                <div>
                  <img
                    src={global_img}
                    alt=""
                    width="100%"
                    style={{ width: "22.5rem", marginTop: "3.5rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <div id="two_points_bg">
                <div id="points_topic">Demand for Software Engineer</div>
                <div id="points_content">
                  High demand for software engineers due to technological
                  advancements and innovation.
                </div>
                <div align="center">
                  <img
                    src={deman_img}
                    alt=""
                    width="100%"
                    style={{  marginTop: "1.5rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <div id="two_points_bg">
                <div id="points_topic">Average salary</div>
                <div id="points_content">
                  A steady increase over the past five years, reflecting the
                  growing for tech professionals
                </div>
                <div align="center">
                  <img
                   width="100%"
                    src={avg_sal}
                    alt=""
                    style={{  marginTop: "1.5rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <div id="two_points_bg">
                <div id="points_topic">In media</div>
                <div id="points_content">
                  The proof of media sources, on the huge demand & growth of SE
                </div>
                <div>
                  <img
                    src={media_img1}
                    alt=""
                    width="100%"
                    style={{  marginTop: "2.5rem" }}
                  />
                  <img
                    src={media_img2}
                    alt=""
                    width="100%"
                    style={{  marginTop: "0.75rem" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* for mobiles */}
        <div className="container d-lg-none d-block">
          <div id="mobile_why_software_topic">
            Why get into <br />{" "}
            <span id="mobile_why_software_text" style={{color:"#8625D2"}}>Software Engineering?</span>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div id="mobile_two_points_bg" className="position-relative">
                <div id="mobile_points_topic">Impact in start-up's</div>
                <div id="mobile_points_content">
                  Startups contribute significantly to the demand for software
                  engineers, with over 70% of new job opportunities arising from
                  innovative and emerging companies.
                </div>
                <div
                  align="center"
                  className="position-absolute bottom-0 start-50 translate-middle-x"
                  id="mobile_impact_img"
                  style={{  top: "10.67rem" }}
                >
                  <img src={impact_img} alt="" width="100%" className="mt-1" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div id="mobile_two_points_bg" className="position-relative">
                <div id="mobile_points_topic">Global Employment Trends</div>
                <div id="mobile_points_content">
                  Software Engineering is among the top 10 most in-demand
                  occupations globally
                </div>
                <div
                  align="center"
                  className=" "
                  id="mobile_impact_img"
                  style={{ marginTop: "5rem" }}
                >
                  <img src={global_img} alt="" width="100%" className="mt-1" />
                </div>
              </div>
            </div>
            <div
              className="d-flex w-100"
              style={{ overflow: "scroll" }}
              id="software_engineer_scroll"
            >
              <div className="">
                {" "}
                <div
                  id="mobile_two_points_bg"
                  style={{ width: "19rem" }}
                  className="position-relative"
                >
                  <div id="mobile_points_topic">
                    Demand for Software Engineer
                  </div>
                  <div id="mobile_points_content">
                    High demand for software engineers due to technological
                    advancements and innovation.
                  </div>
                  <div
                    className=" "
                    id="mobile_impact_img"
                    style={{ top: "9.76rem", marginTop: "1.5rem" }}
                  >
                    <img src={deman_img} alt="" width="100%" className="mt-1" />
                  </div>
                </div>
              </div>
              <div className="">
                {" "}
                <div
                  className="position-relative"
                  id="mobile_two_points_bg"
                  style={{ width: "19rem", marginLeft: "0.5rem" }}
                >
                  <div id="mobile_points_topic">Average salary</div>
                  <div id="mobile_points_content">
                    A steady increase over the past five years, reflecting the
                    growing for tech professionals
                  </div>
                  <div
                    className=" "
                    id="mobile_impact_img"
                    style={{ top: "9.76rem", marginTop: "1.5rem" }}
                  >
                    <img src={avg_sal} alt="" width="100%" className="mt-1" />
                  </div>
                </div>
              </div>
              <div className="">
                {" "}
                <div
                  className="position-relative"
                  id="mobile_two_points_bg"
                  style={{ width: "19rem", marginLeft: "0.5rem" }}
                >
                  <div id="mobile_points_topic">In media</div>
                  <div id="mobile_points_content">
                    The proof of media sources, on the huge demand & growth of
                    SE
                  </div>
                  <div
                    className=" "
                    style={{ marginTop: "2.5rem" }}
                    id="mobile_impact_img"
                    // style={{ top: "9.76rem" }}
                  >
                    <img
                      src={media_img1}
                      alt=""
                      width="100%"
                      className="mt-1"
                    />
                    <img
                      src={media_img2}
                      alt=""
                      width="100%"
                      className="mt-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhySoftware;
