import React from "react";
// import scholarship_hero_bg from "../Assets/Scholarship/sch_bg.png";
import "../Styles/ScholarShip/HeroSection.css";
import sch_img1 from "../Assets/Scholarship/sch_img1.svg";
import sch_img2 from "../Assets/Scholarship/sch_img2.svg";
import sch_img3 from "../Assets/Scholarship/sch_img3.svg";
import sch_img4 from "../Assets/Scholarship/sch_img4.svg";
import sch_img5 from "../Assets/Scholarship/sch_img5.svg";
import sch_img6 from "../Assets/Scholarship/sch_img6.svg";
import sch_img7 from "../Assets/Scholarship/sch_img7.svg";
import sch_img8 from "../Assets/Scholarship/sch_img8.svg";
import sch_img9 from "../Assets/Scholarship/sch_img9.svg";
import sch_img10 from "../Assets/Scholarship/sch_img10.svg";
import mob_sch_bg from "../Assets/Scholarship/mob_sch_bg.png";
import sch_herio_json from "../Assets/Scholarship/sch_hero_json.json";
import Lottie from "react-lottie";

function HeroSection() {
  return (
    <div>
      <div>
        <div className="d-lg-block d-none position-relative" id="sch_bg">
          {/* <img src={scholarship_hero_bg} alt="" width="100%" />{" "} */}
          <div className="container" align="center">
            <div className="row">
              <div className="col-md-2">
                <div align="left">
                  <img
                    src={sch_img1}
                    alt=""
                    style={{
                      //   height: "2.23rem",
                      //   width: "2.23rem",
                      //   transform: "rotate(13.24deg)",
                      marginTop: "3.69rem",
                    }}
                  />
                </div>
                <div align="right">
                  <img
                    src={sch_img2}
                    alt=""
                    style={{
                      //   height: "2.75rem",
                      //   width: "5.687rem",
                      //   transform: "rotate(-18.251deg)",
                      marginTop: "1.17rem",
                    }}
                  />
                </div>
                <div align="left">
                  <img
                    src={sch_img3}
                    alt=""
                    style={{
                      //   height: "8.435rem",
                      //   width: "5.0625rem",
                      //   transform: "rotate(-7.965deg)",
                      marginTop: "7.99rem",
                    }}
                  />
                </div>
                <div align="center">
                  <img
                    src={sch_img4}
                    alt=""
                    style={{
                      //   height: "6.75rem",
                      //   width: "7rem",
                      //   transform: "rotate(-7.965deg)",
                      marginTop: "9.38rem",
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8  position-relative" align="center">
               
                <div
                  className="position-relative z-2"
                  // style={{ marginLeft: "1.5rem" }}
                >
                  {" "}
                  <div align="right">
                    <img
                      src={sch_img10}
                      alt=""
                      style={{
                        //   height: "5.1875rem",
                        //   width: "5.25rem",
                        //   transform: "rotate(13.24deg)",
                        marginTop: "3.37rem",
                      }}
                    />
                  </div>
                  <div id="sch_title" className="" align="center" style={{}}>
                    <div id="first_title">Get Upto</div>
                    <div id="hero_second_title">90%</div>
                    <div id="third_text">Scholarship</div>
                    <a
                      href="/scholarship#apply-scholarship"
                      className="text-decoration-none position-relative"
                    >
                      {" "}
                      <button
                        id="apply_now_button"
                        className="btn position-relative  "
                      >
                        Apply Now{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12.0001 19.1642L18.2072 12.9571L16.793 11.5429L12.0001 16.3358L7.20718 11.5429L5.79297 12.9571L12.0001 19.1642ZM12.0001 13.5143L18.2072 7.30722L16.793 5.89301L12.0001 10.6859L7.20718 5.89301L5.79297 7.30722L12.0001 13.5143Z"
                            fill="white"
                          />
                        </svg>
                      </button>{" "}
                      {/* <div className="box position-absolute left-0"></div> */}
                    </a>
                    {/* <div class="box">BUTTON</div> */}
                    {/* <div>
                      <a
                        href="#explore-programs"
                        data-w-id="71eca297-5c5c-9281-04b4-1b1475e7657d"
                        class="gs-cta2 w-inline-block"
                      >
                        <div class="z-2">Explore Programs</div>
                        <img
                          src="https://assets-global.website-files.com/624194472db3153002097068/63eb6544ab031d68a9595339_double-arrow-down.svg"
                          loading="lazy"
                          alt=""
                        />
                        <div
                          style={{
                            transform:
                              "translate3d(26.1474rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(-30deg, 0deg)",
                            transformStyle: "preserve-3d",
                            willChange: "transform",
                          }}
                          class="glare"
                        ></div>
                      </a>
                    </div> */}
                    {/* <div
                      className="glare"
                    
                    ></div> */}
                    <div
                      className="d-flex justify-content-around"
                      style={{ gap: "20rem" }}
                    >
                      {" "}
                      <div align="left">
                        <img
                          src={sch_img5}
                          alt=""
                          style={{
                            // width: "2.939rem",
                            // height: "2.424rem",
                            // transform: "rotate(-22.43deg)",
                            marginLeft: "6rem",
                            marginTop: "3.5rem",
                          }}
                        />
                      </div>
                      <div align="right">
                        <img
                          src={sch_img6}
                          alt=""
                          style={{
                            // width: "4.625rem",
                            // height: "5.375rem",
                            // transform: "rotate(20.286deg)",
                            marginLeft: "10rem",
                            marginTop: "3rem",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                 <Lottie 
                  isClickToPauseDisabled={true}
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: sch_herio_json,
                  }}
                  width="90%"
                  style={{ objectFit: "cover", position:"absolute", top:"0" , zIndex:"0"}}
                >
                  {" "}
                </Lottie>
              </div>
              <div className="col-md-2 ">
                {" "}
                <div align="right">
                  <img
                    src={sch_img9}
                    alt=""
                    style={{
                      //   height: "3.7219rem",
                      //   width: "3.7219rem",
                      //   transform: "rotate(13.24deg)",
                      marginTop: "2.31rem",
                    }}
                  />
                </div>
                <div align="left">
                  <img
                    src={sch_img8}
                    alt=""
                    style={{
                      //   height: "7.59rem",
                      //   width: "9.45rem",
                      //   transform: "rotate(13.24deg)",
                      marginTop: "6.94rem",
                    }}
                  />
                </div>
                <div align="center">
                  <img
                    src={sch_img7}
                    alt=""
                    style={{
                      //   height: "6.562rem",
                      //   width: "6.25rem",
                      //   transform: "rotate(16.731deg)",
                      marginTop: "12.04rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* for mobiles */}
        <div
          id="mob_sch_bg"
          className="d-lg-none d-block position-relative"
          align="center"
        >
          <Lottie
            isClickToPauseDisabled={true}
            options={{
              loop: true,
              autoplay: true,
              animationData: sch_herio_json,
            }}
            width="90%"
            style={{ objectFit: "cover" }}
          >
            {" "}
          </Lottie>
          <div className="position-absolute top-0 translate-middle-x start-50 ">
            {" "}
            <div id="mob_sch_title">Get Upto</div>
            <div id="mob_90_text">90%</div>
            <div id="mob_sch_text">ScholarShip</div>
            <a href="/scholarship#apply" className="text-decoration-none">
              <button id="mobile_appl_button" align="center" className="btn">
                Apply Now{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.0001 19.1642L18.2072 12.9571L16.793 11.5429L12.0001 16.3358L7.20718 11.5429L5.79297 12.9571L12.0001 19.1642ZM12.0001 13.5143L18.2072 7.30722L16.793 5.89301L12.0001 10.6859L7.20718 5.89301L5.79297 7.30722L12.0001 13.5143Z"
                    fill="white"
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
