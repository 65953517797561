import React from "react";
import "../Styles/WhyIntern.css";
import global_img from "../Assets/techieintern/global_img.png";
import intern_pie from "../Assets/techieintern/intern_pie.png";
import why_intern_img from "../Assets/techieintern/why_intern_img.png";
function WhyIntern() {
  return (
    <div>
      <div className="container d-lg-block d-none">
        <div id="why_intern_topic">
          Why Internship is critical in <br />
          <span id="it_text">IT Industries?</span>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div
              id="two_points_bg"
              className="position-relative"
              style={{ height: "29.5rem" }}
            >
              <div id="points_topic">Full-time employees</div>
              <div id="points_content">
                51.8% of interns, become full-time employees after <br />
                graduation
              </div>
              <div
                className="d-flex align-items-center"
                align=""
                id="impact_img"
                style={{ marginLeft: "0", marginTop: "3rem" }}
              >
                <div align="center">
                  <img
                    src={intern_pie}
                    alt=""
                    width="100%"
                    className="mt-1"
                    style={{ height: "16.5rem", width: "16.5rem" }}
                  />
                </div>
                <div>
                  <img
                    src={why_intern_img}
                    alt=""
                    width="100%"
                    style={{
                      height: "5.75rem",
                      width: "12.375rem",
                      marginLeft: "1.5rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {" "}
            <div id="two_points_bg" style={{ height: "29.5rem" }}>
              <div id="points_topic">Job offers</div>
              <div id="points_content">
                Students who interned received 16% more job offers than those{" "}
                <br />
                who didn’t
              </div>
              <div align="center">
                <img
                  src={global_img}
                  alt=""
                  width="100%"
                  style={{ width: "16.5rem",height:"16.5rem", marginTop:"3rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* for mobiles */}
      <div className="container d-lg-none d-block">
        <div id="mob_why_intern_topic">
          Why Internship is critical in <br />
          <span id="mob_it_text">IT Industries?</span>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div
              id="mobile_two_points_bg"
              className="position-relative"
              style={{ height: "auto" }}
            >
              <div id="mobile_points_topic">Full-time employees</div>
              <div id="mobile_points_content">
                51.8% of interns, become full-time employees after <br />
                graduation
              </div>
              <div
                // className="d-sm-flex align-items-center"
                align="left"
                id="mobile_impact_img"
                style={{ marginLeft: "0", marginTop: "1.25rem" }}
              >
                <div align="center">
                  <img
                    src={intern_pie}
                    alt=""
                    width="100%"
                    className="mt-1"
                    style={{ height: "11.75rem", width: "11.75rem" }}
                  />
                </div>
                <div>
                <div className="d-flex align-items-center" style={{gap:"0.5rem",marginTop:"1.25rem"}}>
                  <div id="mob_single_pt_bg" ></div>
                  <div id="mob_single_pt">Students with Internship</div>
                </div>
                <div className="d-flex align-items-center" style={{gap:"0.5rem",marginTop:"0.5rem"}}>
                  <div id="mob_single_pt_bg" style={{background:"#F09A4B"}} ></div>
                  <div id="mob_single_pt">Students without Internship</div>
                </div>
                <div className="d-flex align-items-center" style={{gap:"0.5rem",marginTop:"0.5rem"}}>
                  <div id="mob_single_pt_bg" style={{background:"#F0CC4B"}} ></div>
                  <div id="mob_single_pt">Others</div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {" "}
            <div id="mobile_two_points_bg" style={{ height: "auto" }}>
              <div id="mobile_points_topic">Job offers</div>
              <div id="mobile_points_content">
                Students who interned received 16% more job offers than those{" "}
                <br />
                who didn’t
              </div>   <div
                // className="d-sm-flex align-items-center"
                align="left"
                id="mobile_impact_img"
                style={{ marginLeft: "0", marginTop: "1.25rem" }}
              >
                <div align="center">
                  <img
                    src={global_img}
                    alt=""
                    width="100%"
                    className="mt-1"
                    style={{ height: "11.75rem", width: "11.75rem" }}
                  />
                </div>
                <div>
                
              </div>
              </div>
              {/* <div>
                <div className="d-flex align-items-center" style={{gap:"0.5rem",marginTop:"0.5rem"}}>
                  <div id="mob_single_pt_bg" ></div>
                  <div id="mob_single_pt">Students with Internship</div>
                </div>
                <div className="d-flex align-items-center" style={{gap:"0.5rem",marginTop:"0.5rem"}}>
                  <div id="mob_single_pt_bg" ></div>
                  <div id="mob_single_pt">Students with Internship</div>
                </div>
                <div className="d-flex align-items-center" style={{gap:"0.5rem",marginTop:"0.5rem"}}>
                  <div id="mob_single_pt_bg" ></div>
                  <div id="mob_single_pt">Students with Internship</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyIntern;
