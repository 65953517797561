import React from "react";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
// import {AiFillPlayCircle,AiOutlineRight} from "react-icons/ai";
import brud_crumbs_arrow_icon from "../Assets/brudcrums_arrow_icon.svg";
import impressive_resume_blog_img from "../Assets/impressive_resume_blog_img.avif";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import blog_details2_img from "../Assests/Images/blog_details2_img.png";
const ImpressiveResumeBlogDetails = () => {
  return (
    <div>
      <Header />
      <div className="py-3" style={{ background: "#FAFAFB" }}>
        <div className="container">
          <div className="d-flex align-items-center mt-5 ">
            <div className="d-flex mt-5 align-items-center">
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: "#065BD6",
                  lineHeight: "16px",
                }}
              >
                <a href="/ezine" className="text-decoration-none">
                  EZine
                </a>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <LazyLoadImage
                  src={brud_crumbs_arrow_icon}
                  width="100%"
                ></LazyLoadImage>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#07152C",
                }}
              >
                {" "}
                Impressive Resume...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex py-3 mt-5">
          <div className="">
            <div
              className=""
              style={{
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                lineHeight: "16px",
                background: "#07152C",
                opacity: "100%",
                bottom: "12px",
                left: "20px",
                Zindex: "3",
                padding: "4px 12px",
                borderRadius: "12px",
              }}
            >
              Technology
            </div>
          </div>
          <div
            className="d-flex my-auto px-2"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#6A7380",
            }}
          >
            <div>14 AUG 2022</div>
            <div
              style={{
                paddingLeft: "8px",
                fontSize: "2rem",
                marginTop: "-5%",
              }}
            >
              .
            </div>
            <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 ">
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "50px",
                  lineHeight: "60px",
                  marginTop: "36px",
                }}
              >
                The Secret Behind An Impressive Resume Unraveled!
              </div>
            </div>
            <div className="mt-5">
              <LazyLoadImage
                src={impressive_resume_blog_img}
                className="w-100"
                alt="blog details image"
              />
            </div>
            <div className="py-3 "></div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              An impressive resume is one of the most powerful tools that you
              can use to showcase your personality and your expertise to land
              the right kind of job that you wish to pursue. A well-written
              resume will not just make a first impression, it will also provide
              potential employers a good idea of your professional history, and
              the relevant qualifications that you hold given the nature of a
              particular job. In today’s competitive job market, a great resume
              does not appear with rules that trickle down through the
              grapevine. These days, employers are looking for specifics, to cut
              the chase and come right to the point.{" "}
              <div className="mt-4">
                At NYINST, we provide you with consulting, counseling and
                coaching across several information technology topics. We help
                you design or even take a different route to a new job or career
                – something that you have been wishing to follow, say, for a
                very long time. We will provide you with opportunities that
                absolutely fits your personality and your skill, while also
                being value-oriented. Our mentors are well-qualified and
                highly-trained to provide you with the pedagogy that will take
                you to greater career heights.
              </div>
              <div className="mt-4">
                Career changing resumes can be challenging because you still
                have to reflect your suitability to a different career other
                than the one that you already possess experience in. To stir the
                interest of the potential employers and to dissolve fierce
                competition, you should have a resume that makes you stand out
                as the superior candidate and compels the employers to call you
                for an interview.
              </div>
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Including significance in your resume
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Ensure that you very clearly outline your technical proficiency
              along with your roles and responsibilities within the previous
              organization. There should be a list of technology platforms that
              you have worked on and those which you should specifically include
              in the list.
            </div>

            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Increase your chances of getting an interview call{" "}
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Your objectives should be clearly defined and presented in the
              resume. It should be brief and avoid making it flowery. Stress on
              the relevant skills in the major part of your resume, especially
              when you are applying for a specific position.
            </div>

            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                A gap in your career
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              A break in your career can be the most challenging part of your
              resume. But you need not hide the fact that you have had a
              sabbatical. You can always provide a valid reason for the gap.
              Most of the times though, employers generally focus on your skills
              and the authenticity of the break.
            </div>

            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Accomplishments
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Boost your resume by providing employers with real-time
                accomplishments instead of just highlight work experience and
                education. Present your strengths, skills and different job
                duties along with your achievements, all of which are ideally
                disparate. Try not to overlap any of the above points
              </div>

              <div
                className="mt-5"
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Subjects
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                The resume has to clearly highlight the different subjects that
                you have learned and the role knowledge that you possess in each
                one of them.
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                At NYINST, we provide you GTM certifications for several
                subjects. You can confidently highlight the hands-on experience
                you have gained in each one of them.
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Proofread your resume end-to-end before you submit it. Grammar,
                spelling, and sentence formation matter. Keep it trendy and
                precise. Know your career path and do not falsify anything
                because most of the time, employers are looking for skills.
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                NYINST
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Here is a glimpse of what you can garner from our courses:
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Java Enterprise Application
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                With a JEE course, you get to master all the elements of the
                Java programming language along with its core features. Study
                the frameworks that are involved in build Java EE solutions.
                Along with core java programming, you will also learn OOP
                concepts, MVC, MySQL, performance tuning and much more.
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Software Testing
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                At NYINST you will be provided with an in-depth understanding of
                software and QA testing modules. Besides, you will also gain
                knowledge about the different testing strategies that exist such
                as DB Testing, Automation Testing, Manual Testing, WS Testing
                and much more. You will also be adept at using testing tools
                such as the Selenium Web Driver, Appium, and HP ALM.
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Android App Development
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                One of the most popular platforms to get acquainted with, a
                course on Android App Development is all about creating layouts,
                designs and web services for the Android Mobile device. With our
                certification, you will learn Object Oriented PHP, MySQL, and DB
                Designs, Generating Product Signed Builds or APKs, using Maps
                and Locations and much more.
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Enterprise Web app Development
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Learn all about web developing and designing as you use
                programming and markup languages such as CSS, HTML, PHP, Jquery,
                MySQL and Javascript to create a website.
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Speak to our mentors to know how you can get GTM certified. Get
                a job change today!
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                NYINST
              </div>
              <div
                className="mt-3 mobile_para_font mb-5"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                As a web designer/developer, there are several languages that
                you must learn such as Java, Python and much more. Check with
                counselors at NYINST to highlight the best modules and Live
                project certifications for your profile.
              </div>
            </div>
          </div>
          {/* <div className='col-md-3'>Enquiry Now</div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ImpressiveResumeBlogDetails;
