import React from "react";
import HeroSection from "./HeroSection";
import Header from "../Pages/Header";
import AboutSch from "./AboutSch";
import WhyTest from "./WhyTest";
import ScholarShipSlabs from "./ScholarshipSlabs";
import ScholarshipFaq from "./Faq";
import Footer from "../Pages/Footer";

function ScholarshipHome() {
  return (
    <div className="position-relative"
    style={{ zIndex: "0", backgroundColor: "white" }}>
      <Header />
      <HeroSection />
      <AboutSch />
      <WhyTest />
      <ScholarShipSlabs />
      <ScholarshipFaq />
      <Footer />
    </div>
  );
}

export default ScholarshipHome;
