import React from "react";
import qa_prog_img from "../../Assets/block_chain_seminar_img1.jpg";
import events_page_2_img from "../../Assets/ams_muthapudupet_img1.jpg";
import gallery_img_3 from "../../Assets/internship_img.jpg";
import gallery_img_4 from "../../Assets/bethlehem_img.jpg";
import gallery_img_5 from "../../Assets/panimalar_institute_img1.jpg";
import gallery_img_6 from "../../Assets/joseph_img1.jpg";
import gallery_img_7 from "../../Assets/skr_img1.jpeg";
import gallery_img_8 from "../../Assets/dmi_img1.jpg";
import { FiSearch } from "react-icons/fi";
import event_hero_img from "../../Assets/event_hero_img.svg";
import live_projects_hero_svg_line from "../../Assets/live_projects_hero_svg_line.svg";
import Header from "../Header";
import Footer from "../Footer";
import block_chain from "../../Assets/block_chain_events.png";
import android_img from "../../Assets/android_event_img.png";
import recruitment from "../../Assets/recruitment_img.png";
// import { img } from "react-lazy-load-image-component";
const EventsListSection = () => {
  return (
    <div className="position-relative"
    style={{ zIndex: "0", backgroundColor: "white" }}>
      <Header />
      <div
        className="position-relative mt-lg-0 mt-5 pt-lg-0 pt-5"
        style={{ background: "#EB871E", color: "white" }}
      >
        <img
          src={live_projects_hero_svg_line}
          className="position-absolute end-0 top-0"
          alt="meet and greet"
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <div className="my-auto">
                <div
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "49px",
                    lineHeight: "52px",
                  }}
                >
                  Meet & Greet
                </div>
                <div
                  className="w-75"
                  style={{
                    fontSize: "20px",
                    lineHeight: "36px",
                    fontWeight: "400",
                    paddingTop: "20px",
                  }}
                >
                  Get to know fellow tech enthusiasts and exchange ideas over
                  refreshments. It is the perfect opportunity to make new
                  connections, collaborate on projects.
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {/* <img
              src={event_hero_img}
              className='w-100'
              alt='events hero image'
            /> */}

              <img
                src={event_hero_img}
                className="w-100"
                alt="events hero image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="d-md-flex justify-content-between ">
          <div className="position-relative ">
            {/* <FiSearch
              className='position-absolute  top-50 translate-middle-y'
              style={{ right: '5%' }}
            /> */}
            {/* <input
              type='text'
              className=' py-2 my-2 ps-3 rounded mobile_search_width'
              style={{ border: '1px solid #D4D4D4', width: '22rem' }}
              placeholder='Search'
            /> */}
          </div>
          <div className="d-md-flex  mobile_blog_filter_width">
            {/* <select
              className=' py-2 my-2 ps-3 px-5 w-100 rounded'
              style={{ border: '1px solid #D4D4D4' }}
            >
              <option value='All Categories'>All Events</option>
              <option value='General'>Upcoming</option>
              <option value='Health'>Completed</option>
            </select> */}
          </div>
        </div>
        <div className="row" align="left">
          <div className="col-md-4 mt-lg-3 mt-3">
            <a href="/sa-engg-events" className="text-decoration-none">
              <div id="single_event_bg">
                <div>
                  <img src={block_chain} alt="" width="100%" />
                </div>
                <div id="event_detail_bg">
                  <div>26 MAR 2019 | Blockchain</div>
                  <div id="college_name">
                    SA Engineering College Blockchain Seminar
                  </div>
                </div>
              </div>{" "}
            </a>
          </div>
          <div className="col-md-4  mt-lg-3 mt-3">
          <a href="/mgr-engg-events" className="text-decoration-none">
            <div id="single_event_bg">
              <div>
                <img src={android_img} alt="" width="100%" />
              </div>
              <div id="event_detail_bg">
                <div>23 FEB 2019 | Android Development</div>
                <div id="college_name">Dr. MGR Engineering College</div>
              </div>
            </div>
            </a>
          </div>{" "}
          <div className="col-md-4  mt-lg-3 mt-3">
          <a href="/ams-engg-events" className="text-decoration-none">
            <div id="single_event_bg">
              <div>
                <img src={recruitment} alt="" width="100%" />
              </div>
              <div id="event_detail_bg">
                <div>18 FEB 2019 |Protocols of Block Chain</div>
                <div id="college_name">Aalim Muhammed Salegh College</div>
              </div>
            </div>
            </a>
          </div>
          <div className="col-md-4  mt-lg-3 mt-3">
          <a href="/beth-engg-events" className="text-decoration-none">
            <div id="single_event_bg">
              <div>
                <img src={recruitment} alt="" width="100%" />
              </div>
              <div id="event_detail_bg">
                <div>18 FEB 2019 | Campus Recruitment</div>
                <div id="college_name">Bethlehem Institute of Engineering</div>
              </div>
            </div>
            </a>
          </div>
          <div className="col-md-4  mt-lg-3 mt-3">
          <a href="/panimalar-engg-events" className="text-decoration-none">
            <div id="single_event_bg">
              <div>
                <img src={recruitment} alt="" width="100%" />
              </div>
              <div id="event_detail_bg">
                <div>20 FEB 2019 | Campus Recruitment</div>
                <div id="college_name">Panimalar Institute of Technology</div>
              </div>
            </div>
            </a>
          </div>
          <div className="col-md-4  mt-lg-3 mt-3">
          <a href="/joseph-engg-events" className="text-decoration-none">
            <div id="single_event_bg">
              <div>
                <img src={recruitment} alt="" width="100%" />
              </div>
              <div id="event_detail_bg">
                <div>13 FEB 2019 | Joseph Synergy 2K19</div>
                <div id="college_name">St. Joseph Arts and Science College</div>
              </div>
            </div>
            </a>
          </div>
          <div className="col-md-4  mt-lg-3 mt-3">
          <a href="/skr-engg-events" className="text-decoration-none">
            <div id="single_event_bg">
              <div>
                <img src={recruitment} alt="" width="100%" />
              </div>
              <div id="event_detail_bg">
                <div>08 FEB 2019 | Placement Drive</div>
                <div id="college_name">SKR Engineering College</div>
              </div>
            </div>
            </a>
          </div>
          <div className="col-md-4  mt-lg-3 mt-3">
          <a href="/dmi-engg-events" className="text-decoration-none">
            <div id="single_event_bg">
              <div>
                <img src={recruitment} alt="" width="100%" />
              </div>
              <div id="event_detail_bg">
                <div>31 JAN 2019 | Campus Recruitment</div>
                <div id="college_name">DMI Engineering College</div>
              </div>
            </div>
            </a>
          </div>
        </div>
        {/* <div align='center' className='py-5'>
          <button
            className='btn   px-5 py-2'
            style={{
              border: '1px solid #D4D4D4',
              whiteSpace: 'nowrap',
              fontWeight: '600',
              borderRadius:"10px"
            }}
          >
            Load More
          </button>
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default EventsListSection;
