import React, { useState } from "react";
import "../Styles/LandingPage/Header.css";
import nyinst_logo from "../Assets/ny_logo.png";
import download_pdf from "../Assets/LandingPage/sample_file.csv";
import BookNowForm from "./ApplyNow";
import BookNowFormWebMobile from "./BookNowWebMobile";
function LandingHeader() {
  const [show, setShow] = useState();
  const handleClick = (e) => {
    setShow(!show);
  };
  const [modalShow, setModalShow] = React.useState(false);

  // const handleForm = ()=>{
  //   setModalShow(false);
  //   setS
  // }

  return (
    <div>
      <div id="landing_header_bg">
        <div
          className="container-fluid d-lg-flex d-none"
          id="landing_header_items"
        >
          {" "}
          <div id="land_ny_logo">
            <a href="/">
              {" "}
              <img src={nyinst_logo} alt="" />
            </a>
          </div>
          <div id="land_header_menus" style={{ color: "#132137" }}>
            <a
              style={{ color: "#132137" }}
              href="/Best-Full-stack-development-training-institute-in-bangalore#webinar"
              className="text-decoration-none"
            >
              Free Webinar
            </a>
            {/* <div></div> */}
            <a
              href="/scholarship"
              className="text-decoration-none"
              style={{ color: "#132137" }}
            >
              Scholarship
            </a>
            <a
              href="javascript:void(Tawk_API.toggle())"
              className="text-decoration-none"
              style={{ color: "#132137" }}
            >
              Talk to expert
            </a>
          </div>
          <div id="land_down_btn">
            {/* <a href={download_pdf} className="text-decoration-none" target="_blank"> */}{" "}
            <button className="btn" onClick={() => setModalShow(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                  fill="white"
                />
              </svg>
              Download Syllabus
            </button>
            {/* </a> */}
          </div>
        </div>
        <BookNowForm
          show={modalShow}
          onHide={() => setModalShow(false)}
          // location="Chennai"
        />
        {/* for mobiles */}
        <div
          className="container-fluid d-lg-none d-flex align-items-center justify-content-between"
          id="mob_landing_header_items"
        >
          {" "}
          <div id="mob_land_ny_logo">
            <a href="/">
              {" "}
              <img src={nyinst_logo} alt="" />
            </a>
          </div>
          <div onClick={handleClick}>
            {show ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8.00277 6.35091L13.7774 0.576172L15.4273 2.22608L9.65267 8.00081L15.4273 13.7755L13.7774 15.4254L8.00277 9.65071L2.22804 15.4254L0.578125 13.7755L6.35287 8.00081L0.578125 2.22608L2.22804 0.576172L8.00277 6.35091Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M18.6667 21V23.3333H5.83333V21H18.6667ZM24.5 12.8333V15.1666H3.5V12.8333H24.5ZM22.1667 4.66663V6.99996H9.33333V4.66663H22.1667Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
          {/* <div id="land_header_menus">
            <div>Free Webinar</div>
            <div>Scholarship</div>
            <div>Talk to expert</div>
          </div>
          <div id="land_down_btn">
            <button className="btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                  fill="white"
                />
              </svg>
              Download Syllabus
            </button>
          </div> */}
        </div>
      </div>
      {show ? (
        <div id="land_side_bar" className="d-lg-none d-block">
          <a
            onClick={() => setShow(false)}
            style={{ color: "#132137" }}
            href="/Best-Full-stack-development-training-institute-in-bangalore#webinar"
            id="side_free_text"
            className="text-decoration-none"
          >
            Free Webinar
          </a>
          <a
            href="/scholarship"
            className="text-decoration-none"
            id="side_free_text"
          >
            Scholarship
          </a>
          <a
            id="side_free_text"
            href="javascript:void(Tawk_API.toggle())"
            className="text-decoration-none"
          >
            Talk to expert
          </a>
          <div
            id="land_down_btn"
            style={{
              marginTop: "1rem",
              marginLeft: "1rem",
              marginBottom: "2rem",
            }}
          >
            <button className="btn" onClick={() => setModalShow(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                  fill="white"
                />
              </svg>
              Download Syllabus
            </button>
          </div>
          <BookNowFormWebMobile
            show={modalShow}
            onHide={() => setModalShow(false)}
            // location="Chennai"
          />
        </div>
      ) : (
        ""
      )}

      {show ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            background: "black",
            position: "absolute",
            top: "6rem",
            // zIndex: "1",
            opacity: "0.5",
          }}
        ></div>
      ) : (
        ""
      )}
    </div>
  );
}

export default LandingHeader;
