import React, { useState } from "react";
import "../Styles/LandingPage/Syllabus.css";
import BookNowForm from "./ApplyNow";
import BookNowFormWeb from "./BookNowWeb";
// import BookNowFormMobile from "./ApplyNowMobile";
import BookNowFormWebMobile from "./BookNowWebMobile";
import sample_gif from "../Assets/LandingPage/sys_gif.png";
import BookNowFormAndroidMobile from "./BookNowAndroidMobile";
import web_gif from "../Assets/LandingPage/web.gif";
import mobile_gif from "../Assets/LandingPage/mobile.gif";
function Syllabus() {
  const [modalShow, setModalShow] = React.useState(false);
  const [webmodalShow, setWebModalShow] = React.useState(false);

  const [mobileWebShow, setMobileWebShow] = React.useState(false);
  const [mobileAndroidShow, setMobileAndroidShow] = React.useState(false);

  const [ipadwebShow, setIpadWebShow] = React.useState(false);
  const [ipadAndroidShow, setIpadAndroidShow] = useState(false);

  return (
    <div>
      <div className="container">
        <div id="syllabus_bg" className="d-lg-block d-md-block d-none">
          <div id="syllabus_sub_topic">What You’ll Learn In This </div>
          <div id="syllabus_topic">Full Stack Development training program</div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div id="syllabus_lang_bg" className="position-relative">
                <div id="sy_icon_bg">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M24.5026 3.5C25.147 3.5 25.6693 4.02234 25.6693 4.66667V23.3333C25.6693 23.9777 25.147 24.5 24.5026 24.5H3.5026C2.85828 24.5 2.33594 23.9777 2.33594 23.3333V4.66667C2.33594 4.02234 2.85828 3.5 3.5026 3.5H24.5026ZM23.3359 12.8333H4.66927V22.1667H23.3359V12.8333ZM23.3359 5.83333H4.66927V10.5H23.3359V5.83333ZM12.8359 7V9.33333H10.5026V7H12.8359ZM8.16927 7V9.33333H5.83594V7H8.16927Z"
                        fill="#006BFF"
                      />
                    </svg>
                  </div>
                </div>
                <div id="sy_lang_topic">Full Stack Web Development</div>
                <div id="sy_lang_subtopic">
                  Involves designing and implementing both front-end and
                  back-end components for seamless & dynamic websites
                </div>
                <div id="sy_land_down_btn" className="d-lg-block d-md-none">
                  <button
                    className="btn "
                    onClick={() => setWebModalShow(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                        fill="white"
                      />
                    </svg>
                    Download Syllabus
                  </button>
                </div>{" "}
                <div id="sy_land_down_btn" className="d-lg-none d-md-block">
                  <button className="btn" onClick={() => setIpadWebShow(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                        fill="white"
                      />
                    </svg>
                    Download Syllabus
                  </button>
                </div>{" "}
                <div className="position-absolute bottom-0" id="sys_video_bg">
                  <img
                    src={web_gif}
                    alt=""
                    width="90%"
                    style={{
                      height: "21rem",
                      // width:"100%"
                      objectFit: "cover",
                      marginLeft:"2rem",
                      // backgroundSize: "cover",
                      backgroundPositionX: "center",
                      backgroundPositionY: "center",
                      borderRadius: "4px",
                    }}
                  />
                </div>
                <BookNowFormWeb
                  dept="Full Stack Web Development"
                  show={webmodalShow}
                  onHide={() => setWebModalShow(false)}
                  // location="Chennai"
                />
                <BookNowFormWebMobile
                  dept="Full Stack Web Development"
                  show={ipadwebShow}
                  onHide={() => setIpadWebShow(false)}
                  // location="Chennai"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div id="syllabus_lang_bg" className="position-relative">
                <div id="sy_icon_bg">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M8.16927 4.66668V23.3333H19.8359V4.66668H8.16927ZM7.0026 2.33334H21.0026C21.647 2.33334 22.1693 2.85568 22.1693 3.50001V24.5C22.1693 25.1444 21.647 25.6667 21.0026 25.6667H7.0026C6.35828 25.6667 5.83594 25.1444 5.83594 24.5V3.50001C5.83594 2.85568 6.35828 2.33334 7.0026 2.33334ZM14.0026 19.8333C14.647 19.8333 15.1693 20.3557 15.1693 21C15.1693 21.6444 14.647 22.1667 14.0026 22.1667C13.3583 22.1667 12.8359 21.6444 12.8359 21C12.8359 20.3557 13.3583 19.8333 14.0026 19.8333Z"
                        fill="#006BFF"
                      />
                    </svg>
                  </div>
                </div>
                <div id="sy_lang_topic">Full Stack Mobile Development</div>
                <div id="sy_lang_subtopic">
                  Full stack Mobile app development encompasses designing and
                  coding both the front-end UI and back-end functionality
                </div>
                <div id="sy_land_down_btn" className="d-lg-block d-md-none">
                  <button className="btn" onClick={() => setModalShow(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                        fill="white"
                      />
                    </svg>
                    Download Syllabus
                  </button>
                </div>
                <div id="sy_land_down_btn" className="d-lg-none d-md-block">
                  <button
                    className="btn"
                    onClick={() => setIpadAndroidShow(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                        fill="white"
                      />
                    </svg>
                    Download Syllabus
                  </button>
                </div>{" "}
                <div className="position-absolute bottom-0 " id="sys_video_bg">
                <img
                  src={mobile_gif}
                  alt=""
                  width="90%"
                  style={{
                    height: "21rem",
                    objectFit: "cover",
                    marginLeft:"2rem",
                    // backgroundSize: "cover",
                    backgroundPositionX: "center",
                    backgroundPositionY: "center",
                    borderRadius: "4px",
                  
                  }}
                />
              </div>
                {/* <div style={{marginTop:"3rem"}}>
                  <img src={sample_gif} alt="" width="100%" />
                </div> */}
                <BookNowForm
                  dept="Full Stack Mobile Development"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  // location="Chennai"
                />
                <BookNowFormAndroidMobile
                  dept="Full Stack Mobile Development"
                  show={ipadAndroidShow}
                  onHide={() => setIpadAndroidShow(false)}
                  // location="Chennai"
                />
              </div>
             
            </div>
          </div>
          <div id="sy_img_bg"></div>
        </div>
        {/* for mobiles */}
        <div id="mob_syllabus_bg" className="d-lg-none d-md-none d-block">
          <div id="mob_syllabus_sub_topic">What You’ll Learn In This </div>
          <div id="mob_syllabus_topic">
            Full Stack Development training program
          </div>
          <div className="row">
            <div className="col-md-6">
              <div id="mob_syllabus_lang_bg" className="position-relative">
                <div id="mob_sy_icon_bg">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.5052 2.5C17.9655 2.5 18.3385 2.8731 18.3385 3.33333V16.6667C18.3385 17.1269 17.9655 17.5 17.5052 17.5H2.50521C2.04497 17.5 1.67188 17.1269 1.67188 16.6667V3.33333C1.67188 2.8731 2.04497 2.5 2.50521 2.5H17.5052ZM16.6719 9.16667H3.33854V15.8333H16.6719V9.16667ZM16.6719 4.16667H3.33854V7.5H16.6719V4.16667ZM9.17188 5V6.66667H7.50521V5H9.17188ZM5.83854 5V6.66667H4.17188V5H5.83854Z"
                        fill="#006BFF"
                      />
                    </svg>
                  </div>
                </div>
                <div id="mob_sy_lang_topic">Full Stack Web Development</div>
                <div id="mob_sy_lang_subtopic">
                  Involves designing and implementing both front-end and
                  back-end components for seamless & dynamic websites
                </div>
                <div id="mob_sy_land_down_btn" className="w-100">
                  <button
                    className="btn w-100"
                    onClick={() => setMobileWebShow(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                        fill="white"
                      />
                    </svg>
                    Download Syllabus
                  </button>{" "}
                  <BookNowFormWebMobile
                    dept="Full Stack Web Development"
                    show={mobileWebShow}
                    onHide={() => setMobileWebShow(false)}
                    // location="Chennai"
                  />
                </div>
                <div className="position-absolute bottom-0" id="mob_sys_video_bg">
                  <img
                    align="center"
                    src={web_gif}
                    alt=""
                    width="90%"
                    style={{
                      height: "14.5rem",
                      objectFit: "cover",
                      // backgroundSize: "cover",
                      // backgroundPositionX: "center",
                      // backgroundPositionY: "center",
                      borderRadius: "4px",
                      overflow: "hidden",
                      marginLeft: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div id="mob_syllabus_lang_bg" className="position-relative">
                <div id="mob_sy_icon_bg">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5.83854 3.33329V16.6666H14.1719V3.33329H5.83854ZM5.00521 1.66663H15.0052C15.4655 1.66663 15.8385 2.03973 15.8385 2.49996V17.5C15.8385 17.9602 15.4655 18.3333 15.0052 18.3333H5.00521C4.54498 18.3333 4.17188 17.9602 4.17188 17.5V2.49996C4.17188 2.03973 4.54498 1.66663 5.00521 1.66663ZM10.0052 14.1666C10.4655 14.1666 10.8385 14.5397 10.8385 15C10.8385 15.4602 10.4655 15.8333 10.0052 15.8333C9.54496 15.8333 9.17188 15.4602 9.17188 15C9.17188 14.5397 9.54496 14.1666 10.0052 14.1666Z"
                        fill="#006BFF"
                      />
                    </svg>
                  </div>
                </div>
                <div id="mob_sy_lang_topic">Full Stack Mobile Development</div>
                <div id="mob_sy_lang_subtopic">
                  Full stack Mobile app development encompasses designing and
                  coding both the front-end UI and back-end functionality
                </div>
                <div id="mob_sy_land_down_btn">
                  <button
                    className="btn"
                    onClick={() => setMobileAndroidShow(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 19H21V21H3V19ZM13 13.1716L19.0711 7.1005L20.4853 8.51472L12 17L3.51472 8.51472L4.92893 7.1005L11 13.1716V2H13V13.1716Z"
                        fill="white"
                      />
                    </svg>
                    Download Syllabus
                  </button>
                  <BookNowFormAndroidMobile
                    dept="Full Stack Mobile Development"
                    show={mobileAndroidShow}
                    onHide={() => setMobileAndroidShow(false)}
                    // location="Chennai"
                  />
                  <div className="position-absolute bottom-0" id="mob_sys_video_bg">
                    <img
                      align="center"
                      src={mobile_gif}
                      alt=""
                      width="90%"
                      style={{
                        height: "14.5rem",
                        objectFit: "cover",
                        // backgroundSize: "cover",
                        // backgroundPositionX: "center",
                        // backgroundPositionY: "center",
                        borderRadius: "4px",
                        overflow: "hidden",
                        marginLeft: "1rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="mob_sy_img_bg"></div>
        </div>
      </div>
    </div>
  );
}

export default Syllabus;
