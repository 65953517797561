import React from "react";
import "../Styles/InternChallenges.css";
import intern_challenging from "../Assets/techieintern/intern_challenging.png";

function InternChallenges() {
  return (
    <div>
      <div
        className="d-lg-block d-none"
        style={{
          background: "#F4F8FF",
          height: "49.5rem",
          marginTop: "6.25rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6" style={{ marginTop: "8.5rem" }}>
              <div id="challenges_text">
                <div>
                  {" "}
                  Current challenges faced
                  <br /> by <span id="most_studs">most students</span>
                </div>
                <div id="chag_single_point" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Getting Internship opportunities for all the students in IT
                    companies is difficult.
                  </div>
                </div>

                <div id="chag_single_point" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Candidates get discouraged as they are not getting proper
                    guidance from the companies.
                  </div>
                </div>
                <div id="chag_single_point" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Students are not getting right exposure to technologies and
                    professional work environment.
                  </div>
                </div>
                <div id="chag_single_point" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Students get engaged in non-core or unproductive work.
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 "
              style={{ marginTop: "4.25rem" }}
              align="end"
            >
              <div align="end">
                <img src={intern_challenging} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* for mobiles */}
      <div
        className="d-lg-none d-block"
        style={{
          background: "#F4F8FF",
          // height: "46.5rem",
          marginTop: "3rem",
          paddingBottom:"2.25rem"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6" style={{ marginTop: "2.25rem" }}>
              <div id="mob_challenges_text">
                <div>
                  {" "}
                  Current challenges faced
                  <br /> by <span id="mob_most_studs">most students</span>
                </div>
                <div id="mob_chag_single_point" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Getting Internship opportunities for all the students in IT
                    companies is difficult.
                  </div>
                </div>

                <div id="mob_chag_single_point" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Candidates get discouraged as they are not getting proper
                    guidance from the companies.
                  </div>
                </div>
                <div id="mob_chag_single_point" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Students are not getting right exposure to technologies and
                    professional work environment.
                  </div>
                </div>
                <div id="mob_chag_single_point" className="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                        fill="#639C19"
                      />
                    </svg>
                  </div>
                  <div>
                    Students get engaged in non-core or unproductive work.
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 "
              style={{ marginTop: "2.25rem" }}
              align="end"
            >
              <div align="end">
                <img src={intern_challenging} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternChallenges;
