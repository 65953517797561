import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import policy_hero_img from "../Assets/policy_hero_img.png";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div style={{ background: "#065BD64D" }}>
        <div className="container text-white mt-lg-0 mt-5  pb-lg-0 pb-3">
          <div
            className="row tablet_privacy_padding"
            style={{ paddingTop: "48px" }}
          >
            <div className="col-lg-6 d-flex">
              <div className="my-auto">
                <div
                  className="condition_policy_header"
                  style={{
                    fontFamily: "Avenir LT Std",
                    fontSize: "97px",
                    fontWeight: "900",
                    color: "#065BD6",
                    lineHeight: "124px",
                  }}
                >
                  Privacy <br /> Policy
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-block d-none">
              <div>
                <img src={policy_hero_img} alt="policy hero image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ color: "#393939" }}>
        <div
          className="row "
          style={{ paddingTop: "48px", paddingBottom: "96px" }}
        >
          <div className="col-lg-12 justify-content-center">
            {" "}
            NYINST EDU SERVICES PVT LTD respects your privacy and is committed to protecting your
            personal data. This privacy policy explains how we collect, use, and
            protect your personal information when you use our website.
          </div>

          <div className="col-lg-12 justify-content-center">
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                1.Information We Collect
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                We may collect personal information from you, such as your name,
                email address, and phone number when you fill out a form on our
                website, register for a course, or subscribe to our newsletter.
                We may also collect non-personal information such as your IP
                address, browser type, and operating system.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                2.How We Use Your Information
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                We may use your personal information to communicate with you
                about our courses, events, and services, and to respond to your
                inquiries. We may also use your information to send you
                newsletters or promotional emails if you have subscribed to our
                mailing list. We may use non-personal information for analytical
                purposes, to improve the functionality and performance of our
                website, and to prevent fraud or other illegal activities.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                3.How We Protect Your Information
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                We take reasonable steps to protect your personal information
                from unauthorized access, disclosure, or destruction. We use
                industry-standard security measures to safeguard your
                information and ensure the integrity of our website. However, we
                cannot guarantee the security of your information transmitted
                through the internet, and you use our website at your own risk.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                4.Sharing Your Information
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                We may share your personal information with third-party service
                providers who help us operate our website and provide services
                to our users. We may also disclose your information to comply
                with a legal obligation, to protect our rights or property, or
                to prevent fraud or other illegal activities. We will not sell
                or rent your personal information to third parties for marketing
                purposes.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                5.Your Rights
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                You have the right to access and update your personal
                information that we hold about you. You also have the right to
                request that we delete your personal information, except for
                data that we are required to keep for legal or administrative
                purposes.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                6.Changes to Our Privacy Policy
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                We may update our privacy policy from time to time to reflect
                changes in our information practices. We will post the revised
                policy on our website, and the changes will take effect
                immediately upon posting. We encourage you to review this policy
                periodically to stay informed about how we collect, use, and
                protect your personal information.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                7.Contact Us
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                If you have any questions or concerns about our privacy policy,
                or if you wish to exercise your rights regarding your personal
                information, please contact us at <a
                          href="mailto:+info@nyinst.com"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          info@nyinst.com
                        </a>.
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
