import React, { useEffect, useState } from "react";
import Header from "./Header";
import "../Styles/Certificate.css";
import Footer from "./Footer";
import golden_tick from "../Assets/golden_tick.png";
import before_cert_search from "../Assets/before_search.png";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { useLocation, useSearchParams } from "react-router-dom";
import no_data_found from "../Assets/no_data_found.png";
import Lottie from "react-lottie";
import loader from "../Assets/loader.json";

function Certificate() {
  let [searchParams] = useSearchParams();
  const [student, setStudent] = useState("");
  const [certId, setCertId] = useState("");
  const [loading, setLoading] = useState();
  let urlCertID = searchParams.get("id");
  console.log(urlCertID, "cert");
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  // };

  useEffect(() => {
    // Check if the URL contains the certificate ID
    if (urlCertID) {
      const apiUrl = `https://api.nyinst.com/verifycertificate.php?certificate_id=${urlCertID}`;

      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Certificate data:", data);
          setStudent(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [urlCertID]); // Add urlCertID as a dependency
  const [id, setId] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Headers setup
    const certificateId = certId;
    const apiUrl = `https://api.nyinst.com/verifycertificate.php?certificate_id=${certificateId}`;

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(response.json());
          }, 1000); // Delay of 1 seconds (1000 milliseconds)
        });
      })

      .then((data) => {
        console.log("Certificate data:", data);
        setStudent(data);
        setLoading(false);
        setId(certId);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const dateString = student.data === undefined ? "" : student.data.time_stamp;
  const dateObject = moment(dateString, "YYYY/MM/DD").format("DD-MMM-YYYY");
  console.log(student.status, certId, "jk");
  const clearCertId = () => {
    setCertId("");
  };
  return (
    <div
      className="position-relative"
      style={{ zIndex: "0", backgroundColor: "white" }}
    >
      <Header />
      <div className="d-lg-block d-none">
        {/* {urlCertID ? (
          ""
        ) : ( */}
        <div id="verify_cert_bg" className="d-flex position-relative">
          <div className="m-auto" align="center">
            <div id="cert_veri_text">Certificate Verifications</div>
            <div id="cert_sub_text">
              Effortlessly confirm the authenticity of attendee certificates
              with
              <br /> our dedicated verification page
            </div>
          </div>
        </div>
        {/* )} */}

        <div className="container position-relative">
          {urlCertID ? (
            " "
          ) : (
            <form
              onSubmit={handleSubmit}
              class="form-inline"
              className=" w-100 position-absolute top-100 start-50 translate-middle"
            >
              <div className="position-relative d-flex">
                <div
                  className="position-absolute  top-50 start-0 translate-middle-y"
                  id="input_search_icon"
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M22.6693 20.3272V29.4892C22.6693 29.8574 22.3707 30.156 22.0026 30.156C21.8818 30.156 21.7633 30.1231 21.6597 30.061L16.0026 26.6667L10.3456 30.061C10.0299 30.2503 9.62038 30.1479 9.43094 29.8323C9.36878 29.7286 9.33594 29.61 9.33594 29.4892V20.3272C6.89736 18.3724 5.33594 15.3686 5.33594 12C5.33594 6.109 10.1116 1.33337 16.0026 1.33337C21.8937 1.33337 26.6693 6.109 26.6693 12C26.6693 15.3686 25.1078 18.3724 22.6693 20.3272ZM12.0026 21.8914V25.9568L16.0026 23.5568L20.0026 25.9568V21.8914C18.7674 22.3914 17.4171 22.6667 16.0026 22.6667C14.5881 22.6667 13.2378 22.3914 12.0026 21.8914ZM16.0026 20C20.4209 20 24.0026 16.4183 24.0026 12C24.0026 7.58176 20.4209 4.00004 16.0026 4.00004C11.5843 4.00004 8.0026 7.58176 8.0026 12C8.0026 16.4183 11.5843 20 16.0026 20Z"
                      fill="#79818D"
                    />
                  </svg>
                </div>
                <input
                  onChange={(e) => setCertId(e.target.value.trimStart())}
                  value={certId}
                  maxLength={24}
                  id="cert_input"
                  class="form-control mr-sm-2 w-100"
                  // type="search"
                  required
                  placeholder="Search by ID"
                  aria-label="Search"
                />
                <div
                  className={
                    certId !== "" ? "top-50 translate-middle-y" : "d-none"
                  }
                  id="cross_button"
                  onClick={clearCertId}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.9968 10.5865L16.9466 5.63672L18.3608 7.05093L13.411 12.0007L18.3608 16.9504L16.9466 18.3646L11.9968 13.4149L7.04703 18.3646L5.63281 16.9504L10.5826 12.0007L5.63281 7.05093L7.04703 5.63672L11.9968 10.5865Z"
                      fill="#222F43"
                    />
                  </svg>
                </div>
                <button
                  class="btn btn-outline-success my-2 my-sm-0 position-absolute  top-50 end-0 translate-middle-y"
                  id="search_button"
                  type="submit"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"
                      fill="white"
                    />
                  </svg>{" "}
                  Search
                </button>
              </div>
            </form>
          )}
        </div>
        {/* {loading ? (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loader,
            }}
            style={{
              height: "22.75rem",
              objectFit: "cover",
              width: "22.75rem",
            }}
          />
        ) : ( */}
        {
          <div className="" id="ver_stud_bg" align="center">
            {loading ? (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loader,
                }}
                style={{
                  height: "22.75rem",
                  objectFit: "cover",
                  width: "22.75rem",
                }}
              />
            ) : (
              <div>
                {" "}
                {urlCertID ? (
                  ""
                ) : (
                  <div id="result_text" align="center">
                    {student.data === undefined &&
                    student.status !== "failed" ? (
                      <>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M15.9974 29.3334C8.6336 29.3334 2.66406 23.3638 2.66406 16C2.66406 8.63622 8.6336 2.66669 15.9974 2.66669C23.3611 2.66669 29.3307 8.63622 29.3307 16C29.3307 23.3638 23.3611 29.3334 15.9974 29.3334ZM15.9974 26.6667C21.8885 26.6667 26.6641 21.8911 26.6641 16C26.6641 10.109 21.8885 5.33335 15.9974 5.33335C10.1064 5.33335 5.33073 10.109 5.33073 16C5.33073 21.8911 10.1064 26.6667 15.9974 26.6667ZM14.6641 9.33335H17.3307V12H14.6641V9.33335ZM14.6641 14.6667H17.3307V22.6667H14.6641V14.6667Z"
                            fill="#222F43"
                          />
                        </svg>{" "}
                        Result data will be shown here. Once, you Search
                      </>
                    ) : student.status === "success" ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M15.9974 29.3334C8.6336 29.3334 2.66406 23.3638 2.66406 16C2.66406 8.63622 8.6336 2.66669 15.9974 2.66669C23.3611 2.66669 29.3307 8.63622 29.3307 16C29.3307 23.3638 23.3611 29.3334 15.9974 29.3334ZM15.9974 26.6667C21.8885 26.6667 26.6641 21.8911 26.6641 16C26.6641 10.109 21.8885 5.33335 15.9974 5.33335C10.1064 5.33335 5.33073 10.109 5.33073 16C5.33073 21.8911 10.1064 26.6667 15.9974 26.6667ZM14.6641 9.33335H17.3307V12H14.6641V9.33335ZM14.6641 14.6667H17.3307V22.6667H14.6641V14.6667Z"
                            fill="#222F43"
                          />
                        </svg>{" "}
                        Result found for the ID:
                        <span id="search_id">
                          {" "}
                          {student.data.certificate_id}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {student.status === "failed" ? (
                  <div>
                    <div id="result_text" align="center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          d="M15.9974 29.3334C8.6336 29.3334 2.66406 23.3638 2.66406 16C2.66406 8.63622 8.6336 2.66669 15.9974 2.66669C23.3611 2.66669 29.3307 8.63622 29.3307 16C29.3307 23.3638 23.3611 29.3334 15.9974 29.3334ZM15.9974 26.6667C21.8885 26.6667 26.6641 21.8911 26.6641 16C26.6641 10.109 21.8885 5.33335 15.9974 5.33335C10.1064 5.33335 5.33073 10.109 5.33073 16C5.33073 21.8911 10.1064 26.6667 15.9974 26.6667ZM14.6641 9.33335H17.3307V12H14.6641V9.33335ZM14.6641 14.6667H17.3307V22.6667H14.6641V14.6667Z"
                          fill="#222F43"
                        />
                      </svg>{" "}
                      No Result found for the ID:
                      <span id="search_id"> {id}</span>
                    </div>
                    <div id="no_data_found">
                      <img src={no_data_found} alt="" />
                    </div>
                  </div>
                ) : student.data === undefined ? (
                  <div id="before_cert_img">
                    <img src={before_cert_search} alt="" />
                  </div>
                ) : (
                  <div id="result_cert_bg" className="position-relative">
                    <div id="verify_bg" className="d-flex align-items-center">
                      <div id="golden_tick">
                        <img src={golden_tick} alt="" width="100%" />
                      </div>
                      <div id="verify_text">Certificate Verified</div>
                    </div>
                    <div id="cand_details_bg" align="left">
                      {" "}
                      <div
                        className="d-flex align-items-center"
                        id="single_user_point"
                      >
                        <div id="user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M20 22H18V20C18 18.3431 16.6569 17 15 17H9C7.34315 17 6 18.3431 6 20V22H4V20C4 17.2386 6.23858 15 9 15H15C17.7614 15 20 17.2386 20 20V22ZM12 13C8.68629 13 6 10.3137 6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7C18 10.3137 15.3137 13 12 13ZM12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="user_label">Name</div>
                          <div id="user_detail">
                            {student.data === undefined
                              ? ""
                              : student.data.name}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        id="single_user_point"
                      >
                        <div id="user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17 15.2454V22.1169C17 22.393 16.7761 22.617 16.5 22.617C16.4094 22.617 16.3205 22.5923 16.2428 22.5457L12 20L7.75725 22.5457C7.52046 22.6877 7.21333 22.6109 7.07125 22.3742C7.02463 22.2964 7 22.2075 7 22.1169V15.2454C5.17107 13.7793 4 11.5264 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9C20 11.5264 18.8289 13.7793 17 15.2454ZM9 16.4185V19.4676L12 17.6676L15 19.4676V16.4185C14.0736 16.7935 13.0609 17 12 17C10.9391 17 9.92643 16.7935 9 16.4185ZM12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="user_label">Certificate ID</div>
                          <div id="user_detail">
                            {student.data === undefined
                              ? ""
                              : student.data.certificate_id}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        id="single_user_point"
                      >
                        <div id="user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="user_label">Issued Date</div>
                          <div id="user_detail">{dateObject}</div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        id="single_user_point"
                      >
                        <div id="user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M13 21V23H11V21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H9C10.1947 3 11.2671 3.52375 12 4.35418C12.7329 3.52375 13.8053 3 15 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H13ZM20 19V5H15C13.8954 5 13 5.89543 13 7V19H20ZM11 19V7C11 5.89543 10.1046 5 9 5H4V19H11Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="user_label">
                            {student.data.certificate_type !== "IV_FACULTY"
                              ? "Branch"
                              : "Designation"}
                          </div>
                          <div id="user_detail">
                            {student.data === undefined
                              ? ""
                              : student.data.certificate_type !== "IV_FACULTY"
                              ? student.data.branch
                              : student.data.designation}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex" id="single_user_point">
                        <div id="user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 0.585693L18 6.58569V9H22V19H23V21H1V19H2V9H6V6.58569L12 0.585693ZM18 19H20V11H18V19ZM6 11H4V19H6V11ZM8 7.41412V18.9999H11V12H13V18.9999H16V7.41412L12 3.41412L8 7.41412Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="user_label">College Name</div>
                          <div id="user_detail" className=" ">
                            {student.data === undefined
                              ? ""
                              : student.data.college_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        }
        {/* // )} */}
      </div>
      {/* for mobiles */}
      <div className="d-lg-none d-block">
        {/* {urlCertID ? (
          ""
        ) : ( */}
        <div id="mob_verify_cert_bg" className="d-flex position-relative">
          <div className="m-auto container" align="center">
            <div id="mob_cert_veri_text">Certificate Verifications</div>
            <div id="mob_cert_sub_text">
              Effortlessly confirm the authenticity of attendee <br />
              certificates with our dedicated <br /> verification page
            </div>
          </div>
        </div>
        {/* // )} */}

        <div className="container position-relative">
          {urlCertID ? (
            " "
          ) : (
            <form
              onSubmit={handleSubmit}
              class="form-inline"
              className="container w-100 position-absolute top-100 start-50 translate-middle"
            >
              <div className="position-relative d-flex">
                <div
                  className="position-absolute  top-50 start-0 translate-middle-y"
                  id="mob_input_search_icon"
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M17 15.2454V22.1169C17 22.393 16.7761 22.617 16.5 22.617C16.4094 22.617 16.3205 22.5923 16.2428 22.5457L12 20L7.75725 22.5457C7.52046 22.6877 7.21333 22.6109 7.07125 22.3742C7.02463 22.2964 7 22.2075 7 22.1169V15.2454C5.17107 13.7793 4 11.5264 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9C20 11.5264 18.8289 13.7793 17 15.2454ZM9 16.4185V19.4676L12 17.6676L15 19.4676V16.4185C14.0736 16.7935 13.0609 17 12 17C10.9391 17 9.92643 16.7935 9 16.4185ZM12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z"
                      fill="#79818D"
                    />
                  </svg>
                </div>
                <input
                  onChange={(e) => setCertId(e.target.value.trimStart())}
                  value={certId}
                  id="mob_cert_input"
                  maxLength={20}
                  class="form-control mr-sm-2 w-100"
                  // type="search"
                  required
                  placeholder="Search by ID"
                  aria-label="Search"
                />
                <div
                  className={
                    certId !== "" ? "top-50 translate-middle-y" : "d-none"
                  }
                  id="mob_cross_button"
                  onClick={clearCertId}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.9968 10.5865L16.9466 5.63672L18.3608 7.05093L13.411 12.0007L18.3608 16.9504L16.9466 18.3646L11.9968 13.4149L7.04703 18.3646L5.63281 16.9504L10.5826 12.0007L5.63281 7.05093L7.04703 5.63672L11.9968 10.5865Z"
                      fill="#222F43"
                    />
                  </svg>
                </div>
                <button
                  class="btn btn-outline-success  position-absolute  top-50 end-0 translate-middle-y"
                  id="mob_search_button"
                  type="submit"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"
                      fill="white"
                    />
                  </svg>{" "}
                </button>
              </div>
            </form>
          )}
        </div>

        <div className="" id="mob_ver_stud_bg" align="center">
          {loading ? (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loader,
              }}
              style={{
                height: "12.5rem",
                objectFit: "cover",
                width: "12.5rem",
              }}
            />
          ) : (
            <div>
              {" "}
              {urlCertID ? (
                ""
              ) : (
                <div id="mob_result_text" align="center">
                  {student.data === undefined && student.status !== "failed" ? (
                    <>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M7.99479 14.6667C4.31289 14.6667 1.32812 11.6819 1.32812 8.00004C1.32812 4.31814 4.31289 1.33337 7.99479 1.33337C11.6767 1.33337 14.6615 4.31814 14.6615 8.00004C14.6615 11.6819 11.6767 14.6667 7.99479 14.6667ZM7.99479 13.3334C10.9403 13.3334 13.3281 10.9456 13.3281 8.00004C13.3281 5.05452 10.9403 2.66671 7.99479 2.66671C5.04927 2.66671 2.66146 5.05452 2.66146 8.00004C2.66146 10.9456 5.04927 13.3334 7.99479 13.3334ZM7.32812 4.66671H8.66146V6.00004H7.32812V4.66671ZM7.32812 7.33337H8.66146V11.3334H7.32812V7.33337Z"
                          fill="#222F43"
                        />
                      </svg>{" "}
                      Result data will be shown here. Once, you Search
                    </>
                  ) : student.status === "success" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M7.99479 14.6667C4.31289 14.6667 1.32812 11.6819 1.32812 8.00004C1.32812 4.31814 4.31289 1.33337 7.99479 1.33337C11.6767 1.33337 14.6615 4.31814 14.6615 8.00004C14.6615 11.6819 11.6767 14.6667 7.99479 14.6667ZM7.99479 13.3334C10.9403 13.3334 13.3281 10.9456 13.3281 8.00004C13.3281 5.05452 10.9403 2.66671 7.99479 2.66671C5.04927 2.66671 2.66146 5.05452 2.66146 8.00004C2.66146 10.9456 5.04927 13.3334 7.99479 13.3334ZM7.32812 4.66671H8.66146V6.00004H7.32812V4.66671ZM7.32812 7.33337H8.66146V11.3334H7.32812V7.33337Z"
                          fill="#222F43"
                        />
                      </svg>{" "}
                      Result found for the ID:
                      <span id="mob_search_id">
                        {" "}
                        {student.data.certificate_id}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {student.status === "failed" ? (
                <div>
                  <div id="mob_result_text" align="center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99479 14.6667C4.31289 14.6667 1.32812 11.6819 1.32812 8.00004C1.32812 4.31814 4.31289 1.33337 7.99479 1.33337C11.6767 1.33337 14.6615 4.31814 14.6615 8.00004C14.6615 11.6819 11.6767 14.6667 7.99479 14.6667ZM7.99479 13.3334C10.9403 13.3334 13.3281 10.9456 13.3281 8.00004C13.3281 5.05452 10.9403 2.66671 7.99479 2.66671C5.04927 2.66671 2.66146 5.05452 2.66146 8.00004C2.66146 10.9456 5.04927 13.3334 7.99479 13.3334ZM7.32812 4.66671H8.66146V6.00004H7.32812V4.66671ZM7.32812 7.33337H8.66146V11.3334H7.32812V7.33337Z"
                        fill="#222F43"
                      />
                    </svg>{" "}
                    No Result found for the ID:
                    <span id="mob_search_id"> {id}</span>
                  </div>
                  <div id="mob_no_data_found">
                    <img src={no_data_found} alt="" width="100%" />
                  </div>
                </div>
              ) : student.data === undefined ? (
                <div id="mob_before_cert_img">
                  <img src={before_cert_search} alt="" width="100%" />
                </div>
              ) : (
                <div className="container">
                  <div
                    id="mob_result_cert_bg"
                    className={
                      urlCertID
                        ? "cert_id_bg position-relative"
                        : "position-relative"
                    }
                  >
                    <div
                      id="mob_verify_bg"
                      className="d-flex align-items-center"
                    >
                      <div id="mob_golden_tick">
                        <img src={golden_tick} alt="" width="100%" />
                      </div>
                      <div id="mob_verify_text">Certificate Verified</div>
                    </div>
                    <div id="mob_cand_details_bg" align="left">
                      {" "}
                      <div
                        className="d-flex align-items-center"
                        id="mob_single_user_point"
                      >
                        <div id="mob_user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M20 22H18V20C18 18.3431 16.6569 17 15 17H9C7.34315 17 6 18.3431 6 20V22H4V20C4 17.2386 6.23858 15 9 15H15C17.7614 15 20 17.2386 20 20V22ZM12 13C8.68629 13 6 10.3137 6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7C18 10.3137 15.3137 13 12 13ZM12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="mob_user_label">Name</div>
                          <div id="mob_user_detail">
                            {student.data === undefined
                              ? ""
                              : student.data.name}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        id="mob_single_user_point"
                      >
                        <div id="mob_user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M17 15.2454V22.1169C17 22.393 16.7761 22.617 16.5 22.617C16.4094 22.617 16.3205 22.5923 16.2428 22.5457L12 20L7.75725 22.5457C7.52046 22.6877 7.21333 22.6109 7.07125 22.3742C7.02463 22.2964 7 22.2075 7 22.1169V15.2454C5.17107 13.7793 4 11.5264 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9C20 11.5264 18.8289 13.7793 17 15.2454ZM9 16.4185V19.4676L12 17.6676L15 19.4676V16.4185C14.0736 16.7935 13.0609 17 12 17C10.9391 17 9.92643 16.7935 9 16.4185ZM12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="mob_user_label">Certificate ID</div>
                          <div id="mob_user_detail">
                            {student.data === undefined
                              ? ""
                              : student.data.certificate_id}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        id="mob_single_user_point"
                      >
                        <div id="mob_user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="mob_user_label">Issued Date</div>
                          <div id="mob_user_detail">{dateObject}</div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        id="mob_single_user_point"
                      >
                        <div id="mob_user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M13 21V23H11V21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H9C10.1947 3 11.2671 3.52375 12 4.35418C12.7329 3.52375 13.8053 3 15 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H13ZM20 19V5H15C13.8954 5 13 5.89543 13 7V19H20ZM11 19V7C11 5.89543 10.1046 5 9 5H4V19H11Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="mob_user_label">
                            {" "}
                            {student.data.certificate_type !== "IV_FACULTY"
                              ? "Branch"
                              : "Designation"}
                          </div>
                          <div id="mob_user_detail">
                            {student.data === undefined
                              ? ""
                              : student.data.certificate_type !== "IV_FACULTY"
                              ? student.data.branch
                              : student.data.designation}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        id="mob_single_user_point"
                      >
                        <div id="mob_user_icon_bg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 0.585693L18 6.58569V9H22V19H23V21H1V19H2V9H6V6.58569L12 0.585693ZM18 19H20V11H18V19ZM6 11H4V19H6V11ZM8 7.41412V18.9999H11V12H13V18.9999H16V7.41412L12 3.41412L8 7.41412Z"
                              fill="#222F43"
                            />
                          </svg>
                        </div>
                        <div>
                          <div id="mob_user_label">College Name</div>
                          <div id="mob_user_detail" className=" ">
                            {student.data === undefined
                              ? ""
                              : student.data.college_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Certificate;
