// @flow
import React,{useRef} from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import guided_programs from "../Assets/guided_program.png";
import guided_program from "../Assets/guided_program.png";
import industry_readiness from "../Assets/industry_img.png";
import mock_interview from "../Assets/mock_interview.png";
import placement from "../Assets/placement.png";
import Reviews from "./Reviews";
const Sticky2Styled = styled.div`
  overflow: hidden;

  .section {
    height: 70vh;
    background: linear-gradient(160deg, #f47c66 0%, #e51906 100%);
  }

  .sticky,
  .sticky2 {
    height:150vh;
    background: linear-gradient(
      160deg,
      #e4f5fc 0%,
      #bfe8f9 26%,
      #9fd8ef 59%,
      #0068f2 100%
    );
    width: 100%;

    & .animation,
    .animation2 {
      width: 100%;
      height: 100%;
      position: absolute;

      & svg {
        opacity: 1;
        position: absolute;
        width: 300px;
        height: 300px;
      }
    }

    .heading {
      position: absolute;
      height: 100%;
      width: 100%;

      h2 {
        font-size: 40px;
        position: absolute;
        bottom: 10%;
        left: 10%;
        margin: 0;
      }
    }
  }
`;

function Sticky2() {
  const sliderRef = useRef();
  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  var settingss = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 3,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    // pauseOnHover:false
  };
  var mobileSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    // centerMode:true,
    // responsive: [
    //   {
    //     breakpoint: 1301,
    //     settings: {
    //       slidesToShow: 4,
    //       slidesToScroll: 4,
    //       variableWidth: true,
    //       centerMode: true
    //       },
    //     },
    //     {
    //       breakpoint: 641,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         initialSlide: 1,
    //         variableWidth: true,

    //       },
    //    },
    // ],
  };
  return (
    <div>
    <Sticky2Styled>

      <Controller>
        <div>
          <Scene triggerHook="onLeave" duration={1000} pin>
            {(progress) => (
              <div className="sticky">
                <Timeline totalProgress={progress} paused>
                
                  <Timeline
                    target={
                      <div className="heading">
                        <div
                        //   {...settingss}
                          className="d-flex overflow-hidden  mt-5 mb-5"
                          id="expertises_bg" style={{marginLeft:"5%", paddingBottom:"20%", width:"max-content", marginRight:"20%"}}
                        >
                          <div>
                            <img src={guided_program} alt="" width="100%" style={{width:"28.5rem"}} />
                          </div>
                          <div>
                            <img src={industry_readiness} alt="" width="100%" style={{width:"28.5rem"}}/>
                          </div>
                          <div>
                            <img src={mock_interview} alt="" width="100%" style={{width:"28.5rem"}}/>
                          </div>
                          <div>
                            <img src={placement} alt="" width="100%" style={{width:"28.5rem"}}/>
                          </div>
                         

                      
                        </div>
                      </div>
                    }
                  >
                    <Tween to={{ opacity: 1 }} />
                    <Tween to={{ x: "-60%" }} />
                  </Timeline>

                </Timeline>
              </div>
            )}
          </Scene>
        </div>
      </Controller>
 
    </Sticky2Styled>
    {/* <Reviews /> */}
    </div>
  );
}

export default Sticky2;
