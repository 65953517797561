import React from "react";
import "../Styles/Clients.css";
import walmart from "../Assets/clients/walmart.png";
import adobe from "../Assets/clients/adobe.png";
import microsoft from "../Assets/clients/microsoft.png";
import ibm from "../Assets/clients/ibm.png";
import cognizant from "../Assets/clients/cognizant.png";
import wipro from "../Assets/clients/wipro.png";
import tcs from "../Assets/clients/tcs.png";
import tech_mahindra from "../Assets/clients/tech_mahindra.png";
import cisco from "../Assets/clients/cisco.png";
import samsung from "../Assets/clients/samsung.png";
import netflix from "../Assets/clients/netflix.png";
import amazon from "../Assets/clients/amazon.png";
import hcl from "../Assets/clients/hcl.png";
import zoho from "../Assets/clients/zoho.png";
import dream11 from "../Assets/clients/dream11.png";
import vi from "../Assets/clients/vi.png";
import capgemini from "../Assets/clients/capgemini.png";
import intel from "../Assets/clients/intel.png";
import flipkart from "../Assets/clients/flipkart.png";
import mindtree from "../Assets/clients/mindtree.png";
import accenture from "../Assets/clients/accenture.png";
import facebook from "../Assets/clients/facebook.png";
import hdfc from "../Assets/clients/hdfc.png";
import hp from "../Assets/clients/hp.png";
import google from "../Assets/clients/google.png";
import jio from "../Assets/clients/jio.png";
import Marquee from "react-fast-marquee";
function Clients() {
  return (
    <div>
      <div id="clients_bg" className="d-lg-block d-none">
        <div id="dream_job_text" align="center">
          Making Your Dream Job a Reality
        </div>
        <div id="clients_text" align="center">
          1000+ Prospective clients
        </div>
        <div id="clients_icon_bg" className="">
          <Marquee
            speed={80}
            direction="right"
            gradient={false}
            style={{
              marginTop: "8.5vh",
              background: "none",
              zIndex: "0",
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
            }}
          >
            <div id="single_icon_bg">
              <img src={walmart} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={adobe} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={microsoft} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={ibm} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={cognizant} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={wipro} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={tcs} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={tech_mahindra} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={cisco} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={samsung} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={netflix} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={amazon} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={hcl} alt="" />
            </div>
          </Marquee>
        </div>
        <div id="second_line_bg" className="">
          <Marquee
            speed={80}
            direction="left"
            gradient={false}
            style={{
              marginTop: "3.3vh",
              background: "none",
              zIndex: "0",
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
            }}
          >
            <div id="single_icon_bg">
              <img src={zoho} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={dream11} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={vi} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={capgemini} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={intel} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={flipkart} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={mindtree} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={accenture} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={facebook} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={hdfc} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={hp} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={google} alt="" />
            </div>
            <div id="single_icon_bg">
              <img src={jio} alt="" />
            </div>
          </Marquee>
        </div>
      </div>

      {/* for mobiles */}
      <div id="mobile_clients_bg" className="d-lg-none d-block">
        <div id="mobile_dream_job_text" align="center">
          Making Your Dream Job a Reality
        </div>
        <div id="mobile_clients_text" align="center">
          1000+ Prospective clients
        </div>
        <div id="mobile_clients_icon_bg" className="">
          <Marquee
            speed={80}
            direction="right"
            gradient={false}
            style={{
              marginTop: "3.3vh",
              background: "none",
              zIndex: "0",
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
            }}
          >
            <div id="mobile_single_icon_bg">
              <img src={walmart} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={adobe} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={microsoft} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={ibm} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={cognizant} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={wipro} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={tcs} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={tech_mahindra} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={cisco} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={samsung} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={netflix} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={amazon} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={hcl} alt="" />
            </div>
          </Marquee>
        </div>
        <div id="second_line_bg" className="">
          <Marquee
            speed={80}
            direction="left"
            gradient={false}
            style={{
              marginTop: "1.7vh",
              background: "none",
              zIndex: "0",
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
            }}
          >
            <div id="mobile_single_icon_bg">
              <img src={zoho} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={dream11} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={vi} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={capgemini} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={intel} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={flipkart} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={mindtree} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={accenture} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={facebook} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={hdfc} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={hp} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={google} alt="" />
            </div>
            <div id="mobile_single_icon_bg">
              <img src={jio} alt="" />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default Clients;
