import React from "react";
import "../Styles/Reviews.css";
import heart from "../Assets/heart.png";
import usha from "../Assets/usha.png";
import sankeeth from "../Assets/sankeeth.png";
import moments_img from "../Assets/moments_new_img.png";
import vector_img from "../Assets/path_vector_bg.png";
import mobile_vector_bg from "../Assets/mobile_vector_bg.png";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import aishwarya from "../Assets/aishwarya.webp";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import "lightgallery/css/lg-video.css";
import lgVideo from "lightgallery/plugins/video";
import LightGallery from "lightgallery/react";
import full_stack_webinar from "../Assets/full_stack_webinar.png";
import data_analytics from "../Assets/data_analytics_webinar.png";
import play_button from "../Assets/youtube_play_button.png";
import moments_pics from "../Assets/moments_pics.png";
import mobile_moments from "../Assets/mobile_moments.png";
import moments_img_papers from "../Assets/moments_img.png";
import review_img1 from "../Assets/review1.png";
import revirew_img2 from "../Assets/review2.png";
import mobile_review1 from "../Assets/mobile_review1.png";
import mobile_review2 from "../Assets/mobile_review2.png";
function Reviews() {
  const onInit = () => {
    // console.log("lightGallery has been initialized");
  };
  const fadeImages = [
    {
      content:
        "“Live projects provided me with a unique opportunity to work alongside experienced professionals. It was an invaluable learning experience.”",
      img: usha,
      detail: "Usha Kumari",
      YOP: "2022",
    },
    {
      content:
        "“It was great to work with a great team of inspired people from both technical and non technical background. Really good experience.”",
      img: sankeeth,
      detail: "Sankeeth",
      YOP: "2022",
    },
    {
      content:
        "“The working environment was fantastic! The team was supportive, collaborative, and had a positive attitude that made coming to work every day enjoyable.”",
      img: aishwarya,
      detail: "Aishwarya",
      YOP: "2022",
    },
  ];
  return (
    <div>
      <div id="reviews_bg">
        <div
          // style={{ height: "55.5rem" }}
          id="review_yellow_bg"
          className="position-relative d-lg-block d-none d-md-block"
        >
          <div id="review_vector_layer" className="position-absolute top-0">
            <img src={vector_img} alt="" width="100%" height="100%" />
          </div>
          <div
            id="review_gradient_bg"
            className="position-absolute top-0"
          ></div>
          <div align="center" id="review_topic" className="position-relative">
            {/* <img src={heart} alt="" /> */}
            <div
              id="words_nyinst"
              style={{
                marginTop: "0",
                fontSize: "2.75rem",
                fontWeight: "800",
                lineHeight: "3.5rem",
                fontStyle: "normal",
                fontFamily: "Plus Jakarta Sans",
                color: "#07152C",
              }}
            >
              Commendations from Our Alumni
            </div>
            <div
              id="sub_content"
              style={{
                marginTop: "0.75rem",
                fontSize: "1rem",
                fontWeight: "400",
                lineHeight: "1.5rem",
              }}
            >
              Different journeys, common goal
            </div>
          </div>
          <div className="container-fluid position-relative">
            <div id="image_layout_bg">
              <div className="row">
                <div className="col-lg-6  col-md-12">
                  <div id="video_bg" className="bg-white">
                    <div>
                      <div id="gallery-videojs-demo">
                        <LightGallery
                          onInit={onInit}
                          speed={500}
                          plugins={[lgThumbnail, lgZoom, lgVideo]}
                          onHasVideo={true}
                        >
                          <a
                            // data-lg-size="1280-720"
                            data-src="https://youtu.be/aXORY0oK9zk"
                            data-poster={review_img1}
                            style={{ backgroundRepeat: "no-repeat" }}
                            // data-sub-html="<h4>Puffin Hunts Fish To Feed Puffling | Blue Planet II | BBC Earth</h4><p>On the heels of Planet Earth II's record-breaking Emmy nominations, BBC America presents stunning visual soundscapes from the series' amazing habitats.</p>"
                          >
                            <div
                              id="first_video"
                              // className="bg-primary"
                              align="center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                style={{ width: "6.25rem", height: "6.25rem" }}
                                src={play_button}
                                alt=""
                                // width="100%"
                              />
                            </div>
                          </a>
                        </LightGallery>
                      </div>
                    </div>
                    <div>
                      <div id="gallery-videojs-demo">
                        <LightGallery
                          onInit={onInit}
                          speed={500}
                          plugins={[lgThumbnail, lgZoom, lgVideo]}
                          onHasVideo={true}
                        >
                          <a
                            // data-lg-size="1280-720"
                            data-src="https://youtu.be/3MYCIz_zBUY"
                            data-poster={revirew_img2}
                            style={{ backgroundRepeat: "no-repeat" }}

                            // data-sub-html="<h4>Puffin Hunts Fish To Feed Puffling | Blue Planet II | BBC Earth</h4><p>On the heels of Planet Earth II's record-breaking Emmy nominations, BBC America presents stunning visual soundscapes from the series' amazing habitats.</p>"
                          >
                            <div
                              id="second_video"
                              // className="bg-primary"
                              align="center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                style={{ width: "6.25rem", height: "6.25rem" }}
                                src={play_button}
                                alt=""
                                // width="100%"
                              />
                            </div>
                          </a>
                        </LightGallery>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-md-5 mt-lg-0 mt-0">
                  <div id="candidates_bg" className="bg-white">
                    <div id="first_candidate_bg" className="row">
                      <div className="col-6">
                        <div id="candidate_content">
                          “Live projects provided me with a unique opportunity
                          to work alongside experienced professionals. It was an
                          invaluable learning experience.”
                          <div
                            className="d-flex align-items-center"
                            id="candidate_data_bg"
                          >
                            <div id="candidate_detail">
                              <img src={usha} />
                            </div>
                            <div id="candidate_name">
                              Usha Kumari <div>2022</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div id="candidate_content">
                          “It was great to work with a great team of inspired
                          people from both technical and non technical
                          background. Really good experience.”
                          <div
                            className="d-flex align-items-center"
                            id="candidate_data_bg"
                          >
                            <div id="candidate_detail">
                              <img src={sankeeth} />
                            </div>
                            <div id="candidate_name">
                              Sankeeth <div>2022</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="moments_bg" className="bg-white d-flex ">
                    <div id="moments_text">
                      The Moments
                      <div id="moments_sub_text">
                        to remember & <br />
                        cherish forever!!
                      </div>
                    </div>
                    <div id="moments_img" align="center">
                      <img src={moments_img_papers} alt="" />
                    </div>
                    <div id="moments_images_bg">
                      <img
                        src={moments_img}
                        alt=""
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* for mobiles */}
        <div
          id="mobile_review_yellow_bg"
          className=" d-block d-lg-none d-md-none position-relative"
        >
          <div
            className="position-absolute top-0 z-1"
            style={{ height: "100%", width: "100%" }}
          >
            <img src={mobile_vector_bg} width="100%" height="100%" />
          </div>
          <div
            id="mobile_gradient_bg"
            className="position-absolute top-0"
          ></div>
          <div className="position-relative container-fluid">
            {/* <div id="mobile_heart" align="center">
              <img src={heart} alt="" />
            </div> */}
            <div
              id="mobile_title"
              style={{
                marginTop: "3.25rem",
                fontSize: "1.55rem",
                lineHeight: "2rem",
              }}
            >
              Commendations from<br /> Our Alumni
            </div>
            <div id="mobile_path_text">Different journeys, common goal</div>
            <div id="mobile_video_bg">
              <div>
                {" "}
                <LightGallery
                  onInit={onInit}
                  speed={500}
                  plugins={[lgThumbnail, lgZoom, lgVideo]}
                  onHasVideo={true}
                >
                  <a
                    // data-lg-size="1280-720"
                    data-src="https://youtu.be/aXORY0oK9zk"
                    data-poster={mobile_review1}
                    // data-sub-html="<h4>Puffin Hunts Fish To Feed Puffling | Blue Planet II | BBC Earth</h4><p>On the heels of Planet Earth II's record-breaking Emmy nominations, BBC America presents stunning visual soundscapes from the series' amazing habitats.</p>"
                  >
                    <div
                      id="mobile_single_video"
                      // id="first_video"
                      // className="bg-primary"
                      align="center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "6.25rem", height: "6.25rem" }}
                        src={play_button}
                        alt=""
                        // width="100%"
                      />
                    </div>
                  </a>
                </LightGallery>
              </div>
              <div>
                {" "}
                <LightGallery
                  onInit={onInit}
                  speed={500}
                  plugins={[lgThumbnail, lgZoom, lgVideo]}
                  onHasVideo={true}
                >
                  <a
                    // data-lg-size="1280-720"
                    data-src="https://youtu.be/3MYCIz_zBUY"
                    data-poster={mobile_review2}
                    // data-sub-html="<h4>Puffin Hunts Fish To Feed Puffling | Blue Planet II | BBC Earth</h4><p>On the heels of Planet Earth II's record-breaking Emmy nominations, BBC America presents stunning visual soundscapes from the series' amazing habitats.</p>"
                  >
                    <div
                      id="mobile_second_video"
                      // id="first_video"
                      // className="bg-primary"
                      align="center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "6.25rem", height: "6.25rem" }}
                        src={play_button}
                        alt=""
                        // width="100%"
                      />
                    </div>
                  </a>
                </LightGallery>
              </div>
            </div>
            <div id="mobile_candidat_bg">
              <Slide arrows={false}>
                {fadeImages.map((images, index) => {
                  return (
                    <div
                      id="mobile_candidate_content_bg"
                      className="w-100"
                      key={index}
                    >
                      {images.content}
                      <div id="mobile_candidate_data">
                        <div>
                          <img
                            src={images.img}
                            alt=""
                            width="100%"
                            style={{ borderRadius: "50%" }}
                          />
                        </div>
                        <div>
                          {images.detail}
                          <div>{images.YOP}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slide>
            </div>
            <div id="mobile_moments_bg">
              <div>The Moments</div>
              <div id="mobile_moments_sub_content">
                to remember &<br /> cherish forever!!
              </div>
              <div id="paper_img">
                <img src={moments_img_papers} alt="" />
              </div>
              <div id="mobile_moments_img" className="position-absolute end-0">
                <img src={mobile_moments} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
