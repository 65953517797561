import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import construction_img from "../Assets/construction_img.png";

function Construction() {
  return (
    <div>
      <Header />
      <div id="construction_bg">
        <div align="center" className="d-lg-block d-none">
          <img
            src={construction_img}
            alt=""
            style={{
              width: "29.625rem",
              height: "18.6875rem",
              marginTop: "8.75rem",
            }}
          />
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "700",
              lineHeight: "2rem",
              marginTop: "3.3rem",
            }}
          >
            Under Construction!!
          </div>
          <div
            style={{
              fontSize: "1rem",
              fontWeight: "400",
              lineHeight: "1.5rem",
              marginTop: "0.75rem",
            }}
          >
            We're in the process of revamping our website to bring you an even
            better
            <br /> experience
          </div>
          <a href="/techie-pro" className="text-decoration-none">
            <div
              style={{
                height: "3rem",
                width: "23.3rem",
                borderRadius: "2.5rem",
                backgroundColor: "#F4F8FF",
                border: "1px solid #EBECEE",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2.25rem",
                marginBottom: "15.25rem",
              }}
            >
              {" "}
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  color: "#79818D",
                }}
              >
                Check Out Our Program -{" "}
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "400",
                    lineHeight: "1.5rem",
                    color: "#006BFF",
                  }}
                >
                  Techie Pro{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#006BFF"
                    />
                  </svg>
                </span>
              </div>
            </div>{" "}
          </a>
        </div>
        <div align="center" className="d-lg-none d-block">
          <img
            src={construction_img}
            alt=""
            style={{
              width: "16.937rem",
              height: "10.687rem",
              marginTop: "8.31rem",
            }}
          />
          <div
            style={{
              fontSize: "1rem",
              fontWeight: "700",
              lineHeight: "2rem",
              marginTop: "1.75rem",
            }}
          >
            Under Construction!!
          </div>
          <div
            style={{
              fontSize: "0.8125rem",
              fontWeight: "400",
              lineHeight: "1.5rem",
              marginTop: "0.5rem",
            }}
          >
            We're in the process of revamping our website to bring you an even
            better
            <br /> experience
          </div>
          <a href="/techie-pro" className="text-decoration-none">
            <div
              style={{
                height: "2.5rem",
                width: "18.75rem",
                borderRadius: "2.5rem",
                backgroundColor: "#F4F8FF",
                border: "1px solid #EBECEE",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1.25rem",
                marginBottom: "6.25rem",
              }}
            >
              {" "}
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                  color: "#79818D",
                }}
              >
                Check Out Our Program -{" "}
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "400",
                    lineHeight: "1.5rem",
                    color: "#006BFF",
                  }}
                >
                  Techie Pro{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#006BFF"
                    />
                  </svg>
                </span>
              </div>
            </div>{" "}
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Construction;
