import React from "react";
import "../Styles/LandingPage/SoftwareTech.css";
import html from "../Assets/LandingPage/html.png";
import css from "../Assets/LandingPage/css.png";
import js from "../Assets/LandingPage/js.png";
import nodejs from "../Assets/LandingPage/nodejs.png";
import kotlin from "../Assets/LandingPage/kotlin.png";
import swift from "../Assets/LandingPage/swift.png";
import bootstrap from "../Assets/LandingPage/bootstrap.png";
import react from "../Assets/LandingPage/react.png";
import mongodb from "../Assets/LandingPage/mongodb.png";
import mysql from "../Assets/LandingPage/mysql.png";
import java from "../Assets/LandingPage/java.png";
import alien from "../Assets/LandingPage/alien.png";
import apple from "../Assets/LandingPage/apple.png";
import php from "../Assets/LandingPage/php.png";
import firebase from "../Assets/LandingPage/firebase.png";
import playstore from "../Assets/LandingPage/playstore.png";
import coco from "../Assets/LandingPage/coco.png";
import android from "../Assets/LandingPage/android.png";
function SoftwareTech() {
  return (
    <div>
      <div className="container d-lg-block d-md-block d-none">
        <div id="tech_text">
        Gain practical experience on wide 
          <div id="soft_tech_title">range of Technologies</div>
        </div>
        <div id="soft_tech_stack_bg">
            <div id="soft_sing_tech_stack_bg">
                <img src={html} alt=""/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={css} alt=""/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={js} alt=""/>
            </div>
            <div id="soft_sing_tech_stack_bg" >
                <img src={bootstrap} alt="" style={{height:"2.983rem", width:"3.75rem"}}/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={react} alt=""/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={nodejs} alt="" style={{height:"4.25rem", width:"3.75rem"}}/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={mongodb} alt="" style={{height:"3.75rem", width:"1.6rem"}}/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={mysql} alt="" style={{height:"2.55338rem", width:"3.75rem"}}/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={java} alt="" style={{height:"3.75rem", width:"2.76581rem"}}/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={alien} alt="" />
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={apple} alt=""/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={kotlin} alt=""/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={swift} alt=""/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={php} alt="" style={{height:"2.31956rem", width:"3.75rem"}}/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={firebase} alt="" style={{height:"3.75rem", width:"2.72919rem"}}/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={playstore} alt=""/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={coco} alt=""/>
            </div>
            <div id="soft_sing_tech_stack_bg">
                <img src={android} alt="" style={{height:"3.75rem", width:"3.6625rem"}}/>
            </div>
        </div>
      </div>
      {/* for mobiles */}
      <div className="container d-lg-none d-md-none d-block">
        <div id="mob_tech_text">
        Gain practical experience on wide 
          <div id="mob_soft_tech_title">range of Technologies</div>
        </div>
        <div id="mob_soft_tech_stack_bg">
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={html} alt=""/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={css} alt=""/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={js} alt=""/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg" >
                <img src={bootstrap} alt="" style={{height:"1.9rem", width:"2.5rem"}}/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={react} alt=""/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={nodejs} alt="" style={{height:"2.8rem", width:"2.5rem"}}/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={mongodb} alt="" style={{height:"2.5rem", width:"1.122rem"}}/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={mysql} alt="" style={{height:"1.7rem", width:"2.5rem"}}/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={java} alt="" style={{height:"2.5rem", width:"1.841rem"}}/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={alien} alt="" />
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={apple} alt=""/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={kotlin} alt=""/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={swift} alt=""/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={php} alt="" style={{height:"1.54rem", width:"2.5rem"}}/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={firebase} alt="" style={{height:"2.5rem", width:"1.8194rem"}}/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={playstore} alt=""/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={coco} alt=""/>
            </div>
            <div id="mob_soft_sing_tech_stack_bg">
                <img src={android} alt="" style={{height:"3.75rem", width:"3.6625rem"}}/>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SoftwareTech;
