import { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import "../Styles/TechiePro/ApplyForm.css";
import axios from "axios";
import qs from "qs";
import india_img from "../Assets/india_img.png";
import success_tick from "../Assets/success_tick_img.png";
import tpro_img from "../Assets/t_pro.png";
import tnerd_img from "../Assets/t_nerds.png";
import tsuper_img from "../Assets/t_super.png";
import { PulseLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import "../Styles/BookForm.css";
import book_now_img from "../Assets/book_now_img.png";
function BookNowBangalore(props) {
  const [show, setShow] = useState(props.show);
  // let show = props.show;
  // console.log(show, "sd");
  // console.log(props.location, "hj");
  const handleClose = () => setShow(false);
  const location = useLocation();
  //   const handleShow = () => setShow(true);
  // console.log(handleClose);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pov, setPov] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState();
  const [status, setStatus] = useState();
  // const location =
  const reqData = {
    mod: "Candidate",
    actionType: "office-visit",
    subAction: JSON.stringify({
      NAME: name,
      PHONE_NUMBER: phone,
      EMAIL: email,
      PURPOSE_OF_VISIT: pov,
      DATE: date,
      TIME: time,
      BRANCH:"Bangalore"
    }),
    // EMAIL: mail,
  };
  const handleSubmit = (e) => {
    console.log(name, phone, email, date, time, "gh");
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "data");
      setLoading(false);
      setStatus(res.data.XSCStatus);
      if (res.data.XSCStatus === 0) {
        setName("");
        setPhone("");
        setEmail("");
        setDate("");
        setTime("");
        setPov("");
      }
    });
  };

  return (
    <div id="book_now">
      <div className="" id="">
        {" "}
        <Modal
          className="d-lg-block d-none"
          {...props}
          size="xl"
          // aria-labelledby="contained-modal"
          centered
          aria-labelledby="example-custom-modal-styling-title"
        >
           <Modal.Header closeButton className="position-absolute top-0" style={{right:"0"}}>
            <Modal.Title id=""></Modal.Title>
          </Modal.Header>

          {/* </Modal.Body> */}
          {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
          <div id="book_form_bg">
            <div id="book_now_img">
              <img src={book_now_img} alt="" />
            </div>
            <div id="book_form">
              <div id="visit_chennai_text">
                Visit NYINST Office{" "}
                <span id="office_location_text">Bangalore</span>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="bangname"
                    id="bangname"
                    className=""
                    value={name}
                    onChange={(e) => setName(e.target.value.trimStart())}
                    type="text"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    htmlFor="bangname"
                    className="position-absolute start-0 top-0 input-label"
                  >
                    Name
                  </label>
                </div>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="bangemail"
                    id="bangemail"
                    className={email.trim() !== "" ? "has_value" :"no_value" }
                    onChange={(e) => setEmail(e.target.value.toLowerCase().trimStart())}
                    value={email}
                    type="email"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    className="position-absolute start-0 top-0 input-label"
                    htmlFor="bangemail"
                  >
                    Email
                  </label>
                </div>

                <div id="name_input_field">
                  <div className="row">
                    <div className="col-3 pe-0 me-0">
                      <div id="india_num">
                        <img src={india_img} alt="" />
                        <span className="ps-1">+91</span>
                      </div>
                    </div>
                    <div className="col-9 ps-0">
                      <div
                        className="position-relative phone_number"
                        id="name_input_field"
                      >
                        <input
                          name="bangphn"
                          id="bangphn"
                          className={phone.trim() !== "" ? "has_value" :"no_value" }
                          value={phone}
                          maxLength={10}
                          pattern="[0-9]{10}"
                          onChange={(e) =>
                            setPhone(e.target.value.replace(/\D/g, ""))
                          }
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="bangphn"
                          className="position-absolute start-0 top-0  input-label"
                        >
                          Phone Number
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="bangpurpose"
                    id="bangpurpose"
                    className=""
                    onChange={(e) => setPov(e.target.value.trimStart())}
                    value={pov}
                    type="text"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    className="position-absolute start-0 top-0 input-label"
                    htmlFor="bangpurpose"
                  >
                    Purpose of Visit
                  </label>
                </div>
                <div id="name_input_field">
                  <div className="row">
                    <div className="col-6">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="bangdate"
                          id="bangdate"
                          className=""
                          value={date}
                          // maxLength={4}
                          // pattern="[0-9]{4}"
                          onChange={(e) => setDate(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="date"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="bangdate"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Date
                        </label>
                      </div>
                    </div>
                    <div className="col-6 ps-0">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="bangtime"
                          id="bangtime"
                          className=""
                          // value={deg}
                          onChange={(e) => setTime(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="time"
                          value={time}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="bangtime"
                          className="position-absolute start-0 top-0  input-label"
                        >
                          Time
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="border_bottom" className=""></div>

                <div id="form_submit_btn">
                  {status === 0 ? (
                    <button
                      className="btn"
                      type="submit"
                      style={{
                        height: "3.25rem",
                        background: "#02EE6A14",
                        border: "2px solid #02EE6A",
                        color: "#02EE6A",
                        fontSize: "0.8rem",
                        lineHeight: "2rem",
                      }}
                    >
                      <img
                        src={success_tick}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />{" "}
                      Thanks For Submitting!
                    </button>
                  ) : (
                    <button className="btn" type="submit">
                      Submit{" "}
                      {loading ? <PulseLoader color="white" size={6} /> : ""}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
      <div className="" id="mobile_apply_form">
        {" "}
        <Modal
          id="main-modal"
          className="d-lg-none d-block"
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="position-absolute top-0"
            style={{ right: "0" }}
          >
            <Modal.Title id=""></Modal.Title>
          </Modal.Header>
          {/* <Modal.Body> */}
            {" "}
            <div id="mobile_register_form">
              <div id="apply_for_text_bg">
                <div id="apply_for_text">
                  Visit NYINST Office{" "}
                  <span style={{ color: "#006BFF" }}>Bangalore</span>
                </div>
              </div>

              <div>
                <form
                  className="position-relative"
                  onSubmit={() => handleSubmit}
                >
                  <div className="position-relative" id="name_input_field">
                    <input
                      name="bang_book_name"
                      id="bang_book_name"
                      className="px-2"
                      value={name}
                      onChange={(e) => setName(e.target.value.trimStart())}
                      // onChange={(e) => setCollege({ collegeName: e.target.value })}
                      // onChange={(e) =>
                      //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                      // }
                      type="text"
                      // value={collegeName}
                      // placeholder="College/University Name"

                      required
                    />
                    <label
                      htmlFor="bang_book_name"
                      className="position-absolute start-0 top-0 input-label"
                    >
                      Name
                    </label>
                  </div>
                  <div className="position-relative" id="name_input_field">
                    <input
                      name="bang_book_email"
                      id="bang_book_email"
                      // className="px-2"
                      className={email.trim() !== "" ? "has_value px-2" :"no_value px-2"  }
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase().trimStart())}
                      // onChange={(e) => setCollege({ collegeName: e.target.value })}
                      // onChange={(e) =>
                      //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                      // }
                      type="email"
                      // value={collegeName}
                      // placeholder="College/University Name"

                      required
                    />
                    <label
                      className="position-absolute start-0 top-0 input-label"
                      htmlFor="bang_book_email"
                    >
                      Email
                    </label>
                  </div>
                  <div id="name_input_field">
                    <div className="row">
                      <div className="col-3">
                        <div id="india_num">
                          <img src={india_img} alt="" />
                          <span className="" style={{ paddingLeft: "0.25rem" }}>
                            +91
                          </span>
                        </div>
                      </div>
                      <div className="col-9 ps-0 ms-0">
                        <div
                          className="position-relative"
                          id="name_input_field"
                        >
                          <input
                            name="bang_mobile_phone_number"
                            id="bang_mobile_phone_number"
                            // className="px-2"
                            className={phone.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                            value={phone}
                            maxLength={10}
                            onChange={(e) =>
                              setPhone(e.target.value.replace(/\D/g, ""))
                            }
                            // onChange={(e) => setCollege({ collegeName: e.target.value })}
                            // onChange={(e) =>
                            //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                            // }
                            type="phone"
                            // value={collegeName}
                            // placeholder="College/University Name"

                            required
                          />
                          <label
                            htmlFor="bang_mobile_phone_number"
                            className="position-absolute start-0 top-0 input-label"
                          >
                            Phone Number
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="position-relative" id="name_input_field">
                    <input
                      name="bang_mobile_email"
                      id="bang_mobile_email"
                      className="px-2"
                      value={pov}
                      onChange={(e) => setPov(e.target.value.trimStart())}
                      // onChange={(e) => setCollege({ collegeName: e.target.value })}
                      // onChange={(e) =>
                      //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                      // }
                      type="text"
                      // value={collegeName}
                      // placeholder="College/University Name"

                      required
                    />
                    <label
                      className="position-absolute start-0 top-0 input-label"
                      htmlFor="bang_mobile_email"
                    >
                      Purpose of Visit
                    </label>
                  </div>
                  <div id="name_input_field">
                    <div className="row">
                      <div className="col-6">
                        <div
                          className="position-relative"
                          id="name_input_field"
                        >
                          <input
                            name="bang_mobile_year"
                            id="bang_mobile_year"
                            className="px-2"
                            value={date}
                            onChange={(e) => setDate(e.target.value.trimStart())}
                            // onChange={(e) => setCollege({ collegeName: e.target.value })}
                            // onChange={(e) =>
                            //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                            // }
                            type="date"
                            // value={collegeName}
                            // placeholder="College/University Name"

                            required
                          />
                          <label
                            htmlFor="bang_mobile_year"
                            className="position-absolute start-0 top-0 input-label"
                          >
                            Date
                          </label>
                        </div>
                      </div>
                      <div className="col-6 ps-0">
                        <div
                          className="position-relative"
                          id="name_input_field"
                        >
                          <input
                            name="bang_mobile_degree"
                            id="bang_mobile_degree"
                            className="px-2"
                            value={time}
                            onChange={(e) => setTime(e.target.value.trimStart())}
                            // onChange={(e) => setCollege({ collegeName: e.target.value })}
                            // onChange={(e) =>
                            //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                            // }
                            type="time"
                            // value={collegeName}
                            // placeholder="College/University Name"

                            required
                          />
                          <label
                            htmlFor="bang_mobile_degree"
                            className="position-absolute start-0 top-0 input-label"
                          >
                            Time
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mobile_border_bottom" className=""></div>

                  <div id="mobile_form_submit_btn">
                    {status === 0 ? (
                      <button
                        className="btn"
                        type="submit"
                        style={{
                          height: "3.25rem",
                          background: "#02EE6A14",
                          border: "2px solid #02EE6A",
                          color: "#02EE6A",
                          fontSize: "0.8rem",
                          lineHeight: "2rem",
                        }}
                      >
                        <img
                          src={success_tick}
                          alt=""
                          style={{ height: "20px", width: "20px" }}
                        />{" "}
                        Thanks For Submitting!
                      </button>
                    ) : (
                      <button className="btn" type="submit">
                        Submit{" "}
                        {loading ? <PulseLoader color="white" size={6} /> : ""}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          {/* </Modal.Body> */}

          {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}

export default BookNowBangalore;
