import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import Footer from "../Pages//Footer";
import Header from "../Pages/Header";
import block_chain_seminar_img1 from "../Assets/ams_muthapudupet_img1.jpg";
import ams_muthapudupet_img2 from "../Assets/ams_muthapudupet_img2.jpg";
import block_chain_seminar_img3 from "../Assets/block_chain_seminar_img3.jpg";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const AmsCollgeEvent = () => {
  return (
    <div>
      <Header />
      <div className="mb-5 mt-5 mt-lg-0" style={{ background: "#065BD6" }}>
        <div className="container py-5">
          <div className="row container">
            <div
              className="col-lg-2 p-0 m-0 text-white text-truncate"
              style={{
                fontSize: "13px",
                lineHeight: "16px",
                whiteSpace: "no-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <a href="/events" className=" text-white">
                Meet & greet Home <AiOutlineRight />
              </a>{" "}
              Aalim Muhammed Salegh College Of Engineering (A.M.S), Muthapudupet{" "}
            </div>
          </div>
          <div className="row">
            <div className=" col-lg-2 text-white"></div>
            <div className="col-lg-8 text-white ">
              <div style={{ paddingTop: "60px" }}>
                <div
                  className="d-flex"
                  style={{
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontWeight: "500",
                  }}
                >
                  <div className="text-start">28 FEB 2019 </div>
                  <div
                    className="mobile_dot_position"
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      fontSize: "2rem",
                      marginTop: "-1%",
                    }}
                  >
                    .
                  </div>
                  <div> 2:00pm</div>
                </div>

                <div className="text-start events_title_font_style">
                  Aalim Muhammed Salegh College Of Engineering (A.M.S),
                  Muthapudupet
                </div>
                <div
                  className="container mobile_hero_event_height"
                  style={{ height: "15rem" }}
                >
                  <img
                    src={ams_muthapudupet_img2}
                    className="w-100 "
                    style={{ bottom: "-50%" }}
                    alt="events details"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
      {/* events bottom height */}
      <div
        className="mobile_bottom_event_height"
        style={{ height: "15rem" }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="py-3 mt-lg-5 events_content_font_style">
              Our experts from NYINST presented a comprehensive overview
              on the protocols of Blockchain in a recent seminar session for
              final year students of Aalim Muhammed Salegh College Of
              Engineering (A.M.S), Muthapudupet, Avadi on 26th February 2019.
              Students who eagerly participated in the event gained knowledge
              about the real-world applications of Blockchain Technology. Wish
              to learn more about #Blockchain Technology and its benefits? Join
              the NYINST GTM course today!{" "}
            </div>
            <div className="py-3 w-100">
              <img
                src={block_chain_seminar_img1}
                className="w-100 img-fluid"
                alt="block chain seminar "
              />
            </div>
          </div>

          <div className="col-lg-2"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AmsCollgeEvent;
