// @flow
import React from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";

const StickyStyled = styled.div`
  .section {
    height: 100vh;
  }

  .sticky {
    // background-color: red;
    // width: 100%;

    & div {
      padding: 50px;
    }
  }

  .blue {
    background-color: blue;
  }
`;

const Sticky = () => (
  <div className="container">
    {" "}
    <div className="">
      <div className="">
        {" "}
        <StickyStyled>
          <div className="section" />
          <Controller>
            <div className="row">
              <div className="col-6">
                {" "}
                <Scene duration={2100} pin={true} enabled={true}>
                  <div className="sticky">
                    <div
                      style={{
                        fontSize: "3.25rem",
                        fontWeight: "400",
                        lineHeight: "4rem",
                      }}
                    >
                      Roles that our candidates Fullfill
                    </div>
                  </div>
                </Scene>
              </div>
              <div className="col-6">
                {" "}
                <Scene duration={200} pin={{ pushFollowers: false }}>
                  <div
                    className="sticky"
                    style={{
                      width: "46rem",
                      height: "22.75rem",
                      backgroundColor: "#E25E3E",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <div>Pin Test</div>
                  </div>
                </Scene>
                <Scene duration={200} pin={{ pushFollowers: false }}>
                  {(progress, event) => (
                    <div
                      className="sticky"
                      style={{
                        width: "46rem",
                        height: "22.75rem",
                        backgroundColor: "#662549",
                        borderRadius: "0.5rem",
                        // opacity: 0.2 + 0.8 * (1 - progress), // Adjust the opacity as per your preference
                      }}
                    >
                      <div>Pin Test</div>
                    </div>
                  )}
                </Scene>
                <Scene duration={200} pin={{ pushFollowers: false }}>
                  {(progress, event) => (
                    <div
                      className="sticky"
                      style={{
                        width: "46rem",
                        height: "22.75rem",
                        backgroundColor: "#4C4B16",
                        borderRadius: "0.5rem", 
                        //  opacity: 0.2 + 0.8 * (1 - progress), // Adjust the opacity as per your preference
                      }}
                    >
                      <div>Pin Test</div>
                    </div>
                  )}
                </Scene>
                <Scene duration={200} pin={true} offset={100}>
                  {(progress, event) => (
                    // console.log((progress,"kl")),
                    <div
                      className="sticky blue"
                      style={{
                        width: "46rem",
                        height: "22.75rem",
                        backgroundColor: "#C79A37",
                        opacity: window.scrollY ? 0.2 + 0.8 * progress : 0.3 + 0.8 * (1-progress), // Adjust the opacity as per your preference
                      }}
                    >
                      <div>Pin Test</div>
                    </div>
                  )}
                </Scene>
              </div>
            </div>
          </Controller>
          <div className="section" />
        </StickyStyled>
      </div>
      {/* <div className="col-6">
      {" "}
      <StickyStyled>
        <div className="section" />
        <Controller>
          <Scene duration={600} pin={true} enabled={true}>
            <div className="sticky">
              <div>Pin Test</div>
            </div>
          </Scene>
          <Scene duration={200} pin={{ pushFollowers: false }}>
            <div className="sticky">
              <div>Pin Test</div>
            </div>
          </Scene>
          <Scene duration={300} pin={true} offset={100}>
            <div className="sticky blue">
              <div>Pin Test</div>
            </div>
          </Scene>
        </Controller>
        <div className="section" />
      </StickyStyled>
    </div> */}
    </div>
  </div>
);

export default Sticky;
