import React, { useEffect, useRef, useState } from "react";
import "../Styles/ScholarShip/WhyTest.css";
import yellow_tick from "../Assets/yellow_tick.png";
import axios from "axios";
import qs from "qs";
import { PulseLoader } from "react-spinners";
import success_tick from "../Assets/success_tick_img.png";
import india_img from "../Assets/india_img.png";

function WhyTest() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [year, setYear] = useState("");
  const [deg, setDeg] = useState("");
  const [city, setCity] = useState("");
  const [testmode, setTestmode] = useState("online");
  const [status, setStatus] = useState();
  const reqData = {
    mod: "Candidate",
    actionType: "scholarship-registration",
    subAction: JSON.stringify({
      NAME: name,
      PHONE_NUMBER: phone,
      EMAIL: email,
      GRADUATION_YEAR: year,
      TEST_MODE: testmode,
      CITY: city,
      DEGREE: deg,
    }),
    // EMAIL: mail,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "data");

      setStatus(res.data.XSCStatus);
      setLoading(false);
      if (res.data.XSCStatus === 0) {
        setName("");
        setPhone("");
        setEmail("");
        setYear("");
        setDeg("");
        setCity("");
        setTestmode("");
      }
    });
  };

  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "#apply-scholarship") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  const mymobRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "#apply") {
      mymobRef.current.scrollIntoView();
      mymobRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);

  return (
    <div>
      {" "}
      <div   className="mt-lg-5 py-lg-5">
        {" "}
        <div id="why_test_bg" className="d-lg-block d-none">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div id="why_test_title">
                  Why To Take The Test <br /> For Scholarship?
                </div>
                <div id="test_single_point">
                  <div>
                    <img src={yellow_tick} alt="" />
                  </div>
                  <div id="test_single_point_text">
                    Test your Industry Readiness
                  </div>
                </div>
                <div id="test_single_point">
                  <div>
                    <img src={yellow_tick} alt="" />
                  </div>
                  <div id="test_single_point_text">
                    Get 5% scholarship by attending the test
                  </div>
                </div>
                <div id="test_single_point">
                  <div>
                    <img src={yellow_tick} alt="" />
                  </div>
                  <div id="test_single_point_text">
                    Merit based Scholarship ranging from 25% to 90%
                  </div>
                </div>
                <div id="test_single_point">
                  <div>
                    <img src={yellow_tick} alt="" />
                  </div>
                  <div id="test_single_point_text">
                    Live project insights to top 5% candidates
                  </div>
                </div>
                <div id="test_single_point">
                  <div>
                    <img src={yellow_tick} alt="" />
                  </div>
                  <div id="test_single_point_text">
                    Exposure on other flagship offering
                  </div>
                </div>
              </div>
              <div className="col-md-6" align="right" ref={myRef} id="apply-scholarship">
                {" "}
                <div id="why_test_form_bg" align="left" className="w-75">
                  <div className="position-relative z-1">
                    <div id="apply_text">
                      Apply To participate
                      <br />
                      <span id="sch_text">& Get Scholarship</span>
                      {/* <div
                    className="position-absolute bottom-0 z-0"
                    id="hire_yellow_bg"
                  ></div> */}
                    </div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="position-relative " id="name_input_field">
                      <input
                        name="Name"
                        id="Name"
                        className=""
                        value={name}
                        onChange={(e) => setName(e.target.value.trimStart())}
                        type="text"
                        // value={collegeName}
                        // placeholder="College/University Name"

                        required
                      />
                      <label
                        htmlFor="Name"
                        className="position-absolute start-0 top-0 input-label"
                      >
                        Your Name
                      </label>
                    </div>
                    <div className="position-relative" id="name_input_field">
                      <input
                        name="email"
                        id="email"
                        className={email.trim() !== "" ? "has_value" :"no_value" }
                        onChange={(e) => setEmail(e.target.value.toLowerCase().trimStart())}
                        value={email}
                        type="email"
                        // value={collegeName}
                        // placeholder="College/University Name"

                        required
                      />
                      <label
                        className="position-absolute start-0 top-0 input-label"
                        htmlFor="email"
                      >
                        Your Work E-mail
                      </label>
                    </div>

                    <div id="name_input_field">
                      <div className="row">
                        <div className="col-3 pe-0 me-0">
                          <div id="india_num">
                            <img src={india_img} alt="" />
                            <span className="ps-1">+91</span>
                          </div>
                        </div>
                        <div className="col-9 ps-0">
                          <div
                            className="position-relative phone_number"
                            id="name_input_field"
                          >
                            <input
                              name="phone_number"
                              id="phone_number"
                              className={phone.trim() !== "" ? "has_value" :"no_value" }
                              value={phone}
                              maxLength={10}
                              pattern="[0-9]{10}"
                              onChange={(e) =>
                                setPhone(e.target.value.toLowerCase().replace(/\D/g, ""))
                              }
                              // onChange={(e) => setCollege({ collegeName: e.target.value })}
                              // onChange={(e) =>
                              //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                              // }
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="phone_number"
                              className="position-absolute start-0 top-0  input-label"
                            >
                              Phone Number
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="name_input_field">
                      <div className="row">
                        <div className="col-6">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="city"
                              id="city"
                              className=""
                              value={city}
                              // maxLength={4}
                              // pattern="[0-9]{4}"
                              onChange={(e) => setCity(e.target.value.trimStart())}
                              // onChange={(e) => setCollege({ collegeName: e.target.value })}
                              // onChange={(e) =>
                              //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                              // }
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="city"
                              className="position-absolute start-0 top-0 input-label"
                            >
                              City
                            </label>
                          </div>
                        </div>
                        <div className="col-6 ps-0">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="graduation_year"
                              id="graduation_year"
                              className={year.trim() !== "" ? "has_value" :"no_value" }
                              value={year}
                              maxLength={4}
                              minLength={2}
                              // pattern="[0-9]{3}"
                              onChange={(e) =>
                                setYear(e.target.value.replace(/\D/g, ""))
                              }
                              // onChange={(e) => setCollege({ collegeName: e.target.value })}
                              // onChange={(e) =>
                              //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                              // }
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="graduation_year"
                              className="position-absolute start-0 top-0  input-label"
                            >
                              Graduation Year
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="name_input_field">
                      <div className="row">
                        <div className="col-6">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="deg"
                              id="deg"
                              className=""
                              value={deg}
                              // maxLength={4}
                              // pattern="[0-9]{4}"
                              onChange={(e) =>
                                setDeg(e.target.value.replace(/[^a-zA-Z!@#$%^&*()]/g, ''))
                              }
                              // onChange={(e) => setCollege({ collegeName: e.target.value })}
                              // onChange={(e) =>
                              //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                              // }
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="deg"
                              className="position-absolute start-0 top-0 input-label"
                            >
                              Degree
                            </label>
                          </div>
                        </div>
                        <div className="col-6 ps-0">
                          <div
                            className="position-relative sel"
                            id="name_input_field"
                          >
                            <select
                              className=""
                              required
                              name="testmode"
                              id="testmode"
                              value={testmode}
                              onChange={(e) => setTestmode(e.target.value.trimStart())}
                            >
                              {/* <option></option> */}
                              <option value="online">Online</option>
                              <option value="offline">Offline</option>
                            </select>
                            <label
                              id="test"
                              htmlFor="testmode"
                              className="position-absolute start-0 top-0  input-label"
                            >
                              Test Mode
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="border_bottom"
                      className=""
                      style={{ marginTop: "-1rem" }}
                    ></div>

                    <div id="form_submit_btn">
                      {status === 0 ? (
                        <button
                          className="btn"
                          type="submit"
                          style={{
                            height: "3.25rem",
                            background: "#02EE6A14",
                            border: "2px solid #02EE6A",
                            color: "#02EE6A",
                            fontSize: "0.8rem",
                            lineHeight: "2rem",
                          }}
                        >
                          <img
                            src={success_tick}
                            alt=""
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          Thanks For Submitting!
                        </button>
                      ) : (
                        <button className="btn" type="submit">
                          Submit{" "}
                          {loading ? (
                            <PulseLoader color="white" size={6} />
                          ) : (
                            ""
                          )}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* for mobiles */}
      <div>
        <div id="mob_why_test_bg" className="d-lg-none d-block">
          <div className="container">
            <div id="mob_why_test_title">
              Why To Take The Test For Scholarship?
            </div>
            <div id="mob_why_test_sing_pt">
              <div>
                <img src={yellow_tick} alt="" />
              </div>
              <div id="mob_why_test_text">Test your Industry Readiness</div>
            </div>
            <div id="mob_why_test_sing_pt">
              <div>
                <img src={yellow_tick} alt="" />
              </div>
              <div id="mob_why_test_text">
                Get 5% scholarship by attending the test
              </div>
            </div>
            <div id="mob_why_test_sing_pt">
              <div>
                <img src={yellow_tick} alt="" />
              </div>
              <div id="mob_why_test_text">
                Merit based Scholarship ranging from 25% to 90%
              </div>
            </div>
            <div id="mob_why_test_sing_pt">
              <div>
                <img src={yellow_tick} alt="" />
              </div>
              <div id="mob_why_test_text">
                Live project insights to top 5% candidates
              </div>
            </div>
            <div id="mob_why_test_sing_pt">
              <div>
                <img src={yellow_tick} alt="" />
              </div>
              <div id="mob_why_test_text">
                Exposure on other flagship offering{" "}
              </div>
            </div>
            <div id="apply" ref={mymobRef}>
              <div id="mobile_register_form" style={{ marginTop: "1.88rem" }}>
                <div className="position-relative">
                  <div id="mob_sch_form_title">
                    Apply To Participate
                    <br />
                    <span id="mob_get_sch_text">& Get Scholarship</span>
                  </div>
                </div>

                <div>
                  <form className="position-relative" onSubmit={handleSubmit}>
                    <div className="position-relative" id="name_input_field">
                      <input
                        name="mobile_name"
                        id="mobile_name"
                        className="px-2"
                        value={name}
                        onChange={(e) => setName(e.target.value.trimStart())}
                        // onChange={(e) => setCollege({ collegeName: e.target.value })}
                        // onChange={(e) =>
                        //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                        // }
                        type="text"
                        // value={collegeName}
                        // placeholder="College/University Name"

                        required
                      />
                      <label
                        htmlFor="mobile_name"
                        className="position-absolute start-0 top-0 input-label"
                      >
                        Name
                      </label>
                    </div>
                    <div className="position-relative" id="name_input_field">
                      <input
                        name="mobile_email"
                        id="mobile_email"
                        // className="px-2"
                        className={email.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase().trimStart())}
                        // onChange={(e) => setCollege({ collegeName: e.target.value })}
                        // onChange={(e) =>
                        //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                        // }
                        type="email"
                        // value={collegeName}
                        // placeholder="College/University Name"

                        required
                      />
                      <label
                        className="position-absolute start-0 top-0 input-label"
                        htmlFor="mobile_email"
                      >
                        Email
                      </label>
                    </div>
                    <div id="name_input_field">
                      <div className="row">
                        <div className="col-3">
                          <div id="india_num">
                            <img src={india_img} alt="" />
                            <span
                              className=""
                              style={{ paddingLeft: "0.25rem" }}
                            >
                              +91
                            </span>
                          </div>
                        </div>
                        <div className="col-9 ps-0 ms-0">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="mobile_phone_number"
                              id="mobile_phone_number"
                              // className="px-2"
                              className={phone.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                              value={phone}
                              maxLength={10}
                              onChange={(e) =>
                                setPhone(e.target.value.replace(/\D/g, ""))
                              }
                              // onChange={(e) => setCollege({ collegeName: e.target.value })}
                              // onChange={(e) =>
                              //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                              // }
                              type="phone"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="mobile_phone_number"
                              className="position-absolute start-0 top-0 input-label"
                            >
                              Phone Number
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="name_input_field">
                      <div className="row">
                        <div className="col-6">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="mob_city"
                              id="mob_city"
                              className="px-2"
                              value={city}
                              onChange={(e) => setCity(e.target.value.trimStart())}
                              // onChange={(e) => setCollege({ collegeName: e.target.value })}
                              // onChange={(e) =>
                              //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                              // }
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="mob_city"
                              className="position-absolute start-0 top-0 input-label"
                            >
                              City
                            </label>
                          </div>
                        </div>
                        <div className="col-6 ps-0">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="mob_year"
                              id="mob_year"
                              // className="px-2"
                              className={year.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                              value={year}
                              maxLength={4}
                              minLength={2}
                              onChange={(e) =>
                                setYear(e.target.value.replace(/\D/g, ""))
                              }
                              // onChange={(e) => setCollege({ collegeName: e.target.value })}
                              // onChange={(e) =>
                              //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                              // }
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="mob_year"
                              className="position-absolute start-0 top-0 input-label"
                            >
                              Graduation Year
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="name_input_field">
                      <div className="row">
                        <div className="col-6">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="mob_deg"
                              id="mob_deg"
                              className="px-2"
                              value={deg}
                              onChange={(e) =>
                                setDeg(e.target.value.replace(/[^a-zA-Z!@#$%^&*()]/g, ''))
                              }
                              // onChange={(e) => setCollege({ collegeName: e.target.value })}
                              // onChange={(e) =>
                              //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                              // }
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="mob_deg"
                              className="position-absolute start-0 top-0 input-label"
                            >
                              Degree
                            </label>
                          </div>
                        </div>
                        <div className="col-6 ps-0">
                          <div
                            className="position-relative mob_sel"
                            id="name_input_field"
                          >
                            <select
                              style={{ backgroundColor: "white" }}
                              required
                              name="mob_testmode"
                              id="mob_testmode"
                              value={testmode}
                              onChange={(e) => setTestmode(e.target.value.trimStart())}
                            >
                              {/* <option></option> */}
                              <option value="online">Online</option>
                              <option value="offline">Offline</option>
                            </select>
                            <label
                              id="test"
                              htmlFor="mob_testmode"
                              className="position-absolute start-0 top-0  input-label"
                            >
                              Test Mode
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="mobile_border_bottom" className=""></div>

                    <div id="mobile_form_submit_btn">
                      {status === 0 ? (
                        <button
                          className="btn"
                          type="submit"
                          style={{
                            height: "3.25rem",
                            background: "#02EE6A14",
                            border: "2px solid #02EE6A",
                            color: "#02EE6A",
                            fontSize: "0.8rem",
                            lineHeight: "2rem",
                          }}
                        >
                          <img
                            src={success_tick}
                            alt=""
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          Thanks For Submitting!
                        </button>
                      ) : (
                        <button className="btn" type="submit">
                          Submit{" "}
                          {loading ? (
                            <PulseLoader color="white" size={6} />
                          ) : (
                            ""
                          )}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyTest;
