import React from "react";
import "../Styles/StudPrograms.css";
import t_intern from "../Assets/t_intern.png";
import t_iv from "../Assets/t_iv.png";

function StudPrograms() {
  return (
    <div>
      <div className="container-fluid">
        <div id="our_programs_bg" className="d-lg-block d-none">
          <div id="our_programs_title" align="center">
            <div id="our_program_text">OUR PROGRAMS</div>
            <div id="it_proffession_text">For Students</div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center "
            id="stud_program_bg"
          >
            <a href="/techie-intern" className="text-decoration-none">
              {" "}
              <div id="stud_single_program_bg" className="position-relative">
                <div
                  className="position-absolute left-0 top-0"
                  id="stud_program_border"
                  style={{ backgroundColor: "#73B61D" }}
                ></div>
                <div className="d-flex align-items-center">
                  <div id="t_intern_bg">
                    <img src={t_intern} alt="" />
                  </div>
                  <div id="stud_program_text">
                    Techie INTERN
                    <div id="stud_program_duration">30-60-90-180 Days</div>
                  </div>
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#006BFF"
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a href="/techie-iv" className="text-decoration-none">
              {" "}
              <div id="stud_single_program_bg" className="position-relative">
                <div
                  className="position-absolute left-0 top-0"
                  id="stud_program_border"
                  style={{ backgroundColor: "#CA334E" }}
                ></div>
                <div className="d-flex align-items-center">
                  <div
                    id="t_intern_bg"
                    style={{ backgroundColor: "#CA334E1A" }}
                  >
                    <img src={t_iv} alt="" />
                  </div>
                  <div id="stud_program_text">
                    Industrial Visit
                    <div id="stud_program_duration">1 Day</div>
                  </div>
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#006BFF"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="d-block d-lg-none">
          <div id="mobile_our_program_bg">
            <div id="mobile_our_program_title" align="center">
              <div id="mobile_our_program_text">OUR PROGRAMS</div>
              <div id="mobile_for_pros_text">For Students</div>
            </div>
            <a href="/techie-intern" className="text-decoration-none">
              {" "}
            <div id="mobile_single_program_bg" className="position-relative">
              <div
                style={{ background: "#73B61D" }}
                className="position-absolute left-0"
                id="mobile_program_border"
              ></div>
              <div
                id="mobile_program_topic"
                className="d-flex justify-content-between"
              >
                <div id="mobile_program_bg">
                  {" "}
                  <div id="mobile_program_img">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="68"
                      height="68"
                      viewBox="0 0 68 68"
                      fill="none"
                    >
                      <rect
                        width="68"
                        height="68"
                        rx="8"
                        fill="#73B61D"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M9 13.3684C9 11.5081 10.4924 10 12.3333 10H55.6667C57.5076 10 59 11.5081 59 13.3684V54.6316C59 56.4919 57.5076 58 55.6667 58H12.3333C10.4924 58 9 56.4919 9 54.6316V13.3684Z"
                        fill="white"
                      />
                      <path
                        d="M20.3691 18.8967L20.3451 20.059L17.5327 20.1686L17.617 27.8485L16.2861 27.8729V20.2173L13.4377 20.3329L13.4136 19.0488L20.3691 18.8967Z"
                        fill="black"
                      />
                      <path
                        d="M23.856 27.1243C24.0687 27.1243 24.2795 27.0919 24.4883 27.027C24.697 26.958 24.8898 26.8606 25.0664 26.7349C25.2431 26.605 25.3956 26.4448 25.5241 26.2541C25.6566 26.0634 25.7529 25.8423 25.8132 25.5908L26.9754 25.8281C26.863 26.1973 26.7044 26.532 26.4997 26.8322C26.2949 27.1325 26.056 27.3901 25.783 27.6051C25.51 27.8201 25.2089 27.9865 24.8797 28.1041C24.5545 28.2218 24.2133 28.2806 23.856 28.2806C23.326 28.2806 22.8563 28.1995 22.4468 28.0372C22.0373 27.8749 21.692 27.6457 21.411 27.3495C21.1299 27.0533 20.9151 26.6963 20.7666 26.2785C20.6221 25.8606 20.5498 25.394 20.5498 24.8788C20.5498 24.3717 20.6341 23.899 20.8027 23.4609C20.9754 23.0186 21.2102 22.6353 21.5073 22.3107C21.8044 21.9861 22.1537 21.7305 22.5552 21.5439C22.9567 21.3573 23.3902 21.264 23.856 21.264C24.2735 21.264 24.6669 21.3471 25.0363 21.5135C25.4057 21.6758 25.7329 21.903 26.0179 22.1951C26.303 22.4831 26.5378 22.8239 26.7225 23.2174C26.9072 23.611 27.0236 24.037 27.0718 24.4954L21.8566 25.2804C21.8847 25.5685 21.955 25.8281 22.0674 26.0594C22.1798 26.2866 22.3223 26.4793 22.495 26.6375C22.6716 26.7957 22.8743 26.9174 23.1032 27.0026C23.336 27.0838 23.587 27.1243 23.856 27.1243ZM25.5181 23.7104L25.4819 23.5947C25.3294 23.1931 25.1186 22.8787 24.8496 22.6515C24.5846 22.4243 24.2534 22.3107 23.856 22.3107C23.6873 22.3107 23.5027 22.3411 23.3019 22.402C23.1012 22.4588 22.9065 22.5582 22.7178 22.7002C22.5291 22.8422 22.3585 23.0328 22.2059 23.2722C22.0573 23.5075 21.953 23.8016 21.8927 24.1546L25.5181 23.7104Z"
                        fill="black"
                      />
                      <path
                        d="M34.5332 23.1201L33.5335 23.7347L33.5215 23.6982C33.4171 23.4913 33.2906 23.3067 33.1421 23.1444C32.9935 22.9781 32.8269 22.8381 32.6423 22.7245C32.4576 22.6069 32.2588 22.5176 32.0461 22.4567C31.8373 22.3959 31.6185 22.3655 31.3896 22.3655C31.0604 22.3655 30.7513 22.4283 30.4622 22.5541C30.1772 22.6799 29.9263 22.8523 29.7095 23.0714C29.4967 23.2905 29.3281 23.5481 29.2036 23.8442C29.0792 24.1363 29.0169 24.4487 29.0169 24.7814C29.0169 25.11 29.0792 25.4204 29.2036 25.7125C29.3281 26.0046 29.4967 26.2602 29.7095 26.4793C29.9263 26.6984 30.1772 26.8708 30.4622 26.9965C30.7513 27.1223 31.0604 27.1852 31.3896 27.1852C31.6024 27.1852 31.8092 27.1568 32.0099 27.1C32.2107 27.0432 32.3994 26.9641 32.576 26.8627C32.7567 26.7572 32.9213 26.6314 33.0698 26.4854C33.2184 26.3353 33.3448 26.1689 33.4492 25.9863L33.4733 25.9498L34.5212 26.5523L34.5091 26.5888C34.3525 26.8525 34.1618 27.0899 33.937 27.3008C33.7122 27.5118 33.4633 27.6923 33.1903 27.8424C32.9213 27.9966 32.6322 28.1143 32.3231 28.1954C32.018 28.2765 31.7068 28.3171 31.3896 28.3171C30.936 28.3171 30.4964 28.2279 30.0708 28.0493C29.6493 27.8708 29.2719 27.6234 28.9386 27.3069C28.6094 26.9905 28.3445 26.6172 28.1437 26.1872C27.947 25.7571 27.8486 25.2885 27.8486 24.7814C27.8486 24.4771 27.8888 24.1789 27.9691 23.8868C28.0534 23.5907 28.1698 23.3107 28.3184 23.047C28.4709 22.7833 28.6536 22.5399 28.8664 22.3168C29.0832 22.0936 29.3221 21.903 29.583 21.7447C29.844 21.5825 30.127 21.4567 30.4321 21.3674C30.7373 21.2741 31.0564 21.2275 31.3896 21.2275C31.7389 21.2275 32.0702 21.2701 32.3833 21.3553C32.7005 21.4405 32.9935 21.5642 33.2625 21.7265C33.5315 21.8847 33.7724 22.0794 33.9852 22.3107C34.198 22.5419 34.3766 22.8036 34.5212 23.0957L34.5332 23.1201Z"
                        fill="black"
                      />
                      <path
                        d="M37.1287 18.562L37.0926 22.262C37.3134 22.0186 37.5483 21.8279 37.7972 21.69C38.0461 21.548 38.279 21.4405 38.4958 21.3674C38.7447 21.2863 38.9916 21.2315 39.2365 21.2031C39.6861 21.2031 40.0956 21.2843 40.465 21.4465C40.8344 21.6088 41.1515 21.8421 41.4165 22.1464C41.6815 22.4507 41.8902 22.8219 42.0428 23.26C42.1994 23.6941 42.2877 24.185 42.3078 24.7327C42.3078 25.0208 42.3038 25.3149 42.2957 25.6151C42.2917 25.9154 42.2777 26.2095 42.2536 26.4975C42.2335 26.7856 42.2034 27.0594 42.1632 27.3191C42.1271 27.5787 42.0769 27.812 42.0127 28.0189L40.6396 27.9215C40.7561 27.5321 40.8384 27.1527 40.8866 26.7836C40.9387 26.4144 40.9749 26.0837 40.995 25.7916C41.015 25.4508 41.021 25.1263 41.013 24.8179C40.9929 24.3838 40.9187 24.0146 40.7902 23.7104C40.6617 23.4061 40.5031 23.1586 40.3145 22.9679C40.1298 22.7732 39.925 22.6332 39.7002 22.548C39.4754 22.4588 39.2546 22.4141 39.0378 22.4141C38.8089 22.4344 38.5781 22.5014 38.3452 22.615C38.1445 22.7123 37.9297 22.8645 37.7008 23.0714C37.472 23.2742 37.2592 23.5623 37.0625 23.9355L37.0264 27.9702L35.846 27.9946L35.7437 18.6107L37.1287 18.562Z"
                        fill="black"
                      />
                      <path
                        d="M45.4935 19.5722C45.4935 19.6899 45.4694 19.8014 45.4212 19.9069C45.3771 20.0124 45.3148 20.1037 45.2345 20.1808C45.1583 20.2578 45.0679 20.3187 44.9635 20.3633C44.8592 20.408 44.7467 20.4303 44.6263 20.4303C44.5099 20.4303 44.3995 20.408 44.2951 20.3633C44.1947 20.3187 44.1044 20.2578 44.0241 20.1808C43.9478 20.1037 43.8876 20.0124 43.8434 19.9069C43.7993 19.8014 43.7772 19.6899 43.7772 19.5722C43.7772 19.4505 43.7993 19.3369 43.8434 19.2314C43.8876 19.1259 43.9478 19.0346 44.0241 18.9576C44.1044 18.8764 44.1947 18.8135 44.2951 18.7689C44.3995 18.7202 44.5099 18.6959 44.6263 18.6959C44.7467 18.6959 44.8592 18.7202 44.9635 18.7689C45.0679 18.8135 45.1583 18.8764 45.2345 18.9576C45.3148 19.0346 45.3771 19.1259 45.4212 19.2314C45.4694 19.3369 45.4935 19.4505 45.4935 19.5722ZM45.2104 21.7143C45.1984 22.2945 45.1864 22.828 45.1743 23.3148C45.1663 23.7976 45.1583 24.2378 45.1502 24.6354C45.1422 25.033 45.1342 25.39 45.1261 25.7064C45.1221 26.0188 45.1181 26.2927 45.1141 26.528C45.1021 27.0878 45.096 27.528 45.096 27.8485H43.8615C43.8695 27.4104 43.8796 26.889 43.8916 26.2845C43.8996 25.7652 43.9097 25.1303 43.9217 24.3798C43.9378 23.6292 43.9518 22.757 43.9639 21.763L45.2104 21.7143Z"
                        fill="black"
                      />
                      <path
                        d="M49.8354 27.1243C50.0482 27.1243 50.259 27.0919 50.4678 27.027C50.6765 26.958 50.8693 26.8606 51.0459 26.7349C51.2225 26.605 51.3751 26.4448 51.5036 26.2541C51.6361 26.0634 51.7324 25.8423 51.7926 25.5908L52.9549 25.8281C52.8425 26.1973 52.6839 26.532 52.4792 26.8322C52.2744 27.1325 52.0355 27.3901 51.7625 27.6051C51.4895 27.8201 51.1884 27.9865 50.8592 28.1041C50.534 28.2218 50.1928 28.2806 49.8354 28.2806C49.3055 28.2806 48.8358 28.1995 48.4263 28.0372C48.0168 27.8749 47.6715 27.6457 47.3905 27.3495C47.1094 27.0533 46.8946 26.6963 46.7461 26.2785C46.6016 25.8606 46.5293 25.394 46.5293 24.8788C46.5293 24.3717 46.6136 23.899 46.7822 23.4609C46.9549 23.0186 47.1897 22.6353 47.4868 22.3107C47.7839 21.9861 48.1332 21.7305 48.5347 21.5439C48.9361 21.3573 49.3697 21.264 49.8354 21.264C50.253 21.264 50.6464 21.3471 51.0158 21.5135C51.3851 21.6758 51.7123 21.903 51.9974 22.1951C52.2824 22.4831 52.5173 22.8239 52.702 23.2174C52.8867 23.611 53.0031 24.037 53.0513 24.4954L47.8361 25.2804C47.8642 25.5685 47.9345 25.8281 48.0469 26.0594C48.1593 26.2866 48.3018 26.4793 48.4744 26.6375C48.6511 26.7957 48.8538 26.9174 49.0827 27.0026C49.3155 27.0838 49.5665 27.1243 49.8354 27.1243ZM51.4976 23.7104L51.4614 23.5947C51.3089 23.1931 51.0981 22.8787 50.8291 22.6515C50.5641 22.4243 50.2329 22.3107 49.8354 22.3107C49.6668 22.3107 49.4822 22.3411 49.2814 22.402C49.0807 22.4588 48.886 22.5582 48.6973 22.7002C48.5086 22.8422 48.3379 23.0328 48.1854 23.2722C48.0368 23.5075 47.9325 23.8016 47.8722 24.1546L51.4976 23.7104Z"
                        fill="black"
                      />
                      <path
                        d="M17.3333 46.6316C17.3333 40.353 22.3701 35.2632 28.5833 35.2632H59V54.6316C59 56.4919 57.5076 58 55.6667 58H17.3333V46.6316Z"
                        fill="#73B61D"
                      />
                      <path
                        d="M22.0556 47.2946V48.9614H22.6111V47.2946C23.7073 47.4327 24.5556 48.3777 24.5556 49.5228H20.1111C20.1111 48.3777 20.9594 47.4327 22.0556 47.2946ZM22.3333 46.9965C21.4125 46.9965 20.6667 46.2428 20.6667 45.3123C20.6667 44.3818 21.4125 43.6281 22.3333 43.6281C23.2542 43.6281 24 44.3818 24 45.3123C24 46.2428 23.2542 46.9965 22.3333 46.9965Z"
                        fill="white"
                      />
                      <path
                        d="M28.7958 49.9158H27.6708V43.8274H28.7958V49.9158Z"
                        fill="white"
                      />
                      <path
                        d="M35.224 43.8274V49.9158H34.649C34.5601 49.9158 34.4851 49.9018 34.424 49.8737C34.3656 49.8428 34.3087 49.7923 34.2531 49.7221L31.1073 45.6632C31.124 45.8484 31.1323 46.0196 31.1323 46.1768V49.9158H30.1448V43.8274H30.7323C30.7795 43.8274 30.8198 43.8302 30.8531 43.8358C30.8892 43.8386 30.9198 43.847 30.9448 43.8611C30.9726 43.8723 30.999 43.8905 31.024 43.9158C31.049 43.9382 31.0767 43.9691 31.1073 44.0084L34.2656 48.0842C34.2573 47.986 34.2503 47.8891 34.2448 47.7937C34.2392 47.6982 34.2365 47.6098 34.2365 47.5284V43.8274H35.224Z"
                        fill="white"
                      />
                      <path
                        d="M40.774 44.7579H38.9574V49.9158H37.8365V44.7579H36.0199V43.8274H40.774V44.7579Z"
                        fill="white"
                      />
                      <path
                        d="M42.6874 44.7284V46.4168H44.7958V47.2884H42.6874V49.0105H45.3624V49.9158H41.5624V43.8274H45.3624V44.7284H42.6874Z"
                        fill="white"
                      />
                      <path
                        d="M48.1766 46.7242C48.3877 46.7242 48.571 46.6975 48.7266 46.6442C48.8849 46.5909 49.0141 46.5179 49.1141 46.4253C49.2168 46.3298 49.2932 46.2175 49.3432 46.0884C49.3932 45.9593 49.4182 45.8175 49.4182 45.6632C49.4182 45.3544 49.3168 45.1172 49.1141 44.9516C48.9141 44.786 48.6071 44.7032 48.1932 44.7032H47.4766V46.7242H48.1766ZM51.0599 49.9158H50.0474C49.8557 49.9158 49.7168 49.84 49.6307 49.6884L48.3641 47.7389C48.3168 47.666 48.2641 47.614 48.2057 47.5832C48.1502 47.5523 48.0668 47.5368 47.9557 47.5368H47.4766V49.9158H46.3557V43.8274H48.1932C48.6016 43.8274 48.9516 43.8709 49.2432 43.9579C49.5377 44.0421 49.778 44.1614 49.9641 44.3158C50.153 44.4702 50.2918 44.6554 50.3807 44.8716C50.4696 45.0849 50.5141 45.3207 50.5141 45.5789C50.5141 45.7839 50.4835 45.9775 50.4224 46.16C50.3641 46.3425 50.278 46.5081 50.1641 46.6568C50.053 46.8056 49.9141 46.9361 49.7474 47.0484C49.5835 47.1607 49.396 47.2491 49.1849 47.3137C49.2571 47.3558 49.3238 47.4063 49.3849 47.4653C49.446 47.5214 49.5016 47.5888 49.5516 47.6674L51.0599 49.9158Z"
                        fill="white"
                      />
                      <path
                        d="M56.7897 43.8274V49.9158H56.2147C56.1258 49.9158 56.0508 49.9018 55.9897 49.8737C55.9314 49.8428 55.8744 49.7923 55.8189 49.7221L52.673 45.6632C52.6897 45.8484 52.6981 46.0196 52.6981 46.1768V49.9158H51.7105V43.8274H52.298C52.3453 43.8274 52.3855 43.8302 52.4189 43.8358C52.455 43.8386 52.4855 43.847 52.5106 43.8611C52.5383 43.8723 52.5647 43.8905 52.5897 43.9158C52.6147 43.9382 52.6425 43.9691 52.673 44.0084L55.8314 48.0842C55.823 47.986 55.8161 47.8891 55.8105 47.7937C55.805 47.6982 55.8022 47.6098 55.8022 47.5284V43.8274H56.7897Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div id="mobile_program_name">
                    Techie INTERN
                    <div id="mobile_program_duration">30-60-90-180 Days</div>
                  </div>
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#006BFF"
                    />
                  </svg>
                </div>
              </div>
            </div>
            </a>
            <a href="/techie-iv" className="text-decoration-none">
              {" "}
            <div id="mobile_single_program_bg" className="position-relative">
              <div
                style={{ background: "#CA334E" }}
                className="position-absolute left-0"
                id="mobile_program_border"
              ></div>
              <div
                id="mobile_program_topic"
                className="d-flex justify-content-between"
              >
                <div id="mobile_program_bg">
                  {" "}
                  <div id="mobile_program_img">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="68"
                      height="68"
                      viewBox="0 0 68 68"
                      fill="none"
                    >
                      <rect
                        width="68"
                        height="68"
                        rx="8"
                        fill="#CA334E"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M9 13.3684C9 11.5081 10.4924 10 12.3333 10H55.6667C57.5076 10 59 11.5081 59 13.3684V54.6316C59 56.4919 57.5076 58 55.6667 58H12.3333C10.4924 58 9 56.4919 9 54.6316V13.3684Z"
                        fill="white"
                      />
                      <path
                        d="M20.3691 18.8967L20.3451 20.059L17.5327 20.1686L17.617 27.8485L16.2861 27.8729V20.2173L13.4377 20.3329L13.4136 19.0488L20.3691 18.8967Z"
                        fill="black"
                      />
                      <path
                        d="M23.856 27.1243C24.0687 27.1243 24.2795 27.0919 24.4883 27.027C24.697 26.958 24.8898 26.8606 25.0664 26.7349C25.2431 26.605 25.3956 26.4448 25.5241 26.2541C25.6566 26.0634 25.7529 25.8423 25.8132 25.5908L26.9754 25.8281C26.863 26.1973 26.7044 26.532 26.4997 26.8322C26.2949 27.1325 26.056 27.3901 25.783 27.6051C25.51 27.8201 25.2089 27.9865 24.8797 28.1041C24.5545 28.2218 24.2133 28.2806 23.856 28.2806C23.326 28.2806 22.8563 28.1994 22.4468 28.0372C22.0373 27.8749 21.692 27.6457 21.411 27.3495C21.1299 27.0533 20.9151 26.6963 20.7666 26.2785C20.6221 25.8606 20.5498 25.394 20.5498 24.8788C20.5498 24.3717 20.6341 23.899 20.8027 23.4609C20.9754 23.0186 21.2102 22.6353 21.5073 22.3107C21.8044 21.9861 22.1537 21.7305 22.5552 21.5439C22.9567 21.3573 23.3902 21.264 23.856 21.264C24.2735 21.264 24.6669 21.3471 25.0363 21.5135C25.4057 21.6758 25.7329 21.903 26.0179 22.1951C26.3029 22.4831 26.5378 22.8239 26.7225 23.2174C26.9072 23.611 27.0236 24.037 27.0718 24.4954L21.8566 25.2804C21.8847 25.5685 21.955 25.8281 22.0674 26.0594C22.1798 26.2866 22.3223 26.4793 22.495 26.6375C22.6716 26.7957 22.8743 26.9174 23.1032 27.0026C23.336 27.0838 23.587 27.1243 23.856 27.1243ZM25.5181 23.7104L25.4819 23.5947C25.3294 23.1931 25.1186 22.8787 24.8496 22.6515C24.5846 22.4243 24.2534 22.3107 23.856 22.3107C23.6873 22.3107 23.5027 22.3411 23.3019 22.402C23.1012 22.4588 22.9065 22.5582 22.7178 22.7002C22.5291 22.8422 22.3585 23.0328 22.2059 23.2722C22.0573 23.5075 21.953 23.8016 21.8927 24.1546L25.5181 23.7104Z"
                        fill="black"
                      />
                      <path
                        d="M34.5332 23.1201L33.5335 23.7347L33.5215 23.6982C33.4171 23.4913 33.2906 23.3067 33.1421 23.1444C32.9935 22.9781 32.8269 22.8381 32.6423 22.7245C32.4576 22.6069 32.2588 22.5176 32.0461 22.4567C31.8373 22.3959 31.6185 22.3655 31.3896 22.3655C31.0604 22.3655 30.7513 22.4283 30.4622 22.5541C30.1772 22.6799 29.9263 22.8523 29.7095 23.0714C29.4967 23.2905 29.3281 23.5481 29.2036 23.8442C29.0792 24.1363 29.0169 24.4487 29.0169 24.7814C29.0169 25.11 29.0792 25.4204 29.2036 25.7125C29.3281 26.0046 29.4967 26.2602 29.7095 26.4793C29.9263 26.6984 30.1772 26.8708 30.4622 26.9965C30.7513 27.1223 31.0604 27.1852 31.3896 27.1852C31.6024 27.1852 31.8092 27.1568 32.0099 27.1C32.2107 27.0432 32.3994 26.9641 32.576 26.8627C32.7567 26.7572 32.9213 26.6314 33.0698 26.4854C33.2184 26.3353 33.3448 26.1689 33.4492 25.9863L33.4733 25.9498L34.5212 26.5523L34.5091 26.5888C34.3525 26.8525 34.1618 27.0899 33.937 27.3008C33.7122 27.5118 33.4633 27.6923 33.1903 27.8424C32.9213 27.9966 32.6322 28.1143 32.3231 28.1954C32.018 28.2765 31.7068 28.3171 31.3896 28.3171C30.936 28.3171 30.4964 28.2278 30.0708 28.0493C29.6493 27.8708 29.2719 27.6234 28.9386 27.3069C28.6094 26.9905 28.3445 26.6172 28.1437 26.1872C27.947 25.7571 27.8486 25.2885 27.8486 24.7814C27.8486 24.4771 27.8888 24.1789 27.9691 23.8868C28.0534 23.5907 28.1698 23.3107 28.3184 23.047C28.4709 22.7833 28.6536 22.5399 28.8664 22.3168C29.0832 22.0936 29.322 21.903 29.583 21.7447C29.844 21.5825 30.127 21.4567 30.4321 21.3674C30.7372 21.2741 31.0564 21.2275 31.3896 21.2275C31.7389 21.2275 32.0701 21.2701 32.3833 21.3553C32.7005 21.4405 32.9935 21.5642 33.2625 21.7265C33.5315 21.8847 33.7724 22.0794 33.9852 22.3107C34.198 22.5419 34.3766 22.8036 34.5212 23.0957L34.5332 23.1201Z"
                        fill="black"
                      />
                      <path
                        d="M37.1287 18.562L37.0926 22.262C37.3134 22.0186 37.5483 21.8279 37.7972 21.69C38.0461 21.548 38.279 21.4405 38.4958 21.3674C38.7447 21.2863 38.9916 21.2315 39.2365 21.2031C39.6861 21.2031 40.0956 21.2843 40.465 21.4465C40.8344 21.6088 41.1515 21.8421 41.4165 22.1464C41.6815 22.4507 41.8902 22.8219 42.0428 23.26C42.1994 23.6941 42.2877 24.185 42.3078 24.7327C42.3078 25.0208 42.3038 25.3149 42.2957 25.6151C42.2917 25.9153 42.2777 26.2095 42.2536 26.4975C42.2335 26.7856 42.2034 27.0594 42.1632 27.3191C42.1271 27.5787 42.0769 27.812 42.0127 28.0189L40.6396 27.9215C40.7561 27.5321 40.8384 27.1527 40.8866 26.7836C40.9387 26.4144 40.9749 26.0837 40.995 25.7916C41.015 25.4508 41.021 25.1263 41.013 24.8179C40.9929 24.3838 40.9187 24.0146 40.7902 23.7104C40.6617 23.4061 40.5031 23.1586 40.3145 22.9679C40.1298 22.7732 39.925 22.6332 39.7002 22.548C39.4754 22.4588 39.2546 22.4141 39.0378 22.4141C38.8089 22.4344 38.5781 22.5014 38.3452 22.615C38.1445 22.7123 37.9297 22.8645 37.7008 23.0714C37.472 23.2742 37.2592 23.5623 37.0625 23.9355L37.0264 27.9702L35.846 27.9946L35.7437 18.6107L37.1287 18.562Z"
                        fill="black"
                      />
                      <path
                        d="M45.4935 19.5722C45.4935 19.6899 45.4694 19.8014 45.4212 19.9069C45.3771 20.0124 45.3148 20.1037 45.2345 20.1808C45.1583 20.2578 45.0679 20.3187 44.9635 20.3633C44.8592 20.4079 44.7467 20.4303 44.6263 20.4303C44.5099 20.4303 44.3995 20.4079 44.2951 20.3633C44.1947 20.3187 44.1044 20.2578 44.0241 20.1808C43.9478 20.1037 43.8876 20.0124 43.8434 19.9069C43.7993 19.8014 43.7772 19.6899 43.7772 19.5722C43.7772 19.4505 43.7993 19.3369 43.8434 19.2314C43.8876 19.1259 43.9478 19.0346 44.0241 18.9576C44.1044 18.8764 44.1947 18.8135 44.2951 18.7689C44.3995 18.7202 44.5099 18.6959 44.6263 18.6959C44.7467 18.6959 44.8592 18.7202 44.9635 18.7689C45.0679 18.8135 45.1583 18.8764 45.2345 18.9576C45.3148 19.0346 45.3771 19.1259 45.4212 19.2314C45.4694 19.3369 45.4935 19.4505 45.4935 19.5722ZM45.2104 21.7143C45.1984 22.2945 45.1864 22.828 45.1743 23.3148C45.1663 23.7976 45.1583 24.2378 45.1502 24.6354C45.1422 25.0329 45.1342 25.39 45.1261 25.7064C45.1221 26.0188 45.1181 26.2927 45.1141 26.528C45.1021 27.0878 45.096 27.528 45.096 27.8485H43.8615C43.8695 27.4104 43.8796 26.889 43.8916 26.2845C43.8996 25.7652 43.9097 25.1303 43.9217 24.3798C43.9378 23.6292 43.9518 22.757 43.9639 21.763L45.2104 21.7143Z"
                        fill="black"
                      />
                      <path
                        d="M49.8354 27.1243C50.0482 27.1243 50.259 27.0919 50.4678 27.027C50.6765 26.958 50.8692 26.8606 51.0459 26.7349C51.2225 26.605 51.3751 26.4448 51.5036 26.2541C51.6361 26.0634 51.7324 25.8423 51.7926 25.5908L52.9549 25.8281C52.8425 26.1973 52.6839 26.532 52.4792 26.8322C52.2744 27.1325 52.0355 27.3901 51.7625 27.6051C51.4895 27.8201 51.1884 27.9865 50.8592 28.1041C50.534 28.2218 50.1928 28.2806 49.8354 28.2806C49.3055 28.2806 48.8358 28.1994 48.4263 28.0372C48.0168 27.8749 47.6715 27.6457 47.3905 27.3495C47.1094 27.0533 46.8946 26.6963 46.7461 26.2785C46.6016 25.8606 46.5293 25.394 46.5293 24.8788C46.5293 24.3717 46.6136 23.899 46.7822 23.4609C46.9549 23.0186 47.1897 22.6353 47.4868 22.3107C47.7839 21.9861 48.1332 21.7305 48.5347 21.5439C48.9361 21.3573 49.3697 21.264 49.8354 21.264C50.253 21.264 50.6464 21.3471 51.0158 21.5135C51.3851 21.6758 51.7123 21.903 51.9974 22.1951C52.2824 22.4831 52.5173 22.8239 52.702 23.2174C52.8867 23.611 53.0031 24.037 53.0513 24.4954L47.8361 25.2804C47.8642 25.5685 47.9345 25.8281 48.0469 26.0594C48.1593 26.2866 48.3018 26.4793 48.4744 26.6375C48.6511 26.7957 48.8538 26.9174 49.0827 27.0026C49.3155 27.0838 49.5665 27.1243 49.8354 27.1243ZM51.4976 23.7104L51.4614 23.5947C51.3089 23.1931 51.0981 22.8787 50.8291 22.6515C50.5641 22.4243 50.2329 22.3107 49.8354 22.3107C49.6668 22.3107 49.4821 22.3411 49.2814 22.402C49.0807 22.4588 48.886 22.5582 48.6973 22.7002C48.5086 22.8422 48.3379 23.0328 48.1854 23.2722C48.0368 23.5075 47.9325 23.8016 47.8722 24.1546L51.4976 23.7104Z"
                        fill="black"
                      />
                      <path
                        d="M17.3333 46.6316C17.3333 40.353 22.3701 35.2632 28.5833 35.2632H59V54.6316C59 56.4919 57.5076 58 55.6667 58H17.3333V46.6316Z"
                        fill="#CA334E"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M33.7525 44.1895C33.794 44.1901 33.8348 44.2029 33.8696 44.2276C33.9252 44.2669 33.9583 44.3314 33.9583 44.4V49.0316C33.9583 49.1469 33.8665 49.2406 33.7527 49.2421H31.4583C31.3433 49.2421 31.25 49.1478 31.25 49.0316C31.25 49.0316 31.25 46.428 31.25 45.537C31.25 45.2714 31.4144 45.0343 31.6615 44.9434C32.2698 44.72 33.6787 44.2021 33.6787 44.2021C33.7008 44.1941 33.7235 44.1899 33.7462 44.1895H33.7525ZM34.375 45.9154L35.0379 46.2023C35.2677 46.3019 35.4167 46.5303 35.4167 46.7829V49.0316C35.4167 49.1478 35.3233 49.2421 35.2083 49.2421H34.3394C34.3625 49.1762 34.375 49.1055 34.375 49.0316V45.9154ZM32.9194 47.7684C33.0331 47.7669 33.125 47.6733 33.125 47.5579C33.125 47.4417 33.0317 47.3474 32.9167 47.3474H32.2917C32.1767 47.3474 32.0833 47.4417 32.0833 47.5579C32.0833 47.6741 32.1767 47.7684 32.2917 47.7684H32.9194ZM32.9194 46.9263C33.0331 46.9248 33.125 46.8312 33.125 46.7158C33.125 46.5996 33.0317 46.5053 32.9167 46.5053H32.2917C32.1767 46.5053 32.0833 46.5996 32.0833 46.7158C32.0833 46.832 32.1767 46.9263 32.2917 46.9263H32.9194ZM32.9194 46.0842C33.0331 46.0827 33.125 45.989 33.125 45.8737C33.125 45.7575 33.0317 45.6632 32.9167 45.6632H32.2917C32.1767 45.6632 32.0833 45.7575 32.0833 45.8737C32.0833 45.9899 32.1767 46.0842 32.2917 46.0842H32.9194Z"
                        fill="white"
                      />
                      <path
                        d="M39.7958 49.9158H38.6708V43.8274H39.7958V49.9158Z"
                        fill="white"
                      />
                      <path
                        d="M46.3073 43.8274L43.874 49.9158H42.8615L40.4281 43.8274H41.3281C41.4253 43.8274 41.5045 43.8512 41.5656 43.8989C41.6267 43.9467 41.6726 44.0084 41.7031 44.0842L43.124 47.7979C43.1712 47.9186 43.2156 48.0519 43.2573 48.1979C43.3017 48.341 43.3434 48.4912 43.3823 48.6484C43.4156 48.4912 43.4517 48.341 43.4906 48.1979C43.5295 48.0519 43.5726 47.9186 43.6198 47.7979L45.0323 44.0842C45.0545 44.0196 45.0976 43.9607 45.1615 43.9074C45.2281 43.854 45.3087 43.8274 45.4031 43.8274H46.3073Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div id="mobile_program_name">
                    Industrial Visit
                    <div id="mobile_program_duration">1 Day</div>
                  </div>
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                      fill="#006BFF"
                    />
                  </svg>
                </div>
              </div>
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudPrograms;
