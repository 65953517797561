import React from "react";
import LandingHeader from "./Header";
import HeroSection from "./Herosection";
import RegisterWebinar from "./RegisterWebinar";
import Syllabus from "./Syllabus";
import SoftwareTech from "./SoftwareTech";
import Footer from "../Pages/Footer";
import Faq from "./Faq";
import Scholarship from "./Scholarship";
import Clients from "./Clients";
import OurExpertise from "./OurExpertise";
import Reviews from "./Reviews";

function LandingHomePage() {
  return (
    <div  className="position-relative"
    style={{ zIndex: "0", backgroundColor: "white" }}>
      <div>
        <LandingHeader />
        <HeroSection />
        <RegisterWebinar />
        <Syllabus />
        <SoftwareTech />
        <Scholarship />
        <Clients />
        <OurExpertise />
        <Reviews />
        <Faq />
        <Footer />
      </div>
    </div>
  );
}

export default LandingHomePage;
