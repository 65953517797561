import React from "react";
import "../Styles/TechiePro/Confuse.css";
import confuse_girl from "../Assets/techiepro/confuse_girl_img.png";
import ques_mark from "../Assets/techiepro/question_mark.png";
import hmm_img from "../Assets/techiepro/hmm_img.png";
import confuse_emoji from "../Assets/techiepro/confuse_emoji.png";
function Confusion() {
  
  return (
    <div>
      <div className="container">
        <div id="confuse_bg" className=" d-lg-block d-none">
          {" "}
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div id="confuse_girl_bg" className="position-relative d-flex">
                <div id="question_mark">
                  <img src={ques_mark} alt="" />
                </div>
                <div
                  className="position-absolute bottom-0 "
                  id="confuse_girl_img"
                >
                  <img src={confuse_girl} alt="" width="100%" />
                </div>
                <div id="hmm_icon">
                  <img src={hmm_img} alt="" />
                </div>
                <div
                  id="confuse_emoji_bg"
                  className="position-absolute top-100 ms-3 translate-middle"
                  style={{ left: "10rem" }}
                >
                  <img src={confuse_emoji} alt="" />
                  <div id="choose_text">What should i choose?</div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div id="confused_content">
                <span id="confuse_text">Still confused</span> about what tech
                stack to choose?
              </div>
              <div id="confuse_sub_content">
                No worriers just reach out to us, our program manager will help
                you out in choosing what’s best for your career.
              </div>
              <div id="confuse_button">
                <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="text-decoration-none"
                >
                  {" "}
                  <button className="btn">Let's have a chat</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* for mobiles */}
        <div id="mobile_confuse_bg" className=" d-lg-none d-block">
          {" "}
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div
                id="mobile_confuse_girl_bg"
                className="position-relative d-flex "
              >
                <div id="mobile_question_mark">
                  <img src={ques_mark} alt="" />
                </div>
                <div
                  className="position-absolute bottom-0"
                  id="mobile_confuse_girl_img"
                >
                  <img src={confuse_girl} alt="" width="100%" />
                </div>
                <div id="mobile_hmm_icon">
                  <img src={hmm_img} alt="" />
                </div>
                <div
                  id="mobile_confuse_emoji_bg"
                  className="position-absolute top-100 ms-3 translate-middle"
                  style={{ left: "7rem" }}
                >
                  <img src={confuse_emoji} alt="" />
                  <div id="mobile_choose_text">What should i choose?</div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div id="mobile_confused_content">
                <span id="mobile_confuse_text">Still confused</span> about
                <br /> what tech stack to choose?
              </div>
              <div id="mobile_confuse_sub_content">
                No worriers just reach out to us, our program manager will help
                you out in choosing what’s best for your career.
              </div>
              <div id="mobile_confuse_button">
              <a
                  href="javascript:void(Tawk_API.toggle())"
                  className="text-decoration-none"
                >
                  {" "}
                  <button className="btn">Let's have a chat</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confusion;
