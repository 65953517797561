import React, { useEffect, useRef } from "react";
import "../Styles/Header.css";

import nyinst_logo from "../Assets/ny_logo.png";
import scholarship_icon from "../Assets/scholarship_icon.png";
import open_braces_icon from "../Assets/opening_braces.png";
import close_braces_icon from "../Assets/closing_braces.png";

import techie_pro from "../Assets/t_pro.png";
import techie_intern from "../Assets/t_intern.png";
import techie_iv from "../Assets/t_iv.png";
import techie_nerds from "../Assets/t_nerds.png";
import techie_super from "../Assets/t_super.png";
import { useState } from "react";

import nav_toggle from "../Assets/nav_toggle.png";

import { useLocation, useSearchParams } from "react-router-dom";
import techie_pro_logo from "../Assets/t_pro.png";
import ApplyForm from "../TechiePro/ApplyForm";

function Header({ menu, activeSectionRef }) {
  let [searchParams] = useSearchParams();
  // const match_id = searchParams.get("");
  // const menu = props.menu;
  useEffect(() => {
    console.log("Active ref in TechieHeroSection:", activeSectionRef);
  }, [activeSectionRef]);
  console.log(menu);
  const [showStuds, setStuds] = useState(false);
  const [prosShow, setProsShow] = useState(false);
  const [show, setShow] = useState(false);

  const handleProsMenu = (menu) => {
    setProsShow(!prosShow);
    setStuds(false);
  };
  const handleStudsMenu = (menu) => {
    setStuds(!showStuds);
    setProsShow(false);
  };
  const [scrollPosition, setScrollPosition] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 0 ? setScrollPosition("fixed") : setScrollPosition("");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();
  const myRef = useRef();

  const [modalShows, setModalShows] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => setModalShows(false);
  const handleShow = () => setModalShows(true);
  const techieProMenus = () => {
    return (
      <div id="header_menu_bg" className="z-8">
        <div className="container-fluid" id="menus_bg">
          <div id="left_items_bg">
            <div id="nyinst_logo">
              <a href="/">
                <img src={nyinst_logo} alt="nyinst_logo" />
              </a>
            </div>
            <a
              href={
                location.pathname === "techie-pro"
                  ? "/techie-pro#home"
                  : location.pathname === "techie-nerds"
                  ? "/techie-nerds#home"
                  : ""
              }
              className="text-decoration-none"
            >
              <div
                id="pro_img_bg"
                className="mt-0"
                style={{
                  width: "4.125rem",
                  height: "4rem",
                  marginLeft: "3rem",
                  background:
                    location.pathname === "/techie-pro"
                      ? "#E252341A"
                      : location.pathname === "/techie-nerds"
                      ? "#D2961D1A"
                      : location.pathname === "/techie-super"
                      ? "#8625D21A"
                      : location.pathname === "/techie-intern"
                      ? "#639C191A"
                      : "",
                }}
              >
                <img
                  src={
                    location.pathname === "/techie-pro"
                      ? techie_pro_logo
                      : location.pathname === "/techie-nerds"
                      ? techie_nerds
                      : location.pathname === "/techie-super"
                      ? techie_super
                      : location.pathname === "/techie-intern"
                      ? techie_intern
                      : ""
                  }
                  alt=""
                  style={{ width: "3rem", height: "3rem" }}
                />
              </div>
            </a>
          </div>
          {/* {menus()} */}

          <div id="techie_menus_bg">
            {" "}
            <a
              href={
                location.pathname === "/techie-pro"
                  ? "/techie-pro#about"
                  : location.pathname === "/techie-nerds"
                  ? "/techie-nerds#about"
                  : location.pathname === "/techie-super"
                  ? "/techie-super#about"
                  : location.pathname === "/techie-intern"
                  ? "/techie-intern#about"
                  : ""
              }
              className="text-decoration-none"
            >
              <div
                style={{
                  color: activeSectionRef === "about" ? "#222F43" : "#6A7380",
                  fontWeight: activeSectionRef === "about" ? "600" : "500",
                  borderBottom:
                    // activeSectionRef === "faq" &&
                    location.pathname === "/techie-pro" &&
                    activeSectionRef === "about"
                      ? "0.125rem solid #E25234"
                      : location.pathname === "/techie-nerds" &&
                        activeSectionRef === "about"
                      ? "0.125rem solid #D2961D"
                      : location.pathname === "/techie-super" &&
                        activeSectionRef === "about"
                      ? "0.125rem solid #8625D2"
                      : location.pathname === "/techie-intern" &&
                        activeSectionRef === "about"
                      ? "0.125rem solid #639C19"
                      : "",
                  height: "6rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                About Program
              </div>
            </a>
            <a
              href={
                location.pathname === "/techie-pro"
                  ? "/techie-pro#techstack"
                  : location.pathname === "/techie-nerds"
                  ? "/techie-nerds#techstack"
                  : location.pathname === "/techie-super"
                  ? "/techie-super#techstack"
                  : location.pathname === "/techie-intern"
                  ? "/techie-intern#benefits"
                  : ""
              }
              className="text-decoration-none"
            >
              <div
                style={{
                  color:
                    activeSectionRef === "techstack" ||
                    activeSectionRef === "benefits"
                      ? "#222F43"
                      : "#6A7380",
                  fontWeight:
                    activeSectionRef === "techstack" ||
                    activeSectionRef === "benefits"
                      ? "600"
                      : "500",
                  borderBottom:
                    // activeSectionRef === "faq" &&
                    location.pathname === "/techie-pro" &&
                    activeSectionRef === "techstack"
                      ? "0.125rem solid #E25234"
                      : location.pathname === "/techie-nerds" &&
                        activeSectionRef === "techstack"
                      ? "0.125rem solid #D2961D"
                      : location.pathname === "/techie-super" &&
                        activeSectionRef === "techstack"
                      ? "0.125rem solid #8625D2"
                      : location.pathname === "/techie-intern" &&
                        activeSectionRef === "benefits"
                      ? "0.125rem solid #639C19"
                      : "",
                  height: "6rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {location.pathname === "/techie-intern"
                  ? "Benefits"
                  : "TechStacks"}
              </div>
            </a>
            <a
              href={
                location.pathname === "/techie-pro"
                  ? "/techie-pro#certificate"
                  : location.pathname === "/techie-nerds"
                  ? "/techie-nerds#certificate"
                  : location.pathname === "/techie-super"
                  ? "/techie-super#certificate"
                  : location.pathname === "/techie-intern"
                  ? "/techie-intern#certificate"
                  : ""
              }
              className="text-decoration-none"
            >
              <div
                style={{
                  color:
                    activeSectionRef === "certificate" ? "#222F43" : "#6A7380",
                  fontWeight:
                    activeSectionRef === "certificate" ? "600" : "500",
                  borderBottom:
                    // activeSectionRef === "faq" &&
                    location.pathname === "/techie-pro" &&
                    activeSectionRef === "certificate"
                      ? "0.125rem solid #E25234"
                      : location.pathname === "/techie-nerds" &&
                        activeSectionRef === "certificate"
                      ? "0.125rem solid #D2961D"
                      : location.pathname === "/techie-super" &&
                        activeSectionRef === "certificate"
                      ? "0.125rem solid #8625D2"
                      : location.pathname === "/techie-intern" &&
                        activeSectionRef === "certificate"
                      ? "0.125rem solid #639C19"
                      : "",
                  height: "6rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Experience
              </div>
            </a>
            <a
              href={
                location.pathname === "/techie-pro"
                  ? "/techie-pro#pricing"
                  : location.pathname === "/techie-nerds"
                  ? "/techie-nerds#pricing"
                  : location.pathname === "/techie-super"
                  ? "/techie-super#pricing"
                  : location.pathname === "/techie-intern"
                  ? "/techie-intern#pricing"
                  : ""
              }
              className="text-decoration-none"
            >
              <div
                style={{
                  color: activeSectionRef === "pricing" ? "#222F43" : "#6A7380",
                  fontWeight: activeSectionRef === "pricing" ? "600" : "500",
                  borderBottom:
                    // activeSectionRef === "faq" &&
                    location.pathname === "/techie-pro" &&
                    activeSectionRef === "pricing"
                      ? "0.125rem solid #E25234"
                      : location.pathname === "/techie-nerds" &&
                        activeSectionRef === "pricing"
                      ? "0.125rem solid #D2961D"
                      : location.pathname === "/techie-super" &&
                        activeSectionRef === "pricing"
                      ? "0.125rem solid #8625D2"
                      : location.pathname === "/techie-intern" &&
                        activeSectionRef === "pricing"
                      ? "0.125rem solid #639C19"
                      : "",
                  height: "6rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Pricing
              </div>
            </a>
            <a
              href={
                location.pathname === "/techie-pro"
                  ? "/techie-pro#faq"
                  : location.pathname === "/techie-nerds"
                  ? "/techie-nerds#faq"
                  : location.pathname === "/techie-super"
                  ? "/techie-super#faq"
                  : location.pathname === "/techie-intern"
                  ? "/techie-intern#faq"
                  : ""
              }
              className="text-decoration-none"
            >
              <div
                style={{
                  color: activeSectionRef === "faq" ? "#222F43" : "#6A7380",
                  fontWeight: activeSectionRef === "faq" ? "600" : "500",
                  borderBottom:
                    // activeSectionRef === "faq" &&
                    location.pathname === "/techie-pro" &&
                    activeSectionRef === "faq"
                      ? "0.125rem solid #E25234"
                      : location.pathname === "/techie-nerds" &&
                        activeSectionRef === "faq"
                      ? "0.125rem solid #D2961D"
                      : location.pathname === "/techie-super" &&
                        activeSectionRef === "faq"
                      ? "0.125rem solid #8625D2"
                      : location.pathname === "/techie-intern" &&
                        activeSectionRef === "faq"
                      ? "0.125rem solid #639C19"
                      : "",
                  height: "6rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                FAQ's
              </div>
            </a>
            {console.log(location.pathname, "kl")}
          </div>
          <div id="right_menu_bg">
            {/* <a href="/scholarship" className="text-decoration-none">
              <div id="scholarship_bg">
                <img src={scholarship_icon} alt="" />
                Scholarship
              </div>
            </a> */}
            {/* <a href="/apply-now" className="text-decoration-none">
              {" "} */}
            <div id="apply_now_bg">
              <button
                onClick={() => setModalShow(true)}
                style={{
                  padding: "0.75rem 1.25rem 0.75rem 1rem",
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  background:
                    location.pathname === "/techie-pro"
                      ? "#E25234"
                      : location.pathname === "/techie-nerds"
                      ? "#D2961D"
                      : location.pathname === "/techie-super"
                      ? "#8625D2"
                      : location.pathname === "/techie-intern"
                      ? "#639C19"
                      : "",
                }}
              >
                <svg
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM7 6H11V10H7V6ZM7 12H17V14H7V12ZM7 16H17V18H7V16ZM13 7H17V9H13V7Z"
                    fill="white"
                  />
                </svg>
                Apply Now
              </button>
            </div>{" "}
            <ApplyForm show={modalShow} onHide={() => setModalShow(false)} />
            {/* </a> */}
          </div>
        </div>
      </div>
    );
  };

  const defaultMenus = () => {
    return (
      <div id="header_menu_bg">
        <div className="container-fluid" id="menus_bg">
          <div id="left_items_bg">
            <div id="nyinst_logo">
              <a href="/">
                <img src={nyinst_logo} alt="nyinst_logo" />
              </a>
            </div>
            {/* <a href="/hire-us" className="text-decoration-none">
              {" "}
              <div id="hire_us_bg">
                <div id="hire_icon_bg">
                  <img src={hire_us_icon} />
                </div>
                <div id="hire_text">Hire from us</div>
              </div>
            </a> */}
          </div>
          {/* {menus()} */}
          <div id="center_menu_bg" style={{ paddingLeft: "3rem" }}>
            {/* <a href="/our-usp" className="text-decoration-none">
              <div style={{ color: "#132137" }}>Our USP</div>
            </a> */}
            <div
              className="d-flex align-items-center justify-content-center "
              onMouseEnter={() => handleProsMenu()}
              style={{
                color: prosShow === true ? "var(--primary_color)" : "",
                cursor: "pointer",
                height: "40px",
              }}
            >
              <div>IT Pros </div>
              <div>
                {prosShow === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99861 9.02367L14.1234 13.1484L15.3019 11.9699L9.99861 6.66659L4.69531 11.9699L5.87383 13.1484L9.99861 9.02367Z"
                      fill="#006BFF"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99861 10.9763L14.1234 6.85156L15.3019 8.03007L9.99861 13.3334L4.69531 8.03007L5.87383 6.85156L9.99861 10.9763Z"
                      fill="#132137"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div
              onMouseEnter={() => handleStudsMenu("studs")}
              style={{
                color: showStuds === true ? "var(--primary_color)" : "",
                cursor: "pointer",
              }}
            >
              Students{" "}
              {showStuds === true ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.99861 9.02367L14.1234 13.1484L15.3019 11.9699L9.99861 6.66659L4.69531 11.9699L5.87383 13.1484L9.99861 9.02367Z"
                    fill="#006BFF"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.99861 10.9763L14.1234 6.85156L15.3019 8.03007L9.99861 13.3334L4.69531 8.03007L5.87383 6.85156L9.99861 10.9763Z"
                    fill="#132137"
                  />
                </svg>
              )}
            </div>
            <a
              href="/hire"
              className="text-decoration-none"
              style={{ color: "var(--g_800" }}
            >
              <div>Hire from us</div>
            </a>
            <a
              href="/contact-us"
              className="text-decoration-none"
              style={{ color: "var(--g_800" }}
            >
              <div>Contact</div>
            </a>
          </div>

          <div id="right_menu_bg">
            <a
              href="/scholarship"
              className="text-decoration-none"
              // target="_blank"
            >
              <div id="scholarship_bg">
                <img src={scholarship_icon} alt="" />
                Scholarship
              </div>
            </a>
            <a href="/techie-thon" className="text-decoration-none">
              {" "}
              <div id="techie_thon_bg">
                <img src={open_braces_icon} alt="" />
                <span>
                  Techie<span id="thon_text">Thon</span>
                </span>
                <img src={close_braces_icon} alt="" />
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const menus = () => (menu === "about" ? techieProMenus() : defaultMenus());
  return (
    <div className="">
      <div className="d-lg-block d-none">
        {/* <div id="header_top_bg">
          <div className="container-fluid">
            <div id="container_top_bg">
              <div id="social_links">
                <div id="social_icon_bg">
                  <div>
                    <a
                      href="https://www.instagram.com/nyinstofficial/"
                      target="_blank"
                    >
                      <img src={insta_icon} alt="yt" />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://twitter.com/nyinstofficial"
                      target="_blank"
                    >
                      <img src={x_icon} alt="yt" />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/company/nyinstofficial/"
                      target="_blank"
                    >
                      <img src={linkedin_icon} alt="yt" />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.youtube.com/@nyinstofficial"
                      target="_blank"
                    >
                      <img src={yt_icon} alt="yt" />
                    </a>
                  </div>
                </div>
              </div>
              <div id="el_exito_text_bg">
                <div>
                  EL EXITO A <span>EXCELENCIA</span>
                </div>
              </div>
              <div id="top_menus_bg">
                <div>Register</div>
                <div>Login</div>
                <div>Career</div>
              </div>
            </div>
          </div>
        </div> */}
        <div
          // className={} bg-success"
          style={{
            position: scrollPosition,
            top: "0",
            width: "100%",
            backgroundColor: "white",
            zIndex: "2",
          }}
        >
          {menus()}

          {prosShow && (
            <div
              id="techie_pro_bg"
              className="container-fluid "
              onMouseLeave={() => setProsShow(false)}
            >
              <>
                <a href="/techie-pro" className="text-decoration-none">
                  <div id="techie_pro">
                    <img src={techie_pro} alt="" />
                    <div id="program_name">
                      Techie PRO
                      <div id="program_duration">2 Months</div>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M21.3376 12.5523L9.86218 24.0277L7.97656 22.1421L19.452 10.6667H9.33766V8H24.0043V22.6667H21.3376V12.5523Z"
                        fill="#E25234"
                      />
                    </svg>
                  </div>
                </a>
                {/* <a href="/techie-nerds" className="text-decoration-none">
                  {" "}
                  <div id="techie_pro">
                    <img src={techie_nerds} alt="" />
                    <div id="program_name">
                      Techie NERDS
                      <div id="program_duration">3 Months</div>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M21.3376 12.5523L9.86218 24.0277L7.97656 22.1421L19.452 10.6667H9.33766V8H24.0043V22.6667H21.3376V12.5523Z"
                        fill="#D2961D"
                      />
                    </svg>
                  </div>
                </a> */}
                <a href="/techie-super" className="text-decoration-none">
                  {" "}
                  <div id="techie_pro">
                    <img src={techie_super} alt="" />
                    <div id="program_name">
                      Techie SUPER
                      <div id="program_duration">3 Months</div>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M21.3376 12.5523L9.86218 24.0277L7.97656 22.1421L19.452 10.6667H9.33766V8H24.0043V22.6667H21.3376V12.5523Z"
                        fill="#8625D2"
                      />
                    </svg>
                  </div>
                </a>
              </>
            </div>
          )}
          {showStuds && (
            <div
              id="techie_pro_bg"
              className="container-fluid"
              onMouseLeave={() => setStuds(false)}
            >
              <>
                <a href="/techie-intern" className="text-decoration-none">
                  <div id="techie_pro" className="position-relative">
                    <img src={techie_intern} alt="" />
                    <div id="program_name">
                      Techie INTERN
                      <div
                        id="program_duration"
                        style={{ fontSize: "0.825vw" }}
                      >
                        30-60-90-180 Days
                      </div>
                    </div>
                    <svg
                      className="position-absolute"
                      style={{ right: "0.5vw" }}
                      align="end"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M21.3376 12.5523L9.86218 24.0277L7.97656 22.1421L19.452 10.6667H9.33766V8H24.0043V22.6667H21.3376V12.5523Z"
                        fill="#73B61D"
                      />
                    </svg>
                  </div>
                </a>
                <a className="text-decoration-none" href="/techie-iv">
                  <div id="techie_pro">
                    <img src={techie_iv} alt="" />
                    <div id="program_name">
                      Industrial Visit
                      <div id="program_duration">1 Day</div>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M21.3376 12.5523L9.86218 24.0277L7.97656 22.1421L19.452 10.6667H9.33766V8H24.0043V22.6667H21.3376V12.5523Z"
                        fill="#CA334E"
                      />
                    </svg>
                  </div>
                </a>
              </>
            </div>
          )}
        </div>
        {/* <div id="opacity" className="position-absolute text-white top-50 left-50 bg-primary mt-5" >ss</div> */}
        {prosShow === true || showStuds === true ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              background: "black",
              position: "absolute",
              // top: "6rem",
              zIndex: "1",
              opacity: "0.5",
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
      {/* for mobiles */}
      <div className="d-block d-lg-none position-relative">
        <div className="">
          <div id="mobile_header_menu_bg" className="position-fixed top-0 z-1">
            <div className="container-fluid" id="logo_bg">
              <div id="nyinst_mobile_logo">
                <a href="/">
                  <img src={nyinst_logo} alt="nyinst_logo" />
                </a>
              </div>
              <div id="nav_toggle" onClick={() => setShow(!show)}>
                {show ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M14.0028 12.3509L19.7774 6.57617L21.4273 8.22608L15.6527 14.0008L21.4273 19.7755L19.7774 21.4254L14.0028 15.6507L8.22804 21.4254L6.57812 19.7755L12.3529 14.0008L6.57812 8.22608L8.22804 6.57617L14.0028 12.3509Z"
                      fill="black"
                    />
                  </svg>
                ) : (
                  <img src={nav_toggle} alt="nav_toggle" />
                )}
              </div>
            </div>
          </div>
          {show && (
            <div id="mobile_menu_bg" className="z-4 w-100 ">
              {/* <a href="/our-usp" className="text-decoration-none">
                {" "}
                <div id="mobile_menu">Our USP</div>
              </a>{" "} */}
              <div
                onClick={handleProsMenu}
                id="mobile_menu"
                className="flex-direction-column justify-content-between"
              >
                <div>IT Pros</div>
                <div>
                  {prosShow === true ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0007 10.8286L16.9505 15.7783L18.3647 14.3641L12.0007 8.0001L5.63672 14.3641L7.05094 15.7783L12.0007 10.8286Z"
                        fill="#006BFF"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0007 13.1714L16.9505 8.22168L18.3647 9.63589L12.0007 15.9999L5.63672 9.63589L7.05094 8.22168L12.0007 13.1714Z"
                        fill="#132137"
                      />
                    </svg>
                  )}
                </div>
              </div>
              {prosShow && (
                <div id="mobile_it_pros_menu_bg">
                  <a href="/techie-pro" className="text-decoration-none w-100">
                    <div
                      id="mobile_techie_bg"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div id="mobile_techie_pro_bg">
                        <div>
                          <img src={techie_pro} alt="" />
                        </div>

                        <div id="mobile_techie_pro_text">
                          Techie PRO
                          <div id="mobile_course_duration">2 Months</div>
                        </div>
                      </div>

                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                            fill="#E25234"
                          />
                        </svg>
                      </div>
                    </div>{" "}
                  </a>
                  {/* <a
                    href="/techie-nerds"
                    className="text-decoration-none w-100"
                  >
                    {" "}
                    <div
                      id="mobile_techie_bg"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div id="mobile_techie_pro_bg">
                        <div>
                          <img src={techie_nerds} alt="" />
                        </div>

                        <div id="mobile_techie_pro_text">
                          Techie NERDS
                          <div id="mobile_course_duration">3 Months</div>
                        </div>
                      </div>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                            fill="#D2961D"
                          />
                        </svg>
                      </div>
                    </div>
                  </a> */}
                  <a
                    href="/techie-super"
                    className="text-decoration-none w-100"
                  >
                    <div
                      id="mobile_techie_bg"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div id="mobile_techie_pro_bg">
                        <div>
                          <img src={techie_super} alt="" />
                        </div>

                        <div id="mobile_techie_pro_text">
                          Techie SUPER
                          <div id="mobile_course_duration">3 Months</div>
                        </div>
                      </div>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                            fill="#8625D2"
                          />
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
              )}
              <div
                onClick={handleStudsMenu}
                id="mobile_menu"
                className="flex-direction-column justify-content-between"
              >
                <div>Students</div>
                <div>
                  {showStuds === true ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0007 10.8286L16.9505 15.7783L18.3647 14.3641L12.0007 8.0001L5.63672 14.3641L7.05094 15.7783L12.0007 10.8286Z"
                        fill="#006BFF"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0007 13.1714L16.9505 8.22168L18.3647 9.63589L12.0007 15.9999L5.63672 9.63589L7.05094 8.22168L12.0007 13.1714Z"
                        fill="#132137"
                      />
                    </svg>
                  )}
                </div>
              </div>
              {showStuds && (
                <div id="mobile_it_pros_menu_bg">
                  <a
                    href="/techie-intern"
                    className="text-decoration-none w-100"
                  >
                    <div
                      id="mobile_techie_bg"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div id="mobile_techie_pro_bg">
                        <div>
                          <img src={techie_intern} alt="" />
                        </div>

                        <div id="mobile_techie_pro_text">
                          Techie INTERN
                          <div id="mobile_course_duration">
                            30-60-90-180 Days
                          </div>
                        </div>
                      </div>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                            fill="#73B61D"
                          />
                        </svg>
                      </div>
                    </div>
                  </a>

                  <a href="/techie-iv" className="text-decoration-none w-100">
                    {" "}
                    <div
                      id="mobile_techie_bg"
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div id="mobile_techie_pro_bg">
                        <div>
                          <img src={techie_iv} alt="" />
                        </div>

                        <div id="mobile_techie_pro_text">
                          Industrial Visit
                          <div id="mo1 Daytion">1 Day</div>
                        </div>
                      </div>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                            fill="#CA334E"
                          />
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
              )}
              <a
                href="/hire"
                className="text-decoration-none"
                style={{ color: "var(--g_800" }}
              >
                <div id="mobile_menu">Hire from us</div>
              </a>
              <a
                href="/contact-us"
                className="text-decoration-none"
                style={{ color: "var(--g_800" }}
              >
                <div id="mobile_menu">Contact</div>
              </a>
              {/* <a href="/careers" className="text-decoration-none">
                <div id="mobile_menu">Careers</div>
              </a> */}
              {/* <a href="/login" className="text-decoration-none">
                {" "}
                <div id="mobile_menu">Login</div>
              </a>
              <a href="/register" className="text-decoration-none">
                <div id="mobile_menu">Register</div>
              </a> */}
              <a href="/scholarship" className="text-decoration-none">
                {" "}
                <div id="mobile_scholarship_btn">
                  <div>
                    <img src={scholarship_icon} />
                  </div>
                  <div>Scholarship</div>
                </div>
              </a>
              <a href="/techie-thon" className="text-decoration-none">
                {" "}
                <div id="mobile_techiethon_btn">
                  <div>
                    <img src={open_braces_icon} />
                  </div>
                  <div>
                    Techie<span id="thon_text">Thon</span>
                  </div>
                  <div>
                    <img src={close_braces_icon} />
                  </div>
                </div>
              </a>
              {/* <a href="/hire-us" className="text-decoration-none">
                <div id="mobile_hire_bg">
                  <div id="mobile_hire_us_bg">
                    <div id="mobile_hire_icon_bg">
                      <img src={hire_us_icon} />
                    </div>
                    <div id="mobile_hire_text">Hire from us</div>
                  </div>
                </div>
              </a> */}
            </div>
          )}
        </div>
      </div>
      {/* <Homepage menu={(showStuds, prosShow)} /> */}
    </div>
  );
}

export default Header;
