import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import blog_hero_img from "../Assets/blog_hero_img.avif";
import data_analytics_blog_detail_img from "../Assets/data_analytics_blog_img.avif";
import full_stack_blog_img from "../Assets/full_stack_blog_img.avif";
import machine_learning_blog_detail_img from "../Assets/machine_learning_img.avif";
import impressive_resume_blog_detail_img from "../Assets/impressive_resume_blog_img.avif";
import database_design_blog_detail_img from "../Assets/data_base_blog_img.avif";
import python_blog_details_img from "../Assets/python_blog_img.avif";
import data_driven_blog_detail_img from "../Assets/data_driven_img.avif";
import software_testing_img from "../Assets/software_testing_blog_img.avif";
import big_data_blog_img from "../Assets/big_data_blog_img.avif";
import automation_testing from "../Assets/automation_testing_blog_img.png";
import arrow_blog from "../Assets/arrow_blog.svg";
import interview_tips_blog_img from "../Assets/interview_tips_blog_img.jpg";
import ai_blog_img from "../Assets/ai_blog_img.jpg";
// import { FiSearch } from "react-icons/fi";
// import { AiFillCalendar } from 'react-icons/ai'
// import { FaArrowUp } from 'react-icons/fa'
// import { IoArrowForwardSharp } from 'react-icons/io5'
import "../Styles/Blogs.css";
import live_projects_hero_svg_line from "../Assets/live_projects_hero_svg_line.svg";
// import { LazyLoadImage } from "react-lazy-load-image-component";
const Blogs = () => {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/5b191ea08859f57bdc7beef2/default";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();
  return (
    <div className="position-relative"
    style={{ zIndex: "0", backgroundColor: "white" }}>
      <Header />

      {/* Blog Hero Section */}
      <div
        className="row w-100 m-0 p-0  mb-0"
        style={{ background: "#065BD6" }}
      >
        <div className="col-md-7 m-0 p-0 b-0">
          <img
            src={blog_hero_img}
            className="w-100"
            alt="Blog Hero Image"
            style={{ objectFit: "cover" }}
          />
        </div>
        <div className="col-md-5 d-flex position-relative ">
          <div
            className="m-md-auto m-2 align-items-center"
            style={{ color: "white" }}
          >
            <div
              className="p-5 mt-5 mobile_blog_hero_text"
              style={{
                fontFamily: "Avenir LT Std",
                fontWeight: "800",
                fontSize: "40px",
                lineHeight: "52px",
              }}
            >
              Every moment presents an opportunity to gain new knowledge and
              insights.
            </div>
          </div>
          <img
            src={live_projects_hero_svg_line}
            className="position-absolute top-0  w-100 svg_line_mobile"
            style={{ right: "0%", objectFit: "cover" }}
            alt="blog svg line"
          />
        </div>
      </div>

      {/* Blog Filters */}
      <div className="container ">
        <div className="d-md-flex justify-content-between py-5">
          {/* <div className='position-relative '>
            <FiSearch
              className='position-absolute  top-50 translate-middle-y'
              style={{ right: '5%' }}
            />
            <input
              type='text'
              className=' py-2 my-2 ps-3 rounded mobile_search_width'
              style={{ border: '1px solid #D4D4D4', width: '22rem' }}
              placeholder='Search'
            />
          </div> */}
          {/* <div className='d-md-flex w-25 mobile_blog_filter_width'>
            <select
              className='w-100 py-2 me-2 my-2 ps-3 rounded'
              style={{ border: '1px solid #D4D4D4' }}
            >
              <option value='All Categories'>All Blogs</option>
              <option value='General'>General</option>
              <option value='Health'>Health</option>
            </select>
            <select
              className='w-100 py-2 my-2 ps-3 rounded'
              style={{ border: '1px solid #D4D4D4' }}
            >
              <option value='All Categories'>All Categories</option>
              <option value='General'>General</option>
              <option value='Health'>Health</option>
            </select>
          </div> */}
        </div>{" "}
        {/* Blogs */}
        <div className="row pb-5">
        <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={ai_blog_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover", width:"100%" }}
                  height="228"
                  alt="ai_blog_img"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "#FFFFFF",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  How AI Is Transforming Data Analysis And Decision-Making In
                  It?
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  In the era of big data, organizations are inundated with vast
                  amounts of information. Extracting meaningful insights from
                  this data and making informed decisions has become a daunting
                  task.
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>21 JUN 2023</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/ai"
                      className="text-decoration-none"
                    >
                      {" "}
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={interview_tips_blog_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Data Driven Online Business"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  Interview Tips To Get Placed At A Product-Based Company in
                  2023
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  Securing a position in a product-based company is a dream for
                  many aspiring professionals in the tech industry. With fierce
                  competition and evolving job market dynamics, it is crucial to
                  prepare yourself thoroughly for the interview process. This
                  blog post aims to provide valuable tips and insights to help
                  you increase your chances of getting placed in a product-based
                  company in 2023.
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>5 JUN 2023</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/interview-tips"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={automation_testing}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Data Driven Online Business"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  Top QA Automation Testing Tools For Software Testing In 2023
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  In the rapidly evolving world of software development, the
                  demand for robust and efficient quality assurance (QA)
                  automation testing tools continues to grow. To help you
                  navigate the vast landscape of options, this blog presents the
                  top QA automation testing tools that are expected to dominate
                  the market in 2023.
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>24 MAY 2023</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/automation-testing"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={full_stack_blog_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Full Stack Developer Salary"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "#FFFFFF",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  Full Stack Developer Salary In India 2023
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  Hello Job Seekers! Glad that you are here today to explore
                  more about the developer career and Full Stack Developer
                  Salary In...
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>14 DEC 2022</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/full-stack"
                      className="text-decoration-none"
                    >
                      {" "}
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={data_analytics_blog_detail_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="India Dawdles in Tech Skills "
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  India dawdless in tech skills amongst peers and ease of doing
                  business.
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  India may have garnered a lot of attention for its global
                  policy changes and ease of doing business. But as far as the
                  job revolution is...
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>12 NOV 2022</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/data-analytics"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={big_data_blog_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Big Data Created a Huge Buzz"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  Big Data created a huge buzz in the tech-driven world
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  Present in both structured and unstructured forms, big data
                  analytics involves the evaluation of a large chunk of...
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>12 NOV 2022</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/big-data"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={machine_learning_blog_detail_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Artificial Inteligence and Machine Learning "
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  Difference Between Machine Learning and Artificial
                  Intelligence
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  These are the current ‘trendy’ buzzwords in the market –
                  Artificial Intelligence and Machine Learning. While both of
                  them are used...
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>29 AUG 2022</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/machine-learning"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={impressive_resume_blog_detail_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Create Resume"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  The Secret Behind An Impressive Resume Unraveled!
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  An impressive resume is one of the most powerful tools that
                  you can use to showcase your personality and your expertise
                  to...
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>14 AUG 2022</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/impressive-resume"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={software_testing_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Software Testing Blog"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  A Successful Career Path to Software Testing
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  Software tester or QA practitioner is one of the interesting
                  Jobs many IT lovers go for. Before unfolding about Software
                  testing, I ‘do like to...
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>03 JAN 2022</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/software-testing"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={database_design_blog_detail_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Learn Database Design"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  What can I learn in Database Design and Development Course?
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  Databases are not unfamiliar to the world of web development.
                  From robust user-oriented websites to the simplest blogs...
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>30 JAN 2022</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/database-design"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={python_blog_details_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Top Reason To Choose Python"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  Top Reasons why you should choose Python for Big Data
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  Python is considered as one of the most popular languages and
                  is in fact, included amongst the top 10 most-used...
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>16 APR 2022</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/big-data"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="center" className="col-lg-4 col-md-6 ">
            <div
              class="card text-start  my-2 mobile_blog_width_home"
              style={{
                width: "336px",
                borderRadius: "12px",
                border: "1px solid #DFE1E4",
              }}
            >
              <div className="position-relative">
                <img
                  src={data_driven_blog_detail_img}
                  class="card-img-top rounded"
                  style={{ objectFit: "cover" }}
                  height="228"
                  alt="Data Driven Online Business"
                />
                <div
                  className="position-absolute"
                  style={{
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "16px",
                    background: "#07152C",
                    opacity: "70%",
                    bottom: "12px",
                    left: "20px",
                    Zindex: "3",
                    padding: "4px 12px",
                    borderRadius: "12px",
                  }}
                >
                  Technology
                </div>
              </div>

              <div class="">
                <div
                  className="single_line_clamp"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "20px 20px 0px 20px",
                    color: "#222F43",
                  }}
                >
                  Way to Run A Data-Driven Online Business
                </div>
                <div
                  className="three_line_clamp"
                  style={{
                    fontSize: "13px",
                    fonWeight: "400",
                    lineHeight: "24px",
                    padding: "12px 20px 0px 20px",
                    color: "#5B6574",
                  }}
                >
                  Being ‘Data-Driven’ seems to be a popular expression in the
                  Business World Nowadays. It’s a cold topic on how AI...
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "20px 20px 24px 20px", color: "#6A7380" }}
                >
                  <div
                    className="d-flex my-auto"
                    style={{
                      fontSize: "13px",
                      lineHeight: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <div>12 DEC 2022</div>
                    <div
                      style={{
                        paddingLeft: "8px",
                        fontSize: "2rem",
                        marginTop: "-5%",
                      }}
                    >
                      .
                    </div>
                    <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
                  </div>
                  <div>
                    <a
                      href="/data-driven"
                      className="text-decoration-none"
                    >
                      <img src={arrow_blog} alt="arrow blog" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div align='center' className='pb-5'>
          <button
            className='btn  rounded-0 px-5 py-2'
            style={{
              border: '1px solid #D4D4D4',
              whiteSpace: 'nowrap',
              fontWeight: '600'
            }}
          >
            Load More
          </button>
        </div> */}
      </div>

      <Footer />
    </div>
  );
};

export default Blogs;
