import React from "react";
import "../Styles/NyinstPath.css";
// import yellow_gradient_layer from "../Assets/yellow_gradient_layer.png";
import register_icon from "../Assets/register_icon.png";
import councelling_icon from "../Assets/councelling_icon.png";
import work_learn_icon from "../Assets/work_learn_icon.png";
import interview_icon from "../Assets/interview_icon.png";
// import vector_svg from "../Assets/vector_svg.png";
import path_vector_bg from "../Assets/path_vector_bg.png";
import mobile_vector_bg from "../Assets/mobile_vector_bg.png";
function NyinstPath() {
  return (
    <div>
      <div className="w-100 p-0 m-0">
        <div id="yellow_bg" className="z-0 position-relative d-lg-block d-none">
          <div className="position-absolute top-0" id="vector_img">
            <img src={path_vector_bg} width="100%" />
          </div>
          <div id="vector_bg" className="position-absolute top-0"></div>
          <div className="container-fluid position-relative">
            <div id="nyinst_path_bg">
              <div id="our_program_title_bg" align="center">
                <div id="our_programs_text">OUR PROCESS</div>
                <div id="part_of_text">How to become a part of NYinst</div>
              </div>
              <div className="">
                <div id="st_line" className=""></div>
                <div id="paths_bg" className="d-flex align-items-center ">
                  <div
                    id="first_step_bg"
                    align="center"
                    className="position-relative"
                  >
                    <div id="icon_bg">
                      <img src={register_icon} alt="" width="100%" />
                    </div>
                    <div id="register_text">Apply</div>
                    <div id="register_content">
                      Apply to a program of your choice & wait for a call back
                      from Nyinst Team
                    </div>
                  </div>
                  <div
                    id="first_step_bg"
                    align="center"
                    className="position-relative"
                  >
                    <div id="icon_bg">
                      <img src={councelling_icon} alt="" width="100%" />
                    </div>
                    <div id="register_text">Counseling</div>
                    <div id="register_content">
                      Know more about the programs and chose the right one for
                      your career
                    </div>
                  </div>

                  <div
                    id="first_step_bg"
                    align="center"
                    className="position-relative"
                  >
                    <div id="icon_bg">
                      <img src={work_learn_icon} alt="" width="100%" />
                    </div>
                    <div id="register_text">Work & Learn</div>
                    <div id="register_content">
                      Work on live project and get hands on with our industry
                      expert
                    </div>
                  </div>
                  {/* <div className="position-absolute">
                    <img
                      src={vector_svg}
                      width="100%"
                    />
                  </div> */}
                  <div
                    id="first_step_bg"
                    align="center"
                    className="position-relative"
                  >
                    <div id="icon_bg">
                      <img src={interview_icon} alt="" width="100%" />
                    </div>
                    <div id="register_text">Interview & Job Ready</div>
                    <div id="register_content">
                      Get industry ready with mock interviews & Get hired in the
                      dream job by our top clients
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* for mobiles */}
        <div className="d-block d-lg-none">
          <div id="mobile_yellow_bg" className="position-relative z-0">
            <div className="position-absolute top-0 z-1" id="review_vector_layer">
              <img src={path_vector_bg} width="100%" height="fit-content" />
            </div>
            <div
              id="mobile_gradient_bg"
              className="position-absolute top-0"
            ></div>
            <div className="container-fluid position-relative">
              <div align="center" id="mobile_our_programs_text">
                OUR PROCESS
                <div id="mobile_part_text">
                  How to become a <br /> part of NYinst
                </div>
              </div>
              <div id="mobile_reg_path_bg">
                <div id="mobile_reg_bg">
                  <img src={register_icon} alt="" />
                </div>
                <div id="mobile_reg_text">
                  Apply
                  <div id="mobile_reg_content">
                    Apply to a program of your choice & wait for a call back
                    from Nyinst Team
                  </div>
                </div>
              </div>
              <div id="mobile_reg_path_bg">
                <div id="mobile_reg_bg">
                  <img src={councelling_icon} alt="" />
                </div>
                <div id="mobile_reg_text">
                  Counseling
                  <div id="mobile_reg_content">
                    Know more about the programs and chose the right one for
                    your career
                  </div>
                </div>
              </div>
              <div id="mobile_reg_path_bg">
                <div id="mobile_reg_bg">
                  <img src={work_learn_icon} alt="" />
                </div>
                <div id="mobile_reg_text">
                  Work & Learn
                  <div id="mobile_reg_content">
                    Work on live project and get hands on with our industry
                    expert
                  </div>
                </div>
              </div>
              <div id="mobile_reg_path_bg">
                <div id="mobile_reg_bg">
                  <img src={interview_icon} alt="" />
                </div>
                <div id="mobile_reg_text">
                  Interview & Job Ready
                  <div id="mobile_reg_content">
                    Get industry ready with mock interviews & Get hired in the
                    dream job by our top clients
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NyinstPath;
