import React from "react";
import pagenotfound from "../Assets/page_not_found_flying_man.svg";
import Header from "./Header";
import notfound_bg from "../Assets/page_not_found_web_bg.svg";
import not_found_bg_tab from "../Assets/page_not_found_ipad_bg.svg";

function PageNotFound() {
  return (
    <div>
      <div
       style={
        window.innerWidth > 993
          ? {
              background: `url(${notfound_bg})`,
              height: "100vh",
              backgroundSize: "cover",
            }
          : {
              background: `url(${not_found_bg_tab})`,
              height: "100vh",
              backgroundSize: "cover",
            }
      }
        className="d-flex flex-column"
      >
        <Header />
        <div className="m-auto">
          <div>
            {" "}
            <img src={pagenotfound} alt="" width="100%"/>
          </div>

          {window.innerWidth > 667 ? (
              <div align="center"
                className="mt-3"
                style={{
                  color: "white",
                  fontSize: "50px",
                  fontWeight: "900",
                }}
              >
                PAGE NOT FOUND
              </div>
            ) : (
              <div align="center"
                className="mt-3"
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "900",
                }}
              >
                PAGE NOT FOUND
              </div>
            )}
          <div className="mt-3" align="center">
            <a href="/" className="text-decoration-none">
              {" "}
              <div
                className="btn mt-3"
                style={{
                  color: "black",
                  background: "white",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  padding: "12px 40px 12px 40px",
                  height: "44px",
                  width: "175px",
                }}
              >
                Go to Home
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
