import React, { useState } from "react";
import Header from "./Header";
import "../Styles/ContactUs.css";
import Footer from "./Footer";
import axios from "axios";
import qs from "qs";
import success_tick from "../Assets/success_tick_img.png";
import { PulseLoader } from "react-spinners";
import msg_icon from "../Assets/msg_icon.png";
function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState("");
  const [status, setStatus] = useState();
  const reqData = {
    mod: "Candidate",
    actionType: "user-enquiry",
    subAction: JSON.stringify({
      FULL_NAME: name,
      PHONE_NUMBER: phone,
      EMAIL: email,
      MESSAGE: message,
    }),
    // EMAIL: mail,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "datsa");
      setLoading(false);
      setStatus(res.data.XSCStatus);
      if (res.data.XSCStatus === 0) {
        setName("");
        setPhone("");
        setEmail("");
        setMessage("");
      }
    });
  };
  return (
    <div>
      <Header />
      <div
        id="contact_us_bg"
        className="position-relative d-lg-block d-none z-0"
      >
        <div id="contact_title">
          <div id="msg_icon_left">
            <img src={msg_icon} alt="" />
          </div>
          <div align="center">
            <div>Get In Touch</div>
            <div id="contact_sub_topic">
              If you have any questions, comments, or concerns, please feel free
              to reach out to us <br /> through our email address or call us.
            </div>
          </div>
          <div id="msg_icon_right">
            <img src={msg_icon} alt="" />
          </div>
          <div className="container">
            <div className="row" id="address_bg">
              <div className="col-6" align="left">
                <div id="bangalore_bg" className="">
                  <div id="text_bg">
                    <div id="bl_text">
                      Bangalore <div id="off_text">Office </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="contact_enq_point"
                  >
                    <div id="con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For Program Enquiry
                      <div id="off_mob">
                        <a
                          href="tel:+91 9364 106 998"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 9364 106 998
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="contact_enq_point"
                  >
                    <div id="con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16H19.9381C19.446 19.9463 16.0796 23 12 23V21C15.3137 21 18 18.3137 18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V16H3C1.89543 16 1 15.1046 1 14V10C1 8.89543 1.89543 8 3 8H4.06189C4.55399 4.05369 7.92038 1 12 1C16.0796 1 19.446 4.05369 19.9381 8H21ZM7.75944 15.7849L8.81958 14.0887C9.74161 14.6662 10.8318 15 12 15C13.1682 15 14.2584 14.6662 15.1804 14.0887L16.2406 15.7849C15.0112 16.5549 13.5576 17 12 17C10.4424 17 8.98882 16.5549 7.75944 15.7849Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For General Enquiry
                      <div id="off_mob">
                        <a
                          href="mailto:+info@nyinst.com"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          info@nyinst.com
                        </a>
                        /
                        <a
                          href="tel:+91 9364 106 998"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 9364 106 998
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="contact_enq_point"
                  >
                    <div id="con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      Address
                      <div id="off_mob">
                        No.1 Pine Hurst, 100 Feet Ring Rd, BTM 2nd Stage, BTM
                        Layout, Bengaluru, Karnataka 560076
                      </div>
                    </div>
                  </div>{" "}
                  <a
                    href="https://maps.app.goo.gl/NgpjLLSpnVttquVEA"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    {" "}
                    <div id="google_map_link">
                      Locate In Google Maps
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-6" align="left">
                <div id="bangalore_bg">
                  <div id="text_bg">
                    <div id="bl_text">
                      Chennai <div id="off_text">Office </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="contact_enq_point"
                  >
                    <div id="con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For Program Enquiry
                      <div id="off_mob">
                        {" "}
                        <a
                          href="tel:+91 7550 290 888"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 7550 290 888
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="contact_enq_point"
                  >
                    <div id="con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16H19.9381C19.446 19.9463 16.0796 23 12 23V21C15.3137 21 18 18.3137 18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V16H3C1.89543 16 1 15.1046 1 14V10C1 8.89543 1.89543 8 3 8H4.06189C4.55399 4.05369 7.92038 1 12 1C16.0796 1 19.446 4.05369 19.9381 8H21ZM7.75944 15.7849L8.81958 14.0887C9.74161 14.6662 10.8318 15 12 15C13.1682 15 14.2584 14.6662 15.1804 14.0887L16.2406 15.7849C15.0112 16.5549 13.5576 17 12 17C10.4424 17 8.98882 16.5549 7.75944 15.7849Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For General Enquiry
                      <div id="off_mob">
                        {" "}
                        <a
                          href="mailto:+info@nyinst.com"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          info@nyinst.com
                        </a>
                        /
                        <a
                          href="tel:+91 9364 106 998"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 9364 106 998
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="contact_enq_point"
                  >
                    <div id="con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      Address
                      <div id="off_mob">
                        Prince Info Park B-Block, 5th Floor 81B, 2nd Main Road,
                        Ambattur Industrial Estate Chennai - 600058
                      </div>
                    </div>
                  </div>{" "}
                  <a
                    href="https://maps.app.goo.gl/MDjorsEpAj6jTYc37"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    {" "}
                    <div id="google_map_link">
                      Locate In Google Maps
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-12 position-relative" align="left" >
                <div id="bangalore_bg" className="position-relative">
                  <div id="text_bg">
                    <div id="bl_text">
                      Belagavi <div id="off_text">Office </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="contact_enq_point"
                  >
                    <div id="con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For Program Enquiry
                      <div id="off_mob">
                        <a
                          href="tel:+91 93641 06991"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 93641 06991
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="contact_enq_point"
                  >
                    <div id="con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16H19.9381C19.446 19.9463 16.0796 23 12 23V21C15.3137 21 18 18.3137 18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V16H3C1.89543 16 1 15.1046 1 14V10C1 8.89543 1.89543 8 3 8H4.06189C4.55399 4.05369 7.92038 1 12 1C16.0796 1 19.446 4.05369 19.9381 8H21ZM7.75944 15.7849L8.81958 14.0887C9.74161 14.6662 10.8318 15 12 15C13.1682 15 14.2584 14.6662 15.1804 14.0887L16.2406 15.7849C15.0112 16.5549 13.5576 17 12 17C10.4424 17 8.98882 16.5549 7.75944 15.7849Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For General Enquiry
                      <div id="off_mob">
                        <a
                          href="mailto:+info@nyinst.com"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          info@nyinst.com
                        </a>
                        /
                        <a
                          href="tel:+91 93641 06991"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                         +91 93641 06991
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="contact_enq_point"
                  >
                    <div id="con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      Address
                      <div id="off_mob">
                        Visvesvaraya Technological University, Machhe, Belagavi,
                        Karnataka 590018
                      </div>
                    </div>
                  </div>{" "}
                  <a
                    href="https://maps.app.goo.gl/YQ2aA5wZyFMjTzg8A"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    {" "}
                    <div id="google_map_link">
                      Locate In Google Maps
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute top-100  w-100 z-0"
          id="contact_form_bg"
        >
          <div id="contact_form" className="z-0">
            <div id="drop_mess">Drop us a message</div>
            <form id="contact_bg" align="left" onSubmit={handleSubmit}>
              <div>
                <label>Full Name</label>
                <br />
                <input
                  required
                  placeholder="Enter Your Full Name"
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value.trimStart())}
                />
              </div>
              <div>
                <label>Email</label>
                <br />
                <input
                  required
                  placeholder="Enter Your Email"
                  type="email"
                  className={
                    email.trim() !== "" ? "has_value px-2" : "no_value px-2"
                  }
                  onChange={(e) =>
                    setEmail(e.target.value.toLowerCase().trimStart())
                  }
                  value={email}
                />
              </div>
              <div>
                <label>Phone Number</label>
                <br />
                <input
                  required
                  placeholder="Enter Your Phone Number"
                  type="tel"
                  maxLength={10}
                  pattern="[0-9]{10}"
                  onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))}
                  value={phone}
                />
              </div>
              <div>
                <label>Message</label>
                <br />
                <textarea
                  required
                  placeholder="Enter Your Message"
                  typeof="text"
                  onChange={(e) => setMessage(e.target.value.trimStart())}
                  value={message}
                />
              </div>
              {status === 0 ? (
                <button
                  id="contact_submit"
                  className="btn"
                  type="submit"
                  style={{
                    // height: "3.25rem",
                    background: "#02EE6A14",
                    border: "2px solid #02EE6A",
                    color: "#02EE6A",
                    // fontSize: "0.8rem",
                    // lineHeight: "2rem",
                  }}
                >
                  <img
                    src={success_tick}
                    alt=""
                    style={{ height: "20px", width: "20px" }}
                  />{" "}
                  Thanks For Submitting!
                </button>
              ) : (
                <button id="contact_submit" className="btn" type="submit">
                  Submit {loading ? <PulseLoader color="white" size={6} /> : ""}
                </button>
              )}
            </form>
          </div>
          <div id="footer">
            <Footer />
          </div>
        </div>
      </div>
      {/* for mobiles */}
      <div
        id="mobile_contact_us_bg"
        className="position-relative d-lg-none d-block"
      >
        <div id="mob_msg_icon">
          <img src={msg_icon} alt="" />
        </div>
        <div id="top_mob_msg_icon">
          <img src={msg_icon} alt="" />
        </div>
        <div id="mobile_contact_title">
          <div>Get In Touch</div>
          <div id="mobile_contact_sub_topic">
            If you have any questions, comments, or concerns, please feel free
            to reach out to us <br /> through our email address or call us.
          </div>
          <div className="container">
            <div className="row" id="mobile_address_bg">
              <div className="col-md-6" align="left">
                <div id="mobile_bangalore_bg" className="position-relative z-4">
                  <div id="mobile_text_bg">
                    <div id="mobile_bl_text">
                      Bangalore <div id="mobile_off_text">Office </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="mobile_contact_enq_point"
                  >
                    <div id="mobile_con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For Program Enquiry
                      <div id="mobile_off_mob">
                        {" "}
                        <a
                          href="tel:+91 9364 106 998"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 9364 106 998
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="mobile_contact_enq_point"
                  >
                    <div id="mobile_con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16H19.9381C19.446 19.9463 16.0796 23 12 23V21C15.3137 21 18 18.3137 18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V16H3C1.89543 16 1 15.1046 1 14V10C1 8.89543 1.89543 8 3 8H4.06189C4.55399 4.05369 7.92038 1 12 1C16.0796 1 19.446 4.05369 19.9381 8H21ZM7.75944 15.7849L8.81958 14.0887C9.74161 14.6662 10.8318 15 12 15C13.1682 15 14.2584 14.6662 15.1804 14.0887L16.2406 15.7849C15.0112 16.5549 13.5576 17 12 17C10.4424 17 8.98882 16.5549 7.75944 15.7849Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For General Enquiry
                      <div id="mobile_off_mob">
                        <a
                          href="mailto:+info@nyinst.com"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          info@nyinst.com
                        </a>
                        /
                        <a
                          href="tel:+91 9364 106 998"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 9364 106 998
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="mobile_contact_enq_point"
                  >
                    <div id="mobile_con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      Address
                      <div id="mobile_off_mob">
                        No.1 Pine Hurst, 100 Feet Ring Rd, BTM 2nd Stage, BTM
                        Layout, Bengaluru, Karnataka 560076
                      </div>
                    </div>
                  </div>{" "}
                  <a
                    href="https://maps.app.goo.gl/NgpjLLSpnVttquVEA"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    {" "}
                    <div id="mobile_google_map_link">
                      Locate In Google Maps
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-6" align="left">
                <div id="mobile_chennai_bg">
                  <div id="mobile_text_bg">
                    <div id="mobile_bl_text">
                      Chennai <div id="mobile_off_text">Office </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="mobile_contact_enq_point"
                  >
                    <div id="mobile_con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For Program Enquiry
                      <div id="mobile_off_mob">
                        {" "}
                        <a
                          href="tel:+91 7550 290 888"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 7550 290 888
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="mobile_contact_enq_point"
                  >
                    <div id="mobile_con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16H19.9381C19.446 19.9463 16.0796 23 12 23V21C15.3137 21 18 18.3137 18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V16H3C1.89543 16 1 15.1046 1 14V10C1 8.89543 1.89543 8 3 8H4.06189C4.55399 4.05369 7.92038 1 12 1C16.0796 1 19.446 4.05369 19.9381 8H21ZM7.75944 15.7849L8.81958 14.0887C9.74161 14.6662 10.8318 15 12 15C13.1682 15 14.2584 14.6662 15.1804 14.0887L16.2406 15.7849C15.0112 16.5549 13.5576 17 12 17C10.4424 17 8.98882 16.5549 7.75944 15.7849Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For General Enquiry
                      <div id="mobile_off_mob">
                        <a
                          href="mailto:+info@nyinst.com"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          info@nyinst.com
                        </a>
                        /
                        <a
                          href="tel:+91 9364 106 998"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 9364 106 998
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="mobile_contact_enq_point"
                  >
                    <div id="mobile_con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      Address
                      <div id="mobile_off_mob">
                        Prince Info Park B-Block, 5th Floor 81B, 2nd Main Road,
                        Ambattur Industrial Estate Chennai - 600058
                      </div>
                    </div>
                  </div>{" "}
                  <a
                    href="https://maps.app.goo.gl/MDjorsEpAj6jTYc37"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    {" "}
                    <div id="mobile_google_map_link">
                      Locate In Google Maps
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-6" align="left">
                <div id="mobile_chennai_bg">
                  <div id="mobile_text_bg">
                    <div id="mobile_bl_text">
                      Belagavi <div id="mobile_off_text">Office </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="mobile_contact_enq_point"
                  >
                    <div id="mobile_con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For Program Enquiry
                      <div id="mobile_off_mob">
                        {" "}
                        <a
                          href="tel:+91 93641 06991"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          +91 93641 06991
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="mobile_contact_enq_point"
                  >
                    <div id="mobile_con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16H19.9381C19.446 19.9463 16.0796 23 12 23V21C15.3137 21 18 18.3137 18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V16H3C1.89543 16 1 15.1046 1 14V10C1 8.89543 1.89543 8 3 8H4.06189C4.55399 4.05369 7.92038 1 12 1C16.0796 1 19.446 4.05369 19.9381 8H21ZM7.75944 15.7849L8.81958 14.0887C9.74161 14.6662 10.8318 15 12 15C13.1682 15 14.2584 14.6662 15.1804 14.0887L16.2406 15.7849C15.0112 16.5549 13.5576 17 12 17C10.4424 17 8.98882 16.5549 7.75944 15.7849Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      For General Enquiry
                      <div id="mobile_off_mob">
                        <a
                          href="mailto:+info@nyinst.com"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          info@nyinst.com
                        </a>
                        /
                        <a
                          href="tel: +91 93641 06991"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                           +91 93641 06991
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    id="mobile_contact_enq_point"
                  >
                    <div id="mobile_con_img_bg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                    <div>
                      Address
                      <div id="mobile_off_mob">
                      Visvesvaraya Technological University, Machhe, Belagavi, Karnataka 590018
                      </div>
                    </div>
                  </div>{" "}
                  <a
                    href="https://maps.app.goo.gl/YQ2aA5wZyFMjTzg8A"
                    className="text-decoration-none"
                    target="_blank"
                  >
                    {" "}
                    <div id="mobile_google_map_link">
                      Locate In Google Maps
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.0052 9.41421L7.39858 18.0208L5.98438 16.6066L14.591 8H7.00519V6H18.0052V17H16.0052V9.41421Z"
                          fill="#006BFF"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute top-100  w-100 "
          id="mobile_contact_form_bg"
        >
          <div id="mobile_contact_form">
            <div id="mobile_drop_mess">Drop us a Message</div>
            <form id="mobile_contact_bg" align="left" onSubmit={handleSubmit}>
              <div>
                <label>Full Name</label>
                <br />
                <input
                  required
                  placeholder="Enter Your Full Name"
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value.trimStart())}
                />
              </div>
              <div>
                <label>Email</label>
                <br />
                <input
                  required
                  placeholder="Enter Your Email"
                  type="email"
                  className={email.trim() !== "" ? "has_value" : "no_value"}
                  onChange={(e) =>
                    setEmail(e.target.value.toLowerCase().trimStart())
                  }
                  value={email}
                />
              </div>
              <div>
                <label>Phone Number</label>
                <br />
                <input
                  required
                  placeholder="Enter Your Phone Number"
                  type="tel"
                  maxLength={10}
                  pattern="[0-9]{10}"
                  onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))}
                  value={phone}
                />
              </div>
              <div>
                <label>Message</label>
                <br />
                <textarea
                  required
                  placeholder="Enter Your Message"
                  typeof="text"
                  onChange={(e) => setMessage(e.target.value.trimStart())}
                  value={message}
                />
              </div>
              {status === 0 ? (
                <button
                  id="mobile_contact_submit"
                  className="btn"
                  type="submit"
                  style={{
                    // height: "3.25rem",
                    background: "#02EE6A14",
                    border: "2px solid #02EE6A",
                    color: "#02EE6A",
                    // fontSize: "0.8rem",
                    // lineHeight: "2rem",
                  }}
                >
                  <img
                    src={success_tick}
                    alt=""
                    style={{ height: "20px", width: "20px" }}
                  />{" "}
                  Thanks For Submitting!
                </button>
              ) : (
                <button
                  id="mobile_contact_submit"
                  className="btn"
                  type="submit"
                >
                  Submit {loading ? <PulseLoader color="white" size={6} /> : ""}
                </button>
              )}
            </form>
          </div>
          <div id="mobile_footer">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
