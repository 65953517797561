import React, { useEffect, useRef } from "react";
import "../Styles/TechiePro/Certificate.css";
import certificate_img from "../Assets/techiepro/certificate.png";
// import certificate_img from "../Assets/techiepro/certificate_sample.png";
import mobile_certificate from "../Assets/techiepro/mobile_certificate.png"
function Certificate({menu}) {
  useEffect(() => {
    // console.log("Active ref in TechieHeroSection:", menu);
  }, [menu]);
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "/techie-pro#certificate") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  return (
    <div id="certificate">
      <div id="certificate_bg"  className="d-lg-block d-none">
        <div className="container d-lg-block d-none">
          <div id="certificate_title_bg" className="position-relative">
            <div id="certificate_title_text">
              Get Your
              <div id="pro_cert_text"> Experience Letter</div>
              <div id="cert_img" className="position-absolute bottom-0">
                <img src={certificate_img} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
 
      </div>
      {/* for mobiles */}
      <div id="certificate_bg" className="d-block d-lg-none ">
 
     
        <div className="container d-lg-none d-block d-md-none">
          <div id="mobile_certificate_title_bg" className="position-relative">
            <div id="mobile_certificate_title_text">
              Get Your
              <div id="mobile_pro_cert_text"> Experience Letter</div>
              <div id="mobile_cert_img" className="position-absolute bottom-0">
                <img src={certificate_img} alt="" width="100%" />
              </div>
            </div>
          </div>
       
        </div>    {/* for ipad */}
          <div id="mobile_certificate_title_bg" className="position-relative d-lg-none d-none d-md-block" style={{height:"32.5rem"}}>
            <div id="mobile_certificate_title_text">
              Get Your
              <div id="mobile_pro_cert_text"> Experience Letter</div>
              <div id="mobile_cert_img" className="position-absolute bottom-0">
                <img src={certificate_img} alt="" width="100%" />
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}
export default Certificate;
