import React, { useRef, useEffect } from "react";
import "../Styles/TechiePro/TechStack.css";
import stack1 from "../Assets/techiepro/tech_stack1.png";
import stack2 from "../Assets/techiepro/tech_stack2.png";
import stack3 from "../Assets/techiepro/tech_stack3.png";
import stack4 from "../Assets/techiepro/tech_stack4.png";
import stack5 from "../Assets/techiepro/tech_stack5.png";
import stack6 from "../Assets/techiepro/tech_stack6.png";
import stack7 from "../Assets/techiepro/tech_stack7.png";
import stack8 from "../Assets/techiepro/tech_stack8.png";
import stack9 from "../Assets/techiepro/tech_stack9.png";
import full_stack_web from "../Assets/techienerds/full_stack_web.png";
import full_stack_mobile from "../Assets/techienerds/full_stack_mobile.png";
function TechStack({ menu }) {
  useEffect(() => {
    // console.log("Active ref in TechieHeroSection:", menu);
  }, [menu]);
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "/techie-pro#techstack") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  return (
    <div id="techstack">
      {" "}
      <div id="tech_stack_bg">
        <div className="container d-lg-block d-none">
          <div id="tech_stack_topic">A Curated set of</div>
          <div id="tech_stack_title">Tech Stack</div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div id="stack_point_bg">
                <div>
                  <img src={full_stack_web} alt="" width="100%" />
                </div>
                <div id="stack_detail">
                  <div id="stack_heading">Full Stack Web</div>
                  <div id="stack_content" className="three_line_clamp">
                    The purpose of creating a full-stack web application using
                    technologies like React, PHP, and MySQL is to build a
                    dynamic and interactive website that can perform various
                    functions and provide a seamless user experience.
                  </div>
                  {/* <div id="download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2 12.6666H14V13.9999H2V12.6666ZM8.66667 8.78098L12.7141 4.73359L13.6569 5.6764L8 11.3333L2.34315 5.6764L3.28595 4.73359L7.33333 8.78098V1.33325H8.66667V8.78098Z"
                        fill="#E25234"
                      />
                    </svg>
                    <span className="ps-1">Download Curriculam</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div id="stack_point_bg">
                <div>
                  <img src={full_stack_mobile} alt="" width="100%" />
                </div>
                <div id="stack_detail">
                  <div id="stack_heading">Full Stack Mobile</div>
                  <div id="stack_content" className="three_line_clamp">
                     Creating mobile applications for Android, iOS, or cross-platform with Flutter, delivering seamless user experiences.
                  </div>
                  {/* <div id="download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2 12.6666H14V13.9999H2V12.6666ZM8.66667 8.78098L12.7141 4.73359L13.6569 5.6764L8 11.3333L2.34315 5.6764L3.28595 4.73359L7.33333 8.78098V1.33325H8.66667V8.78098Z"
                        fill="#E25234"
                      />
                    </svg>
                    <span className="ps-1">Download Curriculam</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div id="stack_point_bg">
                <div>
                  <img src={stack6} alt="" width="100%" />
                </div>
                <div id="stack_detail">
                  <div id="stack_heading">QA Automation Testing</div>
                  <div id="stack_content" className="three_line_clamp">
                    Manages server-side operations, database interactions, and
                    application logic to support frontend functionality in
                    software development
                  </div>
                  {/* <div id="download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2 12.6666H14V13.9999H2V12.6666ZM8.66667 8.78098L12.7141 4.73359L13.6569 5.6764L8 11.3333L2.34315 5.6764L3.28595 4.73359L7.33333 8.78098V1.33325H8.66667V8.78098Z"
                        fill="#E25234"
                      />
                    </svg>
                    <span className="ps-1">Download Curriculam</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <div id="stack_point_bg">
                <div>
                  <img src={stack4} alt="" width="100%" />
                </div>
                <div id="stack_detail">
                  <div id="stack_heading">Data Analyst</div>
                  <div id="stack_content" className="three_line_clamp">
                    Analyzes, interprets, and visualizes data to provide
                    insights and support informed decision-making in
                    organizations
                  </div>
                  {/* <div id="download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2 12.6666H14V13.9999H2V12.6666ZM8.66667 8.78098L12.7141 4.73359L13.6569 5.6764L8 11.3333L2.34315 5.6764L3.28595 4.73359L7.33333 8.78098V1.33325H8.66667V8.78098Z"
                        fill="#E25234"
                      />
                    </svg>
                    <span className="ps-1">Download Curriculam</span>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div id="stack_point_bg">
                <div>
                  <img src={stack5} alt="" width="100%" />
                </div>
                <div id="stack_detail">
                  <div id="stack_heading">Data Science</div>
                  <div id="stack_content" className="three_line_clamp">
                    Extracts knowledge and insights from data using statistical,
                    mathematical, and computational methods for decision-making
                  </div>
                  {/* <div id="download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2 12.6666H14V13.9999H2V12.6666ZM8.66667 8.78098L12.7141 4.73359L13.6569 5.6764L8 11.3333L2.34315 5.6764L3.28595 4.73359L7.33333 8.78098V1.33325H8.66667V8.78098Z"
                        fill="#E25234"
                      />
                    </svg>
                    <span className="ps-1">Download Curriculam</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        {/* for mobiles */}
        <div className="container d-lg-none d-block">
          <div id="mobile_tech_stack_topic">A Curated set of</div>
          <div id="mobile_tech_stack_title">Tech Stack</div>
          <div className="row">
            <div className="col-md-6">
              <div id="mobile_stack_point_bg">
                <div>
                  <img src={full_stack_web} alt="" width="100%" />
                </div>
                <div id="mobile_stack_detail">
                  <div id="mobile_stack_heading">Full Stack Web</div>
                  <div id="mobile_stack_content"  className="three_line_clamp">
                    The purpose of creating a full-stack web application using
                    technologies like React, PHP, and MySQL is to build a
                    dynamic and interactive website that can perform various
                    functions and provide a seamless user experience.
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div id="mobile_stack_point_bg">
                <div>
                  <img src={full_stack_mobile} alt="" width="100%" />
                </div>
                <div id="mobile_stack_detail">
                  <div id="mobile_stack_heading">Full Stack Mobile</div>
                  <div id="mobile_stack_content">
                  Creating mobile applications for Android, iOS, or cross-platform with Flutter, delivering seamless user experiences.
                  </div>
                  {/* <div id="mobile_download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2 12.6666H14V13.9999H2V12.6666ZM8.66667 8.78098L12.7141 4.73359L13.6569 5.6764L8 11.3333L2.34315 5.6764L3.28595 4.73359L7.33333 8.78098V1.33325H8.66667V8.78098Z"
                        fill="#E25234"
                      />
                    </svg>
                    <span className="ps-1">Download Curriculam</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div id="mobile_stack_point_bg">
                <div>
                  <img src={stack6} alt="" width="100%" />
                </div>
                <div id="mobile_stack_detail">
                  <div id="mobile_stack_heading">QA Automation Testing</div>
                  <div id="mobile_stack_content">
                    Manages server-side operations, database interactions, and
                    application logic to support frontend functionality in
                    software development
                  </div>
                  {/* <div id="mobile_download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2 12.6666H14V13.9999H2V12.6666ZM8.66667 8.78098L12.7141 4.73359L13.6569 5.6764L8 11.3333L2.34315 5.6764L3.28595 4.73359L7.33333 8.78098V1.33325H8.66667V8.78098Z"
                        fill="#E25234"
                      />
                    </svg>
                    <span className="ps-1">Download Curriculam</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div id="mobile_stack_point_bg">
                <div>
                  <img src={stack4} alt="" width="100%" />
                </div>
                <div id="mobile_stack_detail">
                  <div id="mobile_stack_heading">Data Analyst</div>
                  <div id="mobile_stack_content">
                    Analyzes, interprets, and visualizes data to provide
                    insights and support informed decision-making in
                    organizations
                  </div>
                  {/* <div id="mobile_download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2 12.6666H14V13.9999H2V12.6666ZM8.66667 8.78098L12.7141 4.73359L13.6569 5.6764L8 11.3333L2.34315 5.6764L3.28595 4.73359L7.33333 8.78098V1.33325H8.66667V8.78098Z"
                        fill="#E25234"
                      />
                    </svg>
                    <span className="ps-1">Download Curriculam</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div id="mobile_stack_point_bg">
                <div>
                  <img src={stack5} alt="" width="100%" />
                </div>
                <div id="mobile_stack_detail">
                  <div id="mobile_stack_heading">Data Science</div>
                  <div id="mobile_stack_content">
                    Extracts knowledge and insights from data using statistical,
                    mathematical, and computational methods for decision-making
                  </div>
                  {/* <div id="mobile_download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2 12.6666H14V13.9999H2V12.6666ZM8.66667 8.78098L12.7141 4.73359L13.6569 5.6764L8 11.3333L2.34315 5.6764L3.28595 4.73359L7.33333 8.78098V1.33325H8.66667V8.78098Z"
                        fill="#E25234"
                      />
                    </svg>
                    <span className="ps-1">Download Curriculam</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TechStack;
