import React, { useRef, useEffect, useState } from "react";
import "../Styles/TechiePro/ProgramOverview.css";
import in_30 from "../Assets/techieintern/in_30.png";
import in_60 from "../Assets/techieintern/in_60.png";
import in_90 from "../Assets/techieintern/in_90.png";
import in_180 from "../Assets/techieintern/in_180.png";

function ProgramOverview({ menu }) {
  useEffect(() => {
    // console.log("Active ref in TechieHeroSection:", menu);
  }, [menu]);
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "/techie-pro#pricing") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  const [scrollPosition, setScrollPosition] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 0 ? setScrollPosition("sticky") : setScrollPosition("");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div id="pricing" ref={myRef}>
      <div className="container d-lg-block d-none">
        <div className=" d-lg-block d-none">
          <div id="program_pricing_title">Pricing</div>
          <div id="po_title">Internship Program Overview</div>

          <div className="row position-relative" id="po_bg">
            <div className="col-md-4 " style={{ width: "78.8rem" }}>
              <div id="po_project_detail_bg">
                <div id="po_single_project_detail_bg">
                  <div>Live Project (OnSite)</div>
                </div>
                <div
                  id="po_single_project_detail_bg"
                  style={{ background: "none" }}
                >
                  <div className=""> Capstone Projects</div>
                </div>
                <div id="po_single_project_detail_bg">
                  <div>Duration</div>
                </div>
                <div
                  id="po_single_project_detail_bg"
                  style={{ background: "none" }}
                >
                  <div>Schedule</div>
                </div>
                <div id="po_single_project_detail_bg">
                  <div>In-Person Mentorship</div>
                </div>
                <div
                  id="po_single_project_detail_bg"
                  style={{ background: "none" }}
                >
                  <div>Professional Experience Letter</div>
                </div>
                <div id="po_single_project_detail_bg">
                  <div>Internship Certificate</div>
                </div>
                <div
                  id="po_single_project_detail_bg"
                  style={{ background: "none" }}
                >
                  <div>Soft Skilling</div>
                </div>
                <div id="po_single_project_detail_bg">
                  <div>Interview Preparation</div>
                </div>
              </div>
            </div>
            <div
              className="col-md-8 position-absolute"
              id="price_detail_bg"
                // style={{ marginLeft: "23.88rem" }}
            >
              <div className="d-md-flex align-items-center w-100 me-0 pe-0 justify-content-between ">
                <div
                  style={{
                    border: "2px solid #F5F6F7",
                    width: "10.5rem",
                    height: "56.25rem",
                    border: "3px solid #006BFF",
                    marginLeft:"0rem"
                  }}
                  id="po_pricing_detail_bg"
                  className="position-relative"
                  align="center"
                
                >
                  <div
                    id="recommended"
                    className="translate-middle"
                    style={{
                      background: "#006BFF",
                    }}
                  >
                    Recommended
                  </div>
                  <div id="po_img_bg">
                    {" "}
                    <img src={in_30} alt="" width="100%" />
                  </div>
                  {/* <div id="program_price_bg">
                    <div id="program_price">₹25,000</div>
                    <div id="gst_text">+GST</div>
                  </div> */}
                  <div id="po_single_point" style={{ marginTop: "3.87rem" }}>
                    Exposure
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">30 Days</div>
                  <div id="po_single_point">2 Hours / day (M-F)</div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                        fill="#9C9DA1"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  {/* <div id="po_apply_now_button" className="btn w-100">
                    <button>Apply now</button>
                  </div> */}
                  <div id="price">
                    ₹7,500
                    <span id="star" align="top">
                      *
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    border: "2px solid #F5F6F7",
                    width: "10.5rem",
                    height: "56.25rem",marginLeft:"0rem"
                  }}
                  id="po_pricing_detail_bg"
                  className="position-relative"
                  align="center"
                >
                  <div id="po_img_bg">
                    {" "}
                    <img src={in_60} alt="" width="100%" />
                  </div>
                  {/* <div id="program_price_bg">
                    <div id="program_price">₹25,000</div>
                    <div id="gst_text">+GST</div>
                  </div> */}
                  <div id="po_single_point" style={{ marginTop: "3.87rem" }}>
                    Exposure
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">60 Days</div>
                  <div id="po_single_point">2 Hours / day (M-F)</div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                        fill="#9C9DA1"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  {/* <div id="po_apply_now_button" className="btn w-100">
                    <button>Apply now</button>
                  </div> */}
                  <div id="price">
                    ₹12,500
                    <span id="star" align="top">
                      *
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    border: "2px solid #F5F6F7",
                    width: "10.5rem",
                    height: "56.25rem",marginLeft:"0rem"
                  }}
                  id="po_pricing_detail_bg"
                  className="position-relative"
                  align="center"
                >
                  <div id="po_img_bg">
                    {" "}
                    <img src={in_90} alt="" width="100%" />
                  </div>
                  {/* <div id="program_price_bg">
                    <div id="program_price">₹25,000</div>
                    <div id="gst_text">+GST</div>
                  </div> */}
                  <div id="po_single_point" style={{ marginTop: "3.87rem" }}>
                    Exposure
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">90 Days</div>
                  <div id="po_single_point">2 Hours / day (M-F)</div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                        fill="#9C9DA1"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">Extensive</div>
                  {/* <div id="po_apply_now_button" className="btn w-100">
                    <button>Apply now</button>
                  </div> */}
                  <div id="price">
                    ₹18,000
                    <span id="star" align="top">
                      *
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    border: "2px solid #F5F6F7",
                    width: "10.5rem",
                    height: "56.25rem",marginLeft:"0rem"
                  }}
                  id="po_pricing_detail_bg"
                  className="position-relative"
                  align="center"
                >
                  <div id="po_img_bg">
                    {" "}
                    <img src={in_180} alt="" width="100%" />
                  </div>
                  {/* <div id="program_price_bg">
                    <div id="program_price">₹25,000</div>
                    <div id="gst_text">+GST</div>
                  </div> */}
                  <div id="po_single_point" style={{ marginTop: "3.87rem" }}>
                    Exposure
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">180 Days</div>
                  <div id="po_single_point">2 Hours / day (M-F)</div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                        fill="#9C9DA1"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                        fill="#065BD6"
                      />
                    </svg>
                  </div>
                  <div id="po_single_point">Extensive</div>
                  {/* <div id="po_apply_now_button" className="btn w-100">
                    <button>Apply now</button>
                  </div> */}
                  <div id="price">
                    ₹30,000
                    <span id="star" align="top">
                      *
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div id="gst" align="end">
              *GST Applicable
            </div>
          </div>
        </div>
      </div>

      {/* for mobiles */}
      <div className="d-lg-none d-block">
        <div className="container ">
          <div id="mobile_pricing">Pricing</div>
          <div id="mobile_overview_text">Internship Program Overview</div>
          <div
            className="row"
            id="mobile_price_bg"
            style={{
              position: scrollPosition,
              top: "0",
              width: "100%",
              backgroundColor: "white",
              position: "-webkit-sticky",
              zIndex: "2",
            }}
          >
            <div className="col-3 position-relative" align="center">
              <div
                style={{
                  background: "#006BFF",
                  width: "4.185rem",
                  fontSize: "0.5rem",
                }}
                id="mobile_recomm"
                className="position-absolute  translate-middle start-50 ms-2"
              >
                Recommended
              </div>
              <div
                id="mobile_po_bg"
                align="center"
                style={{ width: "4.785rem", border: "1px solid #006BFF" }}
              >
                <div id="mobile_po_img_bg">
                  <img src={in_30} alt="" width="100%" />
                </div>
              </div>
            </div>
            <div className="col-3 position-relative" align="center">
              <div
                id="mobile_po_bg" 
                align="center"
                style={{ width: "4.785rem" }}
              >
                <div id="mobile_po_img_bg">
                  <img src={in_60} alt="" width="100%" />
                </div>
              </div>
            </div>
            <div className="col-3" align="center">
              <div
                id="mobile_po_bg"
                align="center"
                style={{ width: "4.785rem" }}
              >
                <div id="mobile_po_img_bg">
                  <img src={in_90} alt="" width="100%" />
                </div>
              </div>
            </div>{" "}
            <div className="col-3" align="center">
              <div
                id="mobile_po_bg"
                align="center"
                style={{ width: "4.785rem" }}
              >
                <div id="mobile_po_img_bg">
                  <img src={in_180} alt="" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Live Project (OnSite)</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container"> Exposure</div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container"> Exposure</div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container"> Extensive</div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container"> Extensive</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Capstone Projects</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66843 10.1141L12.7967 3.98584L13.7395 4.92865L6.66843 11.9997L2.42578 7.75709L3.36859 6.81429L6.66843 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66843 10.1141L12.7967 3.98584L13.7395 4.92865L6.66843 11.9997L2.42578 7.75709L3.36859 6.81429L6.66843 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66843 10.1141L12.7967 3.98584L13.7395 4.92865L6.66843 11.9997L2.42578 7.75709L3.36859 6.81429L6.66843 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66843 10.1141L12.7967 3.98584L13.7395 4.92865L6.66843 11.9997L2.42578 7.75709L3.36859 6.81429L6.66843 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Duration</div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">30 Days</div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">60 Days</div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container"> 90 Days</div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">180 Days</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Schedule</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  2 Hours /<br />
                  day(M-F)
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  2 Hours /<br />
                  day(M-F)
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  2 Hours /<br />
                  day(M-F)
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  2 Hours /<br />
                  day(M-F)
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">In-Person Mentorship</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">In-Person Mentorship</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.00047 7.05767L11.3003 3.75781L12.2431 4.70062L8.94327 8.00047L12.2431 11.3003L11.3003 12.2431L8.00047 8.94327L4.70062 12.2431L3.75781 11.3003L7.05767 8.00047L3.75781 4.70062L4.70062 3.75781L8.00047 7.05767Z"
                      fill="#9C9DA1"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.00047 7.05767L11.3003 3.75781L12.2431 4.70062L8.94327 8.00047L12.2431 11.3003L11.3003 12.2431L8.00047 8.94327L4.70062 12.2431L3.75781 11.3003L7.05767 8.00047L3.75781 4.70062L4.70062 3.75781L8.00047 7.05767Z"
                      fill="#9C9DA1"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Internship Certificate</div>
          </div>
          <div className="container">
            <div className="row ">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Soft skilling</div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Interview Preparation</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">Extensive</div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div className="container">Extensive</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #EBECEE" }}>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Pricing</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div
                  className="container"
                  style={{
                    fontSize: "0.8125rem",
                    fontWeight: "600",
                    lineHeight: "1.25rem", display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  ₹7,000*
                </div>
              </div>
              <div
                className="col-3"
                id="mobile_po_detail_bg"
                align="center"
              
              >
                <div
                  className="container"
                  style={{
                    fontSize: "0.8125rem",
                    fontWeight: "600",
                    lineHeight: "1.25rem",   display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  ₹12,500*
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div
                  className="container"
                  style={{
                    fontSize: "0.8125rem",
                    fontWeight: "600",
                    lineHeight: "1.25rem", display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  ₹18,000*
                </div>
              </div>
              <div className="col-3" id="mobile_po_detail_bg" align="center">
                <div
                  className="container"
                  style={{
                    fontSize: "0.8125rem",
                    fontWeight: "600",
                    lineHeight: "1.25rem", display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  ₹30,000*
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id=""
          align="end"
          style={{
            marginBottom: "3rem",
            marginTop: "0.8rem",
            fontSize: "0.625rem",
            fontWeight: "400",
            lineHeight: "1rem",
            color: "#79818D",
            marginRight: "1rem",
            fontFamily:"Inter"
          }}
        >
          *GST Applicable
        </div>
      </div>
    </div>
  );
}

export default ProgramOverview;
