import React from "react";
import Header from "../Pages/Header";
import HireHero from "./HireHeroSection";
import HowItWork from "./HowItWork";
import Stats from "./Stats";
import Roles from "./Roles";
import HireEnquiry from "./HireEnquiry";
import Footer from "../Pages/Footer";

function HireHomePage() {
  return (
    <div  className="position-relative"
    style={{ zIndex: "0", backgroundColor: "white" }}>
      <Header />
      <HireHero />
      <HowItWork />
      <Stats />
      <Roles />
      <HireEnquiry />
      <Footer />
    </div>
  );
}

export default HireHomePage;
