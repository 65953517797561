import React, { useState } from "react";
import HeroSection from "./HeroSection";
import BookCalendar from "./BookCalendar";
import OurPrograms from "./OurPrograms";
import StudPrograms from "./StudsPrograms";
import NyinstPath from "./NyinstPath";
import Ratings from "./Ratings";
import Reviews from "./Reviews";
import Webinars from "./Webinars";
import Clients from "./Clients";
import OurExpertise from "./OurExpertise";
import Events from "../Pages/EventsComponents/Events";
import Footer from "./Footer";
import Header from "./Header";

function Homepage({showStuds}) {
  // let menu = menu;
  console.log(showStuds,"hk");

  // const [activeMenu, setActiveMenu] = useState(menu);
  // console.log(activeMenu,"as");
  return (
    <div
      className="position-relative"
      style={{ zIndex: "0", backgroundColor: "white" }}
    >
      <Header />
      <div className="position-relative z-1">
        {/* style={{ opacity: menu === true ? "0.7" : "1", zIndex: "1" }} onClick={(e)=> setActiveMenu(false)} */}
        <div >
          {" "}
          <HeroSection />
          <BookCalendar />
          <OurPrograms />
          <StudPrograms />
          <NyinstPath />
          <Ratings />
          <Clients />
          <OurExpertise />
          <Reviews />
          <Webinars />
          <Events />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
