import React, { useState } from "react";
import "../Styles/BookCalendar.css";
import Marquee from "react-fast-marquee";
import infra_img1 from "../Assets/infra_img1.avif";
import infra_img2 from "../Assets/infra_img2.avif";
import infra_img3 from "../Assets/infra_img3.avif";
import infra_img4 from "../Assets/infra_img4.avif";
import infra_img5 from "../Assets/infra_img5.avif";
import infra_img6 from "../Assets/infra_img6.avif";
import infra_img7 from "../Assets/infra_img7.avif";
import infra_img8 from "../Assets/infra_img8.avif";
import infra_img9 from "../Assets/infra_img9.avif";
import calendar_arrow from "../Assets/calendar_arrow.png";
// import calendar_girl from "../Assets/calendar_girl.png";
// import semi_circle from "../Assets/semi_circle_bg.png";
import circle_dot from "../Assets/circle_dot.png";
import mobile_book_img from "../Assets/mobile_book_calendar_img.png";
import calendar_img from "../Assets/calendar_icon.png";
import calendar_girl_img from "../Assets/calendar_girl_img.png";
import bgr1 from "../Assets/b-1.jpg";
import bgr2 from "../Assets/b-2.jpg";
import bgr3 from "../Assets/b-3.jpg";
import bgr4 from "../Assets/b-4.jpg";
import bgr5 from "../Assets/b-5.jpg";
import bgr6 from "../Assets/b-6.jpg";
import bgr7 from "../Assets/b-7.jpg";
import bgr8 from "../Assets/b-8.jpg";
import bgr9 from "../Assets/b-9.jpg";
import BookNowForm from "./BookNowForm";
import BookNowFormMobile from "./BookNowMobile";
import BookNowBangalore from "./BookNowBangalore";
import BookNowBangMobile from "./BookNowBangMobile";
function BookCalendar() {
  // const [show, setShow] = useState(false);
  // const [bangaloreShow, setBangaloreShow] = useState(false);
  const [bangModalShow, setBangModalShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  return (
    <div id="book_calendar">
      <div className="container-fluid d-lg-block d-none">
        <div id="book_calendar_bg" className="position-relative">
          {" "}
          <div>
            <img src={calendar_arrow} alt="" id="cal_arrow" />
          </div>
          <div id="calendar_girl" className="position-absolute">
            <img src={calendar_girl_img} alt="" width="100%" />
          </div>
          <div id="book_calendar_text">
            <span className="">Book the calendar to visit our office</span>
            <div id="sub_text" className="">
              Witness firsthand innovation in action & insightful discussions
            </div>
            <div id="circle_dot" className="ps-0">
              <img src={circle_dot} alt="" />
            </div>
          </div>
          <div id="calendar_icon">
            <img src={calendar_img} alt="" />
          </div>
        </div>

        <div className="row" id="offices_bg">
          <div className="col-6">
            <div id="chennai_office_bg">
              <div id="chennai_address">
                <div>
                  <div id="chennai_text">chennai office</div>
                  <div id="chennai_address_text">
                    Prince info park - Ambattur
                  </div>
                </div>
                {/* <a
                  href="https://docs.google.com/forms/d/1yFmCbF5r_9aCmUjpCb_ETPWBt-mYW99FimMZZ0PMZOA"
                  className="text-decoration-none"
                  target="_blank"
                > */}
                <button
                  id="book_now_bg"
                  onClick={() => setModalShow(true)}
                  className="btn"
                >
                  <div>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"
                        fill="#006BFF"
                      />
                    </svg>
                  </div>
                  <div> Book Now</div>
                </button>

                {/* </a> */}
              </div>{" "}
              <BookNowForm
                show={modalShow}
                onHide={() => setModalShow(false)}
                // location="Chennai"
              />
              <div className="">
                <Marquee
                  //   speed={80}
                  direction="left"
                  gradient={false}
                  style={{ marginTop: "20px", background: "none", zIndex: "0" }}
                >
                  <div id="infra_img">
                    <img src={infra_img1} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={infra_img2} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={infra_img3} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={infra_img4} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={infra_img5} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={infra_img6} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={infra_img7} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={infra_img8} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={infra_img9} alt="" />
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div id="chennai_office_bg">
              <div id="chennai_address">
                <div>
                  <div id="chennai_text">Bangalore office</div>
                  <div id="chennai_address_text">BTM Layout - Bengaluru</div>
                </div>
                {/* <a
                  href="https://docs.google.com/forms/d/1zDfdL2RH4oN07TBxKycrdr-ut-Tpa4fFGF82jN2oWzg"
                  className="text-decoration-none"
                  target="_blank"
                > */}{" "}
                <button
                  id="book_now_bg"
                  onClick={() => setBangModalShow(true)}
                  className="btn"
                >
                  <div>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"
                        fill="#006BFF"
                      />
                    </svg>
                  </div>
                  <div> Book Now</div>
                </button>
                {/* </a> */}
              </div>
              <BookNowBangalore
                show={bangModalShow}
                onHide={() => setBangModalShow(false)}
                // location="Bangalore"
              />
              <div>
                <Marquee
                  // speed={60}
                  direction="right"
                  gradient={false}
                  style={{ marginTop: "20px", background: "none", zIndex: "0" }}
                >
                  <div id="infra_img">
                    <img src={bgr1} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={bgr2} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={bgr3} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={bgr4} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={bgr5} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={bgr6} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={bgr7} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={bgr8} alt="" />
                  </div>
                  <div id="infra_img">
                    <img src={bgr9} alt="" />
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-block d-lg-none" id="mobile_calendar">
        <div className="container-fluid">
          <div id="mobile_book_calendar_text_bg">
            <div id="mobile_book_calendar_text">
              Book the calendar to visit our office
              <div id="mobile_sub_text">
                Witness firsthand innovation in action & insightful discussions
              </div>
              {/* <div
                className="left-0 start-0 w-100 bottom-0 mt-5 position-absolute"
                id="mobile_girl_img"
              >
                <img src={mobile_book_img} alt="" width="100%" />
              </div> */}
              {/* <div id="mobile_calendar_icon" align="right">
                <img src={calendar_img} alt=""/>
              </div> */}
            </div>
            <div id="mobile_girl_img">
              <img
                src={mobile_book_img}
                alt=""
                width="100%"
                className="p-0 m-0"
              />
            </div>
          </div>
          <div id="mobile_chennai_office_bg">
            <div id="mobile_office_text">Chennai Office</div>
            <div id="mobile_office_address">Prince info park - Ambattur</div>
            {/* <a
              href="https://docs.google.com/forms/d/1yFmCbF5r_9aCmUjpCb_ETPWBt-mYW99FimMZZ0PMZOA"
              className="text-decoration-none"
              target="_blank"
            > */}{" "}
            <div id="mobile_book_now_bg" onClick={() => setModalShow(true)}>
              <div>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"
                    fill="#006BFF"
                  />
                </svg>
              </div>
              <div> Book Now</div>
            </div>
            <BookNowFormMobile
              show={modalShow}
              onHide={() => setModalShow(false)}
              location="Bangalore"
            />
            {/* </a> */}
            <div>
              {" "}
              <Marquee
                speed={60}
                direction="right"
                gradient={false}
                style={{ marginTop: "20px", background: "none", zIndex: "0" }}
              >
                <div id="infra_img">
                  <img src={infra_img1} alt="" />
                </div>
                <div id="infra_img">
                  <img src={infra_img2} alt="" />
                </div>
                <div id="infra_img">
                  <img src={infra_img3} alt="" />
                </div>
                <div id="infra_img">
                  <img src={infra_img4} alt="" />
                </div>
                <div id="infra_img">
                  <img src={infra_img5} alt="" />
                </div>
                <div id="infra_img">
                  <img src={infra_img6} alt="" />
                </div>
                <div id="infra_img">
                  <img src={infra_img7} alt="" />
                </div>
                <div id="infra_img">
                  <img src={infra_img8} alt="" />
                </div>
                <div id="infra_img">
                  <img src={infra_img9} alt="" />
                </div>
              </Marquee>
            </div>
          </div>
          <div id="mobile_chennai_office_bg">
            <div id="mobile_office_text">Bangalore Office</div>
            <div id="mobile_office_address">BTM Layout - Bengaluru</div>
            {/* <a
              href="https://docs.google.com/forms/d/1zDfdL2RH4oN07TBxKycrdr-ut-Tpa4fFGF82jN2oWzg"
              className="text-decoration-none"
              target="_blank"
            > */}
            <div id="mobile_book_now_bg" onClick={() => setBangModalShow(true)}>
              <div>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"
                    fill="#006BFF"
                  />
                </svg>
              </div>
              <div> Book Now</div>
            </div>
            <BookNowBangMobile
              show={bangModalShow}
              onHide={() => setBangModalShow(false)}
              location="Bangalore"
            />
            {/* </a> */}

            <div>
              {" "}
              <Marquee
                speed={60}
                direction="right"
                gradient={false}
                style={{ marginTop: "20px", background: "none", zIndex: "0" }}
              >
                <div id="infra_img">
                  <img src={bgr1} alt="" />
                </div>
                <div id="infra_img">
                  <img src={bgr2} alt="" />
                </div>
                <div id="infra_img">
                  <img src={bgr3} alt="" />
                </div>
                <div id="infra_img">
                  <img src={bgr4} alt="" />
                </div>
                <div id="infra_img">
                  <img src={bgr5} alt="" />
                </div>
                <div id="infra_img">
                  <img src={bgr6} alt="" />
                </div>
                <div id="infra_img">
                  <img src={bgr7} alt="" />
                </div>
                <div id="infra_img">
                  <img src={bgr8} alt="" />
                </div>
                <div id="infra_img">
                  <img src={bgr9} alt="" />
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookCalendar;
