import React from "react";
import Footer from "./Footer";

import policy_hero_img from "../Assets/policy_hero_img.png";
import Header from "./Header";
// import { LazyLoadImage } from "react-lazy-load-image-component";
const CookiePolicy = () => {

  return (
    <div>
      <Header />
      <div style={{ background: "#065BD64D" }}>
        <div className="container text-white  mt-lg-0 mt-5  pb-lg-0 pb-3">
          <div
            className="row tablet_privacy_padding"
            style={{ paddingTop: "48px" }}
          >
            <div className="col-lg-6 d-flex">
              <div className="my-auto">
                <div
                  className="condition_policy_header"
                  style={{
                    fontFamily: "Avenir LT Std",
                    fontSize: "97px",
                    fontWeight: "900",
                    color: "#065BD6",
                    lineHeight: "124px",
                  }}
                >
                  Cookie <br /> Policy
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-block d-none">
              <div>
                <img src={policy_hero_img} alt="policy hero image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ color: "#393939" }}>
        <div 
          className="row "
          style={{ paddingTop: "48px", paddingBottom: "96px" }}
        >
          <div className="col-lg-12" >
            NYINST uses cookies and similar technologies to improve the
            functionality and performance of our website, and to provide a
            personalized user experience. This cookie policy explains how we use
            cookies and what choices you have regarding their use.
          </div>
          <div className="col-lg-12" >
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                1.What Are Cookies?
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                A cookie is a small text file that is placed on your device
                (computer, tablet, or smartphone) when you visit a website.
                Cookies allow the website to recognize your device and remember
                your preferences and activities.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                2.Essential Cookies:
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                These cookies are necessary for the website to function properly
                and cannot be turned off in our systems.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                3.Performance Cookies:
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                These cookies allow us to measure and analyze how visitors use
                our website. They help us improve the functionality and
                performance of our site.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                4.Functionality Cookies:
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                These cookies allow us to remember your preferences and
                customize your experience on our website.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                5.Advertising Cookies:
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                These cookies are used to deliver relevant ads to you based on
                your browsing behavior and interests.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                6.Third-Party Cookies
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                We may use third-party cookies on our website to provide
                analytics and advertising services. These cookies are subject to
                the privacy policies of the third-party providers.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                7.Managing Cookies
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                You can control cookies through your web browser settings. Most
                browsers allow you to delete or block cookies, or to receive a
                warning before a cookie is stored on your device. However, if
                you block or delete cookies, some features of our website may
                not function properly.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                8.Changes to Our Cookie Policy
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                We may update our cookie policy from time to time to reflect
                changes in our use of cookies. We will post the revised policy
                on our website, and the changes will take effect immediately
                upon posting. We encourage you to review this policy
                periodically to stay informed about how we use cookies.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                9.Contact Us
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                If you have any questions or concerns about our cookie policy,
                please contact us at <a
                          href="mailto:+info@nyinst.com"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          info@nyinst.com
                        </a>.
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CookiePolicy;
