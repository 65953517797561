import React from "react";
import "../Styles/Rating.css";
import google_icon from "../Assets/google_icon.png";
import ambition_icon from "../Assets/ambition_icon.png";
import glass_door_icon from "../Assets/glass_door_icon.png";

function Ratings() {
  return (
    <div >
      <div className="container-fluid">
        <div id="ratings_bg" className="d-lg-block d-none">
          <div className="d-flex row" id="all_ratings_bg">
            <div className="col-4">
              <div id="google_rating_bg" className="d-flex align-items-center">
                <div>
                  <img src={google_icon} />
                </div>
                <div id="rating_content">
                  4.7 Ratings
                  <div id="review_text">Google Review</div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div
                id="ambition_rating_bg"
                className="d-flex align-items-center"
              >
                <div>
                  <img src={ambition_icon} />
                </div>
                <div id="rating_content">
                  5.0 Ratings
                  <div id="review_text">Ambition Box</div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div
                id="glassdoor_rating_bg"
                className="d-flex align-items-center"
              >
                <div>
                  <img src={glass_door_icon} />
                </div>
                <div id="rating_content">
                  5.0 Ratings
                  <div id="review_text">Glassdoor</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* for mobiles */}
        <div id="mobile_ratings_bg" className="d-block d-lg-none">
        <div id="mobile_rating_bg" >
          <div id="mobile_google_bg">
            <div>
              <img src={google_icon} />
            </div>
            <div id="mobile_google_text">
              4.7 Ratings<div id="google_review_text">Google Review</div>
            </div>
          </div>
        </div>
        <div id="mobile_rating_bg" >
          <div id="mobile_google_bg">
            <div>
              <img src={ambition_icon} />
            </div>
            <div id="mobile_google_text">
              5.0 Ratings<div id="google_review_text">Ambition Box</div>
            </div>
          </div>
        </div>
        <div id="mobile_rating_bg" >
          <div id="mobile_google_bg">
            <div>
              <img src={glass_door_icon} />
            </div>
            <div id="mobile_google_text">
              5.0 Ratings<div id="google_review_text">Glassdoor </div>
            </div>
          </div>
        </div>
        </div>
       
      </div>
    </div>
  );
}

export default Ratings;
