import React, { useState } from "react";
import "../Styles/Footer.css";
import nyinst_logo from "../Assets/ny_logo.png";
import insta_logo from "../Assets/insta_logo.png";
import linked_in_logo from "../Assets/linked_in_logo.png";
import youtube_logo from "../Assets/youtube_logo.png";
import x_logo from "../Assets/x_logo.png";
import axios from "axios";
import qs from "qs";
function Footer() {
  const [mail, setMail] = useState("");

  const handleMail = (e) => {
    // e.preventDefault();
    // if (result !== "SUCCESS") {
    //   setLoading(true);
    // } else {
    //   setLoading(false);
    // }

    const reqData = {
      mod: "Candidate",
      actionType: "send-mail-to-user",
      subAction: JSON.stringify({ EMAIL: mail }),
      // EMAIL: mail,
    };
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "data");

      // setResult(res.data.RESULT);
      // if (res.data.RESULT === "SUCCESS") {
      //   setName("");
      //   setMobile("");
      //   setMail("");
      // }
    });
  };

  return (
    <div>
      <div id="footer_bg">
        <div className="container-fluid">
          <div id="footer_content_bg" className="d-lg-block d-none">
            <div className="row">
              <div className="col-5">
                <div id="nyinst_logo">
                  <a href="/">
                    <img src={nyinst_logo} alt="nyinst_logo" />
                  </a>
                </div>
                <div id="subscribe_text">
                  Subscribe to our newsletter to know about
                  <br /> New Programs & Events!
                </div>

                <form id="email_id" className="d-flex" onSubmit={handleMail}>
                  <input
                    type="email"
                    value={mail}
                    placeholder="Enter your E-mail address"
                    onChange={(e) => setMail(e.target.value)}
                    required
                  />
                  <button className="btn">Subscribe</button>
                </form>
                <div id="logos_bg" className="d-flex">
                  <div id="insta_logo_bg">
                    <a
                      href="https://www.instagram.com/nyinstindia/"
                      target="_blank"
                    >
                      <img src={insta_logo} />
                    </a>
                  </div>
                  <div id="insta_logo_bg">
                    <a
                      href="https://www.linkedin.com/company/nyinstofficial/"
                      target="_blank"
                    >
                      <img src={linked_in_logo} />
                    </a>
                  </div>
                  <div id="insta_logo_bg">
                    <a
                      href="https://www.youtube.com/@nyinstofficial"
                      target="_blank"
                    >
                      <img src={youtube_logo} />
                    </a>
                  </div>
                  <div id="insta_logo_bg">
                    <a
                      href="https://twitter.com/nyinstofficial"
                      target="_blank"
                    >
                      <img src={x_logo}/>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-7 d-flex justify-content-between"
                id="footer_inner_content_bg"
                align="end"
              >
                <div
                  className="d-flex flex-column"
                  style={{ gap: "0.69rem" }}
                  align="left"
                >
                  <div id="title">Programs</div>
                  <div>
                    {" "}
                    <a href="/techie-pro" className="text-decoration-none">
                      <div id="separate_content">Techie Pro</div>
                    </a>
                    {/* <a href="/techie-nerds" className="text-decoration-none">
                      <div id="separate_content">Techie Nerds</div>
                    </a> */}
                    <a href="/techie-super" className="text-decoration-none">
                      <div id="separate_content">Techie Super</div>
                    </a>
                    <a href="/techie-intern" className="text-decoration-none">
                      <div id="separate_content">Techie Intern</div>
                    </a>
                    <a href="/techie-iv" className="text-decoration-none">
                      <div id="separate_content">Techie Industrial Visit</div>
                    </a>
                  </div>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ gap: "0.69rem" }}
                  align="left"
                >
                  <div id="title">Company</div>
                  <div>
                    {" "}
                    {/* <div id="separate_content">About us</div> */}
                    <a href="/hire" className="text-decoration-none">
                      <div id="separate_content">Hire from us</div>
                    </a>
                    <a
                      href="/scholarship"
                      // target="_blank"
                      className="text-decoration-none"
                    >
                      <div id="separate_content">Scholarship</div>
                    </a>
                    <a
                      href="/certificate"
                      // target="_blank"
                      className="text-decoration-none"
                    >
                      <div id="separate_content">Certificate</div>
                    </a>
                    <a href="/contact-us" className="text-decoration-none">
                      <div id="separate_content">Contact</div>
                    </a>
                  </div>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ gap: "0.69rem" }}
                  align="left"
                >
                  <div id="title">Resources</div>
                  <div>
                    {" "}
                    <a
                      href="https://blog.nyinst.com/"
                      className="text-decoration-none"
                      target="_blank"
                      // style={{ color: "var(--g_800" }}
                    >
                      <div id="separate_content">Blogs</div>
                    </a>
                    <a
                      href="/events"
                      className="text-decoration-none"

                      // style={{ color: "var(--g_800" }}
                    >
                      <div id="separate_content">Events</div>
                    </a>
                  </div>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ gap: "0.69rem" }}
                  align="left"
                >
                  <div id="title">Legal</div>
                  <div>
                    {" "}
                    <a href="terms-conditions" className="text-decoration-none">
                      <div id="separate_content">Terms & Conditions</div>
                    </a>
                    <a href="privacy-policy" className="text-decoration-none">
                      <div id="separate_content">Privacy Policy</div>
                    </a>
                    <a href="cookie-policy" className="text-decoration-none">
                      <div id="separate_content">Cookies Policy</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div id="underline"></div>
            <div id="since_text" align="center">
              © 2006-2024 NYINST EDU SERVICES PVT LTD. All rights reserved.
            </div>
          </div>
          <div id="mobile_footer_content_bg" className="d-lg-none d-block">
            <div>
              <a href="/">
                <img src={nyinst_logo} alt="nyinst_logo" />
              </a>
            </div>
            <div id="mobile_subscribe_text">
              Subscribe to our newsletter to know about <br /> New Programs &
              Events!
            </div>
            <form id="mobile_enter_mail_bg" onSubmit={handleMail}>
              <input
                type="email"
                placeholder="Enter your E-mail address"
                onChange={(e) => setMail(e.target.value)}
                required
              />
              <button id="mobile_subscribe_button" className="btn">
                Subscribe
              </button>
            </form>
            <div id="mobile_logos_bg" className="d-flex">
              <div id="mobile_insta_logo_bg">
                {" "}
                <a
                  href="https://www.instagram.com/nyinstindia/"
                  target="_blank"
                >
                  <img src={insta_logo} />
                </a>
              </div>
              <div id="mobile_insta_logo_bg">
                <a
                  href="https://www.linkedin.com/company/nyinstofficial/"
                  target="_blank"
                >
                  <img src={linked_in_logo} />
                </a>
              </div>
              <div id="mobile_insta_logo_bg">
                <a
                  href="https://www.youtube.com/@nyinstofficial"
                  target="_blank"
                >
                  <img src={youtube_logo} />
                </a>
              </div>
              <div id="mobile_insta_logo_bg">
                <a href="https://twitter.com/nyinstofficial" target="_blank">
                  <img src={x_logo} />
                </a>
              </div>
            </div>
            <div className="row" id="mobile_programs_bg">
              <div className="col-6">
                <div id="program_title">Programs</div>
                <a href="/techie-pro" className="text-decoration-none">
                  {" "}
                  <div id="program_points">Techie Pro</div>
                </a>
                {/* <a href="/techie-nerds" className="text-decoration-none">
                  {" "}
                  <div id="program_points">Techie Nerds</div>
                </a> */}
                <a href="/techie-super" className="text-decoration-none">
                  {" "}
                  <div id="program_points">Techie Super</div>
                </a>
                <a href="/techie-intern" className="text-decoration-none">
                  {" "}
                  <div id="program_points">Techie Intern</div>
                </a>
                <a href="/techie-iv" className="text-decoration-none">
                  {" "}
                  <div id="program_points">Techie Industrial Visit</div>
                </a>
              </div>
              <div className="col-6">
                <div id="program_title">Company</div>
                {/* <div id="program_points">About us</div> */}
                <a href="hire" className="text-decoration-none">
                  <div id="program_points">Hire from us</div>
                </a>

                <a
                  href="/scholarship"
                  // target="_blank"
                  className="text-decoration-none"
                >
                  <div id="program_points">Scholarship</div>
                </a>
                <a
                  href="/certificate"
                  // target="_blank"
                  className="text-decoration-none"
                >
                  <div id="program_points">Certificate</div>
                </a>
                <a href="/contact-us" className="text-decoration-none">
                  <div id="program_points">Contact</div>
                </a>
              </div>
              <div className="col-6" style={{ marginTop: "3.7vh" }}>
                <div id="program_title">Resources</div>
                <a
                  href="https://blog.nyinst.com/"
                  className="text-decoration-none"
                  target="_blank"
                  // style={{ color: "var(--g_800" }}
                >
                  <div id="program_points">Blogs</div>
                </a>
                <a
                  href="/events"
                  className="text-decoration-none"

                  // style={{ color: "var(--g_800" }}
                >
                  <div id="program_points">Events</div>
                </a>
              </div>
              <div className="col-6" style={{ marginTop: "3.7vh" }}>
                <div id="program_title">Legal</div>
                <a href="/terms-conditions" className="text-decoration-none">
                  <div id="program_points">Terms & Conditions</div>
                </a>
                <a href="/privacy-policy" className="text-decoration-none">
                  <div id="program_points">Privacy Policy</div>
                </a>
                <a href="cookie-policy" className="text-decoration-none">
                  <div id="program_points">Cookies Policy</div>
                </a>
              </div>
            </div>
            <div id="mobile_exp_text">
              © 2006-2024 NYINST EDU SERVICES PVT LTD. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
