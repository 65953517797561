import React from "react";
import "../Styles/ScholarShip/ScholarshipSlabs.css";
import slab1 from "../Assets/Scholarship/slab1.png";
import slab2 from "../Assets/Scholarship/slabs2.png";
import slab3 from "../Assets/Scholarship/slabs3.png";
import slab4 from "../Assets/Scholarship/slabs4.png";

function ScholarShipSlabs() {
  return (
    <div className="container">
      <div id="slabs_bg" align="center" className="d-lg-block d-none">
        <div id="per_text">PERCENTAGE</div>
        <div id="sch_title">Scholarship Slabs</div>
        <div className="row" id="slab_img_bg">
          <div className="col-md-3">
            <div id="slab_img">
              <img src={slab1} />
            </div>
            <div id="slab_text">Top 5% Students</div>
          </div>
          <div className="col-md-3">
            <div id="slab_img">
              <img src={slab2} />
            </div>
            <div id="slab_text">80-70% Scored</div>
          </div>
          <div className="col-md-3">
            <div id="slab_img">
              <img src={slab3} />
            </div>
            <div id="slab_text">60-50% Scored</div>
          </div>
          <div className="col-md-3">
            <div id="slab_img">
              <img src={slab4} />
            </div>
            <div id="slab_text">{"<"}40% Scored</div>
          </div>
        </div>
      </div>

      {/* for mobiles */}
      <div id="mob_slabs_bg" className="container d-lg-none d-block">
        <div className="d-lg-none d-block">
          <div id="mob_slabs_title">PERCENTAGE</div>
          <div id="mob_slabs_tit">Scholarship Slabs</div>
          <div className="row" id="mob_slabs" align="center">
            <div className="col-6">
              <div id="mob_slab_img">
                <img src={slab1} alt="" />{" "}
                <div id="mob_slab_text">Top 5% Students</div>
              </div>
            </div>
            <div className="col-6">
              <div id="mob_slab_img">
                <img src={slab2} alt="" />{" "}
                <div id="mob_slab_text">80-70% Scored</div>
              </div>
            </div> <div className="col-6">
              <div id="mob_slab_img">
                <img src={slab3} alt="" />{" "}
                <div id="mob_slab_text">60-50% Scored</div>
              </div>
            </div> <div className="col-6">
              <div id="mob_slab_img">
                <img src={slab4} alt="" />{" "}
                <div id="mob_slab_text">{"<"} 40% Scored</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScholarShipSlabs;
