import React, { useState } from "react";
import "../Styles/TechiePro/Journey.css";
import celebrate_img from "../Assets/techiepro/cel_emoji.png";
import celebrate_img1 from "../Assets/techiepro/cel_emoji1.png";
import celebrate_img4 from "../Assets/techiepro/cel_emoji4.png";
import celebrate_img3 from "../Assets/techiepro/cel_emoji3.png";
import ApplyForm from "./ApplyForm";
import professional_interactive from "../Assets/techiepro/interactive_profession.png";
import ApplyNow from "./ApplyNow";
function Journey() {
  const [modalShows, setModalShows] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const handleClose = () => setModalShows(false);
  const handleShow = () => setModalShows(true);
  return (
    <div className="position-relative"
    style={{ zIndex: "0", backgroundColor: "white" }}>
      <div id="nyinst_journey_bg" className=" d-lg-block d-none">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div id="journey_title">
                {" "}
                Your Journey at NYINST?
                <br />
                {/* <span id="look_text"> NYINST look like?</span> */}
              </div>{" "}
              <div id="journey_sub_content">
                Transformational ride, filled with learning, growth, and
                invaluable connections
              </div>
              <div id="join_us_button">
                <button
                  onClick={() => setModalShow(true)}
                  style={{ background: "#8625D2" }}
                >
                  Join us today
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.1657 12.0001L12.9586 5.79297L11.5444 7.20718L16.3373 12.0001L11.5444 16.793L12.9586 18.2072L19.1657 12.0001ZM13.5158 12.0001L7.30874 5.79297L5.89453 7.20718L10.6874 12.0001L5.89453 16.793L7.30874 18.2072L13.5158 12.0001Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <ApplyNow show={modalShow} onHide={() => setModalShow(false)} />
            </div>

            <div className="col-md-6">
              <div id="journey_steps_bg">
                <div id="single_journey_step">
                  <div className="position-relative">
                    <div id="cel_img_bg" className="position-relative z-1">
                      <div id="dotted_line_bg">
                        <div
                          className="position-absolute translate-middle-x start-50"
                          id="dotted_line_journey"
                        ></div>
                      </div>
                      <div id="cel_bg">
                        <img
                          src={celebrate_img1}
                          alt=""
                          width="100%"
                          className="position-relative"
                        />
                      </div>
                    </div>
                  </div>

                  <div id="journey_step_text">
                    Peer-to-Peer
                    <div id="journey_sub_text">
                      Collaborate and work as team
                    </div>
                  </div>
                </div>
                <div id="single_journey_step">
                  <div id="cel_bg">
                    <img src={professional_interactive} alt="" width="100%" />
                  </div>
                  <div id="journey_step_text">
                    Professional Interaction
                    <div id="journey_sub_text">
                      Get to work closely with experts
                    </div>
                  </div>
                </div>
                <div id="single_journey_step">
                  <div id="cel_bg">
                    <img src={celebrate_img4} alt="" width="100%" />
                  </div>
                  <div id="journey_step_text">
                    Projects
                    <div id="journey_sub_text">
                      Work on projects that solve real world problems
                    </div>
                  </div>
                </div>
                <div id="single_journey_step">
                  <div id="cel_bg">
                    <img src={celebrate_img} alt="" width="100%" />
                  </div>
                  <div id="journey_step_text">
                    Party on the go (Job)
                    <div id="journey_sub_text">
                      Creating great memories along the way
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* for mobiles */}
      <div id="mobile_nyinst_journey_bg" className="d-lg-none d-block">
        {" "}
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div id="mobile_journey_title">
                {" "}
                Your Journey at NYINST?
                <br />
                {/* <span id="mobile_look_text"> NYINST look like?</span> */}
              </div>{" "}
              <div id="mobile_journey_sub_content">
                Transformational ride, filled with learning, growth, and
                invaluable connections
              </div>
              <div id="mobile_join_us_button">
                <button
                  onClick={() => setModalShow(true)}
                  style={{ background: "#8625D2" }}
                >
                  Join us today
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.1657 12.0001L12.9586 5.79297L11.5444 7.20718L16.3373 12.0001L11.5444 16.793L12.9586 18.2072L19.1657 12.0001ZM13.5158 12.0001L7.30874 5.79297L5.89453 7.20718L10.6874 12.0001L5.89453 16.793L7.30874 18.2072L13.5158 12.0001Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <ApplyForm show={modalShow} onHide={() => setModalShow(false)} />
            </div>

            <div className="col-md-6">
              <div id="mobile_journey_steps_bg">
                <div id="mobile_single_journey_step">
                  <div className="position-relative">
                    <div
                      id="mobile_cel_img_bg"
                      className="position-relative z-1"
                    >
                      <div id="mobile_dotted_line_bg">
                        <div
                          className="position-absolute translate-middle-x start-50"
                          id="mobile_dotted_line_journey"
                        ></div>
                      </div>
                      <div id="mobile_cel_bg">
                        <img
                          src={celebrate_img1}
                          alt=""
                          width="100%"
                          className="position-relative"
                        />
                      </div>
                    </div>
                  </div>

                  <div id="mobile_journey_step_text">
                    Peer-to-Peer
                    <div id="mobile_journey_sub_text">
                      Collaborate and work as team
                    </div>
                  </div>
                </div>{" "}
                <div id="mobile_single_journey_step">
                  <div id="mobile_cel_bg">
                    <img src={professional_interactive} alt="" width="100%" />
                  </div>
                  <div id="mobile_journey_step_text">
                    Professional Interaction
                    <div id="mobile_journey_sub_text">
                      Get to work closely with experts
                    </div>
                  </div>
                </div>
                <div id="mobile_single_journey_step">
                  <div id="mobile_cel_bg">
                    <img src={celebrate_img4} alt="" width="100%" />
                  </div>
                  <div id="mobile_journey_step_text">
                    Projects
                    <div id="mobile_journey_sub_text">
                      Work on projects that solve real world problems
                    </div>
                  </div>
                </div>
                <div
                  id="mobile_single_journey_step"
                  style={{ paddingBottom: "3.75rem" }}
                >
                  <div id="mobile_cel_bg">
                    <img src={celebrate_img} alt="" width="100%" />
                  </div>
                  <div id="mobile_journey_step_text">
                    Party on the go (Job)
                    <div id="mobile_journey_sub_text">
                      Creating great memories along the way
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Journey;
