import React from "react";
import "../../Styles/Events.css";
import block_chain from "../../Assets/block_chain_events.png";
import android_img from "../../Assets/android_event_img.png";
import recruitment from "../../Assets/recruitment_img.png";

function Events() {
  return (
    <div>
      <div className="container-fluid">
        <div id="events_bg" className="d-lg-block d-none">
          <div id="nyinst_events_text">NYINST Events</div>
          <div id="event_sub_topic">
            Convergence of innovation, inspiration, and community
          </div>
          <div id="events_item_bg" align="center">
            <div className="row" align="left">
              <div className="col-4">
                <a href="/sa-engg-events" className="text-decoration-none">
                  <div id="single_event_bg">
                    <div>
                      <img src={block_chain} alt="" width="100%" />
                    </div>
                    <div id="event_detail_bg">
                      <div>26 MAR 2019 | Blockchain</div>
                      <div id="college_name">
                        SA Engineering College Blockchain Seminar
                      </div>
                    </div>
                  </div>{" "}
                </a>
              </div>
              <div className="col-4">
                <a href="/mgr-engg-events" className="text-decoration-none">
                  <div id="single_event_bg">
                    <div>
                      <img src={android_img} alt="" width="100%" />
                    </div>
                    <div id="event_detail_bg">
                      <div>23 FEB 2019 | Android Development</div>
                      <div id="college_name">Dr. MGR Engineering College</div>
                    </div>
                  </div>
                </a>
              </div>{" "}
              <div className="col-4">
                <a
                  href="/panimalar-engg-events"
                  className="text-decoration-none"
                >
                  <div id="single_event_bg">
                    <div>
                      <img src={recruitment} alt="" width="100%" />
                    </div>
                    <div id="event_detail_bg">
                      <div>20 FEB 2019 | Campus Recruitment</div>
                      <div id="college_name">
                        Panimalar Institute of Technology
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <a href="/events" className="text-decoration-none">
              {" "}
              <div id="view_more_button" align="center">
                View All
                <svg
                  style={{ marginLeft: "0.75rem" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                    fill="#006BFF"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
        <div id="mobile_events_bg" className="d-block d-lg-none">
          <div id="mobile_events_topic">NYINST Events</div>
          <div id="mobile_events_subtopic">
            Convergence of innovation, inspiration, and community
          </div>
          <a href="/sa-engg-events" className="text-decoration-none">
            <div id="mobile_single_events_bg">
              <div>
                <img src={block_chain} width="100%" />
              </div>
              <div id="mobile_events_details">26 MAR 2019 | Blockchain</div>
              <div id="mobile_event_location">
                SA Engineering College Blockchain Seminar
              </div>
            </div>
          </a>
          <a href="/mgr-engg-events" className="text-decoration-none">
            <div id="mobile_single_events_bg">
              <div>
                <img src={android_img} width="100%" />
              </div>
              <div id="mobile_events_details">
                26 MAR 2019 | Android Development
              </div>
              <div id="mobile_event_location">
                Dr. MGR Engineering College Blockchain Seminar
              </div>
            </div>{" "}
          </a>
          <a href="/panimalar-engg-events" className="text-decoration-none">
            <div id="mobile_single_events_bg">
              <div>
                <img src={recruitment} width="100%" />
              </div>
              <div id="mobile_events_details">
                26 MAR 2019 | Campus Recruitment
              </div>
              <div id="mobile_event_location">
                Panimalar Institute of Technology
              </div>
            </div>
          </a>

          <a href="/events" className="text-decoration-none">
            <div id="mobile_view_more_button" align="center">
              View All
              <svg
                className="ms-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                  fill="#006BFF"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Events;
