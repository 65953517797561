import React from "react";
import "../Styles/HireFromUs/HireEnquiry.css";

function HireEnquiry() {
 
  return (
    <div>
      <div
        id="hire_enquiry_bg"
        align="center"
        className="d-lg-flex d-none align-items-center justify-content-center"
      >
        <div id="enquiry_title">
          Hiring right candidate for your company
          <br />
          <span id="hire_made_text"> made simpler!</span>{" "}
          <a
            href="/hire#hire_us_form"
            className="text-decoration-none"
          >
            {" "}
            <div id="hire_enquiry_button" align="center">
              <button className="btn">Enquiry Now</button>
            </div>
          </a>
        </div>
      </div>
      {/* for mobiles */}
      <div
        id="mobile_hire_enquiry_bg"
        align="center"
        className="d-lg-none d-flex align-items-center justify-content-center"
      >
        <div id="mobile_enquiry_title" className="">
          Hiring right candidate for your company
          <br />
          <span id="mob_made_sim">made simpler!</span>{" "}
          <a
            href="/hire#hire-form"
            className="text-decoration-none"
          >
            {" "}
            <div id="mobile_hire_enquiry_button" align="center">
              <button className="btn">Enquiry Now</button>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default HireEnquiry;
