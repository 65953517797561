import React, { useRef, useEffect, useState } from "react";
import "../Styles/TechiePro/ProgramOverview.css";
import tpro from "../Assets/t_pro.png";
import tsuper from "../Assets/t_super.png";
import tnerds from "../Assets/t_nerds.png";

function ProgramOverview({ menu }) {
  useEffect(() => {
    // console.log("Active ref in TechieHeroSection:", menu);
  }, [menu]);
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "/techie-pro#pricing") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  const [scrollPosition, setScrollPosition] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 0 ? setScrollPosition("sticky") : setScrollPosition("");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div id="pricing" ref={myRef}>
      <div className="container d-lg-block d-none">
        <div className=" d-lg-block d-none">
          <div id="program_pricing_title">Pricing</div>
          <div id="po_title">Professional Program Overview</div>

          <div className="row position-relative" id="po_bg">
            <div className="col-md-4 " style={{ width: "78.8rem" }}>
              {/* hj */}
              <div id="po_project_detail_bg">
                <div id="po_single_project_detail_bg">
                  <div>Live Project Details</div>
                </div>
                <div
                  id="po_single_project_detail_bg"
                  style={{ background: "none" }}
                >
                  <div className=""> Duration - Regular</div>
                </div>
                <div id="po_single_project_detail_bg">
                  <div>Duration - Extended (beyond Regular)</div>
                </div>
                <div
                  id="po_single_project_detail_bg"
                  style={{ background: "none" }}
                >
                  <div>Schedule</div>
                </div>
                <div id="po_single_project_detail_bg">
                  <div>Professional Service Job Interview</div>
                </div>
                <div
                  id="po_single_project_detail_bg"
                  style={{ background: "none" }}
                >
                  <div>Job Assistance</div>
                </div>
                <div id="po_single_project_detail_bg">
                  <div>Salary Expectation</div>
                </div>
              </div>
            </div>
            <div className="col-md-8 position-absolute" id="price_detail_bg">
              <div className="d-md-flex align-items-center justify-content-between pe-0 me-0 g-1">
                <a href="/techie-pro#pricing" className="text-decoration-none">
                  <div
                    style={{ border: "2px solid #F5F6F7" }}
                    id="po_pricing_detail_bg"
                    className="position-relative"
                    align="center"
                  >
                    <div
                      id="recommended"
                      className="translate-middle"
                      style={{ background: "#A6ABB3" }}
                    >
                      Recommended
                    </div>
                    <div id="po_img_bg">
                      {" "}
                      <img src={tpro} alt="" width="100%" />
                    </div>
                    {/* <div id="program_price_bg">
                    <div id="program_price">₹25,000</div>
                    <div id="gst_text">+GST</div>
                  </div> */}
                    <div id="po_tick_img">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                          fill="#065BD6"
                        />
                      </svg>
                    </div>
                    <div id="po_single_point">60 days / 8 weeks</div>
                    <div id="po_single_point">60 days / 8 weeks</div>
                    <div id="po_single_point">2 Hours / day (M-F)</div>
                    <div id="po_single_point">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                          fill="#9C9DA1"
                        />
                      </svg>
                    </div>
                    <div id="po_single_point">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                          fill="#065BD6"
                        />
                      </svg>
                    </div>
                    <div id="po_single_point">Open</div>
                    {/* <div id="po_apply_now_button" className="btn w-100">
                    <button>Apply now</button>
                  </div> */}
                    <div id="price">
                      ₹25,000
                      <span id="star" align="top">
                        *
                      </span>
                    </div>
                  </div>
                </a>
                {/* <a href="/techie-nerds#pricing" className="text-decoration-none">
                  <div
                    id="po_pricing_detail_nonreco_bg"
                    align="center"
                  
                  >
                    <div id="po_img_bg">
                      <img src={tnerds} alt="" width="100%" />
                    </div>
                    
                    <div id="po_tick_img">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                          fill="#065BD6"
                        />
                      </svg>
                    </div>
                    <div id="po_single_point">90 days / 12 weeks</div>
                    <div id="po_single_point">60 days / 8 weeks</div>
                    <div id="po_single_point">4 Hours/day (3 days)</div>
                    <div id="po_single_point">
                      5 guaranteed client interview
                    </div>
                    <div id="po_single_point">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                          fill="#065BD6"
                        />
                      </svg>
                    </div>
                    <div id="po_single_point">Industry Standard</div>
                   
                    <div id="price">
                      ₹85,000
                      <span id="star" align="top">
                        *
                      </span>
                    </div>
                  </div>
                </a> */}
                <a href="/techie-super#pricing" className="text-decoration-none">
                  <div id="po_pricing_detail_nonreco_bg" align="center"   style={{ border: "3px solid #006BFF" }}>
                    <div id="po_img_bg">
                      {" "}
                      <img src={tsuper} alt="" width="100%" />
                    </div>
                  
                    <div id="po_tick_img">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                          fill="#065BD6"
                        />
                      </svg>
                    </div>
                    <div id="po_single_point">90 days / 12 weeks</div>
                    <div id="po_single_point">60 days / 8 weeks</div>
                    <div id="po_single_point">9 Hours / day (M-F)</div>
                    <div id="po_single_point">
                      10 guaranteed client interview
                    </div>
                    <div id="po_single_point">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                          fill="#065BD6"
                        />
                      </svg>
                    </div>
                    <div id="po_single_point">Premium</div>
                  
                    <div id="price">
                      ₹1,60,000
                      <span id="star" align="top">
                        *
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            
            <div id="gst" align="end">
              *GST Applicable
            </div>
          </div>
        </div>
      </div>

      {/* for mobiles */}
      <div className="d-lg-none d-block">
        <div className="container ">
          <div id="mobile_pricing">Pricing</div>
          <div id="mobile_overview_text">Professional Program Overview</div>
          <div align="center"
            className="row w-100 container"
            id="mobile_price_bg"
            style={{
              // position: scrollPosition,
              top: "0",
              width: "100%",
              backgroundColor: "white",
              position: "-webkit-sticky",
              zIndex: "2",
            }}
          >
            <div className="col-6 w-50 position-relative" align="center">
              <div style={{background:"#B2B6BE"}}
                id="mobile_recomm"
                className="position-absolute  translate-middle start-50 ms-2"
              >
                Recommended
              </div>
              <div
                id="mobile_po_bg"
                align="center"
              
              >
                <div id="mobile_po_img_bg">
                  <img src={tpro} alt="" width="100%" />
                </div>
                {/* <div id="mobile_po_text" align="center">
                  <div>₹25,000</div>
                  <div id="mobile_gst_text">+GST</div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-6">
              <div id="mobile_po_bg" align="center">
                <div id="mobile_po_img_bg">
                  <img src={tnerds} alt="" width="100%" />
                </div>
               
              </div>
            </div>{" "} */}
            <div className="col-6 w-50" align="center">
              <div id="mobile_po_bg" align="center"   style={{ border: "1px solid #006BFF" }}>
                <div id="mobile_po_img_bg">
                  <img src={tsuper} alt="" width="100%" />
                </div>
                {/* <div id="mobile_po_text" align="center">
                  <div>₹1,60,000</div>
                  <div id="mobile_gst_text">+GST</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Live Project (OnSite)</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66843 10.1141L12.7967 3.98584L13.7395 4.92865L6.66843 11.9997L2.42578 7.75709L3.36859 6.81429L6.66843 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              {/* <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66843 10.1141L12.7967 3.98584L13.7395 4.92865L6.66843 11.9997L2.42578 7.75709L3.36859 6.81429L6.66843 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div> */}
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66843 10.1141L12.7967 3.98584L13.7395 4.92865L6.66843 11.9997L2.42578 7.75709L3.36859 6.81429L6.66843 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Duration - Regular</div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container"> 60 days / 8 weeks</div>
              </div>
              {/* <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container"> 90 days / 12 weeks</div>
              </div> */}
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container"> 90 days / 12 weeks</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">
              Duration - Extended (Beyond Regular)
            </div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container"> 60 days / 8 weeks</div>
              </div>
              {/* <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container"> 60 days / 8 weeks</div>
              </div> */}
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container"> 60 days / 8 weeks</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Schedule</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container"> 2 Hours / day (M-F)</div>
              </div>
              {/* <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container"> 4 Hours / day (3 days)</div>
              </div> */}
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container"> 9 Hours / day (M-F)</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Professional Service Job Interview</div>
          </div>
          <div className="container">
            <div className="row ">
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.00047 7.05767L11.3003 3.75781L12.2431 4.70062L8.94327 8.00047L12.2431 11.3003L11.3003 12.2431L8.00047 8.94327L4.70062 12.2431L3.75781 11.3003L7.05767 8.00047L3.75781 4.70062L4.70062 3.75781L8.00047 7.05767Z"
                      fill="#9C9DA1"
                    />
                  </svg>
                </div>
              </div>
              {/* <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">5 guaranteed client interview</div>
              </div> */}
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">10 guaranteed client interview</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Job Assistance</div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
              {/* <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div> */}
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6.66844 10.1141L12.7967 3.98584L13.7395 4.92865L6.66844 11.9997L2.42578 7.75709L3.36859 6.81429L6.66844 10.1141Z"
                      fill="#065BD6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          // style={{ marginBottom: "3rem", borderBottom: "1px solid #EBECEE" }}
        >
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Salary Expectation</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">Open</div>
              </div>
              {/* <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">Industry Standard</div>
              </div> */}
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container">Premium</div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ borderBottom: "1px solid #EBECEE" }}
        >
          {" "}
          <div id="mobile_po_single_bg">
            <div className="container">Pricing</div>
          </div>
          <div className="container">
            {" "}
            <div className="row ">
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div
                  className="container"
                  style={{
                    fontSize: "0.8125rem",
                    fontWeight: "600",
                    lineHeight: "1.25rem",
                  }}
                >
                  ₹25,000*
                </div>
              </div>
              {/* <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container" style={{
                    fontSize: "0.8125rem",
                    fontWeight: "600",
                    lineHeight: "1.25rem",
                  }}>₹85,000*</div>
              </div> */}
              <div className="col-6 " id="mobile_po_detail_bg" align="center">
                <div className="container" style={{
                    fontSize: "0.8125rem",
                    fontWeight: "600",
                    lineHeight: "1.25rem",
                  }}>₹1,60,000*</div>
              </div>
            </div>
          </div>
        </div>
        <div
          id=""
          align="end"
          style={{
            marginBottom: "3rem",
            marginTop: "0.8rem",
            fontSize: "0.625rem",
            fontWeight: "400",
            lineHeight: "1rem",
            color: "#79818D",
            marginRight:"1rem"
          }}
        >
          *GST Applicable
        </div>
      </div>
    </div>
  );
}

export default ProgramOverview;
