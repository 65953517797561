import { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import "../Styles/TechiePro/ApplyForm.css";
import axios from "axios";
import qs from "qs";
import india_img from "../Assets/india_img.png";
import success_tick from "../Assets/success_tick_img.png";
import tpro_img from "../Assets/t_pro.png";
import tnerd_img from "../Assets/t_nerds.png";
import tsuper_img from "../Assets/t_super.png";
import { PulseLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import "../Styles/BookForm.css";
import book_now_img from "../Assets/book_now_img.png";
function BookNowBangMobile(props) {
  const [show, setShow] = useState(props.show);
  // let show = props.show;
//   console.log(show, "sd");
//   console.log(props.location, "hj");
  const handleClose = () => setShow(false);
  const location = useLocation();
  //   const handleShow = () => setShow(true);
//   console.log(handleClose);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pov, setPov] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState();
  const [status, setStatus] = useState();
  // const location =
  const reqData = {
    mod: "Candidate",
    actionType: "office-visit",
    subAction: JSON.stringify({
      NAME: name,
      PHONE_NUMBER: phone,
      EMAIL: email,
      PURPOSE_OF_VISIT: pov,
      DATE: date,
      TIME: time,
      BRANCH:"Bangalore"
    }),
    // EMAIL: mail,
  };
  const handleSubmit = (e) => {
    console.log(name, phone, email, date, time, "gh");
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "data");
      setLoading(false);
      setStatus(res.data.XSCStatus);
      if (res.data.XSCStatus === 0) {
        setName("");
        setPhone("");
        setEmail("");
        setDate("");
        setTime("");
        setPov("");
      }
    });
  };

  return (
    <div id="book_now">
    
      <div className="" id="mobile_apply_form">
      
        <Modal
          id="main-modal"
          className="d-lg-none d-block"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
        <Modal.Header closeButton className="position-absolute top-0" style={{right:"0"}}>
            <Modal.Title id=""></Modal.Title>
            sad
          </Modal.Header>
          <Modal.Body>
         
            <div id="mobile_register_form">
              <div id="apply_for_text_bg">
                <div id="apply_for_text">
                  Visit NYINST Office{" "}
                  <span style={{ color: "#006BFF" }}>Bangalore</span>
                </div>
              </div>

              <div>
                <form className="position-relative" onSubmit={handleSubmit}>
                  <div className="position-relative" id="name_input_field">
                    <input
                      name="bang_name"
                      id="bang_name"
                      className="px-2"
                      value={name}
                      onChange={(e) => setName(e.target.value.trimStart())}
                      // onChange={(e) => setCollege({ collegeName: e.target.value })}
                      // onChange={(e) =>
                      //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                      // }
                      type="text"
                      // value={collegeName}
                      // placeholder="College/University Name"

                      required
                    />
                    <label
                      htmlFor="bang_name"
                      className="position-absolute start-0 top-0 input-label"
                    >
                      Name
                    </label>
                  </div>
                  <div className="position-relative" id="name_input_field">
                    <input
                      name="bang_email"
                      id="bang_email"
                      // className="px-2"
                      className={email.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase().trimStart())}
                      // onChange={(e) => setCollege({ collegeName: e.target.value })}
                      // onChange={(e) =>
                      //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                      // }
                      type="email"
                      // value={collegeName}
                      // placeholder="College/University Name"

                      required
                    />
                    <label
                      className="position-absolute start-0 top-0 input-label"
                      htmlFor="bang_email"
                    >
                      Email
                    </label>
                  </div>
                  <div id="name_input_field">
                    <div className="row">
                      <div className="col-3">
                        <div id="india_num">
                          <img src={india_img} alt="" />
                          <span className="" style={{ paddingLeft: "0.25rem" }}>
                            +91
                          </span>
                        </div>
                      </div>
                      <div className="col-9 ps-0 ms-0">
                        <div
                          className="position-relative"
                          id="name_input_field"
                        >
                          <input
                            name="bang_phone"
                            id="bang_phone"
                            // className="px-2"
                            className={phone.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                            value={phone}
                            maxLength={10}
                            onChange={(e) =>
                              setPhone(e.target.value.replace(/\D/g, ""))
                            }
                            // onChange={(e) => setCollege({ collegeName: e.target.value })}
                            // onChange={(e) =>
                            //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                            // }
                            type="phone"
                            // value={collegeName}
                            // placeholder="College/University Name"

                            required
                          />
                          <label
                            htmlFor="bang_phone"
                            className="position-absolute start-0 top-0 input-label"
                          >
                            Phone Number
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="position-relative" id="name_input_field">
                    <input
                      name="book_visit"
                      id="book_visit"
                      className="px-2"
                      value={pov}
                      onChange={(e) => setPov(e.target.value.trimStart())}
                      // onChange={(e) => setCollege({ collegeName: e.target.value })}
                      // onChange={(e) =>
                      //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                      // }
                      type="text"
                      // value={collegeName}
                      // placeholder="College/University Name"

                      required
                    />
                    <label
                      className="position-absolute start-0 top-0 input-label"
                      htmlFor="book_visit"
                    >
                     Purpose of Visit
                    </label>
                  </div>
                  <div id="name_input_field">
                    <div className="row">
                      <div className="col-6">
                        <div
                          className="position-relative"
                          id="name_input_field"
                        >
                          <input
                            name="bang_date"
                            id="bang_date"
                            className="px-2"
                            value={date}
                            formTarget="YYYY-MM-DD"
                            onChange={(e) => setDate(e.target.value.trimStart())}
                            // onChange={(e) => setCollege({ collegeName: e.target.value })}
                            // onChange={(e) =>
                            //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                            // }
                            type="date"
                            // value={collegeName}
                            // placeholder="College/University Name"

                            required
                          />
                          <label
                            htmlFor="bang_date"
                            className="position-absolute start-0 top-0 input-label"
                          >
                            Date
                          </label>
                        </div>
                      </div>
                      <div className="col-6 ps-0">
                        <div
                          className="position-relative"
                          id="name_input_field"
                        >
                          <input
                            name="bang_time"
                            id="bang_time"
                            className="px-2"
                            value={time}
                            
                            onChange={(e) => setTime(e.target.value.trimStart())}
                            // onChange={(e) => setCollege({ collegeName: e.target.value })}
                            // onChange={(e) =>
                            //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                            // }
                            type="time"
                            // value={collegeName}
                            // placeholder="College/University Name"

                            required
                          />
                          <label
                            htmlFor="bang_time"
                            className="position-absolute start-0 top-0 input-label"
                          >
                            Time
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mobile_border_bottom" className=""></div>

                  <div id="mobile_form_submit_btn">
                    {status === 0 ? (
                      <button
                        className="btn"
                        type="submit"
                        style={{
                          height: "3.25rem",
                          background: "#02EE6A14",
                          border: "2px solid #02EE6A",
                          color: "#02EE6A",
                          fontSize: "0.8rem",
                          lineHeight: "2rem",
                        }}
                      >
                        <img
                          src={success_tick}
                          alt=""
                          style={{ height: "20px", width: "20px" }}
                        />{" "}
                        Thanks For Submitting!
                      </button>
                    ) : (
                      <button className="btn" type="submit">
                        Submit{" "}
                        {loading ? <PulseLoader color="white" size={6} /> : ""}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>

          {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}

export default BookNowBangMobile;
