import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import Footer from "../Pages//Footer";
import Header from "../Pages/Header";
import block_chain_seminar_img1 from "../Assets/bethlehem_img.jpg";


const BethCollegeEvent = () => {
  return (
    <div>
      <Header />
      <div className="mb-5 mt-5 mt-lg-0" style={{ background: "#065BD6" }}>
        <div className="container py-5">
          <div className="row container">
            <div
              className="col-lg-2 p-0 m-0 text-white text-truncate"
              style={{
                fontSize: "13px",
                lineHeight: "16px",
                whiteSpace: "no-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <a href="/events" className=" text-white">
                Meet & greet Home <AiOutlineRight />
              </a>{" "}
              NYINST conducted a grand pool recruitment drive at Bethlehem
              Institute of Engineering, Karungal, Kanyakumari district{" "}
            </div>
          </div>
          <div className="row">
            <div className=" col-lg-2 text-white"></div>
            <div className="col-lg-8 text-white ">
              <div style={{ paddingTop: "60px" }}>
                <div
                  className="d-flex"
                  style={{
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontWeight: "500",
                  }}
                >
                  <div className="text-start">20 FEB 2019 </div>
                  <div
                    className="mobile_dot_position"
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      fontSize: "2rem",
                      marginTop: "-1%",
                    }}
                  >
                    .
                  </div>
                  <div> 6:00pm</div>
                </div>

                <div className="text-start events_title_font_style">
                  NYINST conducted a grand pool recruitment drive at
                  Bethlehem Institute of Engineering, Karungal, Kanyakumari
                  district
                </div>
                <div
                  className="container mobile_hero_event_height"
                  style={{ height: "15rem" }}
                >
                  <img
                    src={block_chain_seminar_img1}
                    className="w-100 "
                    style={{ bottom: "-50%" }}
                    alt="events details"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
      {/* events bottom height */}
      <div
        className="mobile_bottom_event_height"
        style={{ height: "15rem" }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="py-3 mt-lg-5 events_content_font_style">
              Students from various other colleges also took part in the
              preliminary test and secured their career with NYINST GTM
              training-placement program.
              <br />
              <br />
              We heartily congratulate the students and staff of these colleges:
              <br />
              1. Bethlehem Institute of Engineering
              <br />
              2. Rajas International Engineering College
              <br />
              3. DMI Engineering College
              <br />
              4. Marthandam Engineering College
              <br />
              5. Stella Mary’s Engineering College
              <br />
              NYINST conducted a grand pool recruitment drive at Bethlehem
              Institute of Engineering, Karungal{" "}
            </div>
          </div>

          <div className="col-lg-2"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BethCollegeEvent;
