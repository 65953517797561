import React, { useEffect, useRef, useState } from "react";
import "../Styles/LandingPage/RegisterWebinar.css";
// import register_girll_img from "../Assets/LandingPage/register_girl_img.png";
import green_tick_img from "../Assets/LandingPage/green_tick.png";
import axios from "axios";
import qs from "qs";
import { PulseLoader } from "react-spinners";
import success_tick from "../Assets/success_tick_img.png";
function RegisterWebinar() {
  useEffect(() => {
    // console.log("Active ref in TechieHeroSection:", menu);
  }, ["webinar"]);
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "/Best-Full-stack-development-training-institute-in-bangalore#webinar") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  const [scrollPosition, setScrollPosition] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 0 ? setScrollPosition("sticky") : setScrollPosition("");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleMenu = () => {
    // setActiveMenu(false);
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [year, setYear] = useState("");
  const [deg, setDeg] = useState("");
  const [loading, setLoading] = useState();
  const [status, setStatus] = useState();
  // const location =
  const reqData = {
    mod: "Candidate",
    actionType: "add-user-biodata",
    subAction: JSON.stringify({
      NAME: name,
      MOBILE_NUMBER: phone,
      EMAIL: email,
      YOP: year,
      DEGREE: deg,
      INTERESTED_COURSE:"Fullstack Webinar"
    }),
    // EMAIL: mail,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "data");
      setLoading(false);
      setStatus(res.data.XSCStatus);
      if (res.data.XSCStatus === 0) {
        setName("");
        setPhone("");
        setEmail("");
        setYear("");
        setDeg("");
      }
    });
  }
   console.log(myRef.current,"s")
  return (
    <div id="webinar" ref={myRef}>
      {/* <div id="width" style={{width:"1349px", height:"768px", backgroundColor:"aqua"}}></div> */}

      <div className="container ">
        <div id="land_reg_bg" className="d-lg-block d-md-block d-none">
          <div className="row">
            <div className="col-lg-6 col-md-12  pe-0 me-0">
              <div id="reg_girl_img" style={{ borderRadius: "1rem" }}>
                {/* <img
                  src={register_girll_img}
                  alt=""
                  width="100%"
                  height="100%"
                /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div id="register_form">
                <div id="land_reg_title">
                  Register
                  <br /> <span id="free_text">For Free Webinar</span>
                </div>
                <div className="d-flex" id="reg_point">
                  <div className="d-flex align-items-center ">
                    <div id="green_tick_img">
                      <img src={green_tick_img} alt="" />
                    </div>
                    <div>Access to Expert Knowledge</div>
                  </div>

                  <div
                    className="d-flex align-items-center "
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <div id="green_tick_img">
                      <img src={green_tick_img} alt="" />
                    </div>
                    <div>Skill Enhancement</div>
                  </div>
                </div>
                <div className="d-flex" id="reg_point">
                  <div className="d-flex align-items-center ">
                    <div id="green_tick_img">
                      <img src={green_tick_img} alt="" />
                    </div>
                    <div>Cost-Effective Learning</div>
                  </div>

                  <div
                    className="d-flex align-items-center "
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <div id="green_tick_img">
                      <img src={green_tick_img} alt="" />
                    </div>
                    <div>Interactive Learning Experience</div>
                  </div>
                </div>
                <div className="row " style={{ marginRight: "1.5rem" }}>
                  <form onSubmit={handleSubmit}>
                    <div id="name_input_field">
                      <div className="row">
                        <div className="col-6">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="Name"
                              id="Name"
                              className=""
                              value={name}
                              onChange={(e) =>
                                setName(e.target.value.trimStart())
                              }
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="Name"
                              className="position-absolute start-0 top-0 input-label"
                            >
                              Name
                            </label>
                          </div>
                        </div>
                        <div className="col-6 ps-0">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="email"
                              id="email"
                              className={email.trim() !== "" ? "has_value" :"no_value" }
                              onChange={(e) =>
                                setEmail(e.target.value.toLowerCase().trimStart())
                              }
                              value={email}
                              type="email"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              className="position-absolute start-0 top-0 input-label"
                              htmlFor="email"
                            >
                              Email
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="name_input_field">
                      <div className="row">
                        <div className="col-6">
                          <div id="name_input_field">
                            <div className="row">
                              {/* <div className="col-3 pe-0 me-0">
                                <div id="india_num">
                                  <img src={india_img} alt="" />
                                  <span className="ps-1">+91</span>
                                </div>
                              </div> */}
                              <div className=" ps-0">
                                <div
                                  className="position-relative phone_number"
                                  id="name_input_field"
                                >
                                  <input
                                    name="phone_number"
                                    id="phone_number"
                                    className={phone.trim() !== "" ? "has_value" :"no_value" }
                                    value={phone}
                                    maxLength={10}
                                    pattern="[0-9]{10}"
                                    onChange={(e) =>
                                      setPhone(
                                        e.target.value.replace(/\D/g, "")
                                      )
                                    }
                                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                                    // onChange={(e) =>
                                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                                    // }
                                    type="text"
                                    // value={collegeName}
                                    // placeholder="College/University Name"

                                    required
                                  />
                                  <label
                                    htmlFor="phone_number"
                                    className="position-absolute start-0 top-0  input-label"
                                  >
                                    Phone Number
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 ps-0">
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="graduation_year"
                              id="graduation_year"
                              className=""
                              value={year}
                              maxLength={4}
                              pattern="[0-9]{4}"
                              onChange={(e) =>
                                setYear(e.target.value.replace(/\D/g, ""))
                              }
                              // onChange={(e) => setCollege({ collegeName: e.target.value })}
                              // onChange={(e) =>
                              //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                              // }
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="graduation_year"
                              className="position-absolute start-0 top-0 input-label"
                            >
                              Graduation Year
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="Degree"
                          id="Degree"
                          className=""
                          value={deg}
                          onChange={(e) => setDeg(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="Degree"
                          className="position-absolute start-0 top-0  input-label"
                        >
                          Degree
                        </label>
                      </div>
                    </div>

                    <div id="form_submit_btn" style={{ marginTop: "1.5rem", marginBottom:"1.5rem" }}>
                      {status === 0 ? (
                        <button
                          className="btn"
                          type="submit"
                          style={{
                            height: "3.25rem",
                            background: "#02EE6A14",
                            border: "2px solid #02EE6A",
                            color: "#02EE6A",
                            fontSize: "0.8rem",
                            lineHeight: "2rem",
                          }}
                        >
                          <img
                            src={success_tick}
                            alt=""
                            style={{ height: "20px", width: "20px" }}
                          />{" "}
                          Thanks For Submitting!
                        </button>
                      ) : (
                        <button className="btn" type="submit">
                          Submit{" "}
                          {loading ? (
                            <PulseLoader color="white" size={6} />
                          ) : (
                            ""
                          )}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* for mobiles */}
        <div id="mob_land_reg_bg" className="d-lg-none d-md-none d-block">
          <div className="row">
            <div className="col-md-6 w-100">
              <div id="mob_reg_girl_img" style={{ borderRadius: "1rem" }}>
                {/* <img
                  src={register_girll_img}
                  alt=""
                  width="100%"
                  height="100%"
                /> */}
              </div>
            </div>
            <div className="col-md-6 w-100">
              <div id="mob_register_form" >
                <div id="mob_land_reg_title">
                  Register
                  <br /> <span id="mob_free_text">For Free Webinar</span>
                </div>
                <div className="" id="mob_reg_point" style={{marginTop:"0.4rem"}}>
                  <div
                    className="d-flex align-items-center "
                    style={{ marginTop: "0.5rem" }}
                  >
                    <div id="mob_green_tick_img">
                      <img src={green_tick_img} alt="" />
                    </div>
                    <div>Access to Expert Knowledge</div>
                  </div>

                  <div
                    className="d-flex align-items-center "
                    style={{ marginTop: "0.5rem" }}
                  >
                    <div id="mob_green_tick_img">
                      <img src={green_tick_img} alt="" />
                    </div>
                    <div>Skill Enhancement</div>
                  </div>
                </div>
                <div className="" id="mob_reg_point">
                  <div
                    className="d-flex align-items-center "
                    style={{ marginTop: "0.5rem" }}
                  >
                    <div id="mob_green_tick_img">
                      <img src={green_tick_img} alt="" />
                    </div>
                    <div>Cost-Effective Learning</div>
                  </div>

                  <div
                    className="d-flex align-items-center "
                    style={{ marginTop: "0.5rem" }}
                  >
                    <div id="mob_green_tick_img">
                      <img src={green_tick_img} alt="" />
                    </div>
                    <div>Interactive Learning Experience</div>
                  </div>
                </div>
                <div className="row w-100"align="center" >
                <form className="position-relative pe-0 me-0" onSubmit={handleSubmit}>
                <div className="position-relative " id="name_input_field">
                  <input
                    name="mobile_name"
                    id="mobile_name"
                    className="px-2"
                    value={name}
                    onChange={(e) => setName(e.target.value.trimStart())}
                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                    // onChange={(e) =>
                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                    // }
                    type="text"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    htmlFor="mobile_name"
                    className="position-absolute start-0 top-0 input-label"
                  >
                    Name
                  </label>
                </div>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="mobile_email"
                    id="mobile_email"
                    // className="px-2"
                    className={email.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase().trimStart())}
                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                    // onChange={(e) =>
                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                    // }
                    type="email"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    className="position-absolute start-0 top-0 input-label"
                    htmlFor="mobile_email"
                  >
                    Email
                  </label>
                </div>
                <div id="name_input_field">
                      
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="mobile_phone_number"
                          id="mobile_phone_number"
                          // className="px-2"

                          className={phone.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                          value={phone}
                          onChange={(e) =>
                            setPhone(e.target.value.replace(/\D/g, ""))
                          }
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="phone"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="mobile_phone_number"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Phone Number
                        </label>
                      </div>
                </div>
                <div id="name_input_field">
                  <div className="">
                    <div className="">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="mobile_year"
                          id="mobile_year"
                          className="px-2"
                          value={year}
                          onChange={(e) => setYear(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="mobile_year"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Graduation Year
                        </label>
                      </div>
                    </div>
                    <div className=" ps-0">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="mobile_degree"
                          id="mobile_degree"
                          className="px-2"
                          value={deg}
                          onChange={(e) => setDeg(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="mobile_degree"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Degree
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="mobile_border_bottom" className=""></div>

                <div id="mobile_form_submit_btn" style={{marginBottom:"1.75rem"}}>
                  {status === 0 ? (
                    <button
                      className="btn"
                      type="submit"
                      style={{
                        height: "3.25rem",
                        background: "#02EE6A14",
                        border: "2px solid #02EE6A",
                        color: "#02EE6A",
                        fontSize: "0.8rem",
                        lineHeight: "2rem",
                      }}
                    >
                      <img
                        src={success_tick}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />{" "}
                      Thanks For Submitting!
                    </button>
                  ) : (
                    <button className="btn" type="submit">
                      Submit{" "}
                      {loading ? <PulseLoader color="white" size={6} /> : ""}
                    </button>
                  )}
                </div>
              </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterWebinar;
