import React from "react";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
// import {AiFillPlayCircle,AiOutlineRight} from "react-icons/ai";
import brud_crumbs_arrow_icon from "../Assets/brudcrums_arrow_icon.svg";
import python_blog_img from "../Assets/python_blog_img.avif";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import blog_details2_img from "../Assests/Images/blog_details2_img.png";
const PythonBlogDetails = () => {
  return (
    <div>
      <Header />
      <div className="py-3" style={{ background: "#FAFAFB" }}>
        <div className="container">
          <div className="d-flex align-items-center mt-5 ">
            <div className="d-flex mt-5 align-items-center">
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: "#065BD6",
                  lineHeight: "16px",
                }}
              >
               <a href="/ezine" className="text-decoration-none">EZine</a>  
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <LazyLoadImage src={brud_crumbs_arrow_icon} width="100%"></LazyLoadImage>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#07152C",
                }}
              >
                {" "}
                Python...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex py-3 mt-5">
          <div className="">
            <div
              className=""
              style={{
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                lineHeight: "16px",
                background: "#07152C",
                opacity: "100%",
                bottom: "12px",
                left: "20px",
                Zindex: "3",
                padding: "4px 12px",
                borderRadius: "12px",
              }}
            >
              Technology
            </div>
          </div>
          <div
            className="d-flex my-auto px-2"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#6A7380",
            }}
          >
            <div>16 APR 2022</div>
            <div
              style={{
                paddingLeft: "8px",
                fontSize: "2rem",
                marginTop: "-5%",
              }}
            >
              .
            </div>
            <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 ">
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "50px",
                  lineHeight: "60px",
                  marginTop: "36px",
                }}
              >
                Top Reasons why you should choose Python for Big Data
              </div>
            </div>
            <div className="mt-5">
              <LazyLoadImage
                src={python_blog_img}
                className="w-100"
                alt="Top Reason To Choose Python"
              />
            </div>
            <div className="py-3"></div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Python is considered as one of the most popular languages and is
              in fact, included amongst the top 10 most-used programming
              languages according to TIOBE and the IEEE Spectrum. This is
              primarily because Python can be used for a range of developments
              such as GUIs, web applications, websites and much more. While even
              taking care of common tasks, Python also helps developers focus on
              the core functionality of the application. A high-level
              programming language, it still comes with simple syntax rules
              making base code easier to read and convenient to maintain.{" "}
              <div className="mt-4">
                For Big Data, Python provides a number of libraries and is
                faster than most other programming languages. Besides, Data
                Types in Python is easy to handle as well. For example, if you
                must write 200 lines of code in JAVA, you can accomplish the
                same task with Python in just 20 lines of code. With no
                limitations to data, processing can be achieved on community
                hardware, laptop, desktop, and other such mediums.
              </div>
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Big Data and Python –a great combination
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              While using many advanced libraries such as SciPy, NumPy, and
              Matplotlib, Python also encompasses other scripting features,
              besides having to write fewer codes. The tool is highly readable,
              and in combination with Big Data, Python can perform extremely
              well. Here is why a combination of Big Data and Python is perfect:
            </div>

            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Hadoop
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              While Hadoop remains one of the most popular open source data
              platforms for Big Data, it is Python’s inherent compatibility with
              Hadoop that makes it the most preferred language over others.
              Developers can write Hadoop MapReduce applications when the PyDoop
              package provides access to HDFS API for Hadoop. Thus, you can also
              connect the program to an HDFS installation that contains global
              file system properties and allows you to read, write and obtain
              information on files.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Library of scientific packages
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Backed by its library of packages, the Python Big Data combine can
              fulfill every data and analytical requirement. Some of the best
              libraries available are:
            </div>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              <li>
                analyticsNumPy: Makes scientific computing possible, provides
                avenues for random number calculations, linear algebra, Fourier
                transforms and shape manipulations. It also performs an array of
                extensive high-level mathematical functions.
              </li>
              <li>
                SciPy: Beneficial for both scientific and technical computing,
                it contains varied modules meant for integration, interpolation,
                optimization, linear algebra, and others
              </li>
              <li>
                others: There are other machine learning libraries and
                publication formats such as Mlpy, Matplotlib, Theano, SymPy,
                Dmelt and many more
              </li>
            </div>
            <div className="mt-5 mb-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Easily learnable as is easily maintainable
              </div>

              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Evennon-programmers can easily learn Python and that is why it
                is also considered as the primary language –due to its readable
                code, large community to maintain it and abundance in learning
                resources. It also encompasses some user-friendly features and a
                scripting attribute as well besides readability, auto
                identification, simple syntax, and easy implementation.
              </div>
              <div
                className="mt-5"
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Community Support
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Solutions in Big Data can often be complex and requires support
                from a community. Backed by a large and active community, Python
                helps programmers, developers and data scientists with all
                coding-related issues –gaining more popularity in the process.
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Faster and scalable
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Python comes with immense scalability, which is extremely
                important when you are dealing with massive data. The language
                is also much faster than other data science languages such as
                MatLab, R, or Stata. Anaconda enhanced the performance of Python
                and thereafter, is said to offer great flexibility, making it an
                ideal choice for Big Data.
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                Visualization
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Python has also improved its offering as far as Data
                Visualization is concerned. It is also the toughest competitor
                to R that also offers superior Data Visualization. With
                libraries such as Pygal, NetworkX, etc., and some fantastic APIs
                such as Plotly, Python now offers stunning data visualizations.
              </div>
              <div
                className="mt-5 "
                style={{
                  fontWeight: "700",
                  fontSize: "25px",
                  lineHeight: "40px",
                }}
              >
                At NYINST
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                As a general-purpose programming language, Python has the
                ability to simplify some of the most complex software
                developments. Custom big data solutions can be designed without
                putting extra time and effort when you leverage data analysis
                features of Python. Visualize and present data appealingly and
                even accomplish Artificial Intelligence and natural language
                processing tasks, easily with Python.
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Usually, big data analysts fresh to the field, struggle to
                choose the right kind of programming language for their project.
                NYINST eliminates ambiguity and teaches you the best way
                to execute any big data project. We will also teach you the
                importance of choosing the right kind of programming language
                before you start developing the project, especially when you
                must leverage big data analysis.
              </div>
            </div>
          </div>
          {/* <div className='col-md-3'>Enquiry Now</div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PythonBlogDetails;
