import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../Styles/TechiePro/ApplyForm.css";
import axios from "axios";
import qs from "qs";
import india_img from "../Assets/india_img.png";
import success_tick from "../Assets/success_tick_img.png";
import tpro_img from "../Assets/t_pro.png";
import tnerd_img from "../Assets/t_nerds.png";
import tintern_img from "../Assets/t_intern.png";
import tsuper_img from "../Assets/t_super.png";
import { PulseLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
function ApplyNow(props) {
  const [show, setShow] = useState(props.show);
  // let show = props.show;
  console.log(show, "sd");
  const handleClose = () => setShow(false);
  const location = useLocation();
  //   const handleShow = () => setShow(true);
  console.log(handleClose);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [year, setYear] = useState("");
  const [deg, setDeg] = useState("");
  const [loading, setLoading] = useState();
  const [status, setStatus] = useState();
  // const location =
  const reqData = {
    mod: "Candidate",
    actionType: "add-user-biodata",
    subAction: JSON.stringify({
      NAME: name,
      MOBILE_NUMBER: phone,
      EMAIL: email,
      YOP: year,
      DEGREE: deg,
      INTERESTED_COURSE:
        location.pathname === "/techie-pro"
          ? "Techie-Pro"
          : location.pathname === "/techie-nerds"
          ? "Techie-Nerds"
          : location.pathname === "/techie-super"
          ? "Techie-Super"
          : location.pathname === "/techie-intern"
          ? "Techie-Intern"
          : "Techie-Pro",
    }),
    // EMAIL: mail,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "data");
      setLoading(false);
      setStatus(res.data.XSCStatus);
      if (res.data.XSCStatus === 0) {
        setName("");
        setPhone("");
        setEmail("");
        setYear("");
        setDeg("");
      }
    });
  };

  return (
    <div id="apply_form_bg">
      <div className="" id="apply_form">
        {" "}
        <Modal
          className="d-lg-block d-none program_modal"
          {...props}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          {/* <Modal.Body> */}
          <div id="hero_form" className="mt-0 pt-0">
            <div id="apply_for_text_bg">
              <div
                id="apply_tpro_img_bg"
                style={{
                  background:
                    location.pathname === "/techie-pro"
                      ? "#E252341A"
                      : location.pathname === "/techie-nerds"
                      ? "#D2961D1A"
                      : location.pathname === "/techie-super"
                      ? "#8625D21A"
                      : location.pathname === "/techie-intern"
                      ? "#639C191A"
                      : "",
                }}
              >
                <img
                  src={
                    location.pathname === "/techie-pro"
                      ? tpro_img
                      : location.pathname === "/techie-nerds"
                      ? tnerd_img
                      : location.pathname === "/techie-super"
                      ? tsuper_img
                      : location.pathname === "/techie-intern"
                      ? tintern_img
                      : ""
                  }
                  alt=""
                />
              </div>
              <div
                id="apply_for_text"
                style={{
                  fontSize: "2rem",
                  fontWeight: "700",
                  lineHeight: "2.75rem",
                }}
              >
                Apply for <br />
                <span
                  id="apply_tpro_text"
                  style={{
                    fontSize: "2rem",
                    fontWeight: "600",
                    lineHeight: "2.75rem",
                  }}
                >
                  {" "}
                  {location.pathname === "/techie-pro"
                    ? "Techie Pro"
                    : location.pathname === "/techie-nerds"
                    ? "Techie Nerds"
                    : location.pathname === "/techie-super"
                    ? "Techie Super"
                    : location.pathname === "/techie-intern"
                    ? "Techie Intern"
                    : ""}
                </span>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="position-relative" id="name_input_field">
                <input
                  name="nerdName"
                  id="nerdName"
                  className=""
                  value={name}
                  onChange={(e) => setName(e.target.value.trimStart())}
                  type="text"
                  // value={collegeName}
                  // placeholder="College/University Name"

                  required
                />
                <label
                  htmlFor="nerdName"
                  className="position-absolute start-0 top-0 input-label"
                >
                  Name
                </label>
              </div>
              <div className="position-relative" id="name_input_field">
                <input
                  name="nerdemai"
                  id="nerdemai"
                  className={email.trim() !== "" ? "has_value" : "no_value"}
                  onChange={(e) =>
                    setEmail(e.target.value.toLowerCase().trimStart())
                  }
                  value={email}
                  type="text"
                  // value={collegeName}
                  // placeholder="College/University Name"

                  required
                />
                <label
                  className="position-absolute start-0 top-0 input-label"
                  htmlFor="nerdemai"
                >
                  Email
                </label>
              </div>

              <div id="name_input_field">
                <div className="row">
                  <div className="col-3 pe-0 me-0">
                    <div id="india_num">
                      <img src={india_img} alt="" />
                      <span className="ps-1">+91</span>
                    </div>
                  </div>
                  <div className="col-9 ps-0">
                    <div
                      className="position-relative phone_number"
                      id="name_input_field"
                    >
                      <input
                        name="nerdphon"
                        id="nerdphon"
                        className={
                          phone.trim() !== "" ? "has_value" : "no_value"
                        }
                        value={phone}
                        maxLength={10}
                        pattern="[0-9]{10}"
                        onChange={(e) =>
                          setPhone(e.target.value.replace(/\D/g, ""))
                        }
                        // onChange={(e) => setCollege({ collegeName: e.target.value })}
                        // onChange={(e) =>
                        //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                        // }
                        type="text"
                        // value={collegeName}
                        // placeholder="College/University Name"

                        required
                      />
                      <label
                        htmlFor="nerdphon"
                        className="position-absolute start-0 top-0  input-label"
                      >
                        Phone Number
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div id="name_input_field">
                <div className="row">
                  <div className="col-6">
                    <div className="position-relative" id="name_input_field">
                      <input
                        name="nerdgra"
                        id="nerdgra"
                        className=""
                        value={year}
                        maxLength={4}
                        pattern="[0-9]{4}"
                        onChange={(e) =>
                          setYear(e.target.value.replace(/\D/g, ""))
                        }
                        // onChange={(e) => setCollege({ collegeName: e.target.value })}
                        // onChange={(e) =>
                        //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                        // }
                        type="text"
                        // value={collegeName}
                        // placeholder="College/University Name"

                        required
                      />
                      <label
                        htmlFor="nerdgra"
                        className="position-absolute start-0 top-0 input-label"
                      >
                        Graduation Year
                      </label>
                    </div>
                  </div>
                  <div className="col-6 ps-0">
                    <div className="position-relative" id="name_input_field">
                      <input
                        name="nerdde"
                        id="nerdde"
                        className=""
                        value={deg}
                        onChange={(e) => setDeg(e.target.value.trimStart())}
                        // onChange={(e) => setCollege({ collegeName: e.target.value })}
                        // onChange={(e) =>
                        //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                        // }
                        type="text"
                        // value={collegeName}
                        // placeholder="College/University Name"

                        required
                      />
                      <label
                        htmlFor="nerdde"
                        className="position-absolute start-0 top-0  input-label"
                      >
                        Degree
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div id="border_bottom" className=""></div>

              <div id="form_submit_btn">
                {status === 0 ? (
                  <button
                    className="btn"
                    type="submit"
                    style={{
                      height: "3.25rem",
                      background: "#02EE6A14",
                      border: "2px solid #02EE6A",
                      color: "#02EE6A",
                      fontSize: "0.8rem",
                      lineHeight: "2rem",
                    }}
                  >
                    <img
                      src={success_tick}
                      alt=""
                      style={{ height: "20px", width: "20px" }}
                    />{" "}
                    Thanks For Submitting!
                  </button>
                ) : (
                  <button className="btn" type="submit">
                    Submit{" "}
                    {loading ? <PulseLoader color="white" size={6} /> : ""}
                  </button>
                )}
              </div>
            </form>
          </div>
          {/* </Modal.Body> */}
          {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
        </Modal>
      </div>
      <div className="" id="mobile_apply_form">
        {" "}
        <Modal
          id="main-modal"
          className="d-lg-none d-block"
          {...props}
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <div id="mobile_register_form">
            <div id="apply_for_text_bg">
              <div
                id="apply_tpro_img_bg"
                style={{
                  height: "5rem",
                  width: "5rem",
                  background:
                    location.pathname === "/techie-pro"
                      ? "#E252341A"
                      : location.pathname === "/techie-nerds"
                      ? "#D2961D1A"
                      : location.pathname === "/techie-super"
                      ? "#8625D21A"
                      : location.pathname === "/techie-intern"
                      ? "#639C191A"
                      : "",
                }}
              >
                <img
                  src={
                    location.pathname === "/techie-pro"
                      ? tpro_img
                      : location.pathname === "/techie-nerds"
                      ? tnerd_img
                      : location.pathname === "/techie-super"
                      ? tsuper_img
                      : location.pathname === "/techie-intern"
                      ? tintern_img
                      : ""
                  }
                  alt=""
                  style={{
                    height: "4rem",
                    width: "4rem",
                    fontSize: "1.5rem",
                    lineHeight: "2rem",
                  }}
                />
              </div>
              <div id="apply_for_text">
                Apply for{" "}
                <span id="apply_tpro_text">
                  {location.pathname === "/techie-pro"
                    ? "Techie Pro"
                    : location.pathname === "/techie-nerds"
                    ? "Techie Nerds"
                    : location.pathname === "/techie-super"
                    ? "Techie Super"
                    : location.pathname === "/techie-intern"
                    ? "Techie Intern"
                    : ""}
                </span>
              </div>
            </div>

            <div>
              <form className="position-relative" onSubmit={handleSubmit}>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="nerdmobnam"
                    id="nerdmobnam"
                    className="px-2"
                    value={name}
                    onChange={(e) => setName(e.target.value.trimStart())}
                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                    // onChange={(e) =>
                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                    // }
                    type="text"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    htmlFor="nerdmobnam"
                    className="position-absolute start-0 top-0 input-label"
                  >
                    Name
                  </label>
                </div>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="nerdmobemai"
                    id="nerdmobemai"
                    className={
                      email.trim() !== "" ? "has_value px-2" : "no_value px-2"
                    }
                    value={email}
                    onChange={(e) =>
                      setEmail(e.target.value.toLowerCase().trimStart())
                    }
                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                    // onChange={(e) =>
                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                    // }
                    type="text"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    className="position-absolute start-0 top-0 input-label"
                    htmlFor="nerdmobemai"
                  >
                    Email
                  </label>
                </div>
                <div id="name_input_field">
                  <div className="row">
                    <div className="col-3">
                      <div id="india_num">
                        <img src={india_img} alt="" />
                        <span className="" style={{ paddingLeft: "0.25rem" }}>
                          +91
                        </span>
                      </div>
                    </div>
                    <div className="col-9 ps-0 ms-0">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="nerdmobphon"
                          id="nerdmobphon"
                          className={
                            phone.trim() !== ""
                              ? "has_value px-2"
                              : "no_value px-2"
                          }
                          value={phone}
                          maxLength={10}
                          onChange={(e) =>
                            setPhone(e.target.value.replace(/\D/g, ""))
                          }
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="phone"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="nerdmobphon"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Phone Number
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="name_input_field">
                  <div className="row">
                    <div className="col-6">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="nerdmobyea"
                          id="nerdmobyea"
                          className="px-2"
                          value={year}
                          onChange={(e) => setYear(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="nerdmobyea"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Graduation Year
                        </label>
                      </div>
                    </div>
                    <div className="col-6 ps-0">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="nerdmobde"
                          id="nerdmobde"
                          className="px-2"
                          value={deg}
                          onChange={(e) => setDeg(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="nerdmobde"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Degree
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="mobile_border_bottom" className=""></div>

                <div id="mobile_form_submit_btn">
                  {status === 0 ? (
                    <button
                      className="btn"
                      type="submit"
                      style={{
                        height: "3.25rem",
                        background: "#02EE6A14",
                        border: "2px solid #02EE6A",
                        color: "#02EE6A",
                        fontSize: "0.8rem",
                        lineHeight: "2rem",
                      }}
                    >
                      <img
                        src={success_tick}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />{" "}
                      Thanks For Submitting!
                    </button>
                  ) : (
                    <button className="btn" type="submit">
                      Submit{" "}
                      {loading ? <PulseLoader color="white" size={6} /> : ""}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}

export default ApplyNow;
