import React, { useState, useEffect, useRef } from "react";
import "../Styles/TechiePro/Faq.css";
import Accordion from "react-bootstrap/Accordion";

function Faq({ menu }) {
  useEffect(() => {
    // console.log("Active ref in TechieHeroSection:", menu);
  }, [menu]);
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "/techie-pro#faq") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  return (
    <div id="faq">
      {" "}
      <div className="d-none d-lg-block d-md-block">
        <div className="container">
          <div id="faq_title_bg" style={{ marginTop: "6.25rem" }}>
            <div id="faq_title_text" style={{ color: "#07152C" }}>
              Frequently Asked
              <span id="faq_ques" style={{ color: "#07152C" }}>
                {" "}
                Questions
              </span>{" "}
            </div>
            <div id="accordion_bg" style={{ marginTop: "5.25rem" }}>
              <Accordion alwaysOpen={false}>
                <Accordion.Item eventKey="0" id="accordian_item">
                  <Accordion.Header>
                    What are the qualifications required for the program?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    The program requires a minimum qualification of a B.Tech,
                    B.E, or MCA degree obtained from 2017 onwards & Strong
                    technical proficiency, problem-solving skills, and a
                    commitment to continuous learning are also key
                    qualifications.
                  </Accordion.Body>
                </Accordion.Item>{" "}
                <Accordion.Item eventKey="1" id="accordian_item">
                  <Accordion.Header>
                    Is there any selection process for the program?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, there is a selection process to ensure the right fit
                    for the program. This may include an application review,
                    counseling and assessments to assess technical skills and
                    overall suitability for the program.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" id="accordian_item">
                  <Accordion.Header>
                    How can I apply for Scholarship?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    To apply for a scholarship, you can visit our{" "}
                    <a
                      href="/scholarship"
                      className="text-decoration-none"
                      // target="_blank"
                    >
                      Scholarship page.
                    </a>{" "}
                    <span> </span>
                    Follow the instructions to submit your application,
                    including relevant documents and a personal statement
                    explaining why you deserve the scholarship.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" id="accordian_item">
                  <Accordion.Header>
                    Is there any EMI or Installment Payment available?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, we understand the financial commitment involved. We
                    offer EMI and installment payment options to make the Techie
                    PRO program more accessible. Details about payment plans are
                    available in the course details.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" id="accordian_item">
                  <Accordion.Header>
                    What’s the quickest way to reach you for any queries
                    regarding the program?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                  For quick queries, you can reach our support team by filling
                    the "Talk to our program manager" form on our website home
                    page; our team will contact you immediately. Alternatively,
                    you can email us at {" "}
                    <a
                      href="mailto:+contact@nyinst.com"
                      className="text-decoration-none"
                      // style={{ color: "#5B6574" }}
                    >
                      contact@nyinst.com
                    </a>{" "}
                    or call our helpline at{" "}
                    <a
                      href="tel:+91 9364 106 998"
                      className="text-decoration-none"
                      // style={{ color: "#5B6574" }}
                    >
                     +91 9364 106 998
                    </a>{" "}
                    We're here to assist you promptly.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <Accordion defaultActiveKey={["1"]}>
              
            </Accordion>
            <Accordion defaultActiveKey={["2"]}>
              
            </Accordion>
            <Accordion defaultActiveKey={["3"]}>
            
            </Accordion>
            <Accordion defaultActiveKey={["4"]}>
              
            </Accordion> */}
            </div>
          </div>
        </div>
      </div>
      {/* for mobiles */}
      <div
        id="mobile_faq"
        className="d-block d-md-none d-lg-none"
        style={{ marginTop: "2.5rem" }}
      >
        <div className="container">
          <div id="mobile_faq_title_bg">
            <div
              id="mobile_faq_title_text"
              style={{
                fontSize: "1.5rem",
                fontWeight: "800",
                lineHeight: "2rem",
                color: "#222F43",
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              Frequently Asked
              <br />
              <span
                id="mobile_faq_ques"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "800",
                  lineHeight: "2rem",
                  color: "#222F43",
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {" "}
                Questions
              </span>{" "}
            </div>
            <div id="mobile_accordion_bg">
              <Accordion alwaysOpen={false}>
                <Accordion.Item eventKey="0" id="mobile_accordian_item">
                  <Accordion.Header>
                    What are the qualifications required for the program?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    The program requires a minimum qualification of a B.Tech,
                    B.E, or MCA degree obtained from 2017 onwards & Strong
                    technical proficiency, problem-solving skills, and a
                    commitment to continuous learning are also key
                    qualifications.
                  </Accordion.Body>
                </Accordion.Item>{" "}
                <Accordion.Item eventKey="1" id="mobile_accordian_item">
                  <Accordion.Header>
                    Is there any selection process for the program?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, there is a selection process to ensure the right fit
                    for the program. This may include an application review,
                    counseling and assessments to assess technical skills and
                    overall suitability for the program.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" id="mobile_accordian_item">
                  <Accordion.Header>
                    How can I apply for Scholarship?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    To apply for a scholarship, you can visit our{" "}
                    <a
                      href="/scholarship"
                      className="text-decoration-none"
                      // target="_blank"
                    >
                      Scholarship page.
                    </a>{" "}
                    Follow the instructions to submit your application,
                    including relevant documents and a personal statement
                    explaining why you deserve the scholarship.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" id="mobile_accordian_item">
                  <Accordion.Header>
                    Is there any EMI or Installment Payment available?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, we understand the financial commitment involved. We
                    offer EMI and installment payment options to make the Techie
                    PRO program more accessible. Details about payment plans are
                    available in the course details.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" id="mobile_accordian_item">
                  <Accordion.Header>
                    What’s the quickest way to reach you for any queries
                    regarding the program?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    For quick queries, you can reach our support team by filling
                    the "Talk to our program manager" form on our website home
                    page; our team will contact you immediately. Alternatively,
                    you can email us at 
                    <a
                      href="mailto:+contact@nyinst.com"
                      // className="text-decoration-none"
                      // style={{ color: "#5B6574" }}
                    >
                      contact@nyinst.com
                    </a>{" "}
                    or call our helpline at{" "}
                    <a
                      href="tel:+91 9364 106 998"
                      // className="text-decoration-none"
                      // style={{ color: "#5B6574" }}
                    >
                      +91 9364 106 998.
                    </a>
                    . We're here to assist you promptly.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <Accordion defaultActiveKey={["1"]}>
              
            </Accordion>
            <Accordion defaultActiveKey={["2"]}>
              
            </Accordion>
            <Accordion defaultActiveKey={["3"]}>
            
            </Accordion>
            <Accordion defaultActiveKey={["4"]}>
              
            </Accordion> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
