import React from "react";
import Accordion from "react-bootstrap/Accordion";

function ScholarshipFaq() {
  return (
    <div>
      <div className="container d-lg-block d-none">
        <div
          align="center"
          id="faq_title"
          style={{
            fontSize: "2.75rem",
            fontWeight: "400",
            lineHeight: "3.5rem",
            color: "#222F43",
            fontFamily: "Gilroy-Bold",
            marginTop: "7.5rem",
          }}
        >
          Frequently Asked Questions
        </div>
        <div id="accordion_bg">
          <Accordion alwaysOpen={false}>
            <Accordion.Item eventKey="0" id="accordian_item">
              <Accordion.Header>
                When does the result of the test will be published?
                {/* <button>Click me</button> */}
              </Accordion.Header>
              <Accordion.Body>
                The test results will be pulished within 24hrs from the time you
                taken the test.
              </Accordion.Body>
            </Accordion.Item>{" "}
            <Accordion.Item eventKey="1" id="accordian_item">
              <Accordion.Header>
                Is there any registration fee for the scholarship test?
                {/* <button>Click me</button> */}
              </Accordion.Header>
              <Accordion.Body>
                There is no registration fee for taking the scholarship test.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" id="accordian_item">
              <Accordion.Header>
                Any eligibility does it require to apply for the scholarship
                test?
                {/* <button>Click me</button> */}
              </Accordion.Header>
              <Accordion.Body>
                Any graduate students can apply for the scholarship, *Expect BA
                & B.com
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" id="accordian_item">
              <Accordion.Header>
                What’s the quickest way to reach you out, for any queries
                regarding the scholarship?
                {/* <button>Click me</button> */}
              </Accordion.Header>
              <Accordion.Body>
                For quick queries, you can reach our support team by filling the
                "Drop us a message" form on our website contact page and after
                that you will get a call back from our team. Alternatively, you
                can email us at <a
                      href="mailto:+contact@nyinst.com"
                      className="text-decoration-none"
                      // style={{ color: "#5B6574" }}
                    >
                      contact@nyinst.com
                    </a>{" "}
                    or call our helpline at{" "}
                    <a
                      href="tel:+91 9364 106 998"
                      className="text-decoration-none"
                      // style={{ color: "#5B6574" }}
                    >
                      +91 9364 106 998
                    </a>{" "} We're here to assist you promptly.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* <Accordion defaultActiveKey={["1"]}>
              
            </Accordion>
            <Accordion defaultActiveKey={["2"]}>
              
            </Accordion>
            <Accordion defaultActiveKey={["3"]}>
            
            </Accordion>
            <Accordion defaultActiveKey={["4"]}>
              
            </Accordion> */}
        </div>
      </div>
      {/* for mobiles */}

      <div id="mobile_faq" className="d-block d-lg-none">
        <div className="container">
          <div id="mobile_faq_title_bg">
            <div id="mobile_faq_title_text">
              Frequently Asked
              <br />
              Questions
            </div>
            <div id="mobile_accordion_bg">
              <Accordion alwaysOpen={false}>
                <Accordion.Item eventKey="0" id="mobile_accordian_item">
                  <Accordion.Header>
                    When does the result of the test will be published?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    The test results will be pulished within 24hrs from the time
                    you taken the test.
                  </Accordion.Body>
                </Accordion.Item>{" "}
                <Accordion.Item eventKey="1" id="mobile_accordian_item">
                  <Accordion.Header>
                    Is there any registration fee for the scholarship test?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    There is no registration fee for taking the scholarship
                    test.
                  </Accordion.Body>
                </Accordion.Item>{" "}
                <Accordion.Item eventKey="2" id="mobile_accordian_item">
                  <Accordion.Header>
                    Any eligibility does it require to apply for the scholarship
                    test?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    Any graduate students can apply for the scholarship, *Expect
                    BA & B.com
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" id="mobile_accordian_item">
                  <Accordion.Header>
                    What’s the quickest way to reach you out, for any queries
                    regarding the scholarship?
                    {/* <button>Click me</button> */}
                  </Accordion.Header>
                  <Accordion.Body>
                    For quick queries, you can reach our support team by filling
                    the "Drop us a message" form on our website contact page and
                    after that you will get a call back from our team.
                    Alternatively, you can email us at<a
                      href="mailto:+contact@nyinst.com"
                      className="text-decoration-none"
                      // style={{ color: "#5B6574" }}
                    >
                      contact@nyinst.com
                    </a>{" "}
                    or call our helpline at{" "}
                    <a
                      href="tel:+91 9364 106 998"
                      className="text-decoration-none"
                      // style={{ color: "#5B6574" }}
                    >
                      +91 9364 106 998
                    </a>{" "}We're here to assist
                    you promptly.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <Accordion defaultActiveKey={["1"]}>
              
            </Accordion>
            <Accordion defaultActiveKey={["2"]}>
              
            </Accordion>
            <Accordion defaultActiveKey={["3"]}>
            
            </Accordion>
            <Accordion defaultActiveKey={["4"]}>
              
            </Accordion> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScholarshipFaq;
