import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import policy_hero_img from "../Assets/policy_hero_img.png";

const TermsCondition = () => {

  return (
    <div>
      <Header />
      <div style={{ background: "#065BD64D" }}>
        <div className="container text-white  mt-lg-0 mt-5  pb-lg-0 pb-3">
          <div
            className="row tablet_privacy_padding"
            style={{ paddingTop: "48px" }}
          >
            <div className="col-lg-6 d-flex">
              <div className="my-auto">
                <div
                  className="condition_policy_header"
                  style={{
                    fontFamily: "Avenir LT Std",
                    fontSize: "97px",
                    fontWeight: "900",
                    color: "#065BD6",
                    lineHeight: "124px",
                  }}
                >
                  Terms <br /> & Condition
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-lg-block d-none">
              <div>
                <img src={policy_hero_img} alt="policy hero image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ color: "#393939" }}>
        <div
          className="row "
          style={{ paddingTop: "48px", paddingBottom: "96px" }}
        >
          <div className="col-lg-12"> Welcome to the NYINST website! Before using our site, please take a
          moment to read and understand the following terms and conditions. By
          using our site, you agree to comply with and be bound by these terms.</div>
          
         
          <div className="col-lg-12">
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                1.Intellectual Property
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                All content on this site, including text, images, logos,
                graphics, and software, is the property of NYINST EDU SERVICES PVT LTD or its content
                suppliers and is protected by Indian and international copyright
                laws. You may not use, copy, reproduce, modify, distribute,
                transmit, display, publish, sell, license, or create derivative
                works based on any content on this site without our express
                written permission.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                2.User Conduct
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                You agree to use our site only for lawful purposes and in a way
                that does not infringe the rights of others, restrict or inhibit
                anyone else's use of the site, or interfere with the operation
                of the site. You may not use our site to transmit or distribute
                any content that is illegal, harmful, offensive, or
                inappropriate.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                3.Privacy Policy
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                Our privacy policy explains how we collect, use, and protect
                your personal information when you use our site. By using our
                site, you agree to the terms of our privacy policy.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                4.Disclaimer
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                The content on our site is provided for general information
                purposes only and does not constitute professional advice or
                guidance. We make no representations or warranties of any kind,
                express or implied, about the completeness, accuracy,
                reliability, suitability, or availability with respect to the
                site or the information, products, services, or related graphics
                contained on the site for any purpose. Any reliance you place on
                such information is therefore strictly at your own risk.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                5.Limitation of Liability
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                We will not be liable for any loss or damage, including without
                limitation, indirect or consequential loss or damage, or any
                loss or damage whatsoever arising from loss of data or profits
                arising out of or in connection with the use of our site.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                6.Modifications
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                We reserve the right to modify these terms and conditions at any
                time without prior notice. By using our site, you agree to be
                bound by the current version of these terms and conditions.
              </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#07152C",
                }}
              >
                7.Governing Law
              </div>
              <div
                style={{
                  fontWeight: "400",
                  paddingTop: "12px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  color: "#5B6574",
                }}
              >
                These terms and conditions shall be governed by and construed in accordance with the laws of India, without giving effect to any principles of conflicts of law. Any legal action or proceeding arising out of or related to your use of our site shall be brought exclusively in a court of competent jurisdiction in India.
              </div>
            </div>
            If you have any questions or concerns about these terms and conditions, please contact us at <a
                          href="mailto:+info@nyinst.com"
                          className="text-decoration-none"
                          style={{ color: "#79818D" }}
                        >
                          info@nyinst.com
                        </a>.
          </div>
          <div className="col-lg-2"></div>
       </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsCondition;
