import React from "react";
import sch_boy_img from "../Assets/LandingPage/sch_boy_img.png";
import mob_sch_boy_img from "../Assets/LandingPage/mob_sch_boy.png";
function Scholarship() {
  return (
    <div> 
      <div className="container d-lg-block  d-md-block d-none">
        <a href="/scholarship" className="text-decoration-none">
          <div style={{ marginTop: "7.25rem" }}>
            <img src={sch_boy_img} alt="" width="100%" />
          </div>
        </a>
      </div>
      {/* for mobile */}
      <div className="container d-lg-none d-md-none d-block">
        <a href="/scholarship" className="text-decoration-none">
          <div style={{ marginTop: "2.5rem" }}>
            <img src={mob_sch_boy_img} alt="" width="100%" />
          </div>
        </a>
      </div>
    </div>
  );
}

export default Scholarship;
