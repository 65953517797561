import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import Footer from "../Pages//Footer";
import Header from "../Pages/Header";
import block_chain_seminar_img1 from "../Assets/internship_img.jpg";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const MgrEvent = () => {
  return (
    <div>
      <Header />
      <div className="mb-5" style={{ background: "#065BD6" }}>
        <div className="container py-5">
          <div className="row container">
            <div
              className="col-lg-2 p-0 m-0 text-white text-truncate"
              style={{
                fontSize: "13px",
                lineHeight: "16px",
                whiteSpace: "no-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <a href="/events" className=" text-white">
                Meet & greet Home <AiOutlineRight />
              </a>{" "}
              NYINST organized a Seminar presentation - Dr. MGR
              Engineering College, Maduravoyal{" "}
            </div>
          </div>
          <div className="row">
            <div className=" col-lg-2 text-white"></div>
            <div className="col-lg-8 text-white ">
              <div style={{ paddingTop: "60px" }}>
                <div
                  className="d-flex"
                  style={{
                    fontSize: "13px",
                    lineHeight: "16px",
                    fontWeight: "500",
                  }}
                >
                  <div className="text-start">23 FEB 2019 </div>
                  <div
                    className="mobile_dot_position"
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      fontSize: "2rem",
                      marginTop: "-1%",
                    }}
                  >
                    .
                  </div>
                  <div> 11:00am</div>
                </div>

                <div className="text-start events_title_font_style">
                  NYINST organized a Seminar presentation - Dr. MGR
                  Engineering College, Maduravoyal
                </div>
                <div
                  className="container mobile_hero_event_height"
                  style={{ height: "15rem" }}
                >
                  <img
                    src={block_chain_seminar_img1}
                    className="w-100 "
                    style={{ bottom: "-50%" }}
                    alt="events details"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
      {/* events bottom height */}
      <div
        className="mobile_bottom_event_height"
        style={{ height: "15rem" }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="py-3 mt-lg-5 events_content_font_style">
              To provide knowledge about the current Industry expectations and
              the technological advancements to young graduates, NYINST
              organized a Seminar presentation on the topic of ‘Android
              Application Development’ at Dr. MGR Engineering College,
              Maduravoyal. We thank the entire college management for their
              support that made this event flawlessly successful. If you wish to
              learn more about the career-best IT Industry options? contact us @
              www.nyinst.com
            </div>
            {/* <div className='py-3 w-100'>
                <img src={block_chain_seminar_img2} className="w-100 img-fluid" alt="block chain seminar "/>
            </div>
            <div className='py-3 w-100'>
                <LazyLoadImage src={block_chain_seminar_img3} className="w-100 img-fluid" alt="block chain seminar "/>
            </div> */}
          </div>

          <div className="col-lg-2"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MgrEvent;
