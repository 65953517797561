import React from "react";
// import Header from "./Header";
// import { useEffect } from "react";
import "../Styles/HeroSection.css";
// import nyinst_logo from "../Assets/nyinst_logo_new.png";
import { useState } from "react";
// import hero_layer_bg from "../Assets/bg_hero_layer.png";
import two_stars_img from "../Assets/two_stars.png";
import tick_img from "../Assets/tick_img.png";
import single_star from "../Assets/single_star.png";
// import html from "../Assets/html.png";
// import css from "../Assets/css_img.png";
// import bootstrap from "../Assets/bootstrap.png";
// import js from "../Assets/js.png";
// import swift from "../Assets/swift.png";
// import react from "../Assets/react.png";
// import node from "../Assets/node.png";
// import kotlin from "../Assets/kotlin.png";
import india_img from "../Assets/india_img.png";
import hero_logo_bg from "../Assets/hero_banner_img.svg";
// import { createTheme, ThemeProvider, useTheme } from "@mui/system";
import mobile_logo_bg from "../Assets/mobile_banner_bg.svg";
import Header from "./Header";
import axios from "axios";
import qs from "qs";
import { PulseLoader } from "react-spinners";
import success_tick from "../Assets/success_tick_img.png";
import ipad_tech_circle from "../Assets/ipad_tech_circle.png";
function HeroSection(props) {
  let menu = props.menu;
  const [loading, setLoading] = useState();
  console.log(menu);
  const [activeMenu, setActiveMenu] = useState(menu);
  //  setActiveMenu(menu)

  const handleMenu = () => {
    // setActiveMenu(false);
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [year, setYear] = useState("");
  const [deg, setDeg] = useState("");
  const [status, setStatus] = useState();
  const reqData = {
    mod: "Candidate",
    actionType: "add-user-biodata",
    subAction: JSON.stringify({
      NAME: name,
      MOBILE_NUMBER: phone,
      EMAIL: email,
      YOP: year,
      DEGREE: deg,
    }),
    // EMAIL: mail,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://api.nyinst.com",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "data");

      setStatus(res.data.XSCStatus);
      setLoading(false);
      if (res.data.XSCStatus === 0) {
        setName("");
        setPhone("");
        setEmail("");
        setYear("");
        setDeg("");
      }
    });
  };

  return (
    <div className="position-relative">
      <div id="hero_section_bg" className="position-relative d-lg-block d-none ">
        {/* <img src={hero_layer_bg} width="100%" style={{ objectFit: "cover" }} /> */}
        <div>
          <div className="container">
            <div className="" id="hero_contents">
              <div className=" d-flex " id="hero_content_bg">
                <div className="p-0" id="left_content">
                  <div id="star_img" className="ps-4">
                    <img src={two_stars_img} alt="two_stars" />
                  </div>
                  <div id="hero_text">
                    Transforming Ambition <br /> Into{" "}
                    <span id="expertise_text">Expertise</span>{" "}
                  </div>
                  <div id="hero_sec_text">
                    Code your Future - Shape Your Success
                  </div>
                  <div id="hero_points">
                    <div
                      className="d-flex align-items-center"
                      id="hero_single_point"
                    >
                      <div>
                        <img src={tick_img} alt="" />
                      </div>
                      <div>Top Industry Expert</div>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      id="hero_single_point"
                    >
                      <div>
                        <img src={tick_img} alt="" />
                      </div>
                      <div>Certificate with Experience Letter</div>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      id="hero_single_point"
                    >
                      <div>
                        <img src={tick_img} alt="" />
                      </div>
                      <div>Best Salary in industry</div>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      id="hero_single_point"
                    >
                      <div>
                        <img src={tick_img} alt="" />
                      </div>
                      <div>Work On Live Project</div>
                    </div>
                  </div>
                  <div id="single_star" align="center" className="">
                    <img src={single_star} alt="" />
                  </div>
                </div>
                <div className=" position-relative" align="">
                  <div id="hero_logo_bg">
                    <img src={hero_logo_bg} width="100%" className="d-lg-block d-none d-md-none"/>
                    <div id="hero_register_form">
                      <div id="hero_form">
                        {" "}
                        <div className="position-relative">
                          <div id="hero_form_topic" className="">
                            Talk to our <br /> program Manager
                          </div>
                          <div
                            id="bg_yellow"
                            className="position-absolute ps-2 "
                          ></div>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="Name"
                              id="Name"
                              className=""
                              value={name}
                              onChange={(e) => setName(e.target.value.trimStart())}
                              type="text"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              htmlFor="Name"
                              className="position-absolute start-0 top-0 input-label"
                            >
                              Name
                            </label>
                          </div>
                          <div
                            className="position-relative"
                            id="name_input_field"
                          >
                            <input
                              name="email"
                              id="email"
                              className={email.trim() !== "" ? "has_value" :"no_value" }
                              onChange={(e) => setEmail(e.target.value.toLowerCase().trimStart())}
                              value={email}
                              type="email"
                              // value={collegeName}
                              // placeholder="College/University Name"

                              required
                            />
                            <label
                              className="position-absolute start-0 top-0 input-label"
                              htmlFor="email"
                            >
                              Email
                            </label>
                          </div>

                          <div id="name_input_field">
                            <div className="row">
                              <div className="col-3 pe-0 me-0">
                                <div id="india_num">
                                  <img src={india_img} alt="" />
                                  <span className="ps-1">+91</span>
                                </div>
                              </div>
                              <div className="col-9 ps-0">
                                <div
                                  className="position-relative phone_number"
                                  id="name_input_field"
                                >
                                  <input
                                    name="phone_number"
                                    id="phone_number"
                                    className={phone.trim() !== "" ? "has_value" :"no_value" }
                                    value={phone}
                                    maxLength={10}
                                    pattern="[0-9]{10}"
                                    onChange={(e) =>
                                      setPhone(
                                        e.target.value.replace(/\D/g, "")
                                      )
                                    }
                                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                                    // onChange={(e) =>
                                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                                    // }
                                    type="text"
                                    // value={collegeName}
                                    // placeholder="College/University Name"

                                    required
                                  />
                                  <label
                                    htmlFor="phone_number"
                                    className="position-absolute start-0 top-0  input-label"
                                  >
                                    Phone Number
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div id="name_input_field">
                            <div className="row">
                              <div className="col-6">
                                <div
                                  className="position-relative"
                                  id="name_input_field"
                                >
                                  <input
                                    name="graduation_year"
                                    id="graduation_year"
                                    className={year.trim() !== "" ? "has_value" :"no_value" }
                                    value={year}
                                    maxLength={4}
                                    pattern="[0-9]{4}"
                                    onChange={(e) =>
                                      setYear(e.target.value.replace(/\D/g, ""))
                                    }
                                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                                    // onChange={(e) =>
                                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                                    // }
                                    type="text"
                                    // value={collegeName}
                                    // placeholder="College/University Name"

                                    required
                                  />
                                  <label
                                    htmlFor="graduation_year"
                                    className="position-absolute start-0 top-0 input-label"
                                  >
                                    Graduation Year
                                  </label>
                                </div>
                              </div>
                              <div className="col-6 ps-0">
                                <div
                                  className="position-relative"
                                  id="name_input_field"
                                >
                                  <input
                                    name="Degree"
                                    id="Degree"
                                    className=""
                                    value={deg}
                                    onChange={(e) => setDeg(e.target.value.trimStart())}
                                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                                    // onChange={(e) =>
                                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                                    // }
                                    type="text"
                                    // value={collegeName}
                                    // placeholder="College/University Name"

                                    required
                                  />
                                  <label
                                    htmlFor="Degree"
                                    className="position-absolute start-0 top-0  input-label"
                                  >
                                    Degree
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="border_bottom" className=""></div>

                          <div id="form_submit_btn">
                            {status === 0 ? (
                              <button
                                className="btn"
                                type="submit"
                                style={{
                                  height: "3.25rem",
                                  background: "#02EE6A14",
                                  border: "2px solid #02EE6A",
                                  color: "#02EE6A",
                                  fontSize: "0.8rem",
                                  lineHeight: "2rem",
                                }}
                              >
                                <img
                                  src={success_tick}
                                  alt=""
                                  style={{ height: "20px", width: "20px" }}
                                />{" "}
                                Thanks For Submitting!
                              </button>
                            ) : (
                              <button className="btn" type="submit">
                                Submit{" "}
                                {loading ? (
                                  <PulseLoader color="white" size={6} />
                                ) : (
                                  ""
                                )}
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* For Mobile */}

      <div className="d-block d-lg-none z-0" id="hero_section_bg">
        <div className="container">
          <div>
            {/* <div id="mobile_star_img">
              <img src={two_stars_img} alt="two_stars" />
            </div> */}
            <div id="mobile_hero_content">
              <div id="mobile_star_img">
                {" "}
                <img src={two_stars_img} alt="two_stars" />
              </div>
              <span id="mobile_hero_text" className="mt-5">
                {" "}
                Transforming Ambition Into
              </span>{" "}
              <span id="mobile_expertise_text">Expertise</span>
            </div>
            <div id="hero_sec_text">Code your Future - Shape Your Success</div>
            <div id="mobile_hero_points">
              <div id="mobile_single_point">
                <div>
                  <img src={tick_img} />
                </div>
                <div id="mobile_text">Top Industry Expert</div>
              </div>
              <div id="mobile_single_point">
                <div>
                  <img src={tick_img} />
                </div>
                <div id="mobile_text">Certificate with Experience Letter</div>
              </div>
              <div id="mobile_single_point">
                <div>
                  <img src={tick_img} />
                </div>
                <div id="mobile_text">Best Salary in industry</div>
              </div>
              <div id="mobile_single_point">
                <div>
                  <img src={tick_img} />
                </div>
                <div id="mobile_text">Work On Live Project</div>
              </div>
            </div>
            {/* <div id="single_star" align="end" className="pe-5">
              <img src={single_star} alt="single_star" />
            </div> */}
          </div>
        </div>
        <div id="mobile_banner">
          <img src={mobile_logo_bg} alt="" className="d-md-none d-block d-lg-none"/>
          <img src={ipad_tech_circle} width="100%" className="d-md-block d-none d-lg-none"/>

        </div>
        <div className="container">
          <div id="form_bg_shadow"></div>
          <div id="mobile_register_form">
            <div className="position-relative">
              <div id="form_title">
                Talk to Our
                <br /> Program Manager
              </div>
              <div
                className="position-absolute ps-2"
                style={{
                  height: "0.75rem",
                  width: "5.625rem",
                  // right: "39.5vw"
                  marginLeft: "5.31rem",
                  bottom: "0.2rem",
                  backgroundColor: "#F0C52B",
                }}
              ></div>
            </div>

            <div>
              <form className="position-relative" onSubmit={handleSubmit}>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="mobile_name"
                    id="mobile_name"
                    className="px-2"
                    value={name}
                    onChange={(e) => setName(e.target.value.trimStart())}
                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                    // onChange={(e) =>
                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                    // }
                    type="text"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    htmlFor="mobile_name"
                    className="position-absolute start-0 top-0 input-label"
                  >
                    Name
                  </label>
                </div>
                <div className="position-relative" id="name_input_field">
                  <input
                    name="mobile_email"
                    id="mobile_email"
                    // className="px-2"
                    className={email.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase().trimStart())}
                    // onChange={(e) => setCollege({ collegeName: e.target.value })}
                    // onChange={(e) =>
                    //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                    // }
                    type="email"
                    // value={collegeName}
                    // placeholder="College/University Name"

                    required
                  />
                  <label
                    className="position-absolute start-0 top-0 input-label"
                    htmlFor="mobile_email"
                  >
                    Email
                  </label>
                </div>
                <div id="name_input_field">
                  <div className="row">
                    <div className="col-3">
                      <div id="india_num">
                        <img src={india_img} alt="" />
                        <span className="" style={{ paddingLeft: "0.25rem" }}>
                          +91
                        </span>
                      </div>
                    </div>
                    <div className="col-9 ps-0 ms-0">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="mobile_phone_number"
                          id="mobile_phone_number"
                          // className="px-2"
                          className={phone.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                          value={phone}
                          onChange={(e) =>
                            setPhone(e.target.value.replace(/\D/g, ""))
                          }
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          maxLength={10}
                          type="phone"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="mobile_phone_number"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Phone Number
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="name_input_field">
                  <div className="row">
                    <div className="col-6">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="mobile_year"
                          id="mobile_year"
                          // className="px-2"
                          className={year.trim() !== "" ? "has_value px-2" :"no_value px-2" }
                          value={year}
                          onChange={(e) => setYear(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="mobile_year"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Graduation Year
                        </label>
                      </div>
                    </div>
                    <div className="col-6 ps-0">
                      <div className="position-relative" id="name_input_field">
                        <input
                          name="mobile_degree"
                          id="mobile_degree"
                          className="px-2"
                          value={deg}
                          onChange={(e) => setDeg(e.target.value.trimStart())}
                          // onChange={(e) => setCollege({ collegeName: e.target.value })}
                          // onChange={(e) =>
                          //   setCollegeName(e.target.value.replace(/[^ \w\s]/gi, ""))
                          // }
                          type="text"
                          // value={collegeName}
                          // placeholder="College/University Name"

                          required
                        />
                        <label
                          htmlFor="mobile_degree"
                          className="position-absolute start-0 top-0 input-label"
                        >
                          Degree
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="mobile_border_bottom" className=""></div>

                <div id="mobile_form_submit_btn">
                  {status === 0 ? (
                    <button
                      className="btn"
                      type="submit"
                      style={{
                        height: "3.25rem",
                        background: "#02EE6A14",
                        border: "2px solid #02EE6A",
                        color: "#02EE6A",
                        fontSize: "0.8rem",
                        lineHeight: "2rem",
                      }}
                    >
                      <img
                        src={success_tick}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />{" "}
                      Thanks For Submitting!
                    </button>
                  ) : (
                    <button className="btn" type="submit">
                      Submit{" "}
                      {loading ? <PulseLoader color="white" size={6} /> : ""}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
