import React from "react";
import Footer from "../Pages/Footer";
import Header from "../Pages/Header";
import brud_crumbs_arrow_icon from "../Assets/brudcrums_arrow_icon.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import big_data_blog_img from "../Assets/big_data_blog_img.avif";
const BigDataBlogDetails = () => {
  return (
    <div>
      <Header />
      <div className="py-3" style={{ background: "#FAFAFB" }}>
        <div className="container">
          <div className="d-flex align-items-center mt-5 ">
            <div className="d-flex mt-5 align-items-center">
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  color: "#065BD6",
                  lineHeight: "16px",
                }}
              >
               <a href="/ezine" className="text-decoration-none">EZine</a> 
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <LazyLoadImage src={brud_crumbs_arrow_icon} width="100%"></LazyLoadImage>
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "16px",
                  color: "#07152C",
                }}
              >
                {" "}
                Big Data...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex py-3 mt-5">
          <div className="">
            <div
              className=""
              style={{
                color: "white",
                fontSize: "13px",
                fontWeight: "600",
                lineHeight: "16px",
                background: "#07152C",
                opacity: "100%",
                bottom: "12px",
                left: "20px",
                Zindex: "3",
                padding: "4px 12px",
                borderRadius: "12px",
              }}
            >
              Technology
            </div>
          </div>
          <div
            className="d-flex my-auto px-2"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "#6A7380",
            }}
          >
            <div>12 NOV 2022</div>
            <div
              style={{
                paddingLeft: "8px",
                fontSize: "2rem",
                marginTop: "-5%",
              }}
            >
              .
            </div>
            <div style={{ paddingLeft: "8px" }}>5 MIN READ</div>
          </div>
        </div>
        <div className="row mobile_width">
          <div className="col-lg-9">
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "50px",
                  lineHeight: "60px",
                  marginTop: "36px",
                }}
              >
                Big Data created a huge buzz in the tech-driven world
              </div>
            </div>
            <div className="mt-5">
              <LazyLoadImage
                src={big_data_blog_img}
                className="w-100"
                alt="blog details image"
              />
            </div>
            <div className="py-3"></div>
            <div
              className="py-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Present in both structured and unstructured forms, big data
              analytics involves the evaluation of a large chunk of information,
              and its conclusions utilized for higher implementations. The
              overall importance of processes uses, and major functionalities
              cannot be denied. Big Data created a huge buzz in the tech-driven
              world and in August 2015, even slipped out of the Gartner’s 2015
              Hype Cycle for Emerging Technologies.{" "}
            </div>
            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                What is Big Data(BD)?
              </div>
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Gartner defines BD as high volume, high velocity, and/or
              high-variety information assets that require processing to enable
              decision making. Insight discovery and process optimization are
              two of the most important aspects of BD Analysis.{" "}
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              This simply means, extremely BD sets are collected and analyzed –
              this because, normal computing techniques cannot perform the
              process. It involves a range of frameworks, techniques, and tools
              particularly due to the advent of newer channels of communication,
              which can be a challenge to industry players. BD Analytics is one
              of the best ways to overcome this difficulty. The entire world is
              producing billions of gigabytes of data by the minute. When
              processed, this data is extremely useful. But before the concept
              of big data even evolved, it had been nothing but gross neglect.{" "}
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Learn all about BD and Analytics from NYINST. Consider a
              professional training and certification to upgrade you.{" "}
            </div>

            <div
              className="mt-3"
              style={{
                fontWeight: "700",
                fontSize: "31px",
                lineHeight: "40px",
              }}
            >
              Primary sources of Big Data{" "}
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Data is owned in several forms including the Black Box embedded in
              airplanes and jets. The voices of the flight crew, microphones and
              aircraft performance are recorded for future analysis. Similarly,
              there are other sources of analyzable big data such as:
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Social Media – Sites such as Face book, Twitter and Google+ Stock
              Exchange – Platforms used to sell and buy shares Power Grid – Data
              and information about nodes Transportation – Model of the vehicle,
              it’s capacity, distance covered, availability Search Engines – One
              of the biggest sources of online data
            </div>

            <div className="mt-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "31px",
                  lineHeight: "40px",
                }}
              >
                Merits of Big Data
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Consumer demands, today, have a wider research area. Most of
                them reach out to social media for different buying options.
                Even then-contemporary consumerism is reciprocal where buyers
                look forward to being glorified.
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                BD plays a pivotal role in collecting information that is
                actionable and analyzable, with the results leading you to
                customer engagement in real-time.
              </div>
              <div
                className="mt-3 mobile_para_font"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "36px",
                  color: "#404B5D",
                }}
              >
                Check your customers’ profile in real-time, get information
                about their likes and dislikes to provide better service
              </div>
            </div>
            <div className="mt-5"
              style={{
                fontWeight: "700",
                fontSize: "31px",
                lineHeight: "40px",
              }}
            >
              Build brand and company reputation with BD analytics
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Re-develop products and services to design them according to
              predictive intelligence gathered through BD Obtain product
              development tips through unstructured social networking
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Test variations of computer-aided design or CAD images to
              determine the changes in the product, thus making big data
              invaluable in the manufacturing process
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Predictive analytics always gives you an edge and lets you stay
              ahead of your competitors
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              BD scans social media feeds and analyzes all information present
              in different areas
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Perform health test on your suppliers, stakeholders, and customers
              and reduce default risks
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Additionally, BD aids in keeping information safe. Map the data
              landscape of the company using BD tools to analyze internal
              threats. You can protect your sensitive information.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Diversify revenue streams; gather information about trending data
              to evolve a new revenue stream.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Personalize the look and feel of your website to suit visitors
              based on their preferences (analyzed through BD) Replace the
              pieces of technology with the help of BD if you are running a
              factory, based on the duration of their use. Different parts may
              come with varied wear and tear rates. Use big data analysis to
              predict and then arrange for replacements without having to wait
              for failures.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              The best career move ever!
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              There is a constantly rising demand for BD analytics
              professionals. According to Jeanne Harris, a senior executive at
              Accenture Institute for High Performance “…data is useless without
              the skill to analyze it.” There are many IT professionals today,
              who are willing to invest their time and money for training in big
              data. Job opportunities are also simultaneously soaring.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Gaps are widening due to the high demand for big data
              professionals and the parallel deficit of talent. The situation is
              global and in spite of its augmenting demand, there are several
              unfulfilled jobs across the world. Shortage of skills is one of
              the biggest reasons for the scarcity.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Institute helps you gain in-depth knowledge of Data Science.
              Enroll into our comprehensive GTM course to bridge the gap
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Organizations are prioritizing BD analytics. Conclusively,
              companies do believe that BD application, improves the overall
              performance of the organization. According to a survey, at least
              45% believed that they received more precise business insights
              while 38% would be able to recognize market opportunities. About
              60% thought they could depend on BD analytics to boost their
              social media presence. Lastly, about 65.2% thought that big data
              helped them in some way or the other
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              The adoption of big data is growing, and diversified datasets are
              analyzed with the help of sophisticated big data analytics’ tools.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              To TDWIor The Data Warehousing Institute, some form of advanced
              analytics on Data was used by the respondents either for
              predictive analytics, business intelligence or data mining. With
              the rate of implementation of analytics a tool increasing
              exponentially, BD is providing companies with an edge over the
              competition.
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Apache Hadoop Framework is considered as one of the most popular
              BD analytics tools today
            </div>
            <div
              className="mt-3 mobile_para_font"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Also plays a pivotal role in decision making. This is also a
              competitive resource for many companies. Most respondents to the
              ‘Analytics Advantage’ survey overseen by Tom Davenport indicated
              that BD will become very important for their organizations in the
              next three years.
            </div>

            <div
              className="mt-3 mobile_para_font mb-5"
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "36px",
                color: "#404B5D",
              }}
            >
              Data analytics is everywhere and has tremendous potential for
              growth. Become a certified professional when you attend NYINST.
            </div>
          </div>
          {/* <div className='col-md-3'>Enquiry Now</div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BigDataBlogDetails;
